import { takeLatest, call, put } from "redux-saga/effects";

import DashboardActiontypes from "./DashboardActiontypes";

import { axiosGet, axiosPost } from "../../Utils/axios";

import { unauthorizedMessage } from "../Login/LoginActions";
import { getDashboardResponse } from "./DashboardActions";

// WORKER SAGA

export function* onGetDashboardReqAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("dashboard", payload).then((response) => response?.data)
    );
    yield put(getDashboardResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* dashboardWatcherSaga() {
  // dashboard
  yield takeLatest(
    DashboardActiontypes.DASHBOARD_REQUEST,
    onGetDashboardReqAsync
  );
}
