import { combineReducers } from "@reduxjs/toolkit";
import LoginReducer from "./Login/LoginReducer";
import ProjectReducer from "./ProjectSaga/ProjectReducer";
import RegisterReducer from "./Register/RegisterReducer";
import inviteReducer from "./Invite/InviteReducer";
import BuildReducer from "./BuildSaga/BuildReducer";
import ReleaseReducer from "./ReleaseSaga/ReleaseReducer";
import NotificationReducer from "./NotificationSaga/NotificationReducer";
import RequirementReducer from "./RequirementSaga/RequirementReducer";
import RequirementCommentReducer from "./RequirementSaga/RequirementCommentSaga/RequirementCommentReducer";
import TestDesignReducer from "./TestDesignSaga/TestDesignReducer";
import TestDesignCommentReducer from "./TestDesignSaga/TestDesignCommentSaga/TestDesignCommentReducer";
import TestExecutionReducer from "./TestExecution/TestExecutionReducer";
import DefectReducer from "./DefectsSaga/DefectsReducer";
import DashboardReducer from "./DashboardSaga/DashboardReducer";
import ReportReducer from "./ReportSaga/ReportReducer";
import ColumnReducer from "./ExportcolumnSaga/ExportReducer";
import integrationReducer from "./IntegrationSaga/IntegrationReducer";
import TaskReducer from "./TaskSaga/TaskReducer";

const rootReducer = combineReducers({
  // LOGIN
  loginState: LoginReducer,

  // REGISTER
  register: RegisterReducer,

  // INVITE USER
  invite: inviteReducer,

  // PROJECT
  project: ProjectReducer,

  // BUILD
  build: BuildReducer,

  // RELEASE
  release: ReleaseReducer,

  // NOTIFICATION
  notification: NotificationReducer,

  // REQUIREMENT
  requirement: RequirementReducer,

  // REQUIREMENT COMMENT
  requirementcomment: RequirementCommentReducer,

  // TESTDESIGN
  testDesign: TestDesignReducer,

  // TESTDESIGN COMMENT
  testDesignComment: TestDesignCommentReducer,

  // TEST EXECUTION
  testExecution: TestExecutionReducer,

  // DEFECT
  defect: DefectReducer,

  // DASHBOARD
  dashboard: DashboardReducer,

  // REPORT
  report: ReportReducer,

  //exportcolumn
  column: ColumnReducer,

  //INTEGRATION
  integrate: integrationReducer,

  // TASK
  task: TaskReducer,
});

export default rootReducer;
