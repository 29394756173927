import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ExpandMore,
  ArrowDropDown,
  ArrowDropUp,
  Close,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import {
  deleteRequirementDetailRequest,
  deleteRequirementDetailResponseClear,
  deleteRequirementModuleRequest,
  deleteRequirementModuleResponseClear,
  getRequireDetailAttachmentIndexRequest,
  getRequirementDetailIndexRequest,
  getRequirementModuleIndexRequest,
  postRequirementModuleRequest,
  postRequirementModuleResponseClear,
  showRequirementDetailRequest,
  showRequirementModuleRequest,
  showRequirementModuleResponseClear,
  updateRequirementModuleRequest,
} from "../../../Redux/RequirementSaga/RequirementActions";
import RequirementDetails from "./RequirementDetails";
import CustomInput from "../../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton/CustomButton";
import {
  handleContextMenu,
  handleClose,
  DeleteContext,
} from "../../../Utils/handles";
import "./Requirement.css";
import toast from "react-hot-toast";
import DeleteIndex from "../../DeletePopup/DeleteIndex";
import {
  COLOR_VECTOR_ICON,
  UPVECTOR_ICON,
  UPVECTOR_COLOR_ICON,
} from "../../../Utils/imageConstants";
import { Menu } from "@mui/icons-material";

const RequirementModules = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleReleases, setVisibleReleases] = useState({});
  const [currentImage, setCurrentImage] = useState("release");
  const [isEditing, setIsEditing] = useState(false);
  const [requirementId, setRequirementId] = useState("");
  const [reset, setReset] = useState(false);
  const [type, setType] = useState("");
  const [activeTab, setActiveTab] = useState("details");

  const {
    deleteRequirementModuleResponse,
    postRequirementModuleResponse,
    deleteRequirementModuleLoading,
    postRequirementModuleLoading,
    requirementModuleList,
    requirementModule,
    requirementDetailList,
    deleteRequirementDetailResponse,
    postRequirementDetailLoading,
    requirementDetail,
    deleteRequirementDetailLoading,
  } = useSelector((state) => state.requirement);

  const Validation = Yup.object().shape({
    name: Yup.string()
      .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces alone")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-_=+{}\[\]\n?">]+)$/,
        "Module name should contain characters too"
      )
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .trim()
      .required("Module Name is required!"),
    description: Yup.string().matches(
      /^\s*\S[\s\S]*$/g,
      "Should not contain spaces alone"
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "Module",
      description: "",
      project_fk: id,
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      if (type === "edit") {
        dispatch(updateRequirementModuleRequest(values));
      } else {
        dispatch(postRequirementModuleRequest(values));
      }
    },
  });

  // FUNCTION TO TOGGLE VISIBILITY FOR SPECIFIC MODULE
  const toggleReleaseVisibility = (moduleId) => {
    setVisibleReleases((prev) => (prev === moduleId ? false : moduleId));

    // TO GET REQUIREMENT FOR PARTICULAR MODULE
    dispatch(
      getRequirementDetailIndexRequest({
        filters: {
          module_fk: {
            type: "exact",
            value: moduleId,
          },
        },
      })
    );
  };

  useEffect(() => {
    if (postRequirementDetailLoading) return;
    if (!visibleReleases) return;
    dispatch(
      getRequirementDetailIndexRequest({
        filters: {
          module_fk: {
            type: "exact",
            value: visibleReleases,
          },
        },
      })
    );
  }, [postRequirementDetailLoading]);

  // AFTER DELETE OR CREATE AGAIN GETTING THE REQUIREMENTS
  useEffect(() => {
    if (!deleteRequirementDetailResponse) return;
    if (
      deleteRequirementDetailResponse?.message &&
      deleteRequirementDetailResponse?.success
    ) {
      dispatch(
        getRequirementDetailIndexRequest({
          filters: {
            module_fk: {
              type: "exact",
              value: anchorEl?.release_id,
            },
          },
        })
      );
    }
  }, [deleteRequirementDetailResponse]);

  //  TO SET FORMIK VALUES WHILE EDITING FOR MODULE
  useEffect(() => {
    if (type === "edit" && requirementModule) {
      for (let key in formik.values) {
        formik.setFieldValue(key, requirementModule[key]);
      }
      formik.setFieldValue("id", requirementModule?.id);
    }
    return () => {
      dispatch(showRequirementModuleResponseClear());
    };
  }, [requirementModule]);

  // TO GET ALL REQUIREMENT MODULES ON DELETE,POST,UPDATE
  useEffect(() => {
    if (deleteRequirementModuleLoading || postRequirementModuleLoading) return;
    dispatch(
      getRequirementModuleIndexRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, [deleteRequirementModuleLoading, postRequirementModuleLoading]);

  // TOAST MESSAGE FOR POST AND UPDATE REQUIREMENT MODULE
  useEffect(() => {
    if (!postRequirementModuleResponse) return;
    if (postRequirementModuleResponse?.success) {
      toast.success(postRequirementModuleResponse?.message);
      if (type !== "edit") {
        formik.resetForm();
      }
    } else {
      toast.error(postRequirementModuleResponse?.message);
    }
    return () => {
      dispatch(postRequirementModuleResponseClear());
    };
  }, [postRequirementModuleResponse]);

  // TOAST MESSAGE FOR DELETE REQUIREMENT MODULE
  useEffect(() => {
    if (!deleteRequirementModuleResponse) return;
    if (deleteRequirementModuleResponse?.success) {
      toast.success(deleteRequirementModuleResponse?.message);
      formik.resetForm();
      setType("");
      handleClose(setAnchorEl);
    } else {
      toast.error(deleteRequirementModuleResponse?.message);
    }
    return () => {
      dispatch(deleteRequirementModuleResponseClear());
    };
  }, [deleteRequirementModuleResponse]);

  // TOAST MESSAGE FOR DELETE REQUIREMENT DETAIL
  useEffect(() => {
    if (!deleteRequirementDetailResponse) return;
    if (deleteRequirementDetailResponse?.success) {
      toast.success(deleteRequirementDetailResponse?.message);
      if (type !== "edit") {
        formik.resetForm();
      }
    } else {
      toast.error(deleteRequirementDetailResponse?.message);
    }
    handleClose(setAnchorEl);
    return () => {
      dispatch(deleteRequirementDetailResponseClear());
    };
  }, [deleteRequirementDetailResponse]);

  const handleResetRequirement = () => {
    setCurrentImage("build");
    setType("");
    setReset(true);
    setActiveTab("details");
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="tests_layout">
      {/* SIDEBAR CONTENT */}
      <div className="new_sidebar new_sidebar_hide">
        <div className="side_layout">
          {/* MODULE */}
          <div
            className="sidebar_plan"
            onKeyDown={() => {}}
            onClick={() => {
              setCurrentImage("release");
              setType("");
              formik.resetForm();
            }}
          >
            <img
              src={
                currentImage === "release" ? COLOR_VECTOR_ICON : UPVECTOR_ICON
              }
              alt="releaseicon"
            />
            <p className={currentImage === "release" ? "colorRelease" : ""}>
              Module
            </p>
          </div>
          {/* BUILD */}
          <div
            className="sidebar_plan"
            onClick={handleResetRequirement}
            onKeyDown={() => {}}
          >
            <img
              src={
                currentImage === "build" ? UPVECTOR_COLOR_ICON : UPVECTOR_ICON
              }
              alt="buildicon"
            />
            <p className={currentImage === "build" ? "colorRelease" : ""}>
              Requirement
            </p>
          </div>
        </div>

        {(currentImage === "release" || "build") && (
          <>
            {requirementModuleList?.data?.map((module) => (
              <>
                <div key={module?.id}>
                  <div
                    className={`d-flex mt-3 sub_drop align-items-center ${
                      openDrawer && "draweropenMargin"
                    } `}
                    onKeyDown={() => {}}
                    onClick={() => {
                      toggleReleaseVisibility(module?.id);
                      dispatch(showRequirementModuleRequest(module?.id));
                      setType("edit");
                      setCurrentImage("release");
                    }}
                    onContextMenu={(e) =>
                      handleContextMenu(e, module?.id, "release", setAnchorEl)
                    }
                  >
                    {visibleReleases === module?.id ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                    <p>{module?.name}</p>
                  </div>
                  {visibleReleases === module?.id &&
                    requirementDetailList?.data?.map((build, index) => (
                      <div className="px-4" key={index}>
                        <p
                          className="list"
                          onKeyDown={() => {}}
                          onClick={() => {
                            dispatch(showRequirementDetailRequest(build?.id));
                            setCurrentImage("build");
                            setRequirementId(build?.id);
                            setType("edit");
                            setReset(false);
                            dispatch(
                              getRequireDetailAttachmentIndexRequest({
                                filters: {
                                  requirement_fk: {
                                    type: "exact",
                                    value: build?.id,
                                  },
                                },
                              })
                            );
                          }}
                          onContextMenu={(e) =>
                            handleContextMenu(
                              e,
                              build?.id,
                              "build",
                              setAnchorEl,
                              module?.id
                            )
                          }
                        >
                          {build?.name}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            ))}
            <DeleteContext
              anchorEl={anchorEl?.anchor}
              onClose={() => handleClose(setAnchorEl)}
              name={anchorEl?.type === "release" ? "Module" : "Requirement"}
              action={
                anchorEl?.type === "release"
                  ? deleteRequirementModuleRequest
                  : deleteRequirementDetailRequest
              }
              res={
                anchorEl?.type === "release"
                  ? deleteRequirementModuleResponse
                  : deleteRequirementDetailResponse
              }
              payload={anchorEl?.id}
              loading={
                anchorEl?.type === "release"
                  ? deleteRequirementModuleLoading
                  : deleteRequirementDetailLoading
              }
            />
          </>
        )}
      </div>
      <div className="side_modal bg-white">
        {!openDrawer && (
          <Menu
            className="clickable"
            onClick={() => setOpenDrawer(!openDrawer)}
          />
        )}
        <div className={openDrawer ? "sidenavopen" : "sideNav"}>
          <Close
            sx={{ display: openDrawer ? "unset" : "none" }}
            className="close_sidenav clickable"
            onClick={() => setOpenDrawer(false)}
          />
          <div
            className="new_sidebar new_sidebar_hide"
            style={{ display: openDrawer ? "unset" : "none" }}
          >
            <div className={`side_layout ${openDrawer && "draweropenMargin"}`}>
              {/* MODULE */}
              <div
                className="sidebar_plan"
                onKeyDown={() => {}}
                onClick={() => {
                  setCurrentImage("release");
                  setType("");
                  formik.resetForm();
                }}
              >
                <img
                  src={
                    currentImage === "release"
                      ? COLOR_VECTOR_ICON
                      : UPVECTOR_ICON
                  }
                  alt="releaseicon"
                />
                <p className={currentImage === "release" ? "colorRelease" : ""}>
                  Module
                </p>
              </div>
              {/* BUILD */}
              <div
                className="sidebar_plan"
                onClick={handleResetRequirement}
                onKeyDown={() => {}}
              >
                <img
                  src={
                    currentImage === "build"
                      ? UPVECTOR_COLOR_ICON
                      : UPVECTOR_ICON
                  }
                  alt="buildicon"
                />
                <p className={currentImage === "build" ? "colorRelease" : ""}>
                  Requirement
                </p>
              </div>
            </div>

            {(currentImage === "release" || "build") && (
              <>
                {requirementModuleList?.data?.map((module) => (
                  <>
                    <div key={module?.id}>
                      <div
                        className={`d-flex mt-3 sub_drop align-items-center ${
                          openDrawer && "draweropenMargin"
                        } `}
                        onKeyDown={() => {}}
                        onClick={() => {
                          toggleReleaseVisibility(module?.id);
                          dispatch(showRequirementModuleRequest(module?.id));
                          setType("edit");
                          setCurrentImage("release");
                        }}
                        onContextMenu={(e) =>
                          handleContextMenu(
                            e,
                            module?.id,
                            "release",
                            setAnchorEl
                          )
                        }
                      >
                        {visibleReleases === module?.id ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                        <p>{module?.name}</p>
                      </div>
                      {visibleReleases === module?.id &&
                        requirementDetailList?.data?.map((build, index) => (
                          <div
                            className={`px-4  ${
                              openDrawer && "draweropenMargin"
                            }`}
                            key={index}
                          >
                            <p
                              className="list"
                              onKeyDown={() => {}}
                              onClick={() => {
                                dispatch(
                                  showRequirementDetailRequest(build?.id)
                                );
                                setCurrentImage("build");
                                setRequirementId(build?.id);
                                setType("edit");
                                setReset(false);
                                dispatch(
                                  getRequireDetailAttachmentIndexRequest({
                                    filters: {
                                      requirement_fk: {
                                        type: "exact",
                                        value: build?.id,
                                      },
                                    },
                                  })
                                );
                              }}
                              onContextMenu={(e) =>
                                handleContextMenu(
                                  e,
                                  build?.id,
                                  "build",
                                  setAnchorEl,
                                  module?.id
                                )
                              }
                            >
                              {build?.name}
                            </p>
                          </div>
                        ))}
                    </div>
                  </>
                ))}
                <DeleteContext
                  anchorEl={anchorEl?.anchor}
                  onClose={() => handleClose(setAnchorEl)}
                  name={anchorEl?.type === "release" ? "Module" : "Requirement"}
                  action={
                    anchorEl?.type === "release"
                      ? deleteRequirementModuleRequest
                      : deleteRequirementDetailRequest
                  }
                  res={
                    anchorEl?.type === "release"
                      ? deleteRequirementModuleResponse
                      : deleteRequirementDetailResponse
                  }
                  payload={anchorEl?.id}
                  loading={
                    anchorEl?.type === "release"
                      ? deleteRequirementModuleLoading
                      : deleteRequirementDetailLoading
                  }
                />
              </>
            )}
          </div>
        </div>
        {/* <NewSideBar
          style={{ marginLeft: openDrawer ? "250px" : "-250px" }}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        > */}

        {/* </NewSideBar> */}
      </div>
      {/* RIGHT SIDE CONTENT */}
      {currentImage === "release" ? (
        <div className="w-100 bg-white releaseLayout">
          <form onSubmit={formik.handleSubmit}>
            <div className="position-relative">
              <div
                className={
                  isEditing ? "test_header2" : "test_header test_header_hover"
                }
                onKeyDown={() => {}}
                onClick={() => setIsEditing(true)}
              >
                {isEditing ? (
                  <CustomInput
                    type="text"
                    name="name"
                    size="large"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      if (formik.values.name) {
                        formik.handleBlur(e);
                        setIsEditing(false);
                      }
                    }}
                    value={formik.values.name}
                    formik={formik}
                  />
                ) : (
                  <>
                    <h3>{formik.values.name}</h3>
                  </>
                )}
              </div>
              {formik.errors.name && !isEditing ? (
                <p className="CustErr">{formik.errors.name}</p>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-12 mt-4">
              <CustomInput
                multiline
                rows={4}
                placeholder="Description"
                type="text"
                name="description"
                size="large"
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                formik={formik}
              />
            </div>
            {type === "edit" && (
              <div className="accordian_container mt-4">
                <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3>Statistics</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="requirementStatistic">
                      <div className="text-center statCont">
                        <p className="number">
                          {requirementDetailList?.data?.length}
                        </p>
                        <p className="subTag">Requirements</p>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
            <div className="popformbutton">
              <CustomButton
                size="small"
                label="Cancel"
                btnType="secondary"
                onKeyDown={() => {}}
                onClick={() => navigate(`project/${id}/dashboard`)}
              />
              <CustomButton
                size="small"
                type="submit"
                label={type === "edit" ? "Update" : "Save"}
              />
            </div>
          </form>
        </div>
      ) : (
        <RequirementDetails
          types={type}
          reset={reset}
          requirementId={requirementId}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          requirementDetail={requirementDetail}
        />
      )}
    </div>
  );
};

export default RequirementModules;
