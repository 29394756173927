import RequirementActionTypes from "./RequirementActionTypes";

// REQUIREMENT MODULES
// GET ALL REQUIREMENT MODULES
export const getRequirementModuleIndexRequest = (payload) => ({
  type: RequirementActionTypes.GET_REQUIREMENTMODULES_INDEX_REQUEST,
  payload,
});

export const getRequirementModuleIndexResponse = (payload) => ({
  type: RequirementActionTypes.GET_REQUIREMENTMODULE_INDEX_RESPONSE,
  payload,
});

// POST REQUIREMENT MODULE
export const postRequirementModuleRequest = (payload) => ({
  type: RequirementActionTypes.POST_REQUIREMENTMODULES_REQUEST,
  payload,
});

export const postRequirementModuleResponse = (payload) => ({
  type: RequirementActionTypes.POST_REQUIREMENTMODULES_RESPONSE,
  payload,
});

// UPDATE REQUIREMENT MODULE
export const updateRequirementModuleRequest = (payload) => ({
  type: RequirementActionTypes.UPDATE_REQUIREMENTMODULES_REQUEST,
  payload,
});

export const updateRequirementModuleResponse = (payload) => ({
  type: RequirementActionTypes.UPDATE_REQUIREMENTMODULES_RESPONSE,
  payload,
});

// POST AND UPDATE REQUIREMENT MODULE RESPONSE CLEAR
export const postRequirementModuleResponseClear = () => ({
  type: RequirementActionTypes.POST_REQUIREMENTMODULES_RES_CLEAR,
});

// DELETE REQUIREMENT MODULE
export const deleteRequirementModuleRequest = (payload) => ({
  type: RequirementActionTypes.DELETE_REQUIREMENTMODULES_REQUEST,
  payload,
});

export const deleteRequirementModuleResponse = (payload) => ({
  type: RequirementActionTypes.DELETE_REQUIREMENTMODULES_RESPONSE,
  payload,
});

export const deleteRequirementModuleResponseClear = () => ({
  type: RequirementActionTypes.DELETE_REQUIREMENTMODULES_RES_CLEAR,
});

// GET PARTICULAR REQUIREMENT MODULE
export const showRequirementModuleRequest = (payload) => ({
  type: RequirementActionTypes.SHOW_REQUIREMENTMODULE_REQUEST,
  payload,
});

export const showRequirementModuleResponse = (payload) => ({
  type: RequirementActionTypes.SHOW_REQUIREMENTMODULE_RESPONSE,
  payload,
});

export const showRequirementModuleResponseClear = () => ({
  type: RequirementActionTypes.SHOW_REQUIREMENTMODULE_RES_CLEAR,
});

// REQUIREMENT DETAILS
// GET ALL REQUIREMENTS DETAILS
export const getRequirementDetailIndexRequest = (payload) => ({
  type: RequirementActionTypes.GET_REQUIREMENT_INDEX_REQUEST,
  payload,
});

export const getRequirementDetailIndexResponse = (payload) => ({
  type: RequirementActionTypes.GET_REQUIREMENT_INDEX_RESPONSE,
  payload,
});

// POST REQUIREMENT DETAIL
export const postRequirementDetailRequest = (payload) => ({
  type: RequirementActionTypes.POST_REQUIREMENT_REQUEST,
  payload,
});

export const postRequirementDetailResponse = (payload) => ({
  type: RequirementActionTypes.POST_REQUIREMENT_RESPONSE,
  payload,
});

// UPDATE REQUIREMENT DETAIL
export const updateRequirementDetailRequest = (payload) => ({
  type: RequirementActionTypes.UPDATE_REQUIREMENT_REQUEST,
  payload,
});

export const updateRequirementDetailResponse = (payload) => ({
  type: RequirementActionTypes.UPDATE_REQUIREMENT_RESPONSE,
  payload,
});

// POST AND UPDATE REQUIREMENT DETAIL RESPONSE CLEAR
export const postRequirementDetailResponseClear = () => ({
  type: RequirementActionTypes.POST_REQUIREMENT_RES_CLEAR,
});

// DELETE REQUIREMENT DETAIL
export const deleteRequirementDetailRequest = (payload) => ({
  type: RequirementActionTypes.DELETE_REQUIREMENT_REQUEST,
  payload,
});

export const deleteRequirementDetailResponse = (payload) => ({
  type: RequirementActionTypes.DELETE_REQUIREMENT_RESPONSE,
  payload,
});

export const deleteRequirementDetailResponseClear = () => ({
  type: RequirementActionTypes.DELETE_REQUIREMENT_RES_CLEAR,
});

// GET PARTICULAR REQUIREMENT DETAIL
export const showRequirementDetailRequest = (payload) => ({
  type: RequirementActionTypes.SHOW_REQUIREMENT_REQUEST,
  payload,
});

export const showRequirementDetailResponse = (payload) => ({
  type: RequirementActionTypes.SHOW_REQUIREMENT_RESPONSE,
  payload,
});

export const showRequirementDetailResponseClear = () => ({
  type: RequirementActionTypes.SHOW_REQUIREMENT_RES_CLEAR,
});

// REQUIREMENT DETAIL ATTACHMENTS
// GET ALL REQUIREMENT DETAIL ATTACHMENTS
export const getRequireDetailAttachmentIndexRequest = (payload) => ({
  type: RequirementActionTypes.GET_REQUIREATTACHMENT_INDEX_REQUEST,
  payload,
});

export const getRequireDetailAttachmentIndexResponse = (payload) => ({
  type: RequirementActionTypes.GET_REQUIREATTACHMENT_INDEX_RESPONSE,
  payload,
});

export const getRequireDetailAttachmentIndexResponseClear = () => ({
  type: RequirementActionTypes.GET_REQUIREATTACH_INDEX_RES_CLEAR,
});

// POST REQUIREMENT DETAIL ATTACHMENT
export const postRequireDetailAttachmentRequest = (payload) => ({
  type: RequirementActionTypes.POST_REQUIREATTACHMENT_REQUEST,
  payload,
});

export const postRequireDetailAttachmentResponse = (payload) => ({
  type: RequirementActionTypes.POST_REQUIREATTACHMENT_RESPONSE,
  payload,
});

// UPDATE REQUIREMENT DETAIL ATTACHMENT
export const updateRequireDetailAttachmentRequest = (payload) => ({
  type: RequirementActionTypes.UPDATE_REQUIREATTACHMENT_REQUEST,
  payload,
});

export const updateRequireDetailAttachmentResponse = (payload) => ({
  type: RequirementActionTypes.UPDATE_REQUIREATTACHMENT_RESPONSE,
  payload,
});

// POST AND UPDATE REQUIREMENT DETAIL ATTACHMENT RESPONSE CLEAR
export const postRequireDetailAttachmentResponseClear = () => ({
  type: RequirementActionTypes.POST_REQUIREATTACHMENT_RES_CLEAR,
});

// DELETE REQUIREMENT DETAIL ATTACHMENT
export const deleteRequireDetailAttachmentRequest = (payload) => ({
  type: RequirementActionTypes.DELETE_REQUIREATTACHMENT_REQUEST,
  payload,
});

export const deleteRequireDetailAttachmentResponse = (payload) => ({
  type: RequirementActionTypes.DELETE_REQUIREATTACHMENT_RESPONSE,
  payload,
});

export const deleteRequireDetailAttachmentResponseClear = () => ({
  type: RequirementActionTypes.DELETE_REQUIREATTACHMENT_RES_CLEAR,
});

// GET PARTICULAR REQUIREMENT DETAIL ATTACHMENT
export const showRequireDetailAttachmentRequest = (payload) => ({
  type: RequirementActionTypes.SHOW_REQUIREATTACHMENT_REQUEST,
  payload,
});

export const showRequireDetailAttachmentResponse = (payload) => ({
  type: RequirementActionTypes.SHOW_REQUIREATTACHMENT_RESPONSE,
  payload,
});

export const showRequireDetailAttachmentResponseClear = () => ({
  type: RequirementActionTypes.SHOW_REQUIREATTACHMENT_RES_CLEAR,
});
