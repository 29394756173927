// import React, { useState } from "react";
// import FileSaver from "file-saver";
// import * as XLSX from "xlsx";
// import toast from "react-hot-toast";
// import CustomButton from "../../Atoms/CustomButton/CustomButton";
// import PropTypes from "prop-types";
// import { convertedString } from "../../../Utils/handles";
// import { axiosPost } from "../../../Utils/axios";

// export default function ExportToXL({ fileName, filters, url }) {
//   const [isLoading, setIsLoading] = useState(false);

//   const fileType =
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//   const fileExtension = ".xlsx";

//   const payload = {
//     ...filters,
//     pagination_meta: {
//       page: 1,
//       order_by: filters?.pagination_meta?.order_by,
//     },
//   };

//   const exportToExcel = async (fileName) => {
//     try {
//       setIsLoading(true);
//       await axiosPost(url, payload).then((res) => {
//         const ws = XLSX.utils.json_to_sheet(
//           res?.data?.data?.data?.map((el, i) => {
//             const { id, updated_at, ...rest } = el;
//             const output = {};
//             output["S.No"] = i + 1;
//             for (const key in rest) {
//               if (typeof rest[key] === "object") {
//                 output[convertedString(key, "_")] =
//                   rest[key]?.display_name || rest[key]?.name;
//               } else {
//                 output[convertedString(key, "_")] = rest[key];
//               }
//             }
//             return output;
//           })
//         );
//         const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//         const excelBuffer = XLSX.write(wb, {
//           bookType: "xlsx",
//           type: "array",
//         });
//         const dataFile = new Blob([excelBuffer], { type: fileType });
//         FileSaver.saveAs(dataFile, fileName + fileExtension);
//         setIsLoading(false);
//         return res;
//       });
//     } catch (error) {
//       setIsLoading(false);
//       toast.error("Export Failed");
//     }
//   };
//   return (
//     <div className="exportMain">
//       <CustomButton
//         size="small"
//         label={isLoading ? "Exporting..." : "Export"}
//         onClick={() =>
//           exportToExcel(`${fileName} - ${new Date().toLocaleDateString()}`)
//         }
//         disabled={isLoading}
//       />
//     </div>
//   );
// }

// ExportToXL.propTypes = {
//   fileName: PropTypes.string,
//   filters: PropTypes.object,
//   url: PropTypes.string,
// };

import {
  Height,
  KeyboardArrowDown,
  NotificationAdd,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import "./Export.css";
import { convertedString } from "../../../Utils/handles";
import axios from "axios";
import { toast } from "react-hot-toast";

import CustomInput from "../../Atoms/CustomInput/CustomInput";
import CustomSelect from "../../Atoms/CustomSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { getExportRequest } from "../../../Redux/ExportcolumnSaga/ExportcolumnAction";
import CustomDialog from "../../Atoms/CustomDialog/CustomDialog";
import CustomButton from "../../Atoms/CustomButton/CustomButton";
import { useParams } from "react-router-dom";

const ExportToXL = ({
  editPdf = {},
  exportLinks = [],
  customDates = true,
  cdConfig = {},
  standalone = false,
  standaloneLabel,
  standaloneUrl,
  standaloneFilename,
  standaloneExtension,
  extraFilterFields,
  // initialValues,
  extraValidation,
}) => {
  const dispatch = useDispatch();

  const { id: ids } = useParams();

  useEffect(() => {
    dispatch(
      getExportRequest({
        model: "defect",
      })
    );
  }, []);

  let initialValues;
  if (cdConfig?.type === "defects") {
    initialValues = {
      required_columns: [],
      only_filtered: true,
      filters: {
        created_by_fk_id: {
          type: "exact",
          value: "",
        },
        status: {
          type: "exact",
          value: "",
        },
      },
    };
  } else if (cdConfig?.type === "testcase") {
    initialValues = {
      required_columns: [],
      only_filtered: true,
      filters: {
        // project_id: {
        //   type: "exact",
        //   value: ids,
        // },
        assigned_to_fk_id: {
          type: "exact",
          value: "",
        },
        approved_by_fk: {
          type: "exact",
          value: "",
        },
      },
    };
  } else if (cdConfig?.type === "testexection") {
    initialValues = {
      required_columns: [],
      only_filtered: true,
      filters: {
        executor: {
          value: "",
          type: "exact",
        },
        test_case_fk: {
          type: "exact",
          value: "",
        },
      },
    };
  } else if (cdConfig?.type === "teststep") {
    initialValues = {
      test_case_id: cdConfig?.test_case_id,
      required_columns: [],
    };
  }

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      handleDownloadFile({
        url: cdConfig.url ? cdConfig.url : "/api/report-export",
        filename: `${cdConfig.name}.${cdConfig.extension}`,
        payload: {
          ...values,
          project_id: cdConfig.project_id,
          test_case_id: cdConfig.test_case_id,

          // type: cdConfig.type,
        },
        method: "POST",
      });

      formik.resetForm();

      return handleClose();
    },
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (standalone) {
      return handleDownloadFile({
        url: standaloneUrl,
        filename: `${standaloneFilename} - ${new Date()
          .toISOString()
          .slice(0, 10)}.${standaloneExtension}`,
        method: "GET",
      });
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    return setAnchorEl(null);
  };

  const handleClicks = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloses = () => {
    setAnchorEl(null);
  };

  const [estDialog, setEstDialog] = useState({
    mount: false,
    data: null,
  });

  const handleOpenEstDialog = () =>
    setEstDialog({
      mount: true,
    });
  const handleCloseEstDialog = () => setEstDialog({ mount: false, data: null });

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  const handleDirectExport = ({
    name,
    extension,
    type,
    url,
    project_id,
    test_case_id,
  }) => {
    handleDownloadFile({
      url: url,
      filename: `${name} - ${new Date()
        .toISOString()
        .slice(0, 10)} .${extension}`,
      payload: type
        ? {
            required_columns: [],
            type: type,
            project_id: project_id,
            test_case_id: test_case_id,
          }
        : "",
      method: type ? "POST" : "GET",
    });
    return handleClose();
  };

  const names = [
    "id",
    "summary",
    "status",
    "priority",
    "severity",
    "comment",
    "is_submitted",
    "created_at",
    "updated_at",
    "test_step_fk",
    "execution_meta_fk",
    "test_step_at_execution_fk",
    "fixed_by_fk",
    "retested_by_fk",
    "created_by_fk",
    "test_case_fk",
  ];

  const test_case = [
    "id",
    "name",
    "status",
    "type",
    "version",
    "priority",
    "description",
    "pre_condition",
    "created_at",
    "updated_at",
    "is_approved",
    "is_added_for_execution",
    "requirement_fk",
    "assigned_to_fk",
    "created_by_fk",
    "approved_by_fk",
    "module_fk",
  ];

  const test_execution = [
    "id",
    "execution_start",
    "execution_end",
    "is_running",
    "test_case_fk",
    "executor",
    "stopped_by_fk",
    "log_count",
    "bug_count",
    "running_status",
    "execution_status",
  ];

  const test_step = ["id", "step", "description", "expected_result"];

  const { values, setFieldValue } = formik;

  // Handle change in the Select component
  const handleChange = (event) => {
    setFieldValue("required_columns", event.target.value);
  };

  return (
    <div className="export_data_container">
      <div className="btnWrpr">
        {/* <Button
          aria-describedby={id}
          variant="outlined"
          color="secondary"
          onClick={handleClick}
        >
          <span className="text-start" style={{ width: "95px" }}>
            Export
          </span>{" "}
        </Button> */}
        <CustomButton
          size="small"
          label="Export"
          btnType="secondary"
          onClick={handleClick}
        />

        {!standalone && (
          <CustomDialog
            title="Export"
            id={id}
            open={open}
            anchorEl={anchorEl}
            maxWidth="md"
            handleClose={handleClose}
            onClose={() => handleClose()}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            PaperProps={{ sx: { minWidth: 240 } }}
          >
            <div className="mb-4 d-flex justify-content-start">
              {exportLinks?.map((link) => (
                <CustomButton
                  label={link.label}
                  // btnType="secondary"
                  onClick={() =>
                    handleDirectExport({
                      label: link.label,
                      name: link.name,
                      extension: link.extension,
                      type: link.type,
                      project_id: link?.project_id,
                      url: link.url ? link.url : "",
                      test_case_id: link?.test_case_id,
                    })
                  }
                />
              ))}
            </div>

            {!!exportLinks?.length ? <Divider className="my-0" /> : ""}
            {customDates ? (
              <div className="project_add_container">
                <form onSubmit={formik.handleSubmit} className="">
                  <div className="row">
                    <div className="col-md-6 err_margin details_list">
                      <h3>Selected&nbsp;Column</h3>

                      <FormControl style={{ width: "100%" }}>
                        <Select
                          labelId="column-select-label"
                          size="small"
                          id="column-select"
                          placeholder="Column"
                          multiple
                          style={{ width: "100%", height: "35px" }}
                          value={formik.values.required_columns}
                          onChange={handleChange}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {cdConfig.type == "defects" &&
                            names.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={
                                    formik.values.required_columns.indexOf(
                                      name
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={convertedString(name)
                                    .replace(/_fk$/, "")
                                    .replace(/_/g, " ")}
                                />
                              </MenuItem>
                            ))}
                          {cdConfig.type == "testcase" &&
                            test_case.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={
                                    formik.values.required_columns.indexOf(
                                      name
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <span style={{ fontWeight: "bold" }}>
                                      {name
                                        .replace(/_fk$/, "")
                                        .replace(/_/g, " ")
                                        .replace(/^\w/, (c) => c.toUpperCase())}
                                    </span>
                                  }
                                />
                              </MenuItem>
                            ))}
                          {cdConfig.type == "teststep" &&
                            test_step.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={
                                    formik.values.required_columns.indexOf(
                                      name
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={name
                                    .replace(/_fk$/, "")
                                    .replace(/_/g, " ")
                                    .replace(/^\w/, (c) => c.toUpperCase())}
                                />
                              </MenuItem>
                            ))}
                          {cdConfig.type == "testexection" &&
                            test_execution.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={
                                    formik.values.required_columns.indexOf(
                                      name
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={name
                                    .replace(/_fk$/, "")
                                    .replace(/_/g, " ")
                                    .replace(/^\w/, (c) => c.toUpperCase())}
                                />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    {extraFilterFields &&
                      // cdConfig?.type === "defects" &&
                      extraFilterFields?.map((field) => (
                        <>
                          {field.inputType === "select" ? (
                            <>
                              <div className="col-md-6 err_margin  details_list">
                                <h3>{field.placeholder}</h3>
                                <CustomSelect
                                  size="small"
                                  name={field?.name}
                                  placeholder={field.placeholder}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values[field.name]}
                                  options={
                                    field.name === "filters.status.value"
                                      ? field.options
                                      : field?.options?.data?.map(
                                          (values, index) => {
                                            return {
                                              value: values.user_fk,
                                              name: `${values.first_name} ${values.last_name}`,
                                            };
                                          }
                                        )
                                  }
                                  formik={formik}
                                />
                              </div>
                              {/* <div className="col-md-6 details_list">
                                <h3>Severity</h3>

                                <select
                                  className="remBorder"
                                  size="small"
                                  placeholder="Status"
                                  name="severity"
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.severity}
                                ></select>
                              </div>
                              <div className="col-md-6 details_list">
                                <h3>Severity</h3>
                                <p>:</p>
                                <select
                                  className="remBorder"
                                  size="small"
                                  placeholder="Status"
                                  name="severity"
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.severity}
                                ></select>
                              </div>{" "} */}
                            </>
                          ) : field.inputType === "input" ? (
                            <CustomSelect
                              name={field.name}
                              label={field.label}
                              type={field.type}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values[field.name]}
                              formik={formik}
                            />
                          ) : field.inputType == "radio" ? (
                            <div className="customInputWrpr">
                              <FormControl className="flex_column mb-2">
                                <FormLabel>{field.label}: </FormLabel>
                                <RadioGroup
                                  name={field.name}
                                  value={formik.values[field.name]}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                >
                                  {field?.list?.map(({ value, label }) => (
                                    <FormControlLabel
                                      value={value}
                                      control={<Radio size="small" />}
                                      label={label}
                                    />
                                  ))}
                                </RadioGroup>
                              </FormControl>
                              <p className="errMsg">
                                {formik.errors[field.name]}
                              </p>
                            </div>
                          ) : field.inputType == "checkboxs" ? (
                            // <CustomCheckBox
                            //   name={field.name}
                            //   label={field.label}
                            //   type={field.type}
                            //   onBlur={formik.handleBlur}
                            //   onChange={formik.handleChange}
                            //   value={formik.values[field.name]}
                            // />
                            ""
                          ) : (
                            // <DropdownMenu
                            //   popoverEl={anchorEl}
                            //   popoverOpen={open}
                            //   handlePopoverClose={handleCloses}
                            //   dropContent={<div>xzxzxzxzzx</div>}
                            // >
                            //   <IconButton onClick={handleClicks}>
                            //     <NotificationAdd />
                            //   </IconButton>
                            // </DropdownMenu>
                            ""
                          )}
                        </>
                      ))}
                    <div className="col-12">
                      <div className="popformbutton">
                        <CustomButton
                          size="small"
                          label="Cancel"
                          btnType="secondary"
                          onClick={handleClose}
                        />
                        <CustomButton
                          size="small"
                          label={"Export"}
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </CustomDialog>
        )}
      </div>
    </div>
  );
};

export default ExportToXL;

export const handleDownloadFile = async ({
  url,
  filename,
  payload,
  method,
}) => {
  const notify = toast.loading("Exporting Document..");

  try {
    const response = await axios({
      url: url,
      method: method,
      responseType: "blob",
      data: payload,
    });

    if (response.data instanceof Blob) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success("Document Exported!", { id: notify });
    } else {
      toast.error("Failed to export Document!", { id: notify });
    }
  } catch (error) {
    toast.error("Failed to export Document!", { id: notify });
  }
};
