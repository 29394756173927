import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  postProjectRequest,
  postProjectResponseClear,
  updateProjectRequest,
  showProjectRequest,
  showProjectResponseClear,
  getProjectIndexRequest,
  showProjectMembersRequest,
  showProjectMembersResponseClear,
} from "../../Redux/ProjectSaga/ProjectAction";
import toast from "react-hot-toast";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import { Autocomplete, TextField } from "@mui/material";
import "./Project.css";
import PropTypes from "prop-types";

const FilterForm = ({ handleClose, type, payload, formik }) => {
  const { postProjectResponse, project, projectsList, projectMembers } =
    useSelector((state) => state.project);

  const status = [
    {
      name: "Open",
      value: "OPEN",
    },
    {
      name: "On hold",
      value: "ON_HOLD",
    },
    {
      name: "Completed",
      value: "COMPLETED",
    },
  ];

  return (
    <div className="project_add_container">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6   details_list">
            <h3>Status</h3>
            <CustomSelect
              size="small"
              // placeholder="Status"
              options={status?.map((values, index) => {
                return {
                  value: values.value,
                  name: values.name,
                };
              })}
              name="status.value"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status.value}
              formik={formik}
            />
          </div>

          <div className="col-md-6  details_list">
            <h3>Start date</h3>
            <CustomInput
              type="date"
              name="start_date.value"
              size="large"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.start_date.value}
              formik={formik}
              // shrink={true}
              // label="From Date"
            />
          </div>
          <div className="col-md-6  details_list ">
            <h3>End date</h3>
            <CustomInput
              type="date"
              name="end_date.value"
              size="large"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.end_date.value}
              formik={formik}
              // shrink={true}
              // label="To Date"
            />
          </div>

          <div className="col-12">
            <div className="popformbutton">
              <CustomButton
                size="small"
                label="Cancel"
                btnType="secondary"
                onClick={handleClose}
              />
              <CustomButton size="small" label={"Filter"} type="submit" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterForm;
