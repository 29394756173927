import React, { useEffect, useState } from "react";
import { convertedString, handleDownloadFile } from "../../Utils/handles";
import { BUGCOLOR_ICON, BUGGREY_ICON } from "../../Utils/imageConstants";
import { ImageOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelExecutionRequest,
  cancelExecutionResClear,
  getExecutionByIdRequest,
} from "../../Redux/TestExecution/TestExecutionAction";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import { appUrl } from "../../Utils/axios";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import AddDefectForm from "../TestExecution/AddDefectForm";

const ParticularExecutionViewPage = () => {
  const { id } = useParams();
  const { executionid } = useParams();

  const [open, setOpen] = useState({ openDialog: false, data: "" });

  const handleClose = () => {
    setOpen({ openDialog: false, data: "" });
  };

  const handleOpen = (data) => {
    setOpen({ openDialog: true, data: data });
  };

  const dispatch = useDispatch();

  const { exectionByid, cancelExecutionResponse } = useSelector(
    (state) => state.testExecution
  );

  useEffect(() => {
    dispatch(getExecutionByIdRequest(executionid));
  }, []);

  useEffect(() => {
    if (!cancelExecutionResponse) return;
    if (cancelExecutionResponse) {
      if (
        cancelExecutionResponse?.message &&
        cancelExecutionResponse?.success
      ) {
        dispatch(getExecutionByIdRequest(executionid));

        toast.success(cancelExecutionResponse?.message);
      } else {
        toast.error(cancelExecutionResponse?.message);
      }
      return () => {
        dispatch(cancelExecutionResClear());
      };
    }
  }, [cancelExecutionResponse]);

  const handleDefect = () => {};

  return (
    <div>
      <div className="dashboard_container releaseLayout w-100 overflow-auto">
        <div className="position-relative">
          <div className="test_header ">
            {exectionByid?.execution_data?.test_case_fk?.name || "_"}
          </div>
        </div>

        <div className="tabelCont">
          <div>
            <div className="tabelContS">
              <div className="tabelLeft">
                <h3>Execution Id</h3>
              </div>
              <div className="tabelRight">
                <p>
                  <span>:</span>
                  {`Ex - ${exectionByid?.execution_data?.id}` || "_"}
                </p>
              </div>
            </div>
            <div className="tabelContS">
              <div className="tabelLeft">
                <h3>Test Case Id</h3>
              </div>
              <div className="tabelRight">
                <p>
                  <span>:</span>
                  {`TC - ${exectionByid?.execution_data?.test_case_fk?.id}` ||
                    "_"}
                </p>
              </div>
            </div>
            <div className="tabelContS">
              <div className="tabelLeft">
                <h3>Test Case Name</h3>
              </div>
              <div className="tabelRight">
                <p>
                  <span>:</span>
                  {exectionByid?.execution_data?.test_case_fk?.name || "_"}
                </p>
              </div>
            </div>
            <div className="tabelContS">
              <div className="tabelLeft">
                <h3>Executor</h3>
              </div>
              <div className="tabelRight">
                <p>
                  <span>:</span>
                  {convertedString(
                    exectionByid?.execution_data?.executor?.full_name
                  ) || "_"}
                </p>
              </div>
            </div>
            <div className="tabelContS">
              <div className="tabelLeft">
                <h3>Running Status</h3>
              </div>
              <div className="tabelRight">
                <p>
                  <span>:</span>
                  {convertedString(
                    exectionByid?.execution_data?.running_status
                  ) || "_"}
                </p>
              </div>
            </div>{" "}
            <div className="tabelContS">
              <div className="tabelLeft">
                <h3>Execution Status</h3>
              </div>
              <div className="tabelRight">
                <p>
                  <span>:</span>
                  {convertedString(
                    exectionByid?.execution_data?.execution_status
                  ) || "_"}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="tabelContS">
              <div className="tabelLeft">
                <h3>Action</h3>
              </div>
              <div className="tabelRight">
                {exectionByid?.execution_data?.is_running ? (
                  <p
                    onClick={() =>
                      dispatch(
                        cancelExecutionRequest({
                          execution_id: exectionByid?.execution_data?.id,
                        })
                      )
                    }
                  >
                    <span>:</span>
                    <button>Stop</button>
                  </p>
                ) : (
                  <p>
                    <span>:</span>
                    <button>Stopped</button>
                  </p>
                )}
              </div>
            </div>
            <div className="tabelContS">
              <div className="tabelLeft">
                <h3>Log</h3>
              </div>
              <div className="tabelRight">
                <p>
                  <span>:</span>
                  {exectionByid?.execution_data?.log_count}
                </p>
              </div>
            </div>
            <div className="tabelContS">
              <div className="tabelLeft">
                <div className="bug_image_container">
                  <img src={BUGGREY_ICON} alt="greycolorbug" />
                </div>
              </div>
              <div className="tabelRight">
                <p>
                  <span>:</span>
                  {exectionByid?.execution_data?.bug_count}
                </p>
              </div>
            </div>
            <div className="tabelContS">
              <div className="tabelLeft">
                <h3>Start Time</h3>
              </div>
              <div className="tabelRight">
                <p>
                  <span>:</span>
                  {moment(exectionByid?.execution_data?.execution_start).format(
                    "DD.MM.YYYY hh:mm:ss A"
                  )}
                </p>
              </div>
            </div>
            <div className="tabelContS">
              <div className="tabelLeft">
                <h3>End Time</h3>
              </div>
              <div className="tabelRight">
                <p>
                  <span>:</span>
                  {exectionByid?.execution_data?.execution_end
                    ? moment(
                        exectionByid?.execution_data?.execution_end
                      ).format("DD.MM.YYYY hh:mm:ss A")
                    : "_"}
                </p>
              </div>
            </div>
          </div>
          <div className="tblWrpr">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Step No</th>
                    <th>Description</th>
                    <th>Expected Result</th>
                    <th>Actual Result</th>
                    <th>Log</th>
                    <th>Status</th>
                    <th>Bug</th>
                  </tr>
                </thead>
                <tbody>
                  {exectionByid?.test_steps_data?.length > 0 ? (
                    exectionByid?.test_steps_data?.map((data) => (
                      <tr key={data?.id}>
                        <td>{data?.step}</td>
                        <td>{data?.description}</td>
                        <td>{data?.expected_result}</td>
                        <td>{data?.actual_result || "_"}</td>
                        {data.log ? (
                          <td
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleDownloadFile(`${appUrl}${data?.log}`);
                            }}
                          >
                            <div className="testdesign_attachment clickable">
                              <div>
                                <ImageOutlined
                                  sx={{
                                    width: "30px",
                                    height: "30px",
                                    color: "#adaaaa",
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td>_</td>
                        )}

                        <td>{convertedString(data?.status) || "_"}</td>
                        <td>
                          {data?.bug ? (
                            <div
                              className="bug_image_container"
                              onClick={() => handleOpen(data?.bug)}
                            >
                              <img src={BUGCOLOR_ICON} alt="colorbug" />
                            </div>
                          ) : (
                            "_"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">
                        <div className="text-start">
                          <p>No Executions Found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        open={open.openDialog}
        title="Defects"
        handleClose={handleClose}
      >
        <AddDefectForm
          handleClose={handleClose}
          open={open.openDialog}
          viewData={open?.data}
          handleBugsResponse={handleDefect}
        />
      </CustomDialog>
    </div>
  );
};

export default ParticularExecutionViewPage;
