import { call, put, takeLatest } from "redux-saga/effects";
import notificationTypes from "./NotificationActionTypes";
import { axiosGet, axiosPut } from "../../Utils/axios";
import {
  clearnotificationsResponse,
  notificationsResponse,
} from "./NotificationAction";
import { unauthorizedMessage } from "../Login/LoginActions";

// GET ALL NOTIFICATIONS
export function* notificationReqAsync() {
  try {
    let response = yield call(() =>
      axiosGet(`get_notifications`).then((response) => response?.data)
    );
    yield put(notificationsResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//  CLEAR ALL NOTIFICATIONS
export function* clearnotificationReqAsync({ payload }) {
  try {
    let response = yield call(() =>
      axiosPut("hide_notification", payload).then((response) => response?.data)
    );
    yield put(clearnotificationsResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//=================WATCHER SAGA=====================
export function* notificationWatcherSaga() {
  yield takeLatest(
    notificationTypes.GET_NOTIFICATIONS_REQUEST,
    notificationReqAsync
  );
  yield takeLatest(
    notificationTypes.CLEAR_NOTIFICATIONS_REQUEST,
    clearnotificationReqAsync
  );
}
