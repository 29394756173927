import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Avatar, Badge, Button, IconButton, Popover } from "@mui/material";
import { NOTIFICATION_ICON } from "../../../Utils/imageConstants";
import {
  notificationsRequest,
  clearnotificationsRequest,
} from "../../../Redux/NotificationSaga/NotificationAction";
import "./Notification.css";

const Notification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const { notificationList, clearNotificationLoading } = useSelector(
    (state) => state.notification
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickNotification = (proj_id, id, invitation_type) => {
    dispatch(
      clearnotificationsRequest({
        notification_id: id,
        notification_type: invitation_type,
      })
    );
    handleClose();
    navigate(`/project/${proj_id}/dashboard`);
  };

  useEffect(() => {
    if (clearNotificationLoading) return;
    dispatch(notificationsRequest());
  }, [clearNotificationLoading]);

  return (
    <>
      <IconButton onClick={handleClick} onKeyDown={() => {}}>
        <Badge badgeContent={notificationList?.length} color="error">
          <img
            src={NOTIFICATION_ICON}
            alt="notification"
            style={{ width: "17px" }}
          />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="notiWrapper">
          {notificationList?.length > 0 ? (
            notificationList?.map((data, index) => (
              <Button
                key={data.id}
                className="invtBtn"
                onKeyDown={() => {}}
                onClick={() =>
                  handleClickNotification(
                    data?.full_route?.project_id,
                    data?.id
                  )
                }
              >
                <div>
                  <Avatar
                    sx={{ width: "35px", height: "35px" }}
                    alt="Remy Sharp"
                  >
                    {data?.second_person?.first_name[0].toUpperCase()}
                  </Avatar>
                </div>
                <div className="flex_column text-start">
                  <p> {data?.message}</p>
                  <p>{moment(data?.created_at).format("DD/MM/YYYY HH:mm")}</p>
                </div>
              </Button>
            ))
          ) : (
            <div className="text-center emptyNotiWrpr">
              <p>No Notifications Found</p>
            </div>
          )}
        </div>
      </Popover>
    </>
  );
};

export default Notification;
