import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import AnimatedVideo from "./404Animation";
import "./PageNotFound.css";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="errorpage_container flex_column_center">
      <div>
        <AnimatedVideo />
      </div>
      <div className="error_text_container">
        <h3>
          Sorry, the page you're looking for doesn't exist. If you think
          <span className="line_break mt-2">
            something is broken, report a problem.
          </span>
        </h3>
        <div>
          <CustomButton label="RETURN HOME" onClick={() => navigate("/")} />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
