import { DeleteOutlineOutlined, Edit, MoreVert } from "@mui/icons-material";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import DeleteIndex from "../../DeletePopup/DeleteIndex";
import moment from "moment";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const TestMenu = ({ data, deleteAction, editAction, res, loading }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const createdTime = moment(data.created_at);
  const currentTime = moment();

  const timeDifferenceInMillis = currentTime.diff(createdTime);
  const duration = moment.duration(timeDifferenceInMillis);
  const formattedTimeDifference = duration.minutes();

  return (
    <div>
      <IconButton>
        <MoreVert
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
        />
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {formattedTimeDifference > 10 ? (
            ""
          ) : (
            <MenuItem
              onClick={() => {
                dispatch(editAction(data?.id));
                handleClose();
              }}
              disableRipple
            >
              <Edit style={{ marginRight: "10px" }} />
              Edit
            </MenuItem>
          )}

          <Divider style={{ margin: 0 }} />
          <MenuItem>
            <DeleteIndex
              action={deleteAction}
              payload={data?.id}
              res={res}
              loading={loading}
              label={
                <>
                  <DeleteOutlineOutlined />
                  Delete
                </>
              }
            />
            {/* Delete */}
          </MenuItem>
        </StyledMenu>
      </IconButton>
      {/* )} */}
    </div>
  );
};

export default TestMenu;
