import React, { useEffect, useState } from "react";
import { PlayArrow } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../../Components/Atoms/CustomDialog/CustomDialog";
import TestDesignHistoryView from "./TestDesignHistoryView";
import moment from "moment/moment";
import { getTestDesignHistoryIndexRequest } from "../../../Redux/TestDesignSaga/TestDesignCommentSaga/TestDesignCommentAction";
import CustomButton from "../../../Components/Atoms/CustomButton/CustomButton";

export default function TestDesignHistory({ testCaseId }) {
  const [open, setOpen] = useState(false);
  const [teststep, setTestStep] = useState();
  const [teststep1, setTestStep1] = useState();

  const { testDesignHistoryList } = useSelector(
    (state) => state.testDesignComment
  );

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (teststep, teststep1) => {
    setOpen(true);
    setTestStep(teststep);
    setTestStep1(teststep1);
  };

  const dispatch = useDispatch();
  const [accordionVisible, setAccordionVisible] = useState([]);

  const handlePlayArrowClick = (index) => {
    const updatedAccordionVisibleArray = [...accordionVisible];
    updatedAccordionVisibleArray[index] = !updatedAccordionVisibleArray[index];
    setAccordionVisible(updatedAccordionVisibleArray);
  };

  useEffect(() => {
    dispatch(getTestDesignHistoryIndexRequest(testCaseId));
  }, [testCaseId]);
  return (
    <>
      <div className="accordian_containers">
        <div className="hisHead hisHeadNew">
          <p className="hisDate">Changes</p>
          <p className="hisDate" style={{ marginRight: "100px" }}>
            Date
          </p>
          <p className="hisDate">By</p>
        </div>
        <div className="">
          {testDesignHistoryList?.length > 0 ? (
            testDesignHistoryList?.map((history, index) => (
              <div key={index}>
                {index !== testDesignHistoryList?.length - 1 && (
                  <div className="d-flex justify-content-between py-1 histRow">
                    <div
                      className="d-flex gap-2 HisFont"
                      onClick={() => handlePlayArrowClick(index)}
                      onKeyDown={() => {}}
                    >
                      <p
                        className={
                          accordionVisible?.[index]
                            ? "conthrd clickable"
                            : "clickable"
                        }
                      >
                        <PlayArrow
                          style={{
                            transform: accordionVisible?.[index]
                              ? "rotate(90deg)"
                              : "rotate(0deg)",
                          }}
                        />
                        {`0.${history.version - 1}`}
                      </p>
                    </div>
                    <div>
                      <p
                        className={accordionVisible?.[index] ? "conthrdr" : ""}
                      >
                        {moment(history?.updated_at).format(
                          "DD.MM.YYYY h:mm:ssA"
                        )}
                      </p>
                    </div>
                    <div>
                      <p
                        className={accordionVisible?.[index] ? "conthrdr" : ""}
                      >
                        {`${
                          history?.updated_by?.full_name?.[0].toUpperCase() +
                          history?.updated_by?.full_name?.slice(1)
                        }` || ""}
                      </p>
                    </div>
                  </div>
                )}
                {accordionVisible?.[index] &&
                  index !== testDesignHistoryList?.length - 1 && (
                    <>
                      <div
                        className="hisHead hisHead2"
                        style={{ padding: "0px 67px 0px 60px" }}
                      >
                        <p className="hisDate">Field</p>
                        <p className="hisDate" style={{ marginRight: "-26px" }}>
                          Old Value
                        </p>
                        <p className="hisDate">New Value</p>
                      </div>
                      <div className="d-flex justify-content-between hisAlltRow">
                        <div
                          className="d-flex gap-2 "
                          onClick={handlePlayArrowClick}
                        >
                          <p
                            className={`field_values ${
                              accordionVisible ? "conthrdr" : ""
                            }`}
                          >
                            <div class="form-container px-5">
                              <div class="form-row">
                                <p class="field-label">Test Case Name</p>
                              </div>
                              <div class="form-row">
                                <p class="field-label">Description</p>
                              </div>

                              <div class="form-row">
                                <p class="field-label">Type</p>
                              </div>

                              <div class="form-row">
                                <p class="field-label">Status</p>
                              </div>
                              <div class="form-row">
                                <p class="field-label">Priority</p>
                              </div>
                              <div class="form-row">
                                <p class="field-label">Pre condition</p>
                              </div>
                              <div class="form-row">
                                <p class="field-label">Assigned To</p>
                              </div>

                              <div
                                class="form-row"
                                onClick={() =>
                                  handleOpen(
                                    testDesignHistoryList?.[index + 1],
                                    testDesignHistoryList?.[index]
                                  )
                                }
                              >
                                <CustomButton
                                  size="small"
                                  label="Test Step History"
                                  btnType="secondary"
                                />
                              </div>
                            </div>
                          </p>
                        </div>

                        <div className="oldValues">
                          <p>{testDesignHistoryList?.[index + 1]?.name}</p>
                          <p className="mt-2">
                            {testDesignHistoryList?.[index + 1]?.description}
                          </p>
                          <p className="mt-2">
                            {testDesignHistoryList?.[index + 1]?.type}
                          </p>
                          <p className="mt-2">
                            {testDesignHistoryList?.[index + 1]?.status}
                          </p>
                          <p className="mt-2">
                            {testDesignHistoryList?.[index + 1]?.priority}
                          </p>
                          <p className="mt-2">
                            {testDesignHistoryList?.[index + 1]?.pre_condition}
                          </p>
                          <p className="mt-2">
                            {
                              testDesignHistoryList?.[index + 1]?.assigned_to_fk
                                ?.full_name
                            }
                          </p>
                        </div>
                        <div className="newValues">
                          <p>{testDesignHistoryList?.[index]?.name}</p>

                          <p className="mt-2">
                            {testDesignHistoryList?.[index]?.description}
                          </p>
                          <p className="mt-2">
                            {testDesignHistoryList?.[index]?.type}
                          </p>
                          <p className="mt-2">
                            {testDesignHistoryList?.[index]?.status}
                          </p>
                          <p className="mt-2">
                            {testDesignHistoryList?.[index]?.priority}
                          </p>
                          <p className="mt-2">
                            {testDesignHistoryList?.[index]?.pre_condition}
                          </p>
                          <p className="mt-2">
                            {
                              testDesignHistoryList?.[index]?.assigned_to_fk
                                ?.full_name
                            }
                          </p>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            ))
          ) : (
            <p className="text-center">No History Yet</p>
          )}
        </div>
      </div>
      <CustomDialog
        open={open}
        title="History"
        handleClose={handleClose}
        maxWidth="md"
      >
        <TestDesignHistoryView
          testCaseId={testCaseId}
          teststep={teststep}
          teststep1={teststep1}
        />
      </CustomDialog>
    </>
  );
}
