import { Delete } from "@mui/icons-material";
import { Popover, Typography } from "@mui/material";
import DeleteIndex from "../Views/DeletePopup/DeleteIndex";
import toast from "react-hot-toast";

//HANDLE PASTE
export const handlePaste = (e) => {
  e.preventDefault(); /* Prevents user from pasting the password in password field */
};

//HANDLE SHOW OR HIDE PASSWORD
export const handlePassword = (setPassword, field) => {
  setPassword((prevState) => ({
    ...prevState,
    [field]: !prevState[field],
  }));
};

// ATTACHMENT NAME SPLIT
export const handleAttachmentName = (name, splitby) => {
  if (!name) return;
  return name.split(`${splitby}`).pop();
};

// CAPITAL TO SMALL LETTER (ON_HOLD -> On Hold)
export const convertedString = (str) => {
  if (!str) return;
  let words = str.split("_");
  for (let i = 0; i < words.length; i++) {
    words[i] =
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }
  return words.join(" ");
};

// HANDLE TOAST MESSAGE
export const handleToastResponse = (response) => {
  if (!response) return;
  if (response?.message && response?.success) {
    toast.success(response?.message);
  } else {
    toast.error(response?.message);
  }
};

// POPUP COMPONENT FOR DELETE THE RELEASE
export const handleContextMenu = (e, id, type, setAnchorEl, releaseId) => {
  e.preventDefault();
  setAnchorEl({
    anchor: e.currentTarget,
    id: id,
    type: type,
    release_id: releaseId,
  });
};

export const handleClose = (setAnchorEl) => {
  setAnchorEl(null);
};

export const DeleteContext = ({
  anchorEl,
  onClose,
  action,
  res,
  payload,
  loading,
  name,
}) => {
  return (
    <div className="delete_popup_container">
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="delete_popup"
        style={{ left: "-70px" }}
      >
        <Typography sx={{ padding: "10px 5px", cursor: "pointer" }}>
          <DeleteIndex
            action={action}
            loading={loading}
            res={res}
            payload={payload}
            name={name}
            label={
              <h3>
                <Delete sx={{ marginBottom: "4px", color: "red" }} />
                Delete
              </h3>
            }
          />
        </Typography>
      </Popover>
    </div>
  );
};

// HANDLE DOWNLOAD DOCUMENTS
export const handleDownloadFile = (url) => {
  window.open(url);
};

// HANDLE NO CHART DATA
export const handleNoChartData = (result) => {
  return Object.values(result || {}).every(
    (result) => result?.percentage === 0
  );
};

export const checkFilterValues = (
  filter,
  setPage,
  setLimit,
  setSort,
  order_by
) => {
  if (filter?.pagination_meta?.page !== 1) {
    setPage(filter?.pagination_meta?.page);
  }
  if (filter?.pagination_meta?.limit !== 10) {
    setLimit(filter?.pagination_meta?.limit);
  }
  if (filter?.pagination_meta?.order_by !== order_by) {
    setSort(filter?.pagination_meta?.order_by);
  }
};
