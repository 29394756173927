export default {
  GET_DEFECTS_INDEX_REQUEST: "GET_DEFECTS_INDEX_REQUEST",
  GET_DEFECTS_INDEX_RESPONSE: "GET_DEFECTS_INDEX_RESPONSE",

  UPDATE_DEFECT_REQUEST: "UPDATE_DEFECT_REQUEST",
  UPDATE_DEFECT_RESPONSE: "UPDATE_DEFECT_RESPONSE",

  UPDATE_DEFECT_RES_CLEAR: "UPDATE_DEFECT_RES_CLEAR",

  SHOW_DEFECT_REQUEST: "SHOW_DEFECT_REQUEST",
  SHOW_DEFECT_RESPONSE: "SHOW_DEFECT_RESPONSE",
  SHOW_DEFECT_RES_CLEAR: "SHOW_DEFECT_RES_CLEAR",

  COMMENT_HISTORY_REQUEST: "COMMENT_HISTORY_REQUEST",
  COMMENT_HISTORY_RESPONSE: "COMMENT_HISTORY_RESPONSE",

  NEW_DEFECT_REQUEST: "NEW_DEFECT_REQUEST",
  NEW_DEFECT_RESPONSE: "NEW_DEFECT_RESPONSE",
  NEW_DEFECT_RESCLEAR: "NEW_DEFECT_RESCLEAR",

  INDEX_DEFECT_REQUEST: "INDEX_DEFECT_REQUEST",
  INDEX_DEFECT_RESPOSE: "INDEX_DEFECT_RESPOSE",
};
