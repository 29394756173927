import React, { useEffect } from "react";
import "./UserProfile.css";
import MetaData from "../../Components/Organisms/MetaData/MetaData";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { CameraAlt, Delete, Edit, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appUrl } from "../../Utils/axios";
import { convertedString, handleToastResponse } from "../../Utils/handles";
import DeleteIndex from "../DeletePopup/DeleteIndex";
import {
  CurrentUserRequest,
  postProfilePhotoRequest,
  postProfilePhotoResponseClear,
} from "../../Redux/Login/LoginActions";

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { user, postPhotoLoading, postPhotoResponse } = useSelector(
    (state) => state.loginState
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //EDIT
  const handleChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    if (file) {
      const formContent = new FormData();
      formContent.append("user_profile_photo", file);
      dispatch(
        postProfilePhotoRequest({ id: user?.id, formContent, type: "add" })
      );
    }
  };

  useEffect(() => {
    handleToastResponse(postPhotoResponse);
    if (postPhotoResponse?.success) {
      handleClose();
    }
    return () => {
      dispatch(postProfilePhotoResponseClear());
    };
  }, [postPhotoResponse]);

  useEffect(() => {
    if (postPhotoLoading) return;
    dispatch(CurrentUserRequest());
  }, [postPhotoLoading]);

  return (
    <>
      <MetaData title="UserProfile" />
      <div className="pageTop">
        <div className="pageTopLeft">
          <h3>Profile</h3>
        </div>
        <div className="back_button">
          <CustomButton
            size="small"
            btnType="secondary"
            label="Back"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
      <div className="profile_container">
        <div className="profile_card bg-white">
          <div className="profile_user_photo">
            <div>
              <div className="profilePageWrapr pt-4">
                <div className="prflMnWrpr">
                  <div className="prflImg">
                    <Avatar src={`${appUrl}${user?.user_profile_photo}`} />
                    <Button
                      className="acntDropBtn Camera"
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <CameraAlt />
                    </Button>
                    <Menu
                      className="prflDrop"
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem className="position-relative">
                        <ListItemIcon>
                          <Edit />
                        </ListItemIcon>
                        Edit
                        <input
                          type="file"
                          accept="image/*"
                          className="image-upload__input"
                          name="user_profile_photo"
                          onChange={handleChange}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <DeleteIndex
                            payload={{
                              user_profile_photo: null,
                              id: user?.id,
                              type: "delete",
                            }}
                            action={postProfilePhotoRequest}
                            loading={postPhotoLoading}
                            res={postPhotoResponse}
                            label={
                              <h3>
                                <Delete
                                  sx={{
                                    marginBottom: "4px",
                                    marginRight: "20px",
                                    color: "#757575",
                                  }}
                                />
                                Delete
                              </h3>
                            }
                            name="Image"
                          />
                        </ListItemIcon>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            <h3>{user?.department?.department_name}</h3>
            <div className="user_active_container">
              <p className="text-white fw-bold">Active</p>
            </div>
          </div>
          <div className="profile_user_details">
            <div className="tabel_cont">
              <div className="tabel_conts">
                <div className="tabel_left">
                  <p className="table_left_para">Name</p>
                </div>
                <div className="tabel_left">
                  <p className="table_left_value">
                    {convertedString(`${user?.first_name}_${user?.last_name}`)}
                  </p>
                </div>
              </div>
              <div className="tabel_conts">
                <div className="tabel_left">
                  <p className="table_left_para ">Email</p>
                </div>
                <div className="tabel_left">
                  <p className=" email">{user?.email}</p>
                </div>
              </div>
              <div className="tabel_conts">
                <div className="tabel_left">
                  <p className="table_left_para">{`Project${
                    user?.project_names?.length > 1 ? "s" : ""
                  } Name`}</p>
                </div>
                <div className="tabel_left d-flex flex-column align-items-start">
                  {user?.project_names?.length > 0
                    ? user?.project_names?.map((data, index) => (
                        <p className="table_left_value">{`${convertedString(
                          data
                        )}${
                          user?.project_names?.length === index + 1 ? "" : ","
                        }`}</p>
                      ))
                    : "NA"}
                </div>
              </div>
              <div className="tabel_conts">
                <div className="tabel_left">
                  <p className="table_left_para">Organization Name</p>
                </div>
                <div className="tabel_left">
                  <p className="table_left_value">
                    {convertedString(user?.organisation_name)}
                  </p>
                </div>
              </div>
              <div className="tabel_conts">
                <div className="tabel_left">
                  <p className="table_left_para">Address</p>
                </div>
                <div className="tabel_left">
                  <p className="table_left_value">
                    {[
                      convertedString(user?.city),
                      convertedString(user?.state),
                      convertedString(user?.country),
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="user_project_details bg-white">
          <div className="accordian_container accordian_container2 m-3">
            <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>My Projects</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex-grow-1">
                  <div className="tblWrpr">
                    <div className="table-responsive">
                      <table className="table table_border">
                        <thead>
                          <tr>
                            <th className="table_history_head">No.</th>
                            <th className="table_history_head">Name</th>
                            <th className="table_history_head">Status</th>
                            <th className="table_history_head">
                              No of Test Cases
                            </th>
                            <th className="table_history_head">
                              No of Test Steps
                            </th>
                            <th className="table_history_head">
                              No of Defects
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {user?.my_projects_data?.length > 0 ? (
                            user?.my_projects_data?.map((data, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td
                                  className="cursor"
                                  onClick={() =>
                                    navigate(`/project/${data?.id}/dashboard`)
                                  }
                                >
                                  {data?.name}
                                </td>
                                <td>{convertedString(data?.status)}</td>
                                <td>{data?.test_case_count}</td>
                                <td>{data?.test_step_count}</td>
                                <td>{data?.defect_count}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6}>No Projects Yet</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
