import React, { useEffect, useState } from "react";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import Delete from "./Delete";

export default function DeleteIndex({
  payload,
  action,
  res,
  loading,
  className = "",
  label,
  disabled = false,
  name,
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  //CLOSE POPUP AFTER SUCCESS
  useEffect(() => {
    if (res?.success) {
      handleDeleteClose();
    }
  }, [res]);

  return (
    <>
      <button
        className={className}
        onClick={() => {
          handleDeleteOpen();
        }}
        disabled={disabled}
      >
        {label ?? <DeleteOutlineOutlined />}
      </button>
      <CustomDialog
        open={deleteOpen}
        handleClose={handleDeleteClose}
        divider={false}
        maxWidth="xs"
      >
        <Delete
          loading={loading}
          handleClose={handleDeleteClose}
          payload={payload}
          action={action}
          name={name}
        />
      </CustomDialog>
    </>
  );
}
