import { Insights } from "@mui/icons-material";
import React from "react";

const NoChartData = ({ isVisible = true }) => {
  return (
    <div>
      <div className="h-100 d-flex align-items-center justify-content-center">
        <h6 className="text-muted m-4">
          {isVisible && <Insights className="me-2" fontSize="large" />}
          No data available to render the chart.
        </h6>
      </div>
    </div>
  );
};

export default NoChartData;
