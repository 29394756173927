import { takeLatest, call, put } from "redux-saga/effects";
import TaskActionTypes from "./TaskActionTypes";
import { axiosPost } from "../../Utils/axios";
import { unauthorizedMessage } from "../Login/LoginActions";
import {
  getTaskChoicesIndexResponse,
  getTasksIndexResponse,
  postTaskResponse,
  showTaskResponse,
  updateTaskResponse,
} from "./TaskAction";

// WORKER SAGA
// GET ALL TASKS
export function* onGetTasksReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("integration/associated_tasks", payload).then(
        (response) => response?.data
      )
    );
    yield put(getTasksIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST TASK
export function* newTaskReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("integration/create_task", payload).then(
        (response) => response?.data
      )
    );
    yield put(postTaskResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE TASK
export function* updateTaskReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost(`integration/edit_task`, payload).then(
        (response) => response?.data
      )
    );
    yield put(updateTaskResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR TASK
export function* viewTaskReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost(`integration/get_task`, payload).then(
        (response) => response?.data
      )
    );
    yield put(showTaskResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET ALL TASK CHOICES
export function* onGetTaskChoicesReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("integration/choices_for_task_fields", payload).then(
        (response) => response?.data
      )
    );
    yield put(getTaskChoicesIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* TaskWatcherSaga() {
  yield takeLatest(
    TaskActionTypes.GET_TASK_INDEX_REQUEST,
    onGetTasksReqWorkerSaga
  );
  yield takeLatest(TaskActionTypes.POST_TASK_REQUEST, newTaskReqWorkerSaga);
  yield takeLatest(TaskActionTypes.EDIT_TASK_REQUEST, updateTaskReqWorkerSaga);
  yield takeLatest(TaskActionTypes.SHOW_TASK_REQUEST, viewTaskReqWorkerSaga);
  yield takeLatest(
    TaskActionTypes.GET_TASKCHOICES_REQUEST,
    onGetTaskChoicesReqWorkerSaga
  );
}
