import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { GWAY_IMG } from "../../../Utils/imageConstants";
import {
  Toolbar,
  ListItemText,
  ListItemButton,
  ListItem,
  List,
  IconButton,
  Drawer,
  CssBaseline,
  Box,
  AppBar,
  ListItemIcon,
  Collapse,
} from "@mui/material";
import {
  Close,
  CorporateFare,
  ExpandLess,
  ExpandMore,
  Menu,
  SettingsOutlined,
} from "@mui/icons-material";
import Navbar from "../Navbar/Navbar";
import "./SideNav.css";

const drawerWidth = 200;

function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const handleClickOpenSettings = () => {
    setOpenSettings(!openSettings);
  };

  const handleDrawerToggle = (e) => {
    e.stopPropagation();
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="sidenav_container">
      <div
        className="sidenav_logo"
        onClick={() => navigate("/")}
        onKeyDown={() => {}}
      >
        <img src={GWAY_IMG} alt="gwayLogo" />
        <Close
          sx={{ mr: 2, display: { sm: "none" } }}
          className="close_sidebar"
          onKeyDown={() => {}}
          onClick={(e) => handleDrawerToggle(e)}
        />
      </div>
      <div className="sidenav">
        <List>
          <NavLink to="/">
            <ListItem key={"Project"} disablePadding>
              <ListItemButton disableRipple>
                <ListItemIcon>
                  <CorporateFare className="sidenav_icon" />
                </ListItemIcon>
                <ListItemText primary={"Projects"} className="sidenav_name" />
              </ListItemButton>
            </ListItem>
          </NavLink>

          <>
            <ListItem key={""} disablePadding>
              <ListItemButton
                onClick={handleClickOpenSettings}
                onKeyDown={() => {}}
              >
                <ListItemIcon>
                  <SettingsOutlined className="sidenav_icon" />
                </ListItemIcon>
                <ListItemText primary="Settings" className="sidenav_name" />
                {openSettings ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={openSettings} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NavLink to="/integrate">
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText
                      primary="Integrations"
                      className="sidenav_name"
                    />
                  </ListItemButton>
                </NavLink>
              </List>
            </Collapse>
          </>
        </List>
      </div>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
        className="main_header"
      >
        <Toolbar className="navbar_toolbar">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onKeyDown={() => {}}
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <Menu />
          </IconButton>
          <Navbar />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { sm: "block", xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
