export default {
  LOGIN_REQUESTED: "LOGIN_REQUESTED",
  LOGIN_RESPONSE: "LOGIN_RESPONSE",

  LOGIN_RES_CLEAR: "LOGIN_RES_CLEAR",

  LOGOUT_REQ: "LOGOUT_REQ",
  LOGOUT_RESPONSE: "LOGOUT_RESPONSE",
  LOGOUT_RESPONSE_CLEAR: "LOGOUT_RESPONSE_CLEAR",
  LOGOUT_USER: "LOGOUT_USER",

  FORGOT_PASSWORD_REQ: "FORGOT_PASSWORD_REQ",
  FORGOT_PASSWORD_RESPONSE: "FORGOT_PASSWORD_RESPONSE",
  FORGOT_PASSWORD_RES_CLEAR: "FORGOT_PASSWORD_RES_CLEAR",

  CHANGE_PASSWORD_REQ: "CHANGE_PASSWORD_REQ",
  CHANGE_PASSWORD_RESPONSE: "CHANGE_PASSWORD_RESPONSE",
  CHANGE_PASSWORD_RES_CLEAR: "CHANGE_PASSWORD_RES_CLEAR",

  CHANGE_OLD_PASSWORD_REQ: "CHANGE_OLD_PASSWORD_REQ",
  CHANGE_OLD_PASSWORD_RESPONSE: "CHANGE_OLD_PASSWORD_RESPONSE",
  CHANGE_OLD_PASSWORD_RES_CLEAR: "CHANGE_OLD_PASSWORD_RES_CLEAR",

  ALL_USER_REQUESTED: "ALL_USER_REQUESTED",
  ALL_USER_SUCCESS: "ALL_USER_SUCCESS",
  ALL_USER_RES_CLEAR: "ALL_USER_RES_CLEAR",

  ALL_GROUPS_REQUESTED: "ALL_GROUPS_REQUESTED",
  ALL_GROUPS_SUCCESS: "ALL_GROUPS_SUCCESS",
  ALL_GROUPS_RES_CLEAR: "ALL_GROUPS_RES_CLEAR",

  CURRENT_USER_REQUESTED: "CURRENT_USER_REQUESTED",
  CURRENT_USER_SUCCESS: "CURRENT_USER_SUCCESS",
  CURRENT_USER_RES_CLEAR: "CURRENT_USER_RES_CLEAR",

  UPDATE_PROFILEPHOTO_REQUEST: "UPDATE_PROFILEPHOTO_REQUEST",
  UPDATE_PROFILEPHOTO_RESPONSE: "UPDATE_PROFILEPHOTO_RESPONSE",
  UPDATE_PROFILEPHOTO_RES_CLEAR: "UPDATE_PROFILEPHOTO_RES_CLEAR",
};

export const UNAUTHORIZED_ERROR = "UNAUTHORIZED_ERROR";
export const CLEAR_ERROR_RES = "CLEAR_ERROR_RES";
