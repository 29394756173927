import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./TestDesign.css";
import { Avatar } from "@mui/material";
import CustomInput from "../../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  deleteTestDesignCommentRequest,
  deleteTestDesignCommentResponseClear,
  getTestDesignCommentIndexRequest,
  postTestDesignCommentRequest,
  postTestDesignCommentResponseClear,
  showTestDesignCommentRequest,
  showTestDesignCommentResponseClear,
  updateTestDesignCommentRequest,
} from "../../../Redux/TestDesignSaga/TestDesignCommentSaga/TestDesignCommentAction";
import moment from "moment";
import TestMenu from "./TestMenu";

export default function TestDesignComment({ testCaseId }) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.loginState);

  const {
    testDesignCommentList,
    testDesignComment,
    postTestDesignCommentLoading,
    deleteTestDesignCommentLoading,
    postTestDesignCommentResponse,
    deleteTestDesignCommentResponse,
  } = useSelector((state) => state.testDesignComment);

  const validation = Yup.object().shape({
    comment: Yup.string().when("type", {
      is: (type) => type !== "edit",
      then: (schema) => schema.required("Comment is Required"),
    }),
    editComment: Yup.string().when("type", {
      is: (type) => type === "edit",
      then: (schema) => schema.required("Comment is required!"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      if (values?.type === "edit") {
        dispatch(
          updateTestDesignCommentRequest({
            id: values?.id,
            value: values?.editComment,
          })
        );
      } else {
        dispatch(
          postTestDesignCommentRequest({
            test_case_fk: testCaseId,
            comment: values?.comment,
          })
        );
      }
    },
  });

  // TO GET ALL COMMENTS ON INITIAL RENDERING
  useEffect(() => {
    if (postTestDesignCommentLoading || deleteTestDesignCommentLoading) return;
    dispatch(
      getTestDesignCommentIndexRequest({
        filters: {
          test_case_fk: {
            type: "exact",
            value: testCaseId,
          },
        },
      })
    );
  }, [
    postTestDesignCommentLoading,
    deleteTestDesignCommentLoading,
    testCaseId,
  ]);

  // TOAST MESSAGE FOR CREATE AND EDIT COMMENT
  useEffect(() => {
    if (!postTestDesignCommentResponse) return;
    if (postTestDesignCommentResponse) {
      if (
        postTestDesignCommentResponse?.message &&
        postTestDesignCommentResponse?.success
      ) {
        toast.success(postTestDesignCommentResponse?.message);
        formik.resetForm();
      } else {
        toast.error(postTestDesignCommentResponse?.message);
      }
    }
    return () => {
      dispatch(postTestDesignCommentResponseClear());
    };
  }, [postTestDesignCommentResponse]);

  // TOAST MESSAGE FOR DELETE COMMENT
  useEffect(() => {
    if (!deleteTestDesignCommentResponse) return;
    if (deleteTestDesignCommentResponse) {
      if (
        deleteTestDesignCommentResponse?.message &&
        deleteTestDesignCommentResponse?.success
      ) {
        toast.success(deleteTestDesignCommentResponse?.message);
        formik.setFieldValue("editComment", "");
      } else {
        toast.error(deleteTestDesignCommentResponse?.message);
      }
    }
    return () => {
      dispatch(deleteTestDesignCommentResponseClear());
    };
  }, [deleteTestDesignCommentResponse]);

  // SETTING DATA TO FIELD WHILE EDITING
  useEffect(() => {
    if (testDesignComment) {
      formik.setFieldValue("editComment", testDesignComment?.comment);
      formik.setFieldValue("id", testDesignComment?.id);
      formik.setFieldValue("type", "edit");
    }
    return () => {
      dispatch(showTestDesignCommentResponseClear());
    };
  }, [testDesignComment]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="col-md-12">
            <CustomInput
              placeholder="Enter Comment"
              multiline
              rows={5}
              type="text"
              name="comment"
              size="large"
              autoComplete="off"
              onChange={!formik.values.type ? formik.handleChange : ""}
              onBlur={formik.handleBlur}
              value={formik.values.comment}
              formik={formik}
              disabled={formik.values.type}
            />
          </div>
        </div>

        <div className="popformbutton">
          <CustomButton
            size="small"
            label="Add Comment"
            type="submit"
            disabled={formik.values.type}
          />
        </div>
      </form>
      <div className="commentWrpr">
        <p className="commentTit">Comments</p>
        {testDesignCommentList?.data?.length > 0 ? (
          testDesignCommentList?.data?.map((data) => (
            <>
              <div className="singleComment" key={data?.id}>
                <Avatar>
                  {data?.commented_by_fk?.full_name[0].toUpperCase()}
                </Avatar>
                <div className="commentCont">
                  <div>
                    <div className="d-flex gap-2">
                      <p className="commentTit">
                        {data?.commented_by_fk?.full_name[0].toUpperCase() +
                          data?.commented_by_fk?.full_name.slice(1)}
                      </p>
                      <p>
                        {moment(data.created_at).format(
                          "DD.MM.YYYY hh:mm:ss A"
                        )}
                      </p>
                    </div>
                    <p className="mt-1">{data?.comment}</p>
                  </div>
                  <div>
                    {user?.id === data?.commented_by_fk?.id && (
                      <TestMenu
                        data={data}
                        deleteAction={deleteTestDesignCommentRequest}
                        editAction={showTestDesignCommentRequest}
                        res={deleteTestDesignCommentResponse}
                        loading={deleteTestDesignCommentLoading}
                      />
                    )}
                  </div>
                </div>
              </div>
              {formik?.values?.type === "edit" &&
                formik?.values?.id === data?.id && (
                  <div className="noneInput">
                    <div className="w-100 ">
                      <CustomInput
                        name="editComment"
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.editComment}
                        formik={formik}
                        className="input"
                        placeholder="Edit Comment.."
                      />
                      <div className="popformbutton">
                        <CustomButton
                          size="small"
                          label="Send"
                          btnType="secondary"
                          onClick={formik.handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </>
          ))
        ) : (
          <div className="singleComment d-flex">
            <div className="commentCont">
              <div>
                <p className="mt-1">No Comments</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
