export default {
  // BUILD
  BUILD_INDEX_REQUEST: "BUILD_INDEX_REQUEST",
  BUILD_INDEX_RESPONSE: "BUILD_INDEX_RESPONSE",

  POST_BUILD_REQUEST: "POST_BUILD_REQUEST",
  POST_BUILD_RESPONSE: "POST_BUILD_RESPONSE",

  UPDATE_BUILD_REQUEST: "UPDATE_BUILD_REQUEST",
  UPDATE_BUILD_RESPONSE: "UPDATE_BUILD_RESPONSE",

  POST_BUILD_RES_CLEAR: "POST_BUILD_RES_CLEAR",

  DELETE_BUILD_REQUEST: "DELETE_BUILD_REQUEST",
  DELETE_BUILD_RESPONSE: "DELETE_BUILD_RESPONSE",
  DELETE_BUILD_RES_CLEAR: "DELETE_BUILD_RES_CLEAR",

  SHOW_BUILD_REQUEST: "SHOW_BUILD_REQUEST",
  SHOW_BUILD_RESPONSE: "SHOW_BUILD_RESPONSE",
  SHOW_BUILD_RES_CLEAR: "SHOW_BUILD_RES_CLEAR",

  // BUILD ATTACHMENTS
  BUILDATTACHMENT_INDEX_REQUEST: "BUILDATTACHMENT_INDEX_REQUEST",
  BUILDATTACHMENT_INDEX_RESPONSE: "BUILDATTACHMENT_INDEX_RESPONSE",
  BUILDATTACHMENT_INDEX_RES_CLEAR: "BUILDATTACHMENT_INDEX_RES_CLEAR",

  POST_BUILDATTACHMENT_REQUEST: "POST_BUILDATTACHMENT_REQUEST",
  POST_BUILDATTACHMENT_RESPONSE: "POST_BUILDATTACHMENT_RESPONSE",

  UPDATE_BUILDATTACHMENT_REQUEST: "UPDATE_BUILDATTACHMENT_REQUEST",
  UPDATE_BUILDATTACHMENT_RESPONSE: "UPDATE_BUILDATTACHMENT_RESPONSE",

  POST_BUILDATTACHMENT_RES_CLEAR: "POST_BUILDATTACHMENT_RES_CLEAR",

  DELETE_BUILDATTACHMENT_REQUEST: "DELETE_BUILDATTACHMENT_REQUEST",
  DELETE_BUILDATTACHMENT_RESPONSE: "DELETE_BUILDATTACHMENT_RESPONSE",
  DELETE_BUILDATTACHMENT_RES_CLEAR: "DELETE_BUILDATTACHMENT_RES_CLEAR",

  SHOW_BUILDATTACHMENT_REQUEST: "SHOW_BUILDATTACHMENT_REQUEST",
  SHOW_BUILDATTACHMENT_RESPONSE: "SHOW_BUILDATTACHMENT_RESPONSE",
  SHOW_BUILDATTACHMENT_RES_CLEAR: "SHOW_BUILDATTACHMENT_RES_CLEAR",
};
