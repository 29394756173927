import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Pie, Bar } from "react-chartjs-2";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import moment from "moment";
import { convertedString, handleNoChartData } from "../../Utils/handles";
import { getReportIndexRequest } from "../../Redux/ReportSaga/ReportAction";
import { showProjectRequest } from "../../Redux/ProjectSaga/ProjectAction";
import {
  Chart as ChartJs,
  Tooltip,
  ArcElement,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import {
  TEST_FORGE_IMG,
  PIE_CHART_IMG,
  BAR_CHART_IMG,
  NO_CHART_IMG,
} from "../../Utils/imageConstants";
import {
  reportPieChartStatus,
  reportBarChartStatus,
} from "../../Utils/constants";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import ReportFilter from "./ReportFilter";
import ReportChartLabel from "./ReportChartLabel";
import "./Report.css";
import NoChartData from "../../Components/Molecules/NoChartData/NoChartData";

ChartJs.register(
  ArcElement,
  Tooltip,
  Title,
  CategoryScale,
  LinearScale,
  BarElement
);

const Report = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState({ open: false, type: "" });
  const [currentDateTime, setCurrentDateTime] = useState({
    date: "",
    time: "",
  });
  const [checkboxValues, setCheckboxValues] = useState({
    selectAll: false,
    passed: false,
    in_progress: false,
    failed: false,
    unexecuted: false,
  });
  const [barCheckBoxValues, setBarCheckBoxValues] = useState({
    selectAll: false,
    new: false,
    reopened: false,
    suggestion: false,
    not_a_bug: false,
    on_hold: false,
    closed: false,
    fixed: false,
  });

  const { report } = useSelector((state) => state.report);
  const { user } = useSelector((state) => state.loginState);
  const { project } = useSelector((state) => state.project);

  // GET PARTICULAR PROJECT & REPORT ON INITIAL RENDERING
  useEffect(() => {
    dispatch(showProjectRequest(id));
    dispatch(getReportIndexRequest({ project_id: id }));
    setCurrentDateTime({
      date: moment().format("DD/MM/YYYY"),
      time: moment().format("HH:mm"),
    });
  }, []);

  // HANDLE OPEN AND CLOSE DIALOG
  const handleOpenDialog = (value) => {
    setOpenDialog((prev) => ({ ...prev, open: true, type: value }));
  };

  const handleCloseDialog = () => {
    setOpenDialog((prev) => ({ ...prev, open: false, type: "" }));
  };

  const defectStatusesArray = Object.values(report?.defect_statuses || {});
  const testRunResultsArray = Object.values(report?.test_run_results || {});
  const piechartkeys = Object.keys(report?.test_run_results || {});
  const barChartKeys = Object.keys(report?.defect_statuses || {});

  const tablePieChartData = piechartkeys.map((key, index) => ({
    ...testRunResultsArray[index],
    name: key,
  }));
  const tableBarChartData = barChartKeys.map((key, index) => ({
    ...defectStatusesArray[index],
    name: key,
  }));

  // DATA FOR PIECHART
  const pieChartData = Object.keys(report?.test_run_results || {}).map(
    (key) => report?.test_run_results?.[key]?.count || 0
  );

  const handleBackGroudColor = () => {
    const backGroundColorData = [];
    const keys = Object.keys(report?.test_run_results || {}).map((key) => {
      if (key === "PASSED") {
        backGroundColorData.push("rgb(167,200,43)");
      } else if (key === "UNEXECUTED") {
        backGroundColorData.push("rgb(253,140,28)");
      } else if (key === "IN_PROGRESS") {
        backGroundColorData.push("rgb(126,179,239)");
      } else {
        backGroundColorData.push("rgb(219,51,73)");
      }
    });
    return backGroundColorData;
  };

  const data = {
    datasets: [
      {
        data: pieChartData,
        backgroundColor: handleBackGroudColor(),
        borderWidth: 1,
      },
    ],
  };

  // OPTIONS FOR PIECHART
  const options = {
    cutout: "50%",
    plugins: {
      tooltip: {
        enabled: true,
      },
    },
    elements: {
      arc: {
        borderRadius: 5,
      },
    },
    spacing: 5,
  };
  const barChartData = Object.keys(report?.defect_statuses || {}).map(
    (key) => report?.defect_statuses?.[key]?.count || 0
  );

  const handleBackGroudColorBarChart = () => {
    const backGroundColorData = [];
    const keys = Object.keys(report?.defect_statuses || {}).map((key) => {
      if (key === "NEW") {
        backGroundColorData.push("rgb(43,186,194)");
      } else if (key === "FIXED") {
        backGroundColorData.push("rgb(28,102,168)");
      } else if (key === "REOPENED") {
        backGroundColorData.push("rgb(219,48,71)");
      } else if (key === "ON_HOLD") {
        backGroundColorData.push("rgb(212,102,133)");
      } else if (key === "CLOSED") {
        backGroundColorData.push("rgb(247,124,0)");
      } else if (key === "NOT_A_BUG") {
        backGroundColorData.push("rgb(138,166,59)");
      } else {
        backGroundColorData.push("rgb(126,179,239)");
      }
    });
    return backGroundColorData;
  };

  const maximum = Math.max(...barChartData);
  // DATA FOR BARCHART
  const multiLineData = {
    labels: ["", "", "", "", "", "", ""],
    datasets: [
      {
        data: barChartData,
        backgroundColor: handleBackGroudColorBarChart(),
      },
    ],
  };

  //OPTIONS FOR BARCHART
  const multiLineOptions = {
    cutout: "10%",
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: maximum,
        ticks: {
          stepSize: parseInt(maximum / 5),
        },
        grid: {
          // display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
      bar: {
        borderRadius: 10,
        barThickness: 1,
      },
    },
  };

  const pieChartCanvas = useRef(null);
  const barChartCanvas = useRef(null);
  const pdfHeadData = [""];

  const exportToPDF = async () => {
    let TEST_FORGE_IMG_BASE64 = "";
    let PIE_CHART_IMG_BASE64 = "";
    const table = document.getElementById("table_content");
    const testForgelogo = document.getElementById("testforge_logo");
    const pieChartlogo = document.getElementById("piechart_status");
    const barChartlogo = document.getElementById("barchart_status");

    try {
      const canvas = await html2canvas(pieChartCanvas.current);
      const imgData = canvas.toDataURL("image/png");

      const pieCanvas = await html2canvas(pieChartlogo);
      PIE_CHART_IMG_BASE64 = pieCanvas.toDataURL("image/png");

      const barCanvas = await html2canvas(barChartlogo);
      const barChartData = barCanvas.toDataURL("image/png");

      const logoCanvas = await html2canvas(testForgelogo);
      TEST_FORGE_IMG_BASE64 = logoCanvas.toDataURL("image/png");

      const barChart = await html2canvas(barChartCanvas?.current);
      const imgBarData = barChart.toDataURL("image/png");

      const pdf = new jsPDF("l", "mm", "a4");
      const width = pdf.internal.pageSize.width;

      pdf.autoTable({
        startY: 10,
        head: [["", "", "", "", ""]],
        body: pdfHeadData.map((data, index) => [
          "                                    ",
          "                                    ",
          "                                    ",
          "                                    ",
          ` Date : ${currentDateTime.date} 
 Time : ${currentDateTime.time}
 Generated by : ${user?.first_name} ${user?.last_name}`,
        ]),
        theme: "grid",
        headStyles: {
          fillColor: "#fff",
          textColor: "#000",
        },
        styles: {
          cellPadding: 2,
          cellSpacing: 0,
          lineWidth: 0,
          fontSize: 12,
          lineColor: [0, 0, 0],
        },
        didDrawCell: (data) => {
          if (
            data.section === "body" &&
            data.column.index === 0 &&
            data.row.index === 0
          ) {
            const base64Img = TEST_FORGE_IMG_BASE64;
            const imgWidth = data.cell.width * 1; // Width of 2 columns
            const imgHeight = data.cell.height * 1; // Height of 2 rows
            const adjustedX = data.cell.y + data.cell.height - imgHeight;
            const adjustedY = data.cell.y + data.cell.height - imgHeight;

            pdf.addImage(
              base64Img,
              "JPEG",
              adjustedX - 5,
              adjustedY + 0,
              imgWidth,
              imgHeight
            );
          }
        },
      });

      const pdfWidth = pdf.internal.pageSize.width;
      const imgWidth = 100; // Replace with the actual width of your image

      // Calculate the x-coordinate to center the image
      const xCoordinate = (pdfWidth - imgWidth) / 2;

      pdf.addImage(imgData, "PNG", xCoordinate, 40, 100, 60);

      pdf.addImage(PIE_CHART_IMG_BASE64, "PNG", xCoordinate + 10, 110, 100, 20);

      pdf.autoTable({
        startY: pdf.autoTable.previous.finalY + 100,
        head: [["No", "Status", "Percentage", "Count"]],
        body: tablePieChartData.map((data, index) => [
          index + 1,
          convertedString(data?.name),
          data?.percentage,
          data?.count,
        ]),
        theme: "grid",
        headStyles: {
          fillColor: "#f3f2f2",
          textColor: "#000",
        },
        styles: {
          cellPadding: 2,
          cellSpacing: 0,
          lineWidth: 0,
          lineColor: [0, 0, 0],
          fontSize: 11, // Adjust font size for body
          rowHeight: 11, // Adjust rowHeight as needed
        },
      });

      pdf.addPage();

      const pdfWidth2 = pdf.internal.pageSize.width;
      const imgWidth2 = 210; // Replace with the actual width of your image

      // Calculate the x-coordinate to center the image
      const xCoordinate2 = (pdfWidth2 - imgWidth2) / 2;

      pdf.addImage(imgBarData, "PNG", xCoordinate2, 20, 210, 50);

      pdf.addImage(barChartData, "PNG", xCoordinate2 + 60, 70, 170, 20);

      pdf.autoTable({
        startY: 90,
        head: [["No", "Status", "Percentage", "Count"]],
        body: tableBarChartData.map((data, index) => [
          index + 1,
          convertedString(data?.name),
          data?.percentage,
          data?.count,
        ]),
        theme: "grid",
        headStyles: {
          fillColor: "#f3f2f2",
          textColor: "#000",
          fontSize: 12,
          lineColor: [0, 0, 0],
        },
        styles: {
          cellPadding: 2,
          cellSpacing: 0,
          lineWidth: 0,
          lineColor: [0, 0, 0],
          fontSize: 11, // Adjust font size for body
          rowHeight: 12, // Adjust rowHeight as needed
        },
      });

      pdf.save(`${project?.name} report.pdf`);
    } catch (error) {}
  };

  // const getSelectedValues = () => {
  //   if (openDialog.type === "pie") {
  //     const selectedValues = [];
  //     Object.entries(checkboxValues).forEach(([key, value]) => {
  //       if (value && key !== "selectAll") {
  //         selectedValues.push(key.toUpperCase());
  //       }
  //     });
  //     return selectedValues;
  //   } else {
  //     const barSelectedValues = [];
  //     Object.entries(barCheckBoxValues).forEach(([key, value]) => {
  //       if (value && key !== "selectAll") {
  //         barSelectedValues.push(key.toUpperCase());
  //       }
  //     });
  //     return barSelectedValues;
  //   }
  // };

  // const handlePreviousPieValue = () => {
  //   const selectedValues = [];
  //   Object.entries(checkboxValues).forEach(([key, value]) => {
  //     if (value && key !== "selectAll") {
  //       selectedValues.push(key.toUpperCase());
  //     }
  //   });
  //   return selectedValues;
  // };

  // const handlePreviousBarValue = () => {
  //   const barSelectedValues = [];
  //   Object.entries(barCheckBoxValues).forEach(([key, value]) => {
  //     if (value && key !== "selectAll") {
  //       barSelectedValues.push(key.toUpperCase());
  //     }
  //   });
  //   return barSelectedValues;
  // };

  return (
    <div className="report_container">
      <div
        className="dashboard_container releaseLayout w-100 overflow-auto"
        style={{ position: "absolute", zIndex: "10" }}
      >
        <div className="position-relative">
          <div className="test_header d-flex align-items-center justify-content-between">
            <h3>Reports</h3>
            <div>
              {handleNoChartData(report?.defect_statuses) ? (
                ""
              ) : (
                <CustomButton
                  size="small"
                  label="Export"
                  onClick={exportToPDF}
                />
              )}
            </div>
          </div>
        </div>

        <div className="d-flex gap-3 w-100 report_chart_container">
          <div className="tblWrpr w-100">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Latest Test Run Results</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex gap-2 align-items-end justify-content-end">
                        {/* <CustomButton
                          label="Reset All"
                          size={"small"}
                          onClick={() => {
                            dispatch(
                              getReportIndexRequest({
                                project_id: id,
                                test_run_report: {
                                  required_statuses:
                                    openDialog.type === "pie"
                                      ? []
                                      : handlePreviousPieValue(),
                                },
                                defect_report: {
                                  required_statuses:
                                    openDialog.type === "pie"
                                      ? handlePreviousBarValue()
                                      : getSelectedValues(),
                                },
                              })
                            );
                          }}
                        /> */}
                        <CustomButton
                          size="small"
                          label="Filter"
                          onClick={() => handleOpenDialog("pie")}
                        />
                      </div>
                      <div className="piechart2" ref={pieChartCanvas}>
                        {handleNoChartData(report?.test_run_results) ? (
                          <NoChartData isVisible={false} />
                        ) : (
                          <Pie data={data} options={options} />
                        )}
                      </div>
                      <ReportChartLabel data={reportPieChartStatus} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="tblWrpr w-100">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Defects Results</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ padding: 0 }}>
                      <div className="d-flex gap-2 align-items-end justify-content-end m-2">
                        {/* <CustomButton
                          label="Reset All"
                          size={"small"}
                          onClick={() => {
                            dispatch(
                              getReportIndexRequest({
                                project_id: id,
                                test_run_report: {
                                  required_statuses:
                                    openDialog.type === "pie"
                                      ? getSelectedValues()
                                      : handlePreviousPieValue(),
                                },
                                defect_report: {
                                  required_statuses:
                                    openDialog.type === "pie"
                                      ? handlePreviousBarValue()
                                      : [],
                                },
                              })
                            );
                          }}
                        /> */}
                        <CustomButton
                          label="Filter"
                          size={"small"}
                          onClick={() => handleOpenDialog("bar")}
                        />
                      </div>
                      <div className="barchart2" ref={barChartCanvas}>
                        {handleNoChartData(report?.defect_statuses) ? (
                          <NoChartData isVisible={false} />
                        ) : (
                          <Bar
                            options={multiLineOptions}
                            data={multiLineData}
                          />
                        )}
                      </div>
                      <ReportChartLabel data={reportBarChartStatus} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        open={openDialog.open}
        title={"Report"}
        handleClose={handleCloseDialog}
        maxWidth="xs"
      >
        <ReportFilter
          handleClose={handleCloseDialog}
          type={openDialog.type}
          checkboxValues={checkboxValues}
          barCheckBoxValues={barCheckBoxValues}
          setCheckboxValues={setCheckboxValues}
          setBarCheckBoxValues={setBarCheckBoxValues}
          // getSelectedValues={getSelectedValues}
          // handlePreviousPieValue={handlePreviousPieValue}
          // handlePreviousBarValue={handlePreviousBarValue}
        />
      </CustomDialog>
      <div style={{ opacity: 0, position: "absolute", zIndex: 0, top: 0 }}>
        <img src={TEST_FORGE_IMG} id="testforge_logo" />
        <img src={PIE_CHART_IMG} id="piechart_status" />
        <img src={BAR_CHART_IMG} id="barchart_status" />
      </div>
    </div>
  );
};

export default Report;
