import { takeLatest, call, put } from "redux-saga/effects";
import RegisterActionTypes from "./RegisterActionTypes";
import {
  RegisterSuccess,
  cityListResponse,
  countryListResponse,
  departmentListResponse,
  otpSuccess,
  stateListResponse,
} from "./RegisterActions";
import { axiosGet, axiosPost } from "../../Utils/axios";
import { unauthorizedMessage } from "../Login/LoginActions";

export function* registerUserWorkerSaga({ payload }) {
  try {
    let res = yield call(() =>
      axiosPost("register", payload).then((response) => response?.data)
    );

    yield put(RegisterSuccess(res));
  } catch (error) {
    yield put(RegisterSuccess(error));
  }
}

export function* otpWorkerSaga({ payload }) {
  try {
    let queryParams = "verify_otp";
    let res = yield call(() =>
      axiosPost(queryParams, payload).then((response) => response?.data)
    );
    yield put(otpSuccess(res));
  } catch (error) {
    yield put(otpSuccess(error));
  }
}

// COUNTRY LIST
export function* countryListWorkerSaga({ payload }) {
  try {
    let res = yield call(() =>
      axiosGet("get_country").then((response) => response)
    );

    yield put(countryListResponse(res));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// STATE LIST
export function* stateListWorkerSaga({ payload }) {
  try {
    let res = yield call(() =>
      axiosGet(`get_state_by_country/${payload}`).then((response) => response)
    );

    yield put(stateListResponse(res));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// CITY LIST
export function* cityListWorkerSaga({ payload }) {
  try {
    let res = yield call(() =>
      axiosGet(
        `get_city_by_state_country/${payload?.country}/${payload?.state}`
      ).then((response) => response)
    );

    yield put(cityListResponse(res));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DEPARTMENT LIST
export function* departmentListWorkerSaga({ payload }) {
  try {
    let res = yield call(() =>
      axiosGet("get_departments").then((response) => response?.data)
    );

    yield put(departmentListResponse(res));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* registerWatcherSaga() {
  yield takeLatest(
    RegisterActionTypes.REGISTER_USER_REQUEST,
    registerUserWorkerSaga
  );
  yield takeLatest(RegisterActionTypes.OTP_REQUEST, otpWorkerSaga);
  yield takeLatest(
    RegisterActionTypes.GET_COUNTRYLIST_REQUEST,
    countryListWorkerSaga
  );
  yield takeLatest(
    RegisterActionTypes.GET_STATELIST_REQUEST,
    stateListWorkerSaga
  );
  yield takeLatest(
    RegisterActionTypes.GET_CITYLIST_REQUEST,
    cityListWorkerSaga
  );
  yield takeLatest(
    RegisterActionTypes.GET_DEPARTMENTLIST_REQUEST,
    departmentListWorkerSaga
  );
}
