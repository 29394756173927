import InviteActionTypes from "./InviteActiontypes";

export const inviteRequest = (payload) => ({
  type: InviteActionTypes.INVITE_REQUESTED,
  payload: payload,
});

export const inviteSuccess = (payload) => ({
  type: InviteActionTypes.INVITE_RESPONSE,
  payload: payload,
});

export const InviteUserResClear = () => ({
  type: InviteActionTypes.INVITE_RES_CLEAR,
});

export const inviteIndexUserRequest = () => ({
  type: InviteActionTypes.INVITE_INDEX_REQUEST,
});

export const inviteIndexUserSuccess = (payload) => ({
  type: InviteActionTypes.INVITE_INDEX_RESPONSE,
  payload: payload,
});

export const inviteIndexUserResClear = () => ({
  type: InviteActionTypes.INVITE_INDEX_RES_CLEAR,
});
