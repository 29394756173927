import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { LogoutReq, clearErrorRes } from "../Redux/Login/LoginActions";
import LoadingAnimation from "../Components/Atoms/LoadingAnimation/LoadingAnimation";

function ProtectedRoutes() {
  const { user, userLoading, unauthorized } = useSelector(
    (state) => state.loginState
  );
  const token = localStorage.getItem("token");

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!unauthorized) return;
    if (unauthorized?.response?.data?.detail) {
      if (unauthorized?.response?.data?.detail === "Token Must be Provided") {
        // Do nothing if no token is provided
      } else if (unauthorized?.response?.data?.detail === "Unauthorized") {
        localStorage.clear();
        alert(
          "You're logged in from different device or location. So you,ll be redirected to login page, Thank you!"
        );
        window.location.reload();
      } else if (unauthorized?.response?.data?.detail === "Signature Failed") {
        //handle logout here
        dispatch(LogoutReq({ token: localStorage.getItem("token") }));
        localStorage.clear();
      } else if (unauthorized?.response?.data?.detail === "Token Expired") {
        toast.error("Session Expired!");
        //handle logout here
        dispatch(LogoutReq({ token: localStorage.getItem("token") }));
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (
        unauthorized?.response?.data?.detail === "Your account has been deleted"
      ) {
        toast.error(unauthorized?.response?.data?.detail);
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error(unauthorized?.response?.data?.detail);
      }
    } else {
      toast.error(unauthorized?.message || "Something went wrong!");
    }
    dispatch(clearErrorRes());
  }, [unauthorized]);

  if (userLoading && !user) {
    return <LoadingAnimation />;
  }
  return Boolean(token) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default ProtectedRoutes;
