import React, { useEffect, useState } from "react";
import IntegrationForm from "./IntegrationForm";
import CustomDialog from "../../../Components/Atoms/CustomDialog/CustomDialog";
import CustomButton from "../../../Components/Atoms/CustomButton/CustomButton";
import MetaData from "../../../Components/Organisms/MetaData/MetaData";
import "./Integration.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationsIndexRequest,
  postIntegrationResClear,
  showIntegrateRequest,
} from "../../../Redux/IntegrationSaga/IntegrationAction";
import LoadingAnimation from "../../../Components/Atoms/LoadingAnimation/LoadingAnimation";
import { convertedString, handleToastResponse } from "../../../Utils/handles";
import { appUrl } from "../../../Utils/axios";

const IntegrationIndex = () => {
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState({
    open: false,
    type: "",
    id: "",
  });

  const {
    appList,
    postIntegrationLoading,
    postIntegrationResponse,
    viewIntegration,
    appListLoading,
  } = useSelector((state) => state.integrate);

  const handleOpen = (val, id) => {
    setOpenDialog({ open: true, type: val, id: id });
  };

  const handleClose = () => {
    setOpenDialog({ open: false, type: "", id: "" });
  };

  // GET ALL APPLICATIONS
  useEffect(() => {
    if (postIntegrationLoading) return;
    dispatch(
      getApplicationsIndexRequest({
        filters: {},
        pagination_meta: {},
      })
    );
  }, [postIntegrationLoading]);

  if (appListLoading) {
    <LoadingAnimation />;
  }

  useEffect(() => {
    handleToastResponse(postIntegrationResponse);
    if (postIntegrationResponse?.success) {
      handleClose();
    }
    return () => {
      dispatch(postIntegrationResClear());
    };
  }, [postIntegrationResponse]);

  return (
    <>
      <MetaData title="Integration" />
      <div className="pageTop">
        <div className="pageTopLeft">
          <h3>Integrations</h3>
        </div>
      </div>
      <div className="integration_container bg-white py-4 px-4 ">
        {appList?.data?.map((data, index) => (
          <div className="integration_box" key={data?.id}>
            <div className="logo_container">
              <div className="logo_img_box">
                {typeof data?.icon === "string" ? (
                  <img src={`${appUrl}${data?.icon}`} alt={data?.name} /> // For mapping images
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    {data.icon}
                  </div> // For mapping MUI Icon
                )}
              </div>
              <h2>{convertedString(data?.name)}</h2>
            </div>
            <div>
              <p className="mb-2"> {data?.help_text}</p>
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={`connect_button ${
                    data?.is_integrated ? "not_clickable" : "clickable"
                  }`}
                >
                  <CustomButton
                    label={data.is_integrated ? "Connected" : "Connect"}
                    size="small"
                    btnType={data?.is_integrated ? "secondary" : ""}
                    onClick={() => handleOpen("add", data?.id)}
                    disabled={data.is_integrated}
                  />
                </div>
                {data?.is_integrated && (
                  <div className="clickable">
                    <p
                      style={{ color: "#5B6CEE", fontWeight: 500 }}
                      className="clickable"
                      onClick={() => {
                        dispatch(
                          showIntegrateRequest(data?.integration_data?.id)
                        );
                        handleOpen("edit", data?.integration_data?.id);
                      }}
                    >
                      View Details
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <CustomDialog
        title="Integrate"
        handleClose={handleClose}
        open={openDialog.open}
      >
        <IntegrationForm
          handleClose={handleClose}
          openDialog={openDialog}
          viewIntegration={viewIntegration}
        />
      </CustomDialog>
    </>
  );
};

export default IntegrationIndex;
