import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import Moment from "moment";
import "./Requirement.css";
import { Avatar } from "@mui/material";

import CustomInput from "../../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton/CustomButton";
import { IconButton } from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";

import { styled, alpha } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";

import toast from "react-hot-toast";
import {
  deleteRequirementCommentRequest,
  deleteRequirementCommentResponseClear,
  getRequirementCommentIndexRequest,
  postRequirementCommentRequest,
  postRequirementCommentResponseClear,
  showRequirementCommentRequest,
  showRequirementCommentResponse,
  showRequirementCommentResponseClear,
  updateRequirementCommentRequest,
} from "../../../Redux/RequirementSaga/RequirementCommentSaga/RequirementCommentAction";
import { appUrl } from "../../../Utils/axios";
import { Form } from "react-router-dom";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const RequirementComment = ({ requirementId }) => {
  const {
    postRequirementCommentResponse,
    requirementCommentList,
    deleteRequirementCommentResponse,

    requirementComment,
  } = useSelector((state) => state.requirementcomment);
  const [selectedCommentId, setSelectedCommentId] = useState(null);

  const { user } = useSelector((state) => state.loginState);

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const payload = {
    filters: {
      requirement_fk: {
        type: "exact",
        value: requirementId,
      },
    },
  };

  useEffect(() => {
    dispatch(getRequirementCommentIndexRequest(payload));
  }, []);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedCommentId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickView = () => {
    if (selectedCommentId !== null) {
      dispatch(showRequirementCommentRequest(selectedCommentId));
    }
    handleClose();
  };

  const validation = Yup.object().shape({
    comment: Yup.string()
      .required("Comment is required!")
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters"),
  });

  const formik = useFormik({
    initialValues: {
      requirement_fk: requirementId,
      comment: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      dispatch(postRequirementCommentRequest(values));
    },
  });

  // Toast message for add commend
  useEffect(() => {
    if (postRequirementCommentResponse) {
      if (
        postRequirementCommentResponse?.message &&
        postRequirementCommentResponse?.success
      ) {
        formik.resetForm();
        Formik.resetForm();
        dispatch(getRequirementCommentIndexRequest(payload));
        toast.success(postRequirementCommentResponse?.message);
      } else {
        toast.error(postRequirementCommentResponse?.message);
      }
    }
    return () => {
      dispatch(postRequirementCommentResponseClear());
      dispatch(showRequirementCommentResponseClear());
    };
  }, [postRequirementCommentResponse]);

  //delete
  useEffect(() => {
    if (deleteRequirementCommentResponse) {
      if (
        deleteRequirementCommentResponse?.message &&
        deleteRequirementCommentResponse?.success
      ) {
        dispatch(getRequirementCommentIndexRequest(payload));
        toast.success(deleteRequirementCommentResponse?.message);
      } else {
        toast.error(deleteRequirementCommentResponse?.message);
      }
    }
    return () => {
      dispatch(deleteRequirementCommentResponseClear());
    };
  }, [deleteRequirementCommentResponse]);

  //edit comment

  const Formik = useFormik({
    initialValues: {
      requirement_fk: selectedCommentId,
      comment: "",
    },
    validationSchema: validation,

    onSubmit: (values) => {
      dispatch(updateRequirementCommentRequest(values));
    },
  });

  useEffect(() => {
    Formik.setValues({
      requirement_fk: selectedCommentId,
      comment: requirementComment?.comment,
    });
  }, [selectedCommentId, requirementComment]);

  return (
    <>
      <div className="">
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="col-md-12">
              <CustomInput
                placeholder="Enter a comment "
                multiline
                rows={5}
                type="text"
                name="comment"
                size="large"
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.comment}
                formik={formik}
              />
            </div>
          </div>

          <div className="popformbutton">
            <CustomButton
              size="small"
              label="Add Comment"
              btnType="primary"
              type="submit"
            />
          </div>
        </form>
        <div className="commentWrpr">
          <p className="commentTit">Comments</p>
          {requirementCommentList?.total_count > 0 ? (
            <>
              {requirementCommentList?.data?.map((data) => (
                <>
                  <div className="singleComment" key={data.id}>
                    <Avatar
                      src={
                        data?.commented_by_fk.profile_photo
                          ? `${appUrl}${data?.commented_by_fk.profile_photo}`
                          : null
                      }
                    />
                    <div className="commentCont">
                      <div>
                        <div className="d-flex gap-2">
                          <p className="commentTit">
                            {data?.commented_by_fk?.name}
                          </p>
                          <p className="">
                            {Moment(data.created_at).format(
                              "DD.MM.YYYY hh:mm:ss A"
                            )}
                          </p>
                        </div>
                        <p className="mt-1">{data.comment}</p>
                      </div>
                      {data?.commented_by_fk?.id === user?.id && (
                        <div>
                          <IconButton>
                            <MoreVertIcon
                              id="demo-customized-button"
                              aria-controls={
                                open ? "demo-customized-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              variant="contained"
                              disableElevation
                              onKeyDown={() => {}}
                              onClick={(event) => handleClick(event, data.id)}
                            />

                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                "aria-labelledby": "demo-customized-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                            >
                              <MenuItem
                                key={data.id}
                                onKeyDown={() => {}}
                                onClick={() => handleClickView(data.id)}
                                disableRipple
                              >
                                <EditIcon style={{ marginRight: "10px" }} />
                                Edit
                              </MenuItem>
                              {/* <Divider style={{ margin: 0 }} />
                            <MenuItem onClick={handleClose} disableRipple>
                              <FileCopyIcon style={{ marginRight: "10px" }} />
                              Copy
                            </MenuItem>
                            <Divider style={{ margin: 0 }} /> */}
                              {/* <MenuItem onClick={handleClose} disableRipple>
                              <ContentPasteIcon
                                style={{ marginRight: "10px" }}
                              />
                              Paste
                            </MenuItem> */}
                              <Divider style={{ margin: 0 }} />
                              <MenuItem
                                onKeyDown={() => {}}
                                onClick={() =>
                                  dispatch(
                                    deleteRequirementCommentRequest(
                                      selectedCommentId
                                    )
                                  )
                                }
                                disableRipple
                              >
                                <DeleteOutlineOutlinedIcon
                                  style={{ marginRight: "10px" }}
                                />
                                Delete
                              </MenuItem>
                            </StyledMenu>
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <div className="singleComment d-flex">
              <div className="commentCont">
                <div>
                  <p className="mt-1">No Comments</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {requirementCommentList?.total_count > 0 &&
        requirementComment !== null ? (
          <form onSubmit={Formik.handleSubmit} className="customInputWrpr">
            <div className="noneInput mt-1">
              <div></div>
              <div className="commentBrdr">
                <textarea
                  name="comment"
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                  value={Formik.values.comment}
                  formik={Formik}
                  className="input"
                  placeholder="Edit Comment.."
                />

                <div className="popformbutton">
                  <CustomButton
                    size="small"
                    label="Send"
                    btnType="secondary"
                    type="submit"
                  />
                </div>
              </div>
              {Formik.errors ? (
                <p className="errMsg commErr mt-2">{Formik.errors.comment}</p>
              ) : (
                ""
              )}
            </div>
          </form>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default RequirementComment;
