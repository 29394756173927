import DefectsActionTypes from "./DefectsActionTypes";

// DEFECTS
// GET ALL DEFECTS
export const getDefectsIndexRequest = (payload) => ({
  type: DefectsActionTypes.GET_DEFECTS_INDEX_REQUEST,
  payload,
});

export const getDefectsIndexResponse = (payload) => ({
  type: DefectsActionTypes.GET_DEFECTS_INDEX_RESPONSE,
  payload,
});

// GET PARTICULAR DEFECT
export const showDefectRequest = (payload) => ({
  type: DefectsActionTypes.SHOW_DEFECT_REQUEST,
  payload,
});

export const showDefectResponse = (payload) => ({
  type: DefectsActionTypes.SHOW_DEFECT_RESPONSE,
  payload,
});

export const showDefectResponseClear = () => ({
  type: DefectsActionTypes.SHOW_DEFECT_RES_CLEAR,
});

// UPDATE DEFECT
export const updateDefectRequest = (payload) => ({
  type: DefectsActionTypes.UPDATE_DEFECT_REQUEST,
  payload,
});

export const updateDefectResponse = (payload) => ({
  type: DefectsActionTypes.UPDATE_DEFECT_RESPONSE,
  payload,
});

// UPDATE DEFECT RESPONSE CLEAR
export const postDefectResponseClear = () => ({
  type: DefectsActionTypes.UPDATE_DEFECT_RES_CLEAR,
});

//COMMENTHISTOTY
export const getCommentHistoryRequest = (payload) => ({
  type: DefectsActionTypes.COMMENT_HISTORY_REQUEST,
  payload,
});
export const getCommentHistoryResponse = (payload) => ({
  type: DefectsActionTypes.COMMENT_HISTORY_RESPONSE,
  payload,
});

//new

export const newDefectRequest = (payload) => ({
  type: DefectsActionTypes.NEW_DEFECT_REQUEST,
  payload,
});

export const newDefectResponse = (payload) => ({
  type: DefectsActionTypes.NEW_DEFECT_RESPONSE,
  payload,
});
export const newDefectResponseClear = () => ({
  type: DefectsActionTypes.NEW_DEFECT_RESCLEAR,
});

//INDEXDEFECT
export const getIndexDefectRequest = (payload) => ({
  type: DefectsActionTypes.INDEX_DEFECT_REQUEST,
  payload,
});

export const getIndexDefectResponse = (payload) => ({
  type: DefectsActionTypes.INDEX_DEFECT_RESPOSE,
  payload,
});
