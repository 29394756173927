export default {
  GET_TASK_INDEX_REQUEST: "GET_TASK_INDEX_REQUEST",
  GET_TASK_INDEX_RESPONSE: "GET_TASK_INDEX_RESPONSE",

  POST_TASK_REQUEST: "POST_TASK_REQUEST",
  POST_TASK_RESPONSE: "POST_TASK_RESPONSE",

  EDIT_TASK_REQUEST: "EDIT_TASK_REQUEST",
  EDIT_TASK_RESPONSE: "POST_TASK_RESPONSE",

  POST_TASK_RES_CLEAR: "POST_TASK_RES_CLEAR",

  SHOW_TASK_REQUEST: "SHOW_TASK_REQUEST",
  SHOW_TASK_RESPONSE: "SHOW_TASK_RESPONSE",
  SHOW_TASK_RES_CLEAR: "SHOW_TASK_RES_CLEAR",

  // TASK CHOICES
  GET_TASKCHOICES_REQUEST: "GET_TASKCHOICES_REQUEST",
  GET_TASKCHOICES_RESPONSE: "GET_TASKCHOICES_RESPONSE",
};
