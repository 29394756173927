import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AttachFile } from "@mui/icons-material";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import { Checkbox } from "@mui/material";
import { BUGGREY_ICON, BUGCOLOR_ICON } from "../../Utils/imageConstants";
import TestRunAttachmentForm from "./TestRunAttachmentForm";
import TablePagination from "@mui/material/TablePagination";
import { act } from "react-dom/test-utils";
import {
  runTestCaseResultRequest,
  saveDefectsRequest,
  saveDefectsResponseClear,
  submitDefectsRequest,
  submitDefectsResponseClear,
} from "../../Redux/TestExecution/TestExecutionAction";
import { useFormik } from "formik";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import toast from "react-hot-toast";
import * as Yup from "yup";

import AddDefectForm from "./AddDefectForm"; //p
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import TestExecutionTestSTeps from "../TestExecetionTab/TestExecutionTestSteps";
import ViewTestCaseAttachments from "../TestExecetionTab/ViewTestCaseAttachments";
import ViewTestCaseDetails from "../TestExecetionTab/ViewTestCaseDetails";

export default function TestExecutionRun() {
  const [activeTab, setActiveTab] = useState("teststeps");

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { testCaseRunList, submitDefectsResponse, saveDefectsResponse } =
    useSelector((state) => state.testExecution);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [index, setIndex] = useState(null);

  const [isStatusEditing, setIsStatusEditing] = useState(false);
  const [listPerPage, setListPerPage] = useState(10);

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { bugListResponse } = useSelector((state) => state.testExecution);
  const [bugResponse, setBugResponse] = useState(bugListResponse);

  const handleClose = () => {
    setOpen(false);
  };
  // p
  const [openDialog, setOpenDialog] = useState({
    open: false,
    type: null,
  });

  // HANDLE DIALOG OPENING
  // p
  const handleOpenDialog = (type, data) => {
    setOpenDialog({ open: true, type: type, data: data });
  };

  const handleCloseDialog = () => {
    setOpenDialog({ open: false, type: null });
  };

  const handleOpen = (index) => {
    setIndex(index);

    setOpen(true);
  };

  // HANDLE TAB SWITCH
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const payload = {
    filters: {
      project_id: {
        type: "exact",
        value: id,
      },
    },
    pagination_meta: {
      limit: listPerPage,
      page: 1,
    },
  };
  useEffect(() => {
    dispatch(runTestCaseResultRequest(payload));
  }, [id]);

  const validationSchema = Yup.object().shape({
    steps: Yup.array().of(
      Yup.object().shape({
        actual_result: Yup.string().required("Actual Result is required"),
        status: Yup.string().required("Status is required"),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      project_id: id,
      steps: [
        {
          test_step_fk: "",
          actual_result: "",
          bug: "",
          log: null,
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const id = anchorEl?.id;
      const bug = bugResponse;

      const formData = new FormData();
      for (let requirement_key in values) {
        if (requirement_key === "steps" && values.steps.length > 0) {
          values.steps.forEach((item, index) => {
            if (item) {
              formData.append(
                `steps[${index}].actual_result`,
                item.actual_result
              );

              // formData.append(`steps[${index}].bug`, bug);

              // formData.append(`steps[${index}].log`, item.log);

              formData.append(`steps[${index}].test_step_fk`, id);

              if (typeof item.log === "string") {
                // Do nothing if attachment is a string
              } else if (item.log instanceof Blob || item.log instanceof File) {
                // Append attachment if it is binary (Blob or File)
                formData.append(`steps[${index}].log`, item.log);
              } else if (item.log === null) {
                formData.append(`steps[${index}].log`, null);
              }
            }
          });
        } else {
          formData.append(`${requirement_key}`, values[requirement_key]);
        }
      }

      dispatch(saveDefectsRequest(formData));
    },
  });

  // useEffect(() => {
  //   if (!submitDefectsResponse) return;
  //   if (submitDefectsResponse) {
  //     if (submitDefectsResponse?.message && submitDefectsResponse?.success) {
  //       toast.success(submitDefectsResponse?.message);
  //       dispatch(runTestCaseResultRequest(payload));
  //       navigate(-1);
  //     } else {
  //       toast.error(submitDefectsResponse?.message);
  //     }
  //   }
  //   return () => {
  //     dispatch(submitDefectsResponseClear());
  //   };
  // }, [submitDefectsResponse]);

  //save

  // useEffect(() => {
  //   if (!saveDefectsResponse) return;
  //   if (saveDefectsResponse) {
  //     if (saveDefectsResponse?.message && saveDefectsResponse?.success) {
  //       toast.success(saveDefectsResponse?.message);
  //       dispatch(runTestCaseResultRequest(payload));
  //     } else {
  //       toast.error(saveDefectsResponse?.message);
  //     }
  //   }
  //   return () => {
  //     dispatch(saveDefectsResponseClear());
  //   };
  // }, [saveDefectsResponse]);

  const [steps, setSteps] = useState([]);

  const type = [
    {
      value: "Unexecuted",
      name: "unexecuted",
    },
    {
      value: "Passed",
      name: "passed",
    },
    {
      value: "Failed",
      name: "failed",
    },
    {
      value: "Hold",
      name: "hold",
    },
    {
      value: "Closed",
      name: "closed",
    },
  ];

  return (
    <>
      <div className="dashboard_container releaseLayout w-100 overflow-auto">
        <div className="position-relative">
          <div className="test_header ">
            <h3>Run</h3>
          </div>
        </div>
        <div className="reqTabHead">
          <div className="">
            <div className="pageTopLeft">
              <div className="project-boardhead-down-container d-flex gap-4 align-items-center">
                <div className="empViewBtn">
                  <p
                    className={
                      activeTab === "teststeps" ? "actives" : "details"
                    }
                    onClick={() => handleTabClick("teststeps")}
                  >
                    Test Steps
                  </p>
                  {/* <p
                    className={activeTab === "testrun" ? "actives" : ""}
                    onClick={() => handleTabClick("testrun")}
                  >
                    Test Run
                  </p> */}
                  <p
                    className={activeTab === "testcasedetails" ? "actives" : ""}
                    onClick={() => handleTabClick("testcasedetails")}
                  >
                    Test Case Details
                  </p>
                  <p
                    className={
                      activeTab === "attachments" ? "actives" : "details"
                    }
                    onClick={() => handleTabClick("attachments")}
                  >
                    Attachments
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {activeTab == "teststeps" && (
          <>
            <TestExecutionTestSTeps />
            {/* <form onSubmit={formik.handleSubmit}>
              <div className="testexecution_container">
                <div className="mt-4">
                  <div className="tblWrpr">
                    <div className="table-responsive ">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Step No</th>
                            <th>Description</th>
                            <th>Expected Result</th>
                            <th>Actual Result</th>
                            <th>Log</th>
                            <th>Status</th>
                            <th>
                              <div className="bug_image_container">
                                <img src={BUGGREY} alt="greycolorbug" />
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {testCaseRunList?.data?.map((data, index) => (
                            <tr
                              key={index}
                              onClick={() => setSelectedId(data?.id)}
                            >
                              <td>{data?.step}</td>
                              <td>{data?.description}</td>
                              <td>{data?.expected_result}</td>

                              <td>
                                <div
                                  className={
                                    isEditing
                                      ? "test_header2"
                                      : "test_header test_header_hover"
                                  }
                                  onClick={() => {
                                    if (activeTab === "teststeps") {
                                      setIsEditing(true);
                                    } else {
                                      setIsEditing(false);
                                    }
                                  }}
                                >
                                  {isEditing ? (
                                    <>
                                      <div className="customInputWrpr">
                                        <CustomInput
                                          type="text"
                                          name={`steps[${index}].actual_result`}
                                          size="large"
                                          autoComplete="off"
                                          onChange={formik.handleChange}
                                          onBlur={(e) => {
                                            setAnchorEl(data);
                                            if (
                                              formik.values.steps[index]
                                                ?.actual_result
                                            ) {
                                              formik.handleBlur(e);
                                              setIsEditing(false);
                                            }
                                          }}
                                          value={
                                            formik.values.steps[index]
                                              ?.actual_result
                                          }
                                          formik={formik}
                                        />
                                        {formik.errors.steps?.[index]
                                          ?.actual_result ? (
                                          <p className="errMsg">
                                            {
                                              formik.errors.steps?.[index]
                                                ?.actual_result
                                            }
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <h3>
                                        {
                                          formik.values.steps[index]
                                            ?.actual_result
                                        }
                                      </h3>
                                    </>
                                  )}
                                </div>
                              </td>
                              <td>
                                <AttachFile
                                  className="clickable"
                                  onClick={() => handleOpen(index)}
                                />
                              </td>

                              <td>
                                <div
                                  className={
                                    isStatusEditing
                                      ? "test_header2"
                                      : "test_header test_header_hover"
                                  }
                                  onClick={() => {
                                    if (activeTab === "teststeps") {
                                      setIsStatusEditing(true);
                                    } else {
                                      setIsStatusEditing(false);
                                    }
                                  }}
                                >
                                  {isStatusEditing ? (
                                    <div className="customInputWrpr">
                                      <CustomSelect
                                        type="text"
                                        name={`steps[${index}].status`}
                                        options={type.map((values, index) => {
                                          return {
                                            value: values.value,
                                            name: values.name,
                                          };
                                        })}
                                        size="large"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={(e) => {
                                          if (
                                            formik.values.steps[index]?.status
                                          ) {
                                            formik.handleBlur(e);
                                            setIsStatusEditing(false);
                                          }
                                        }}
                                        value={
                                          formik.values.steps[index]?.status
                                        }
                                        formik={formik}
                                      />
                                      {formik.errors.steps?.[index]
                                        ?.actual_result ? (
                                        <p className="errMsg">
                                          {
                                            formik.errors.steps?.[index]
                                              ?.actual_result
                                          }
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      <h3>
                                        {formik.values.steps[index]?.status}
                                      </h3>
                                    </>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="bug_image_container"
                                  onClick={() =>
                                    handleOpenDialog("defect", data)
                                  }
                                >
                                  <span>{data?.no_of_bugs}</span>
                                  <img src={BUGCOLOR} alt="greycolorbug" />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p>Showing 6 results</p>
                    <div className="tables_pagination">
                      <TablePagination
                        component="div"
                        count={100}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Results per page:"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="popformbutton">
                <CustomButton
                  size="small"
                  label="Submit Defect"
                  onClick={() =>
                    dispatch(submitDefectsRequest({ project_id: id }))
                  }
                />
                <CustomButton size="small" type="submit" label="Save" />
              </div>
            </form> */}
          </>
        )}

        {activeTab == "attachments" && <ViewTestCaseAttachments />}
        {
          activeTab == "comments" && ""
          //   <RequirementComment requirementId={requirementId} />
        }
        {activeTab === "testcasedetails" && <ViewTestCaseDetails />}
      </div>
      {/* p */}
      <CustomDialog
        open={openDialog.open && openDialog.type === "attachment"}
        title="Attachments"
        handleClose={handleCloseDialog}
        maxWidth="sm"
      >
        <TestRunAttachmentForm
          handleClose={handleClose}
          index={index}
          formik={formik}
        />
      </CustomDialog>
      {/* DEFECT ADD FORM */}
      <CustomDialog
        open={openDialog.open && openDialog.type === "defect"}
        title="Add Defect"
        handleClose={handleCloseDialog}
        // maxWidth="xs"
      >
        <AddDefectForm handleClose={handleCloseDialog} open={openDialog} />
      </CustomDialog>
    </>
  );
}
