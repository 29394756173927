import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_WHITE_ICON } from "../../Utils/imageConstants";
import PropTypes from "prop-types";
import "./InviteForm.css";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import { Autocomplete, Avatar, TextField } from "@mui/material";
import {
  InviteUserResClear,
  inviteRequest,
} from "../../Redux/Invite/InviteActions";
import {
  deleteProjectMemberRequest,
  deleteProjectMemberResClear,
  showProjectMembersRequest,
} from "../../Redux/ProjectSaga/ProjectAction";
import "./InviteForm.css";
import DeleteIndex from "../DeletePopup/DeleteIndex";

const InviteForm = ({ handleClose }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { inviteResponse } = useSelector((state) => state.invite);
  const {
    projectMembers,

    deleteProjectMemberResponse,
    deleteProjectMemberLoading,
  } = useSelector((state) => state.project);
  const { user } = useSelector((state) => state.loginState);

  const validation = Yup.object().shape({
    mail_list: Yup.array()
      .of(Yup.string().email("Invalid Email").required("Email is required"))
      .min(1, "Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      mail_list: [],
    },
    validationSchema: validation,
    onSubmit: () => {
      dispatch(
        inviteRequest({ project_id: id, mail_list: formik.values.mail_list })
      );
    },
  });

  // INVITE MEMBERS TOAST
  useEffect(() => {
    if (!inviteResponse) return;
    if (inviteResponse) {
      if (inviteResponse?.message && inviteResponse?.success) {
        toast.success(inviteResponse?.message);
        formik.resetForm();
        handleClose();
      } else {
        toast.error(inviteResponse?.message);
      }
    }
    return () => {
      dispatch(InviteUserResClear());
    };
  }, [inviteResponse]);

  // GET PROJECT MEMBERS
  useEffect(() => {
    if (deleteProjectMemberLoading) return;
    dispatch(
      showProjectMembersRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, [deleteProjectMemberLoading]);

  // DELETE PROJECT MEMBER TOAST
  useEffect(() => {
    if (!deleteProjectMemberResponse) return;
    if (deleteProjectMemberResponse) {
      if (
        deleteProjectMemberResponse?.message &&
        deleteProjectMemberResponse?.success
      ) {
        toast.success(deleteProjectMemberResponse?.message);
      } else {
        toast.error(deleteProjectMemberResponse?.message);
      }
    }
    return () => {
      dispatch(deleteProjectMemberResClear());
    };
  }, [deleteProjectMemberResponse]);

  return (
    <div className="project_form">
      <form onSubmit={formik.handleSubmit}>
        <div className="inviteuser_container">
          <div className="w-100 position-relative">
            <Autocomplete
              multiple={true}
              freeSolo={formik.values.mail_list}
              name="mail_list"
              id="mail_list"
              options={[]}
              getOptionLabel={(option) => option}
              onChange={(e, newValues) => {
                formik.setFieldValue("mail_list", newValues);
              }}
              limitTags={2}
              onBlur={formik.handleBlur}
              value={formik.values.mail_list}
              formik={formik}
              renderInput={(params) => <TextField {...params} />}
              sx={{ width: "100%" }}
            />
            {formik.touched.mail_list && formik.errors.mail_list ? (
              <p className="CustErr">
                {formik.errors.mail_list === "Email is required"
                  ? formik.errors.mail_list
                  : [...new Set(formik.errors.mail_list)].filter((el) => el)[0]}
              </p>
            ) : (
              ""
            )}
          </div>
          <div>
            <CustomButton
              label="Invite"
              type="submit"
              disabled={
                user?.id ===
                projectMembers?.data?.[projectMembers?.data?.length - 1]
                  ?.user_fk
                  ? false
                  : true
              }
            />
          </div>
        </div>
      </form>
      <h3 className="members_count">
        {`Member${projectMembers?.total_count !== 1 ? "s" : ""} ${
          projectMembers?.total_count ?? 0
        }`}
      </h3>
      {projectMembers &&
        projectMembers?.data?.length > 0 &&
        projectMembers?.data?.map((data) => (
          <div
            className="view_invited_members d-flex justify-content-between align-items-end"
            key={data?.id}
          >
            <div className="d-flex gap-2">
              <div>
                <Avatar sx={{ width: "35px", height: "35px" }}>
                  {data?.first_name[0].toUpperCase()}
                </Avatar>
              </div>
              <div>
                <h3>{`${data?.first_name[0].toUpperCase()}${data?.first_name.slice(
                  1
                )} ${data?.last_name}`}</h3>
                <h3 className="invite_label">{`${data?.email}, ${
                  data?.department ?? ""
                }`}</h3>
              </div>
            </div>
            <div className="remove_invite_user" onKeyDown={() => {}}>
              <DeleteIndex
                action={deleteProjectMemberRequest}
                payload={{
                  member_id: data?.id,
                }}
                loading={deleteProjectMemberLoading}
                res={deleteProjectMemberResponse}
                name="Member"
                label={
                  <img
                    src={DELETE_WHITE_ICON}
                    alt="Delete Icon"
                    className="delete_inviteuser_icon"
                  />
                }
              />
            </div>
          </div>
        ))}
    </div>
  );
};
InviteForm.propTypes = {
  handleClose: PropTypes.func.isRequired, // Example: handleClose prop is a required function
};

export default InviteForm;
