import React, { useEffect } from "react";
import CustomInput from "../../../Components/Atoms/CustomInput/CustomInput";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import CustomCancelSubmit from "../../../Components/Molecules/CancelSubmit/CancelSubmit";
import { useDispatch, useSelector } from "react-redux";
import {
  postIntegrateRequest,
  updateIntegrateRequest,
} from "../../../Redux/IntegrationSaga/IntegrationAction";
import * as Yup from "yup";

const IntegrationForm = ({ handleClose, openDialog, viewIntegration }) => {
  const dispatch = useDispatch();

  const { postIntegrationLoading } = useSelector((state) => state.integrate);

  const validationArr = Yup.object().shape({
    server_url: Yup.string()
      .trim()
      .url("Invalid URL format")
      .required("URL is required!"),
    api_token: Yup.string().required("Token is required!"),
  });

  const formik = useFormik({
    initialValues: {
      server_url: "",
      api_token: "",
    },
    validationSchema: validationArr,
    onSubmit: (values) => {
      if (openDialog.type === "edit")
        dispatch(updateIntegrateRequest({ id: openDialog?.id, ...values }));
      else
        dispatch(
          postIntegrateRequest({ application_fk: openDialog?.id, ...values })
        );
    },
  });

  useEffect(() => {
    if (openDialog.type === "edit" && viewIntegration) {
      formik.setFieldValue("server_url", viewIntegration?.server_url);
    }
    if (
      openDialog.type == "edit" &&
      viewIntegration &&
      viewIntegration?.token
    ) {
      formik.setFieldValue("api_token", viewIntegration?.token);
    } else if (openDialog.type == "edit" && viewIntegration) {
      formik.setFieldValue("api_token", "*****");
    }
  }, [viewIntegration]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <CustomInput
              placeholder="Server URL"
              type="text"
              name="server_url"
              size="large"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.server_url}
              formik={formik}
              autoComplete="off"
              helperText="Ex: https://test.management.jira.com"
            />
          </div>
          <div className="col-md-6">
            <CustomInput
              placeholder="API"
              type="text"
              name="api_token"
              size="large"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.api_token}
              autoComplete={"off"}
              formik={formik}
              helperText={
                <a
                  // href="https://id.jira.com/"
                  target="_blank"
                  style={{ color: "#918b8b" }}
                >
                  Get API token id.jira.com
                </a>
              }
            />
          </div>
          <CustomCancelSubmit
            cancelLabel="Cancel"
            handleClose={handleClose}
            submitLabel={
              openDialog.type === "edit" ? "Update" : "Save & Configure"
            }
            loading={postIntegrationLoading}
          />
        </div>
      </form>
    </div>
  );
};

IntegrationForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default IntegrationForm;
