import React, { useEffect } from "react";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { convertedString } from "../../Utils/handles";
import {
  getRunningTestCaseIndexRequest,
  showRunningTestCaseRequest,
} from "../../Redux/TestExecution/TestExecutionAction";
import { useFormik } from "formik";

const ViewTestCaseDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.loginState);

  const { testCaseRunningList, testCaseRun } = useSelector(
    (state) => state.testExecution
  );

  useEffect(() => {
    dispatch(
      getRunningTestCaseIndexRequest({
        project_id: id,
        filters: {
          is_running: {
            type: "exact",
            value: true,
          },
          executor: {
            type: "exact",
            value: user?.id,
          },
        },
      })
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      id: "",
    },
  });

  useEffect(() => {
    if (testCaseRunningList && testCaseRunningList?.data?.length > 0) {
      formik.setFieldValue(
        "id",
        testCaseRunningList?.data?.[0]?.test_case_fk?.id
      );
    }
  }, [testCaseRunningList]);

  useEffect(() => {
    if (!formik.values.id) return;
    if (formik.values.id) {
      dispatch(showRunningTestCaseRequest(formik.values.id));
    }
  }, [formik.values.id]);

  return (
    <div>
      <CustomSelect
        placeholder="id"
        options={testCaseRunningList?.data?.map((values) => {
          return {
            value: values?.test_case_fk?.id,
            name: values?.test_case_fk?.name,
          };
        })}
        name="id"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.id}
        formik={formik}
      />
      {testCaseRunningList?.data?.length > 0 && testCaseRunningList ? (
        <div className="tabelCont">
          <div className="tabelContS">
            <div className="tabelLeft">
              <h3>Test Case Id</h3>
            </div>
            <div className="tabelRight">
              <p>
                <span>:</span>
                {testCaseRun?.id}
              </p>
            </div>
          </div>
          <div className="tabelContS">
            <div className="tabelLeft">
              <h3>Test Case Name</h3>
            </div>
            <div className="tabelRight">
              <p>
                <span>:</span>
                {testCaseRun?.name}
              </p>
            </div>
          </div>
          <div className="tabelContS">
            <div className="tabelLeft">
              <h3>Test Case Version</h3>
            </div>
            <div className="tabelRight">
              <p>
                <span>:</span>
                {`0.${testCaseRun?.version}`}
              </p>
            </div>
          </div>
          <div className="tabelContS">
            <div className="tabelLeft">
              <h3>Status</h3>
            </div>
            <div className="tabelRight">
              <p>
                <span>:</span>
                {convertedString(testCaseRun?.status)}
              </p>
            </div>
          </div>
          <div className="tabelContS">
            <div className="tabelLeft">
              <h3>Priority</h3>
            </div>
            <div className="tabelRight">
              <p>
                <span>:</span>
                {convertedString(testCaseRun?.priority)}
              </p>
            </div>
          </div>

          <div className="tabelContS">
            <div className="tabelLeft">
              <h3>Assigned to</h3>
            </div>
            <div className="tabelRight">
              <p>
                <span>:</span>
                {convertedString(
                  testCaseRun?.assigned_to_fk?.full_name || "NA"
                )}
              </p>
            </div>
          </div>
          <div className="tabelContS">
            <div className="tabelLeft">
              <h3>Test Case Approved By</h3>
            </div>
            <div className="tabelRight">
              <p>
                <span>:</span>
                {convertedString(testCaseRun?.approved_by_fk?.full_name) ||
                  "NA"}
              </p>
            </div>
          </div>
          <div className="tabelContS">
            <div className="tabelLeft">
              <h3>Description</h3>
            </div>
            <div className="tabelRight">
              <p>
                <span>:</span>
                {testCaseRun?.description || "NA"}
              </p>
            </div>
          </div>
          <div className="tabelContS">
            <div className="tabelLeft">
              <h3>Precondition</h3>
            </div>
            <div className="tabelRight">
              <p>
                <span>:</span>
                {testCaseRun?.pre_condition || "NA"}
              </p>
            </div>
          </div>
          <div className="tabelContS">
            <div className="tabelLeft">
              <h3>Execution Type</h3>
            </div>
            <div className="tabelRight">
              <p>
                <span>:</span>
                {convertedString(testCaseRun?.type)}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <p>No TestCases found</p>
      )}
    </div>
  );
};

export default ViewTestCaseDetails;
