import RegisterActionTypes from "./RegisterActionTypes";

const initialState = {
  registerLoading: false,
  registerResponse: null,

  otpLoading: false,
  otpResponse: null,

  countryList: [],
  countryListLoading: false,
  countryListResponse: null,

  stateList: null,
  stateListLoading: false,
  stateListResponse: null,

  cityList: null,
  cityListLoading: false,
  cityListResponse: null,

  departmentList: [],
  departmentListLoading: false,
  departmentListResponse: null,
};

const RegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case RegisterActionTypes.REGISTER_USER_REQUEST:
      return {
        ...state,
        registerLoading: true,
      };
    case RegisterActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        registerResponse: action.payload,
      };

    case RegisterActionTypes.REGISTER_USER_RESCLEAR:
      return {
        ...state,
        registerResponse: null,
      };

    case RegisterActionTypes.OTP_REQUEST:
      return {
        ...state,
        otpLoading: true,
      };
    case RegisterActionTypes.OTP_SUCCESS:
      return {
        ...state,
        otpLoading: false,
        otpResponse: action.payload,
      };

    case RegisterActionTypes.OTP_RES_CLEAR:
      return {
        ...state,
        otpResponse: null,
      };
    case RegisterActionTypes.GET_COUNTRYLIST_REQUEST:
      return {
        ...state,
        countryListLoading: true,
      };
    case RegisterActionTypes.GET_COUNTRYLIST_RESPONSE:
      return {
        ...state,
        countryListLoading: false,
        countryList: action.payload?.data,
        countryListResponse: action.payload,
      };

    case RegisterActionTypes.GET_COUNTRYLIST_RES_CLEAR:
      return {
        ...state,
        countryListResponse: null,
      };
    case RegisterActionTypes.GET_STATELIST_REQUEST:
      return {
        ...state,
        stateListLoading: true,
      };
    case RegisterActionTypes.GET_STATELIST_RESPONSE:
      return {
        ...state,
        stateListLoading: false,
        stateList: action.payload?.data,
        stateListResponse: action.payload,
      };

    case RegisterActionTypes.GET_STATELIST_RES_CLEAR:
      return {
        ...state,
        stateListResponse: null,
      };
    case RegisterActionTypes.GET_CITYLIST_REQUEST:
      return {
        ...state,
        cityListLoading: true,
      };
    case RegisterActionTypes.GET_CITYLIST_RESPONSE:
      return {
        ...state,
        cityListLoading: false,
        cityList: action.payload?.data,
        cityListResponse: action.payload,
      };

    case RegisterActionTypes.GET_CITYLIST_RES_CLEAR:
      return {
        ...state,
        cityListResponse: null,
      };
    case RegisterActionTypes.GET_DEPARTMENTLIST_REQUEST:
      return {
        ...state,
        departmentListLoading: true,
      };
    case RegisterActionTypes.GET_DEPARTMENTLIST_RESPONSE:
      return {
        ...state,
        departmentListLoading: false,
        departmentList: action.payload?.data,
        departmentListResponse: action.payload,
      };

    case RegisterActionTypes.GET_DEPARTMENTLIST_RES_CLEAR:
      return {
        ...state,
        departmentListResponse: null,
      };
    default:
      return state;
  }
};

export default RegisterReducer;
