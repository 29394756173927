import React, { useEffect, useState } from "react";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import {
  defectPriority,
  priority,
  severity,
  type,
} from "../../Utils/constants";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  editBugRequest,
  editBugResponseClear,
  getAllDefectListRequest,
  getBugRequest,
  getBugResponseClear,
} from "../../Redux/TestExecution/TestExecutionAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { convertedString } from "../../Utils/handles";
import {
  getIndexDefectRequest,
  newDefectRequest,
  newDefectResponseClear,
  postDefectResponseClear,
  updateDefectRequest,
} from "../../Redux/DefectsSaga/DefectsAction";
import ImageSlider from "./ImageSlider";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import { VisibilityOutlined } from "@mui/icons-material";

const AddDefectForm = ({
  open,
  handleClose,
  newData,
  handleBugsResponse,
  viewData,
}) => {
  const { id } = useParams();
  const { bugListResponse, list, editBugListResponse, saveDefectsResponse } =
    useSelector((state) => state.testExecution);
  const { indexDefectsList, newDefectResponse, updateDefectResponse } =
    useSelector((state) => state.defect);

  console.log(open, "opnes");

  const Validation = Yup.object().shape({
    summary: Yup.string()
      .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces alone")

      .min(3, "Minimum of 3 characters")
      .max(100, "Maximum of 100 characters")
      .trim()
      .required("Defect is required!"),

    priority: Yup.string().required("Priority is required!"),
    severity: Yup.string().required("Severity is required!"),
  });

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      test_step_id: open?.data?.id,
      status: "",
      summary: "",
      attachment: [],
      priority: "",
      severity: "",
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      const formData = new FormData();

      // Append other form fields
      formData.append("test_step_id", values.test_step_id);
      formData.append("status", values.status);
      formData.append("summary", values.summary);
      formData.append("priority", values.priority);
      formData.append("severity", values.severity);
      for (const file of values.attachment) {
        formData.append("attachment", file);
      }

      dispatch(newDefectRequest(formData));
      handleClose();
    },
  });

  // useEffect(() => {
  //   if (newData?.bug) {
  //     formik.setFieldValue("summary", newData?.bug?.summary);
  //   }
  // }, [newData?.bug]);

  useEffect(() => {
    dispatch(getIndexDefectRequest({ test_step_id: open?.data?.id }));
  }, []);

  // useEffect(() => {
  //   if (bugListResponse.success) {
  //     handleClose();
  //   }
  // }, [bugListResponse]);

  useEffect(() => {
    if (newDefectResponse) {
      if (newDefectResponse?.message && newDefectResponse?.success) {
        toast.success(newDefectResponse?.message);
      } else {
        toast.error(newDefectResponse?.message);
      }
      return () => {
        dispatch(newDefectResponseClear());
      };
    }
  }, [newDefectResponse]);

  useEffect(() => {
    if (updateDefectResponse) {
      if (updateDefectResponse?.message && updateDefectResponse?.success) {
        toast.success(updateDefectResponse?.message);
        dispatch(getIndexDefectRequest({ test_step_id: open?.data?.id }));
        handleClose();
      } else {
        toast.error(updateDefectResponse?.message);
      }
      return () => {
        dispatch(postDefectResponseClear());
      };
    }
  }, [updateDefectResponse]);

  const payload = {
    filters: {
      project_id: {
        value: id,
      },
      is_submitted: {
        type: "equal",
        value: true,
      },
    },
  };

  // useEffect(() => {
  //   if (newData?.bug) {
  //     // dispatch(getAllDefectListRequest({ payload, id: open?.data?.id }));
  //   } else {
  //     // dispatch(getBugRequest({ test_step_id: newData?.test_step_fk }));
  //   }
  // }, []);

  useEffect(() => {
    if (!bugListResponse?.data) return;
    if (bugListResponse?.data) {
      handleBugsResponse(bugListResponse?.data);
    }
  }, [bugListResponse?.data]);
  const [editMode, setEditMode] = useState(false);
  const [editedSummary, setEditedSummary] = useState("");

  // Handle click on the summary

  const handleBlur = () => {
    setCardType(null);

    setEditMode(false);
  };
  const [editedSummaries, setEditedSummaries] = useState([]);

  const handleEditChange = (value, index) => {
    const newSummaries = [...editedSummaries];
    newSummaries[index] = value;
    setEditedSummaries(newSummaries);
  };

  const handleSummaryClick = (index) => {
    setEditedSummaries((prev) => {
      const newSummaries = [...prev];
      newSummaries[index] = true;
      return newSummaries;
    });
    setEditMode(true);
  };
  const projectFormik = useFormik({
    initialValues: {
      data: indexDefectsList,
    },
    onSubmit: (values) => {},
  });
  const handleUpdate = (summary, id, value, index) => {
    if (value === indexDefectsList[index]?.[summary]) {
      projectFormik.setFieldValue(
        `data.${index}.${summary}`,
        indexDefectsList[index]?.[summary]
      );
      return;
    }

    dispatch(updateDefectRequest({ summary: value, id }));
  };

  const [cardType, setCardType] = useState(null);

  const handleEditClick = (type) => {
    setCardType(type);
  };

  // const handleBlur = () => {
  //   setCardType(null);
  // };
  const [preview, setPreview] = React.useState(false);
  const [fileData, setFileData] = React.useState();

  const handlePreviewallImages = (files) => {
    setPreview(true);
    setFileData(files);
  };

  const handlePreviewallImagesClose = () => {
    setPreview(false);
    setFileData(null);
  };

  return (
    <div className="defectform_container">
      <form onSubmit={formik.handleSubmit}>
        {viewData ? (
          ""
        ) : (
          <>
            <div className="row">
              <div className="col-md-6">
                <CustomInput
                  placeholder="Enter Defect"
                  name="summary"
                  value={formik.values.summary}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                />
              </div>
              <div className="col-md-6">
                <CustomSelect
                  placeholder="Priority"
                  name="priority"
                  size="small"
                  value={formik.values?.priority}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  options={defectPriority?.map((val, index) => {
                    return {
                      value: val?.value,
                      name: val?.name,
                    };
                  })}
                />
              </div>

              <div className="col-md-6">
                <CustomSelect
                  size="small"
                  placeholder="Severity"
                  name="severity"
                  value={formik.values.severity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  options={severity?.map((val, index) => {
                    return {
                      value: val?.value,
                      name: val?.name,
                    };
                  })}
                />
              </div>
              <div className="col-md-6">
                <input
                  className=" defect_file"
                  size="small"
                  placeholder="Severity"
                  name="attachment"
                  // value={formik.values.attachments}
                  // onChange={formik.handleChange}
                  onChange={(event) =>
                    formik.setFieldValue(
                      "attachment",
                      event.currentTarget.files
                    )
                  }
                  onBlur={formik.handleBlur}
                  formik={formik}
                  type="file"
                  multiple
                />
              </div>
            </div>
          </>
        )}

        <div className="defect_table_container mb-4">
          <h3 className="mb-1">Test Steps Defects</h3>
          <div className="tblWrpr  ">
            <div className="table-responsive ">
              <table className="table accordian_container2 table-border">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Summary</th>

                    <th>Priority</th>
                    <th>Severity</th>
                    <th>Attachments</th>

                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {viewData ? (
                    ""
                  ) : (
                    <tr> */}
                  {/* {testCaseExecutionAddedList?.length > 0 ? (
                      testCaseExecutionAddedList?.map((data, index) => ())} */}
                  {indexDefectsList?.length > 0 ? (
                    indexDefectsList.map((data, index) => (
                      <>
                        <tr>
                          <td>{data?.id}</td>
                          <td className="defect_summary">
                            {cardType === `name${index}` ? (
                              <input
                                className="title_input"
                                name={`data.${index}.summary`}
                                autoComplete="off"
                                onChange={projectFormik.handleChange}
                                onBlur={(e) => {
                                  projectFormik.handleBlur(e);
                                  handleUpdate(
                                    "summary",
                                    data?.id,
                                    projectFormik.values?.data?.[index]
                                      ?.summary,
                                    index
                                  );
                                  handleBlur();
                                }}
                                value={
                                  projectFormik.values?.data[index]?.summary
                                }
                                onClick={(e) => e.stopPropagation()}
                                autoFocus
                              />
                            ) : (
                              // <input
                              //   type="text"
                              // value={formik.values.summary[index]}
                              // value={editedSummary}
                              // onChange={(e) =>
                              //   setEditedSummary(
                              //     e.target.value,
                              //     data.summary
                              //   )
                              // }
                              // onBlur={() =>
                              //   handleBlur(editedSummary, data.id)
                              // }
                              // value={editedSummaries || ""}
                              // onChange={(e) =>
                              //   handleEditChange(e.target.value, index)
                              // }
                              // onBlur={() =>
                              //   handleBlur(editedSummaries[index], data.id)
                              // }
                              // />
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditClick(`name${index}`);
                                }}
                              >
                                {data.summary}
                              </span>
                            )}
                          </td>
                          <td className="defect_summary">{data.priority}</td>
                          <td className="defect_summary">{data.severity}</td>
                          {/* <td className="px-5">
                            <VisibilityOutlined
                              className="preview"
                              onClick={() =>
                                handlePreviewallImages(data.attachments)
                              }
                            />
                          </td> */}
                          <td className="px-5">
                            <div className="view">
                              <button
                                onClick={() =>
                                  handlePreviewallImages(data.attachments)
                                }
                              >
                                <VisibilityOutlined />
                              </button>
                            </div>
                          </td>
                          {/* Preview Images */}
                          {/* </button> */}

                          <td>
                            <div className="defect_dropdown">
                              <CustomSelect
                                placeholder="New"
                                options={type.map((values, index) => {
                                  return {
                                    value: values.value,
                                    name: values.name,
                                  };
                                })}
                                name="type"
                                value={formik.values.status}
                                disabled={true}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    ))
                  ) : viewData ? (
                    viewData.map((data) => (
                      <tr>
                        <td>{data?.id}</td>
                        <td>{data?.summary}</td>
                        <td>{data?.priority}</td>
                        <td>{data?.severity}</td>
                        <td>{data?.attachments.length}</td>
                        <td>{convertedString(data?.status)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-start py-4 fs-6">
                        <div className="text-start">
                          <p>No Data Found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                  {/* </tr>
                  )} */}

                  <tr>
                    <td>{viewData?.id}</td>
                    <td>{viewData?.summary}</td>
                    <td>{convertedString(viewData?.status)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {viewData ? (
          ""
        ) : (
          <div className="popformbutton">
            <CustomButton
              size="small"
              label="Cancel"
              btnType="secondary"
              onClick={() => handleClose()}
            />
            <CustomButton size="small" type="submit" label="Save" />
          </div>
        )}
      </form>
      <CustomDialog
        open={preview}
        maxWidth="md"
        handleClose={handlePreviewallImagesClose}
        title="Attachments"
      >
        <ImageSlider
          data={fileData}
          // attachments={data.attachments}
          handleClose={handlePreviewallImagesClose}
        />
      </CustomDialog>
    </div>
  );
};

export default AddDefectForm;
