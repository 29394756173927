import RequirementCommentActionTypes from "./RequirementCommentActionTypes";

const initialState = {
  // REQUIREMENT HISTORY
  requirementHistoryList: [],
  requirementHistoryListLoading: false,
  requirementHistoryListResponse: null,

  // REQUIREMENT COMMENT
  requirementCommentList: [],
  requirementCommentListLoading: false,
  requirementCommentListResponse: null,

  postRequirementCommentLoading: false,
  postRequirementCommentResponse: null,

  deleteRequirementCommentLoading: false,
  deleteRequirementCommentResponse: null,

  requirementComment: null,
  viewrequirementCommentLoading: false,
  viewrequirementCommentResponse: null,
};

export default function RequirementCommentReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    // REQUIREMENT HISTORY
    // GET ALL REQUIREMENT HISTORY
    case RequirementCommentActionTypes.GET_REQUIREMENTHISTORY_INDEX_REQUEST:
      return {
        ...state,
        requirementHistoryListLoading: true,
      };
    case RequirementCommentActionTypes.GET_REQUIREMENTHSTORY_INDEX_RESPONSE:
      return {
        ...state,
        requirementHistoryListLoading: false,
        requirementHistoryList: action.payload?.data,
        requirementHistoryListResponse: action.payload,
      };

    // REQUIREMENT COMMENT
    // GET ALL REQUIREMENT COMMENT
    case RequirementCommentActionTypes.GET_REQUIREMENTCOMMENT_INDEX_REQUEST:
      return {
        ...state,
        requirementCommentListLoading: true,
      };
    case RequirementCommentActionTypes.GET_REQUIREMENTCOMENT_INDEX_RESPONSE:
      return {
        ...state,
        requirementCommentListLoading: false,
        requirementCommentList: action.payload?.data,
        requirementCommentListResponse: action.payload,
      };
    // POST REQUIREMENT COMMENT
    case RequirementCommentActionTypes.POST_REQUIREMENTCOMMENT_REQUEST:
      return {
        ...state,
        postRequirementCommentLoading: true,
      };
    case RequirementCommentActionTypes.POST_REQUIREMENTCOMMENT_RESPONSE:
      return {
        ...state,
        postRequirementCommentLoading: false,
        postRequirementCommentResponse: action.payload,
      };

    case RequirementCommentActionTypes.POST_REQUIREMENTCOMMENT_RES_CLEAR:
      return {
        ...state,
        postRequirementCommentLoading: false,
        postRequirementCommentResponse: null,
      };
    // UPDATE REQUIREMENT COMMENT
    case RequirementCommentActionTypes.UPDATE_REQUIREMENTCOMMENT_REQUEST:
      return {
        ...state,
        postRequirementCommentLoading: true,
      };
    case RequirementCommentActionTypes.UPDATE_REQUIREMENTCOMMENT_RESPONSE:
      return {
        ...state,
        postRequirementCommentLoading: false,
        postRequirementCommentResponse: action.payload,
      };
    // POST AND UPDATE REQUIREMENT COMMENT RESPONSE CLEAR
    case RequirementCommentActionTypes.POST_REQUIREMENTCOMMENT_RES_CLEAR:
      return {
        ...state,
        postRequirementCommentLoading: false,
        postRequirementCommentResponse: null,
      };
    // DELETE REQUIREMENT COMMENT
    case RequirementCommentActionTypes.DELETE_REQUIREMENTCOMMENT_REQUEST:
      return {
        ...state,
        deleteRequirementCommentLoading: true,
      };
    case RequirementCommentActionTypes.DELETE_REQUIREMENTCOMMENT_RESPONSE:
      return {
        ...state,
        deleteRequirementCommentLoading: false,
        deleteRequirementCommentResponse: action.payload,
      };
    case RequirementCommentActionTypes.DELETE_REQUIREMENTCOMMENT_RES_CLEAR:
      return {
        ...state,
        deleteRequirementCommentLoading: false,
        deleteRequirementCommentResponse: null,
      };
    // GET PARTICULAR REQUIREMENT COMMENT
    case RequirementCommentActionTypes.SHOW_REQUIREMENTCOMMENT_REQUEST:
      return {
        ...state,
        viewrequirementCommentLoading: true,
      };
    case RequirementCommentActionTypes.SHOW_REQUIREMENTCOMMENT_RESPONSE:
      return {
        ...state,
        viewrequirementCommentLoading: false,
        requirementComment: action.payload?.data,
        viewrequirementCommentResponse: action.payload,
      };
    case RequirementCommentActionTypes.SHOW_REQUIREMENTCOMMENT_RES_CLEAR:
      return {
        ...state,
        viewrequirementCommentLoading: false,
        requirementComment: null,
        viewrequirementCommentResponse: null,
      };
    default:
      return state;
  }
}
