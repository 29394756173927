import * as Yup from "yup";
import React, { useEffect } from "react";
import MetaData from "../../../Components/Organisms/MetaData/MetaData";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton/CustomButton";
import { GWAY_IMG, LOGIN_IMG } from "../../../Utils/imageConstants";
import {
  forgotPasswordClearResponse,
  forgotPasswordReq,
} from "../../../Redux/Login/LoginActions";
import { useNavigate } from "react-router";
import LoadingButton from "../../../Components/Atoms/LoadingButton/LoadingButton";
import { handleToastResponse } from "../../../Utils/handles";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { forgotPasswordResponse, forgotPasswordLoading } = useSelector(
    (state) => state.loginState
  );

  const validation = Yup.object().shape({
    email: Yup.string()
      .matches(/^[^\s]*$/, "Email should not contain spaces")
      .matches(/^[^A-Z]*$/, "Email must not contain uppercase letters")
      .email("Invalid email")
      .required("Email is required!"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      dispatch(forgotPasswordReq(values));
    },
  });

  useEffect(() => {
    handleToastResponse(forgotPasswordResponse);
    if (forgotPasswordResponse?.success) {
      formik.handleReset();
      navigate("/login");
    }
    return () => dispatch(forgotPasswordClearResponse());
  }, [forgotPasswordResponse]);

  return (
    <>
      <MetaData title="Forgot Password" />
      <div className="access_container">
        <div
          className="access_image_container"
          style={{ backgroundImage: `url(${LOGIN_IMG})` }}
        ></div>
        <div className="access_form_container">
          <form onSubmit={formik.handleSubmit} className="forgot_password_form">
            <div className="empty_content"></div>
            <div className="form_img">
              <img src={GWAY_IMG} alt="gway" />
            </div>
            <div className="form_head">
              <h2>FORGOT YOUR PASSWORD?</h2>
              <p>Enter registered email ID to reset the password</p>
            </div>
            <div className="row">
              <div className="col-12">
                <CustomInput
                  placeholder="Email"
                  type="text"
                  name="email"
                  size="large"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  formik={formik}
                />
              </div>
            </div>
            <CustomButton
              type="submit"
              label={forgotPasswordLoading ? <LoadingButton /> : "Continue"}
              disabled={forgotPasswordLoading}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
