import TestDesignActionTypes from "./TestDesignActionTypes";

const initialState = {
  // TESTDESIGN MODULE
  testDesignModuleList: [],
  testDesignModuleListLoading: false,
  testDesignModuleListResponse: null,

  postTestDesignModuleLoading: false,
  postTestDesignModuleResponse: null,

  deleteTestDesignModuleLoading: false,
  deleteTestDesignModuleResponse: null,

  testDesignModule: null,
  viewTestDesignModuleLoading: false,
  viewTestDesignModuleResponse: null,

  // TESTDESIGN DETAIL
  testDesignDetailList: [],
  testDesignDetailListLoading: false,
  testDesignDetailListResponse: null,

  postTestDesignDetailLoading: false,
  postTestDesignDetailResponse: null,

  deleteTestDesignDetailLoading: false,
  deleteTestDesignDetailResponse: null,

  testDesignDetail: null,
  viewTestDesignDetailLoading: false,
  viewTestDesignDetailResponse: null,

  // TESTDESIGN DETAIL ATTACHMENTS
  testDesignDetailAttachmentList: [],
  testDesignDetailAttachmentListLoading: false,
  testDesignDetailAttachmentListResponse: null,

  postTestDesignDetailAttachmentLoading: false,
  postTestDesignDetailAttachmentResponse: null,

  deleteTestDesignDetailAttachmentLoading: false,
  deleteTestDesignDetailAttachmentResponse: null,

  testDesignDetailAttachment: null,
  viewTestDesignDetailAttachmentLoading: false,
  viewTestDesignDetailAttachmentResponse: null,
};

export default function TestDesignReducer(state = initialState, action) {
  switch (action.type) {
    // TESTDESIGN MODULE
    // GET ALL TESTDESIGN MODULES
    case TestDesignActionTypes.GET_TESTDESIGNMODULE_INDEX_REQUEST:
      return {
        ...state,
        testDesignModuleListLoading: true,
      };
    case TestDesignActionTypes.GET_TESTDESIGNMODULE_INDEX_RESPONSE:
      return {
        ...state,
        testDesignModuleListLoading: false,
        testDesignModuleList: action.payload?.data,
        testDesignModuleListResponse: action.payload,
      };
    // POST TESTDESIGN MODULES
    case TestDesignActionTypes.POST_TESTDESIGNMODULE_REQUEST:
      return {
        ...state,
        postTestDesignModuleLoading: true,
      };
    case TestDesignActionTypes.POST_TESTDESIGNMODULE_RESPONSE:
      return {
        ...state,
        postTestDesignModuleLoading: false,
        postTestDesignModuleResponse: action.payload,
      };
    //  UPDATE TESTDESIGN MODULE
    case TestDesignActionTypes.UPDATE_TESTDESIGNMODULE_REQUEST:
      return {
        ...state,
        postTestDesignModuleLoading: true,
      };

    case TestDesignActionTypes.UPDATE_TESTDESIGNMODULE_RESPONSE:
      return {
        ...state,
        postTestDesignModuleLoading: false,
        postTestDesignModuleResponse: action.payload,
      };

    // POST AND UPDATE TESTDESIGN MODULE RESPONSE CLEAR
    case TestDesignActionTypes.POST_TESTDESIGNMODULE_RES_CLEAR:
      return {
        ...state,
        postTestDesignModuleLoading: false,
        postTestDesignModuleResponse: null,
      };

    //  DELETE TESTDESIGN MODULE
    case TestDesignActionTypes.DELETE_TESTDESIGNMODULE_REQUEST:
      return {
        ...state,
        deleteTestDesignModuleLoading: true,
      };

    case TestDesignActionTypes.DELETE_TESTDESIGNMODULE_RESPONSE:
      return {
        ...state,
        deleteTestDesignModuleLoading: false,
        deleteTestDesignModuleResponse: action.payload,
      };
    case TestDesignActionTypes.DELETE_TESTDESIGNMODULE_RES_CLEAR:
      return {
        ...state,
        deleteTestDesignModuleLoading: false,
        deleteTestDesignModuleResponse: null,
      };

    //  GET PARTICULAR TESTDESIGN MODULE
    case TestDesignActionTypes.SHOW_TESTDESIGNMODULE_REQUEST:
      return {
        ...state,
        viewTestDesignModuleLoading: true,
      };

    case TestDesignActionTypes.SHOW_TESTDESIGNMODULE_RESPONSE:
      return {
        ...state,
        viewTestDesignModuleLoading: false,
        testDesignModule: action.payload?.data,
        viewTestDesignModuleResponse: action.payload,
      };
    case TestDesignActionTypes.SHOW_TESTDESIGNMODULE_RES_CLEAR:
      return {
        ...state,
        viewTestDesignModuleLoading: false,
        testDesignModule: null,
        viewTestDesignModuleResponse: null,
      };

    // TESTDESIGN DETAILS
    // GET ALL TESTDESIGN DETAILS
    case TestDesignActionTypes.GET_TESTDESIGNDETAIL_INDEX_REQUEST:
      return {
        ...state,
        testDesignDetailListLoading: true,
      };
    case TestDesignActionTypes.GET_TESTDESIGNDETAIL_INDEX_RESPONSE:
      return {
        ...state,
        testDesignDetailListLoading: false,
        testDesignDetailList: action.payload?.data,
        testDesignDetailListResponse: action.payload,
      };
    // POST TESTDESIGN DETAIL
    case TestDesignActionTypes.POST_TESTDESIGNDETAIL_REQUEST:
      return {
        ...state,
        postTestDesignDetailLoading: true,
      };
    case TestDesignActionTypes.POST_TESTDESIGNDETAIL_RESPONSE:
      return {
        ...state,
        postTestDesignDetailLoading: false,
        postTestDesignDetailResponse: action.payload,
      };
    //  UPDATE TESTDESIGN DETAIL
    case TestDesignActionTypes.UPDATE_TESTDESIGNDETAIL_REQUEST:
      return {
        ...state,
        postTestDesignDetailLoading: true,
      };

    case TestDesignActionTypes.UPDATE_TESTDESIGNDETAIL_RESPONSE:
      return {
        ...state,
        postTestDesignDetailLoading: false,
        postTestDesignDetailResponse: action.payload,
      };

    // POST AND UPDATE TESTDESIGN DETAIL RESPONSE CLEAR
    case TestDesignActionTypes.POST_TESTDESIGNDETAIL_RES_CLEAR:
      return {
        ...state,
        postTestDesignDetailLoading: false,
        postTestDesignDetailResponse: null,
      };

    //  DELETE TESTDESIGN DETAIL
    case TestDesignActionTypes.DELETE_TESTDESIGNDETAIL_REQUEST:
      return {
        ...state,
        deleteTestDesignDetailLoading: true,
      };

    case TestDesignActionTypes.DELETE_TESTDESIGNDETAIL_RESPONSE:
      return {
        ...state,
        deleteTestDesignDetailLoading: false,
        deleteTestDesignDetailResponse: action.payload,
      };
    case TestDesignActionTypes.DELETE_TESTDESIGNDETAIL_RES_CLEAR:
      return {
        ...state,
        deleteTestDesignDetailLoading: false,
        deleteTestDesignDetailResponse: null,
      };

    //  GET PARTICULAR TESTDESIGN DETAIL
    case TestDesignActionTypes.SHOW_TESTDESIGNDETAIL_REQUEST:
      return {
        ...state,
        viewTestDesignDetailLoading: true,
      };

    case TestDesignActionTypes.SHOW_TESTDESIGNDETAIL_RESPONSE:
      return {
        ...state,
        viewTestDesignDetailLoading: false,
        testDesignDetail: action.payload?.data,
        viewTestDesignDetailResponse: action.payload,
      };
    case TestDesignActionTypes.SHOW_TESTDESIGNDETAIL_RES_CLEAR:
      return {
        ...state,
        viewTestDesignDetailLoading: false,
        testDesignDetail: null,
        viewTestDesignDetailResponse: null,
      };

    // TESTDESIGN DETAIL ATTACHMENTS
    // GET ALL TESTDESIGN DETAIL ATTACHMENTS
    case TestDesignActionTypes.GET_TESTDESIGNATTACH_INDEX_REQUEST:
      return {
        ...state,
        testDesignDetailAttachmentListLoading: true,
      };
    case TestDesignActionTypes.GET_TESTDESIGNATTACH_INDEX_RESPONSE:
      return {
        ...state,
        testDesignDetailAttachmentListLoading: false,
        testDesignDetailAttachmentList: action.payload?.data,
        testDesignDetailAttachmentListResponse: action.payload,
      };
    case TestDesignActionTypes.GET_TESTDESIGNATTACH_INDEX_RES_CLEAR:
      return {
        ...state,
        testDesignDetailAttachmentListLoading: false,
        testDesignDetailAttachmentList: null,
        testDesignDetailAttachmentListResponse: null,
      };
    // POST TESTDESIGN DETAIL ATTACHMENTS
    case TestDesignActionTypes.POST_TESTDESIGNATTACHMENT_REQUEST:
      return {
        ...state,
        postTestDesignDetailAttachmentLoading: true,
      };
    case TestDesignActionTypes.POST_TESTDESIGNATTACHMENT_RESPONSE:
      return {
        ...state,
        postTestDesignDetailAttachmentLoading: false,
        postTestDesignDetailAttachmentResponse: action.payload,
      };
    //  UPDATE TESTDESIGN DETAIL ATTACHMENTS
    case TestDesignActionTypes.UPDATE_TESTDESIGNATTACHMENT_REQUEST:
      return {
        ...state,
        postTestDesignDetailAttachmentLoading: true,
      };

    case TestDesignActionTypes.UPDATE_TESTDESIGNATTACHMENT_RESPONSE:
      return {
        ...state,
        postTestDesignDetailAttachmentLoading: false,
        postTestDesignDetailAttachmentResponse: action.payload,
      };

    // POST AND UPDATE TESTDESIGN DETAIL RESPONSE CLEAR
    case TestDesignActionTypes.POST_TESTDESIGNATTACHMENT_RES_CLEAR:
      return {
        ...state,
        postTestDesignDetailAttachmentLoading: false,
        postTestDesignDetailAttachmentResponse: null,
      };

    //  DELETE TESTDESIGN DETAIL ATTACHMENTS
    case TestDesignActionTypes.DELETE_TESTDESIGNATTACHMENT_REQUEST:
      return {
        ...state,
        deleteTestDesignDetailAttachmentLoading: true,
      };

    case TestDesignActionTypes.DELETE_TESTDESIGNATTACHMENT_RESPONSE:
      return {
        ...state,
        deleteTestDesignDetailAttachmentLoading: false,
        deleteTestDesignDetailAttachmentResponse: action.payload,
      };
    case TestDesignActionTypes.DELETE_TESTDESIGNATTACH_RES_CLEAR:
      return {
        ...state,
        deleteTestDesignDetailAttachmentLoading: false,
        deleteTestDesignDetailAttachmentResponse: null,
      };

    //  GET PARTICULAR TESTDESIGN DETAIL ATTACHMENTS
    case TestDesignActionTypes.SHOW_TESTDESIGNATTACHMENT_REQUEST:
      return {
        ...state,
        viewTestDesignDetailAttachmentLoading: true,
      };

    case TestDesignActionTypes.SHOW_TESTDESIGNATTACHMENT_RESPONSE:
      return {
        ...state,
        viewTestDesignDetailAttachmentLoading: false,
        testDesignDetailAttachment: action.payload?.data,
        viewTestDesignDetailAttachmentResponse: action.payload,
      };
    case TestDesignActionTypes.SHOW_TESTDESIGNATTACHMENT_RES_CLEAR:
      return {
        ...state,
        viewTestDesignDetailAttachmentLoading: false,
        testDesignDetailAttachment: null,
        viewTestDesignDetailAttachmentResponse: null,
      };

    default:
      return state;
  }
}
