export default {
  // GET ALL NOTIFICATIONS
  GET_NOTIFICATIONS_REQUEST: "GET_NOTIFICATIONS_REQUEST",
  GET_NOTIFICATIONS_RESPONSE: "GET_NOTIFICATIONS_RESPONSE",
  GET_NOTIFICATIONS_RES_CLEAR: "GET_NOTIFICATIONS_RES_CLEAR",

  //  CLEAR ALL NOTIFICATIONS
  CLEAR_NOTIFICATIONS_REQUEST: "CLEAR_NOTIFICATIONS_REQUEST",
  CLEAR_NOTIFICATIONS_RESPONSE: "CLEAR_NOTIFICATIONS_RESPONSE",
  CLEAR_NOTIFICATIONS_RES_CLEAR: "CLEAR_NOTIFICATIONS_RES_CLEAR",
};
