import { ReportActionTypes } from "./ReportActionTypes";

export const getReportIndexRequest = (payload) => ({
  type: ReportActionTypes.GET_REPORT_INDEX_REQUEST,
  payload,
});

export const getReportIndexResponse = (payload) => ({
  type: ReportActionTypes.GET_REPORT_INDEX_RESPONSE,
  payload,
});
