import BuildActionTypes from "./BuildActionTypes";

// GET ALL BUILD
export const getBuildIndexRequest = (payload) => ({
  type: BuildActionTypes.BUILD_INDEX_REQUEST,
  payload,
});

export const getBuildIndexResponse = (payload) => ({
  type: BuildActionTypes.BUILD_INDEX_RESPONSE,
  payload,
});

// POST BUILD
export const postBuildRequest = (payload) => ({
  type: BuildActionTypes.POST_BUILD_REQUEST,
  payload,
});

export const postBuildResponse = (payload) => ({
  type: BuildActionTypes.POST_BUILD_RESPONSE,
  payload,
});

// UPDATE BUILD
export const updateBuildRequest = (payload) => ({
  type: BuildActionTypes.UPDATE_BUILD_REQUEST,
  payload,
});

export const updateBuildResponse = (payload) => ({
  type: BuildActionTypes.UPDATE_BUILD_RESPONSE,
  payload,
});

// POST AND PUT RESPONSE CLEAR
export const postBuildResponseClear = () => ({
  type: BuildActionTypes.POST_BUILD_RES_CLEAR,
});

// DELETE BUILD
export const deleteBuildRequest = (payload) => ({
  type: BuildActionTypes.DELETE_BUILD_REQUEST,
  payload,
});

export const deleteBuildResponse = (payload) => ({
  type: BuildActionTypes.DELETE_BUILD_RESPONSE,
  payload,
});

export const deleteBuildResponseClear = () => ({
  type: BuildActionTypes.DELETE_BUILD_RES_CLEAR,
});

// GET PARTICULAR BUILD
export const showBuildRequest = (payload) => ({
  type: BuildActionTypes.SHOW_BUILD_REQUEST,
  payload,
});

export const showBuildResponse = (payload) => ({
  type: BuildActionTypes.SHOW_BUILD_RESPONSE,
  payload,
});

export const showBuildResponseClear = () => ({
  type: BuildActionTypes.SHOW_BUILD_RES_CLEAR,
});

// GET ALL BUILD ATTACHMENTS
export const getBuildAttachmentIndexRequest = (payload) => ({
  type: BuildActionTypes.BUILDATTACHMENT_INDEX_REQUEST,
  payload,
});

export const getBuildAttachmentIndexResponse = (payload) => ({
  type: BuildActionTypes.BUILDATTACHMENT_INDEX_RESPONSE,
  payload,
});

export const getBuildAttachmentIndexResClear = (payload) => ({
  type: BuildActionTypes.BUILDATTACHMENT_INDEX_RES_CLEAR,
  payload,
});

// POST BUILD ATTACHMENTS
export const postBuildAttachmentRequest = (payload) => ({
  type: BuildActionTypes.POST_BUILDATTACHMENT_REQUEST,
  payload,
});

export const postBuildAttachmentResponse = (payload) => ({
  type: BuildActionTypes.POST_BUILDATTACHMENT_RESPONSE,
  payload,
});

// UPDATE BUILD ATTACHMENTS
export const updateBuildAttachmentRequest = (payload) => ({
  type: BuildActionTypes.UPDATE_BUILDATTACHMENT_REQUEST,
  payload,
});

export const updateBuildAttachmentResponse = (payload) => ({
  type: BuildActionTypes.UPDATE_BUILDATTACHMENT_RESPONSE,
  payload,
});

// POST AND PUT RESPONSE CLEAR
export const postBuildAttachmentResponseClear = () => ({
  type: BuildActionTypes.POST_BUILDATTACHMENT_RES_CLEAR,
});

// DELETE BUILD ATTACHMETNS
export const deleteBuildAttachmentRequest = (payload) => ({
  type: BuildActionTypes.DELETE_BUILDATTACHMENT_REQUEST,
  payload,
});

export const deleteBuildAttachmentResponse = (payload) => ({
  type: BuildActionTypes.DELETE_BUILDATTACHMENT_RESPONSE,
  payload,
});

export const deleteBuildAttachmentResponseClear = () => ({
  type: BuildActionTypes.DELETE_BUILDATTACHMENT_RES_CLEAR,
});

// GET PARTICULAR BUILD ATTACHMENTS
export const showBuildAttachmentRequest = (payload) => ({
  type: BuildActionTypes.SHOW_BUILDATTACHMENT_REQUEST,
  payload,
});

export const showBuildAttachmentResponse = (payload) => ({
  type: BuildActionTypes.SHOW_BUILDATTACHMENT_RESPONSE,
  payload,
});

export const showBuildAttachmentResponseClear = () => ({
  type: BuildActionTypes.SHOW_BUILDATTACHMENT_RES_CLEAR,
});
