import React, { useEffect, useState } from "react";
import { convertedString } from "../../Utils/handles";
import { BUGGREY_ICON, BUGCOLOR_ICON } from "../../Utils/imageConstants";
import { VisibilityOutlined, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelExecutionRequest,
  cancelExecutionResClear,
  getRunningTestCaseIndexRequest,
} from "../../Redux/TestExecution/TestExecutionAction";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { CustomPagination } from "../../Components/Molecules/PaginationComponent/Pagination";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import FilterForm from "../ProjectsPage/FilterForm";
import { useFormik } from "formik";
import TestExecutionFilter from "./TestExecutionFilter";
import ExportData from "../../Components/Molecules/ExportComponent/ExportToXL";
import { showProjectMembersRequest } from "../../Redux/ProjectSaga/ProjectAction";

const ViewExecutions = () => {
  const { id } = useParams();
  const [filter, setFilter] = useState(false);
  const [reset, setReset] = useState(false);

  const handleCloseFilter = () => {
    setFilter(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [listPerPage, setListPerPage] = useState(10);

  const {
    testCaseRunningList,
    cancelExecutionLoading,
    cancelExecutionResponse,
  } = useSelector((state) => state.testExecution);

  const { projectMembers } = useSelector((state) => state.project);

  const { defectsList } = useSelector((state) => state.defect);
  useEffect(() => {
    dispatch(
      showProjectMembersRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, [id]);

  useEffect(() => {
    if (cancelExecutionLoading) return;
    dispatch(
      getRunningTestCaseIndexRequest({
        project_id: id,
        pagination_meta: {
          page: page,
          limit: listPerPage,
          order_by: "id_desc",
        },
      })
    );
  }, [cancelExecutionLoading, page]);

  // TOAST MESSAGE FOR CANCELLING EXECUTION
  useEffect(() => {
    if (!cancelExecutionResponse) return;
    if (cancelExecutionResponse) {
      if (
        cancelExecutionResponse?.message &&
        cancelExecutionResponse?.success
      ) {
        toast.success(cancelExecutionResponse?.message);
      } else {
        toast.error(cancelExecutionResponse?.message);
      }
      return () => {
        dispatch(cancelExecutionResClear());
      };
    }
  }, [cancelExecutionResponse]);

  const searchSchema = {
    filters: {
      executor: {
        type: "exact",
        value: "",
      },
      test_case_fk: {
        type: "exact",
        value: "",
      },
    },
    pagination_meta: {
      page: "",
      limit: "",
      order_by: "id_desc",
    },
  };

  const searchFormik = useFormik({
    initialValues: searchSchema.filters,

    onSubmit: (values) => {
      // searchFormik.resetForm();
      handleCloseFilter();
      setReset(true);
      dispatch(
        getRunningTestCaseIndexRequest({
          project_id: id,
          ...payload,
        })
      );
    },
  });

  const payload = {
    filters: {
      executor: {
        value: searchFormik.values.executor.value,
        type: "exact",
      },
      test_case_fk: {
        type: "exact",
        value: searchFormik.values.test_case_fk.value,
      },
    },

    pagination_meta: {
      page: page,
      limit: listPerPage,
      order_by: "id_desc",
    },
  };
  const handleFilter = () => {
    searchFormik.resetForm();
    setReset(false);
    dispatch(
      getRunningTestCaseIndexRequest({
        project_id: id,
        pagination_meta: {
          page: page,
          limit: listPerPage,
          order_by: "id_desc",
        },
      })
    );
  };
  return (
    <div>
      <div className="dashboard_container releaseLayout w-100 overflow-auto">
        <div className="position-relative">
          <div className="test_header d-flex align-items-center justify-content-between">
            <h3>Run</h3>
            <div className="d-flex gap-2">
              <CustomButton
                size="small"
                label="Filter"
                onClick={() => setFilter(true)}
              />
              {reset && (
                <CustomButton
                  label="Reset All"
                  onClick={handleFilter}
                  size="small"
                />
              )}
              <ExportData
                exportLinks={[
                  {
                    url: "api/test_execution/export",
                    label: "Export All",
                    name: "Test Execution",
                    extension: "xls",
                    type: "testexection",
                    project_id: id,
                  },
                ]}
                cdConfig={{
                  project_id: id,
                  url: "api/test_execution/export",
                  type: "testexection",
                  name: "testexection",
                  extension: "xls",
                }}
                extraFilterFields={[
                  {
                    placeholder: "Assigned To",
                    name: "filters.executor.value",
                    inputType: "select",
                    options: projectMembers,
                  },
                  {
                    placeholder: "Executor",
                    name: "filters.test_case_fk.value",
                    inputType: "select",
                    options: projectMembers,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="tblWrpr">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Execution ID</th>
                  <th>Test Case ID</th>
                  <th>Test Case Name</th>
                  <th>Executor</th>
                  <th>Status</th>
                  <th>Log</th>
                  <th>
                    <div className="bug_image_container">
                      <img src={BUGGREY_ICON} alt="greycolorbug" />
                    </div>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {testCaseRunningList?.data?.length > 0 ? (
                  testCaseRunningList?.data?.map((data, index) => (
                    <tr>
                      <td>{`Ex - ${data?.id}`}</td>
                      <td>{`TC - ${data?.test_case_fk?.id}`}</td>
                      <td>{data?.test_case_fk?.name}</td>
                      <td>{convertedString(data?.executor?.full_name)}</td>
                      <td>{convertedString(data?.running_status)}</td>
                      <td>{data?.log_count}</td>
                      <td>
                        <div className="bug_image_container">
                          <span>{data?.bug_count}</span>
                          <img
                            src={BUGCOLOR_ICON}
                            alt="colorbug"
                            style={{ marginBottom: "4px", marginLeft: "3px" }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="view">
                          {data?.is_stoppable && (
                            <button
                              onClick={() =>
                                dispatch(
                                  cancelExecutionRequest({
                                    execution_id: data?.id,
                                  })
                                )
                              }
                            >
                              <Close sx={{ color: "red !important" }} />
                            </button>
                          )}
                          <button>
                            <VisibilityOutlined
                              onClick={() =>
                                navigate(
                                  `/projects/${id}/viewexecutions/${data?.id}`
                                )
                              }
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">
                      <div className="text-start">
                        <p>No Executions Found</p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {testCaseRunningList?.data?.length > 0 && (
          <CustomPagination
            dataList={testCaseRunningList}
            name="Execution"
            page={page}
            setPage={setPage}
            listPerPage={listPerPage}
            applied={false}
          />
        )}
      </div>

      <CustomDialog
        open={filter}
        handleClose={handleCloseFilter}
        title="Filter"
        maxWidth="md"
      >
        <TestExecutionFilter
          handleClose={handleCloseFilter}
          payload={payload}
          formik={searchFormik}
          testCaseRunningList={testCaseRunningList}
        />
      </CustomDialog>
    </div>
  );
};

export default ViewExecutions;
