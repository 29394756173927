import React from "react";
import HistoryTable from "./HistoryTable";
export default function TestDesignHistoryView({
  testCaseId,
  teststep,
  teststep1,
}) {
  return (
    <div className="d-flex flex-wrap gap-2 mb-4 history_view_container">
      <HistoryTable title="Old Value" data={teststep} testCaseId={testCaseId} />
      <HistoryTable title="New Value" data={teststep1} />
    </div>
  );
}
