import { takeLatest, call, put } from "redux-saga/effects";
import LoginActionTypes from "./LoginActionTypes";
import {
  CurrentUserSuccess,
  LoginSuccess,
  LogoutResponse,
  changeOldPasswordResponse,
  changePasswordResponse,
  deleteProfilePhotoResponse,
  forgotPasswordResponse,
  getAllGroupsRes,
  getAllUsersResponse,
  postProfilePhotoResponse,
  unauthorizedMessage,
} from "./LoginActions";

import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
  setAuthToken,
} from "../../Utils/axios";

export function* loginUserWorkerSaga({ payload }) {
  let newPayload = { email: payload.email, password: payload.password };
  try {
    let res = yield call(() =>
      axiosPost("login", newPayload).then((response) => response?.data)
    );

    if (res?.success) {
      localStorage.setItem("token", res?.data?.token);
      setAuthToken(res?.data?.token);
    }
    yield put(LoginSuccess(res));
  } catch (error) {
    yield put(LoginSuccess(error));
  }
}

//CURRENT USER
export function* currentUserWorkerSaga({ payload }) {
  try {
    let res = yield call(() =>
      axiosGet("current_user").then((res) => res?.data)
    );
    yield put(CurrentUserSuccess(res));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//ALL USERS
export function* allUserWorkerSaga({ payload }) {
  try {
    let res = yield call(() =>
      axiosPost("view_users", payload).then((res) => res?.data)
    );
    yield put(getAllUsersResponse(res));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//ALL GROUPS
export function* allGroupsWorkerSaga({ payload }) {
  try {
    let res = yield call(() =>
      axiosPost("view_groups", payload).then((res) => res?.data)
    );
    yield put(getAllGroupsRes(res));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//===============LOGOUT==================//
function* onlogoutAsync({ payload }) {
  try {
    const response = yield call(() => axiosPost("logout", payload));
    yield put(LogoutResponse(response?.data));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//FORGOT PASSWORD

function* onForgotPasswordAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("forgot_password", payload).then((response) => response?.data)
    );
    yield put(forgotPasswordResponse(response));
  } catch (error) {
    yield put(forgotPasswordResponse(error));
  }
}

//CHANGE PASSWORD

function* onChangePasswordAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost(
        `reset_password?tk=${payload.getToken}&id=${payload.getid}`,
        payload.values
      ).then((response) => response?.data)
    );
    yield put(changePasswordResponse(response));
  } catch (error) {
    yield put(changePasswordResponse(error));
  }
}

// CHANGE OLD PASSWORD
function* onChangeOldPasswordAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`change_password`, payload).then((response) => response?.data)
    );
    yield put(changeOldPasswordResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE PROFILE PHOTO
function* onUpdateProfilePhotoAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(
        `user/${payload?.id}`,
        payload?.type === "add"
          ? payload?.formContent
          : { user_profile_photo: null }
      ).then((response) => response?.data)
    );
    yield put(postProfilePhotoResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

export function* loginWatcherSaga() {
  yield takeLatest(LoginActionTypes.LOGIN_REQUESTED, loginUserWorkerSaga);
  yield takeLatest(LoginActionTypes.LOGIN_RESPONSE, currentUserWorkerSaga);
  yield takeLatest(
    LoginActionTypes.CURRENT_USER_REQUESTED,
    currentUserWorkerSaga
  );
  yield takeLatest(LoginActionTypes.LOGOUT_REQ, onlogoutAsync);
  yield takeLatest(LoginActionTypes.FORGOT_PASSWORD_REQ, onForgotPasswordAsync);
  yield takeLatest(LoginActionTypes.CHANGE_PASSWORD_REQ, onChangePasswordAsync);
  yield takeLatest(
    LoginActionTypes.CHANGE_OLD_PASSWORD_REQ,
    onChangeOldPasswordAsync
  );
  yield takeLatest(LoginActionTypes.ALL_USER_REQUESTED, allUserWorkerSaga);
  yield takeLatest(LoginActionTypes.ALL_GROUPS_REQUESTED, allGroupsWorkerSaga);
  yield takeLatest(
    LoginActionTypes.UPDATE_PROFILEPHOTO_REQUEST,
    onUpdateProfilePhotoAsync
  );
}
