import React, { useEffect, useState } from "react";

import { PlayArrow } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getRequirementHistoryIndexRequest } from "../../../Redux/RequirementSaga/RequirementCommentSaga/RequirementCommentAction";
import moment from "moment";

const RequirementHistory = ({ requirementId }) => {
  const dispatch = useDispatch();
  const [accordionVisible, setAccordionVisible] = useState([]);

  const { requirementHistoryList } = useSelector(
    (state) => state.requirementcomment
  );

  // Function to toggle accordion visibility for a specific index
  // Create a copy of the array to avoid mutating the state directly
  const handlePlayArrowClick = (index) => {
    const updatedAccordionVisibleArray = [...accordionVisible];
    updatedAccordionVisibleArray[index] = !updatedAccordionVisibleArray[index];
    setAccordionVisible(updatedAccordionVisibleArray);
  };

  // TO GET HISTORY LIST FOR REQUIREMENT
  useEffect(() => {
    dispatch(getRequirementHistoryIndexRequest(requirementId));
  }, [requirementId]);

  return (
    <>
      <div className="accordian_containers">
        <div className="hisHead">
          <p className="hisDate">Date</p>
          <p className="hisDate">By</p>
        </div>
        <div className="p-1">
          {requirementHistoryList?.length > 0 ? (
            requirementHistoryList?.map((history, index) => (
              <div key={index}>
                {/* Add a condition to skip mapping when index is last */}
                {index !== requirementHistoryList?.length - 1 && (
                  <div className="d-flex justify-content-between px-5 py-1 histRow">
                    <div
                      className="d-flex gap-2 HisFont"
                      onClick={() => handlePlayArrowClick(index)}
                      onKeyDown={() => {}}
                    >
                      <p
                        className={
                          accordionVisible?.[index]
                            ? "conthrd clickable"
                            : "clickable"
                        }
                      >
                        <PlayArrow
                          style={{
                            transform: accordionVisible?.[index]
                              ? "rotate(90deg)"
                              : "rotate(0deg)",
                          }}
                        />
                        {moment(history?.updated_at).format(
                          "DD.MM.YYYY h:mm:ssA"
                        )}
                      </p>
                    </div>
                    <p className={accordionVisible?.[index] ? "conthrdr" : ""}>
                      {`${
                        history?.updated_by?.full_name?.[0].toUpperCase() +
                        history?.updated_by?.full_name?.slice(1)
                      }` || ""}
                    </p>
                  </div>
                )}
                {accordionVisible?.[index] &&
                  index !== requirementHistoryList?.length - 1 && (
                    <div className="latestHstry">
                      <div>
                        <p className="conthrdr">Field</p>
                        <p>Name</p>
                        <p>Priority</p>
                        <p>Status</p>
                        <p>Type</p>
                        <p>Description</p>
                        <p>Assigned To</p>
                      </div>
                      <div>
                        <p className="conthrdr">Old Values</p>
                        <p>{requirementHistoryList?.[index + 1]?.name}</p>
                        <p>{requirementHistoryList?.[index + 1]?.priority}</p>
                        <p>{requirementHistoryList?.[index + 1]?.status}</p>
                        <p>{requirementHistoryList?.[index + 1]?.type}</p>
                        <p>
                          {requirementHistoryList?.[index + 1]?.description ||
                            "null"}
                        </p>
                        <p>
                          {`${
                            requirementHistoryList?.[
                              index + 1
                            ]?.assigned_to_fk?.full_name?.[0].toUpperCase() +
                            requirementHistoryList?.[
                              index + 1
                            ]?.assigned_to_fk?.full_name?.slice(1)
                          }`}
                        </p>
                      </div>
                      <div>
                        <p className="conthrdr">New Values</p>
                        <p>{requirementHistoryList?.[index]?.name}</p>
                        <p>{requirementHistoryList?.[index]?.priority}</p>
                        <p>{requirementHistoryList?.[index]?.status}</p>
                        <p>{requirementHistoryList?.[index]?.type}</p>
                        <p>
                          {requirementHistoryList?.[index]?.description ||
                            "null"}
                        </p>
                        <p>
                          {`${
                            requirementHistoryList?.[
                              index
                            ]?.assigned_to_fk?.full_name?.[0].toUpperCase() +
                            requirementHistoryList?.[
                              index
                            ]?.assigned_to_fk?.full_name?.slice(1)
                          }` || "NA"}
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            ))
          ) : (
            <p className="text-center">No History Yet</p>
          )}
        </div>
      </div>
    </>
  );
};

export default RequirementHistory;
