import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  postProjectRequest,
  postProjectResponseClear,
  updateProjectRequest,
  showProjectRequest,
  showProjectResponseClear,
  getProjectIndexRequest,
  showProjectMembersRequest,
  showProjectMembersResponseClear,
  getApplicationsIndexRequest,
  getAppProjectsIndexRequest,
} from "../../Redux/ProjectSaga/ProjectAction";
import toast from "react-hot-toast";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import { Autocomplete, TextField } from "@mui/material";
import "./Project.css";
import PropTypes from "prop-types";
import { projectStatus } from "../../Utils/constants";
import CustomCancelSubmit from "../../Components/Molecules/CancelSubmit/CancelSubmit";
import { convertedString } from "../../Utils/handles";

const ProjectAddForm = ({ handleClose, type, data, payload }) => {
  const dispatch = useDispatch();

  const [selectMembers, setSelectMembers] = useState(false);

  const {
    postProjectResponse,
    project,
    projectsList,
    projectMembers,
    applicationList,
    appProjectList,
  } = useSelector((state) => state.project);

  const Validation = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-=+\n]+)$/,
        "Project name should contain characters too"
      )
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .trim()
      .required("Project Name is required!"),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date()
      .min(
        Yup.ref("start_date"),
        "End Date must be greater than or equal to Start Date"
      )
      .required("End Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      start_date: "",
      end_date: "",
      description: "",
      admin: "",
      project: "",
      status: "OPEN",
      assign_user_group: [],
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      if (type === "edit") {
        const { project, ...payload } = values;
        dispatch(updateProjectRequest(payload));
      } else {
        const { project, ...payload } = {
          ...values,
          assign_user_group: values.assign_user_group?.map((el) => el.id),
        };
        dispatch(postProjectRequest(payload));
      }
    },
  });

  // GET PARTICULAR PROJECT
  useEffect(() => {
    if (type === "edit") {
      dispatch(showProjectRequest(data?.id));
    } else dispatch(getProjectIndexRequest(payload));
  }, []);

  // TOAST MESSAGE FOR POST AND EDIT
  useEffect(() => {
    if (postProjectResponse) {
      if (postProjectResponse?.message && postProjectResponse?.success) {
        toast.success(postProjectResponse?.message);
        handleClose();
      } else {
        toast.error(postProjectResponse?.message);
      }
    }
    return () => {
      dispatch(postProjectResponseClear());
      dispatch(showProjectMembersResponseClear());
    };
  }, [postProjectResponse]);

  useEffect(() => {
    if (type === "edit" && project) {
      for (const key in formik.values) {
        formik.setFieldValue(key, project[key]);
      }
      formik.setFieldValue("id", project?.id);
      return () => {
        dispatch(showProjectResponseClear());
      };
    }
  }, [project]);

  const handleGetProject = (id) => {
    dispatch(
      showProjectMembersRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
    setSelectMembers(true);
    formik.setFieldValue("assign_user_group", []);
  };

  // useEffect(() => {
  //   if (formik.values.application_id && type === "edit") {
  //     dispatch(
  //       getAppProjectsIndexRequest({
  //         application_id: formik.values.application_id,
  //       })
  //     );
  //   }
  // }, [formik.values.application_id]);

  // console.log("sdddddd", appProjectList);

  return (
    <div className="project_add_container">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 err_margin">
            <CustomInput
              placeholder="Project Name"
              type="text"
              name="name"
              size="large"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              formik={formik}
            />
          </div>
          {type !== "edit" && (
            <div className="col-md-6">
              <CustomSelect
                size="small"
                placeholder="Assign Project Users"
                options={projectsList?.data?.map((values, index) => {
                  return {
                    value: values.id,
                    name: values.name,
                    onClick: () => handleGetProject(values?.id),
                  };
                })}
                name="project"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.project}
                formik={formik}
              />
            </div>
          )}
          {projectMembers && selectMembers && (
            <div className="col-12 mb-3 autocomplete_container">
              <Autocomplete
                name="assign_user_group"
                multiple
                id="assign_user_group"
                options={projectMembers?.data}
                className="w-100 project_autocomplete"
                getOptionLabel={(option) => option.email}
                defaultValue={[projectMembers?.data[0]]} // Make sure the defaultValue matches an option
                filterSelectedOptions
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                size="small"
                shrink={false}
                onChange={(event, newValue) => {
                  formik.setFieldValue("assign_user_group", newValue);
                }}
                onBlur={formik.handleBlur}
                value={formik.values?.assign_user_group || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      formik?.values?.assign_user_group?.length > 0
                        ? ""
                        : "Select members"
                    }
                  />
                )}
              />
            </div>
          )}

          <div className="col-md-6 err_margin">
            <CustomInput
              type="date"
              name="start_date"
              size="large"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.start_date}
              formik={formik}
              shrink={true}
              label="Start Date"
            />
          </div>
          <div className="col-md-6">
            <CustomInput
              type="date"
              name="end_date"
              size="large"
              shrink={true}
              label="End Date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.end_date}
              formik={formik}
            />
          </div>
          {type === "edit" && (
            <div className="col-md-6">
              <CustomSelect
                size="small"
                placeholder="Select Status"
                options={projectStatus.map((values) => {
                  return {
                    value: values.value,
                    name: values.name,
                  };
                })}
                name="status"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.status}
                formik={formik}
              />
            </div>
          )}
          <div className="col-12">
            <CustomInput
              multiline
              rows={3}
              value={formik.values.description}
              placeholder="Description"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
            />
            <CustomCancelSubmit
              cancelLabel="Cancel"
              handleClose={handleClose}
              submitLabel={type === "edit" ? "Edit" : "Add"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

ProjectAddForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  payload: PropTypes.object.isRequired,
};

export default ProjectAddForm;
