import DashboardActiontypes from "./DashboardActiontypes";

// dashboard
export const getDashboardRequest = (payload) => ({
  type: DashboardActiontypes.DASHBOARD_REQUEST,
  payload,
});

export const getDashboardResponse = (payload) => ({
  type: DashboardActiontypes.DASHBOARD_RESPONSE,
  payload,
});

export const getDashboardResponseClear = () => ({
  type: DashboardActiontypes.DASHBOARD_RESCLEAR,
});
