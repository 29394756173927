import React, { useEffect, useState } from "react";
import { VisibilityOutlined } from "@mui/icons-material";
import { convertedString } from "../../Utils/handles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getDefectsIndexRequest } from "../../Redux/DefectsSaga/DefectsAction";
import { CustomPagination } from "../../Components/Molecules/PaginationComponent/Pagination";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import DefectsFilter from "./DefectsFilter";
import { useFormik } from "formik";
import ExportToXL from "../../Components/Molecules/ExportComponent/ExportToXL";
import ExportData from "../../Components/Molecules/ExportComponent/ExportToXL";
import { showProjectMembersRequest } from "../../Redux/ProjectSaga/ProjectAction";

const DefectsIndex = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reset, setReset] = useState(false);

  const [page, setPage] = useState(1);
  const [listPerPage, setListPerPage] = useState(10);
  const { projectMembers } = useSelector((state) => state.project);

  const { defectsList } = useSelector((state) => state.defect);
  useEffect(() => {
    dispatch(
      showProjectMembersRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, [id]);

  useEffect(() => {
    dispatch(
      getDefectsIndexRequest({
        filters: {
          project_id: {
            value: id,
          },
        },
        pagination_meta: {
          page: page,
          limit: listPerPage,
          order_by: "id_desc",
        },
      })
    );
  }, [page, listPerPage]);
  const [filter, setFilter] = useState(false);
  const handleCloseFilter = () => {
    setFilter(false);
  };

  const searchSchema = {
    filters: {
      project_id: {
        type: "exact",
        value: id,
      },
      created_by_fk_id: {
        type: "exact",
        value: "",
      },
      status: {
        type: "exact",
        value: "",
      },
    },
    pagination_meta: {
      page: "",
      limit: "",
      order_by: "id_desc",
    },
  };

  const searchFormik = useFormik({
    initialValues: searchSchema.filters,

    onSubmit: (values) => {
      // searchFormik.resetForm();
      handleCloseFilter();
      setReset(true);
      dispatch(getDefectsIndexRequest(payload));
    },
  });

  const payload = {
    filters: {
      project_id: {
        type: "exact",
        value: id,
      },
      created_by_fk_id: {
        value: searchFormik.values.created_by_fk_id.value,
        type: "exact",
      },
      status: {
        type: "exact",
        value: searchFormik.values.status.value,
      },
    },

    pagination_meta: {
      page: page,
      limit: listPerPage,
      order_by: "id_desc",
    },
  };

  const handleFilter = () => {
    searchFormik.resetForm();
    setReset(false);
    dispatch(
      getDefectsIndexRequest({
        filters: {
          project_id: {
            value: id,
          },
        },
        pagination_meta: {
          page: page,
          limit: listPerPage,
          order_by: "id_desc",
        },
      })
    );
  };

  const status = [
    {
      name: "New",
      value: "NEW",
    },
    {
      name: "Fixed",
      value: "FIXED",
    },
    {
      name: "Reopened",
      value: "REOPENED",
    },
    {
      name: "On hold",
      value: "ON_HOLD",
    },
    {
      name: "Closed",
      value: "CLOSED",
    },
    {
      name: "Not a bug",
      value: "NOT_A_BUG",
    },
    {
      name: "Suggestion",
      value: "SUGGESTION",
    },
  ];

  return (
    <>
      <div>
        <div className="dashboard_container releaseLayout w-100 overflow-auto">
          <div className="position-relative">
            <div className="test_header d-flex justify-content-between align-items-center">
              <h3>Defects</h3>
              <div className="d-flex gap-2">
                <CustomButton
                  size="small"
                  label="Filter"
                  onClick={() => setFilter(true)}
                />
                {reset && (
                  <CustomButton
                    label="Reset All"
                    onClick={handleFilter}
                    size="small"
                  />
                )}
               <ExportData
                  exportLinks={[
                    {
                      url: "api/defect/export",
                      label: "Export All",
                      name: "Defects",
                      extension: "xls",
                      type: "defects",
                      project_id: id,
                    },
                  ]}
                  cdConfig={{
                    project_id: id,
                    url: "api/defect/export",
                    type: "defects",
                    name: "defects",
                    extension: "xls",
                  }}
                  extraFilterFields={[
                    {
                      placeholder: "Assigned To",
                      name: "filters.created_by_fk_id.value",
                      inputType: "select",
                      options: projectMembers,
                    },
                    {
                      placeholder: "Status",
                      name: "filters.status.value",
                      inputType: "select",
                      options: status,
                    },
                  ]}
                /> 
              </div>
            </div>
          </div>
          <div className="tblWrpr">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Defect ID</th>
                    <th>Test Step</th>
                    <th>Summary</th>
                    <th>Status</th>
                    <th>Created by</th>
                    <th>Created at</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {defectsList?.data?.length > 0 ? (
                    defectsList?.data?.map((data, index) => (
                      <tr>
                        <td>{`DF - ${data?.id}`}</td>
                        <td>{`TS - ${data?.test_step_fk}`}</td>
                        <td
                          style={{
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {data?.summary}
                        </td>
                        <td>{convertedString(data?.status)}</td>
                        <td>
                          {convertedString(data?.created_by_fk?.full_name)}
                        </td>
                        <td>
                          {moment(data?.created_at).format("DD/MM/YYYY HH:mm")}
                        </td>
                        <td>
                          <div className="view">
                            <button
                              onClick={() =>
                                navigate(`project/${id}/viewDefect/${data?.id}`)
                              }
                            >
                              <VisibilityOutlined />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">
                        <div className="text-start">
                          <p>No Defects Found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {defectsList?.data?.length > 0 && (
            <CustomPagination
              dataList={defectsList}
              name="Defect"
              page={page}
              setPage={setPage}
              listPerPage={listPerPage}
              applied={false}
            />
          )}
        </div>
      </div>

      <CustomDialog
        open={filter}
        maxWidth="md"
        handleClose={handleCloseFilter}
        title="Filter"
      >
        <DefectsFilter
          handleClose={handleCloseFilter}
          payload={payload}
          formik={searchFormik}
          defectsList={defectsList}
        />
      </CustomDialog>
    </>
  );
};

export default DefectsIndex;
