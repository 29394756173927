import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_DOMAIN;
export const appUrl = process.env.REACT_APP_DOMAIN;

export const axiosPost = (url, payload) => axios.post("/api/" + url, payload);
export const axiosGet = (url, payload, config) =>
  axios.get("/api/" + url, payload, config);
export const axiosPut = (url, payload) => axios.put("/api/" + url, payload);
export const axiosPatch = (url, payload) => axios.put("/api/" + url, payload);
export const axiosDelete = (url, payload) =>
  axios.delete("/api/" + url, payload);

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
