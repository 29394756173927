import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import ProjectBody from "./ProjectBody";
import ProjectAddForm from "./ProjectAddForm";
import MetaData from "../../Components/Organisms/MetaData/MetaData";
import {
  clearProjectTextAction,
  deleteProjectResponseClear,
  getProjectIndexRequest,
  saveProjectFilter,
} from "../../Redux/ProjectSaga/ProjectAction";
import { CustomPagination } from "../../Components/Molecules/PaginationComponent/Pagination";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import FilterForm from "./FilterForm";
import { checkFilterValues } from "../../Utils/handles";
import CloseIcon from "@mui/icons-material/Close";

import { Search } from "@mui/icons-material";

const Projects = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(false);

  const [page, setPage] = useState(1);
  const [listPerPage, setListPerPage] = useState(10);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseFilter = () => {
    setFilter(false);
  };

  const {
    projectsList,
    deleteProjectResponse,
    postProjectLoading,
    deleteProjectLoading,
    savedFilters,
    searchProjectText,
  } = useSelector((state) => state.project);
  const [reset, setReset] = useState(false);

  const searchSchema = {
    filters: {
      name: {
        type: "like",
        value: "",
      },
      status: {
        type: "like",
        value: "",
      },
      start_date: {
        type: "gte",
        value: "",
      },
      end_date: {
        type: "lte",
        value: "",
      },
    },
    pagination_meta: {
      page: "",
      limit: "",
      order_by: "id_desc",
    },
  };

  const searchFormik = useFormik({
    initialValues: searchSchema.filters,

    onSubmit: () => {
      // searchFormik.resetForm();
      handleCloseFilter();
      setReset(true);
      dispatch(saveProjectFilter(payload));
      dispatch(getProjectIndexRequest(payload));
    },
  });

  useEffect(() => {
    dispatch(saveProjectFilter(payload));
  }, [page, listPerPage, reset]);

  const payload = {
    filters: {
      name: {
        type: searchProjectText.filters.name.value ? "like" : "exact",
        value:
          searchProjectText.filters.name.value ||
          searchFormik.values.name.value,
      },
      status: {
        value: searchFormik.values.status.value,
        type: "in",
      },
      start_date: {
        value: searchFormik.values.start_date.value,
        type: "gte",
      },
      end_date: {
        value: searchFormik.values.end_date.value,
        type: "lte",
      },
    },

    pagination_meta: {
      page: page,
      limit: listPerPage,
      order_by: "id_desc",
    },
  };

  useEffect(() => {
    if (postProjectLoading || deleteProjectLoading) return;
    dispatch(
      getProjectIndexRequest(
        postProjectLoading || deleteProjectLoading || page || listPerPage
          ? payload
          : searchProjectText
      )
    );
  }, [
    postProjectLoading,
    deleteProjectLoading,
    page,
    listPerPage,
    savedFilters,
  ]);

  // Toast message for delete
  useEffect(() => {
    if (deleteProjectResponse) {
      if (deleteProjectResponse?.message && deleteProjectResponse?.success) {
        toast.success(deleteProjectResponse?.message);
      } else {
        toast.error(deleteProjectResponse?.message);
      }
    }
    return () => {
      dispatch(deleteProjectResponseClear());
    };
  }, [deleteProjectResponse]);

  const handleFilter = () => {
    searchFormik.resetForm();
    setReset(false);
    dispatch(getProjectIndexRequest(payload));
  };

  useEffect(() => {
    if (!savedFilters) return;
    if (
      savedFilters.filters?.end_date?.value !== "" ||
      savedFilters.filters?.name?.value !== "" ||
      savedFilters.filters?.start_date?.value !== "" ||
      savedFilters.filters?.status?.value !== ""
    ) {
      setReset(true);
    }
    searchFormik.setValues(savedFilters?.filters);
    // checkFilterValues(
    //   savedFilters,
    //   setPage,
    //   setListPerPage,

    //   "id_desc"
    // );
  }, []);

  return (
    <>
      <MetaData title="Projects" />
      <div className="pageTop">
        <div className="pageTopLeft">
          <h3>Projects</h3>
        </div>
        <div className="d-flex gap-2">
          <form className="left_side_nav" onSubmit={searchFormik.handleSubmit}>
            <input
              type="text"
              name="name.value"
              id="projectSearchInput"
              placeholder={"Search Project"}
              className="search_input_project"
              onChange={searchFormik.handleChange}
              onBlur={searchFormik.handleBlur}
              value={searchFormik.values.name.value}
              formik={searchFormik}
              autoComplete="off"
            />
            <Search className="search_input_icon" />
            {searchFormik.values.name.value && (
              <CloseIcon
                className="reset_filter_icon"
                onClick={() => {
                  searchFormik.resetForm();
                  dispatch(clearProjectTextAction());

                  dispatch(
                    getProjectIndexRequest({
                      filters: {
                        name: {
                          type: "like",
                          value: "",
                        },
                      },
                      pagination_meta: {
                        page: page,
                        limit: listPerPage,
                        order_by: "id_desc",
                      },
                    })
                  );
                }}
              />
            )}
          </form>
          <div className="">
            <CustomButton
              size="small"
              label="Add Project"
              onClick={() => setOpen(true)}
            />
          </div>
          <div className="d-flex gap-2">
            <CustomButton
              size="small"
              label="Filter"
              onClick={() => setFilter(true)}
            />
            {reset && (
              <CustomButton
                label="Reset All"
                onClick={handleFilter}
                size="small"
              />
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="p-1 bg-white project_container">
          <div className="tblWrpr">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Project Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Users</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {projectsList?.data?.length > 0 ? (
                    projectsList?.data?.map((data, index) => (
                      <tr>
                        <ProjectBody
                          data={data}
                          index={index}
                          listPerPage={listPerPage}
                          page={page}
                        />
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-start py-4 fs-6">
                        <div className="text-start">
                          <p>No Projects Found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {projectsList?.data?.length > 0 && (
              <CustomPagination
                dataList={projectsList}
                name="Project"
                page={page}
                setPage={setPage}
                listPerPage={listPerPage}
              />
            )}
          </div>
        </div>
      </div>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        title="Start a new project"
      >
        <ProjectAddForm handleClose={handleClose} payload={payload} />
      </CustomDialog>
      <CustomDialog
        open={filter}
        handleClose={handleCloseFilter}
        title="Filter"
        maxWidth="md"
      >
        <FilterForm
          handleClose={handleCloseFilter}
          payload={payload}
          formik={searchFormik}
          projectsList={projectsList}
        />
      </CustomDialog>
    </>
  );
};

export default Projects;
