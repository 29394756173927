export default {
  PROJECT_INDEX_REQUEST: "PROJECT_INDEX_REQUEST",
  PROJECT_INDEX_RESPONSE: "PROJECT_INDEX_RESPONSE",

  POST_PROJECT_REQUEST: "POST_PROJECT_REQUEST",
  POST_PROJECT_RESPONSE: "POST_PROJECT_RESPONSE",

  UPDATE_PROJECT_REQUEST: "UPDATE_PROJECT_REQUEST",
  UPDATE_PROJECT_RESPONSE: "UPDATE_PROJECT_RESPONSE",

  POST_PROJECT_RES_CLEAR: "POST_PROJECT_RES_CLEAR",

  DELETE_PROJECT_REQUEST: "DELETE_PROJECT_REQUEST",
  DELETE_PROJECT_RESPONSE: "DELETE_PROJECT_RESPONSE",
  DELETE_PROJECT_RES_CLEAR: "DELETE_PROJECT_RES_CLEAR",

  SHOW_PROJECT_REQUEST: "SHOW_PROJECT_REQUEST",
  SHOW_PROJECT_RESPONSE: "SHOW_PROJECT_RESPONSE",
  SHOW_PROJECT_RES_CLEAR: "SHOW_PROJECT_RES_CLEAR",

  GET_PROJECTMEMBERS_REQUEST: "GET_PROJECTMEMBERS_REQUEST",
  GET_PROJECTMEMBERS_RESPONSE: "GET_PROJECTMEMBERS_RESPONSE",
  GET_PROJECTMEMBERS_RES_CLEAR: "GET_PROJECTMEMBERS_RES_CLEAR",

  // DELETE PROJECT MEMBER
  DELETE_PROJECTMEMBERS_REQUEST: "DELETE_PROJECTMEMBERS_REQUEST",
  DELETE_PROJECTMEMBERS_RESPONSE: "DELETE_PROJECTMEMBERS_RESPONSE",
  DELETE_PROJECTMEMBERS_RES_CLEAR: "DELETE_PROJECTMEMBERS_RES_CLEAR",

  // VIEW APPLICATIONS
  GET_APPLICATIONS_INDEX_REQUEST: "GET_APPLICATIONS_INDEX_REQUEST",
  GET_APPLICATIONS_INDEX_RESPONSE: "GET_APPLICATIONS_INDEX_RESPONSE",

  // GET APPLICATION PROJECTS
  GET_APP_PROJECTS_INDEX_REQUEST: "GET_APP_PROJECTS_INDEX_REQUEST",
  GET_APP_PROJECTS_INDEX_RESPONSE: "GET_APP_PROJECTS_INDEX_RESPONSE",

  // ISSUE TRACKER
  GET_ISSUETRACKER_INDEX_REQUEST: "GET_ISSUETRACKER_INDEX_REQUEST",
  GET_ISSUETRACKER_INDEX_RESPONSE: "GET_ISSUETRACKER_INDEX_RESPONSE",

  POST_ISSUETRACKER_REQUEST: "POST_ISSUETRACKER_REQUEST",
  POST_ISSUETRACKER_RESPONSE: "POST_ISSUETRACKER_RESPONSE",
  POST_ISSUETRACKER_RES_CLEAR: "POST_ISSUETRACKER_RES_CLEAR",

  DELETE_ISSUETRACKER_REQUEST: "DELETE_ISSUETRACKER_REQUEST",
  DELETE_ISSUETRACKER_RESPONSE: "DELETE_ISSUETRACKER_RESPONSE",
  DELETE_ISSUETRACKER_RES_CLEAR: "DELETE_ISSUETRACKER_RES_CLEAR",

  SEARCH_PROJECT_TEXT: "SEARCH_PROJECT_TEXT",
  CLEAR_PROJECT_TEXT: "CLEAR_PROJECT_TEXT",

  SAVED_PROJECT_FILTER: "SAVED_PROJECT_FILTER",
};
