import { takeLatest, call, put } from "redux-saga/effects";
import TestDesignActionTypes from "./TestDesignActionTypes";
import { axiosGet, axiosPost, axiosPut, axiosDelete } from "../../Utils/axios";
import { unauthorizedMessage } from "../Login/LoginActions";
import {
  deleteTestDesignDetailAttachmentResponse,
  deleteTestDesignDetailResponse,
  deleteTestDesignModuleResponse,
  getTestDesignDetailAttachmentIndexResponse,
  getTestDesignDetailIndexResponse,
  getTestDesignModuleIndexResponse,
  postTestDesignDetailAttachmentResponse,
  postTestDesignDetailResponse,
  postTestDesignModuleResponse,
  showTestDesignDetailAttachmentResponse,
  showTestDesignDetailResponse,
  showTestDesignModuleResponse,
  updateTestDesignDetailAttachmentResponse,
  updateTestDesignDetailResponse,
  updateTestDesignModuleResponse,
} from "./TestDesignAction";

// WORKER SAGA
// TESTDESIGN MODULE
// GET ALL TESTDESIGN MODULE
export function* onGetTestDesignModuleIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_modules", payload).then((response) => response?.data)
    );
    yield put(getTestDesignModuleIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST TESTDESIGN MODULE
export function* onPostTestDesignModuleWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("module/new", payload).then((response) => response?.data)
    );
    yield put(postTestDesignModuleResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE TESTDESIGN MODULE
export function* onUpdateTestDesignModuleWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`module/${payload?.id}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(updateTestDesignModuleResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE TESTDESIGN MODULE
export function* onDeleteTestDesignModuleWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`module/${payload}`).then((response) => response?.data)
    );
    yield put(deleteTestDesignModuleResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR TESTDESIGN MODULE
export function* onViewTestDesignModuleWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`module/${payload}`).then((response) => response?.data)
    );
    yield put(showTestDesignModuleResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// TESTDESIGN DETAIL
// GET ALL TESTDESIGN DETAILS
export function* onGetTestDesignDetailIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_test_cases", payload).then((response) => response?.data)
    );
    yield put(getTestDesignDetailIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST TESTDESIGN DETAILS
export function* onPostTestDesignDetailWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("test_case/new", payload).then((response) => response?.data)
    );
    yield put(postTestDesignDetailResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE TESTDESIGN DETAILS
export function* onUpdateTestDesignDetailWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`test_case/${payload?.id}`, payload?.formData).then(
        (response) => response?.data
      )
    );
    yield put(updateTestDesignDetailResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE TESTDESIGN DETAILS
export function* onDeleteTestDesignDetailWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`requirement/${payload}`).then((response) => response?.data)
    );
    yield put(deleteTestDesignDetailResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR TESTDESIGN DETAIL
export function* onViewTestDesignDetailWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`test_case/${payload}`).then((response) => response?.data)
    );
    yield put(showTestDesignDetailResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// TESTDESIGN DETAIL ATTACHMENTS
// GET ALL TESTDESIGN DETAIL ATTACHMENTS
export function* onGetTestDesignDetailAttachmentIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("requirement/view_attachments", payload).then(
        (response) => response?.data
      )
    );
    yield put(getTestDesignDetailAttachmentIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST TESTDESIGN DETAIL ATTACHMENTS
export function* onPostTestDesignDetailAttachmentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("requirement/attachment/new", payload).then(
        (response) => response?.data
      )
    );
    yield put(postTestDesignDetailAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE TESTDESIGN DETAIL ATTACHMENTS
export function* onUpdateTestDesignDetailAttachmentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`requirement/attachment/${payload?.id}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(updateTestDesignDetailAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE TESTDESIGN DETAIL ATTACHMENTS
export function* onDeleteTestDesignDetailAttachmentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`requirement/attachment/${payload}`).then(
        (response) => response?.data
      )
    );
    yield put(deleteTestDesignDetailAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR TESTDESIGN DETAIL ATTACHMENTS
export function* onViewTestDesignDetailAttachmentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`requirement/attachment/${payload}`).then(
        (response) => response?.data
      )
    );
    yield put(showTestDesignDetailAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* TestDesignWatcherSaga() {
  // TESTDESIGN MODULE
  yield takeLatest(
    TestDesignActionTypes.GET_TESTDESIGNMODULE_INDEX_REQUEST,
    onGetTestDesignModuleIndexWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.POST_TESTDESIGNMODULE_REQUEST,
    onPostTestDesignModuleWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.UPDATE_TESTDESIGNMODULE_REQUEST,
    onUpdateTestDesignModuleWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.DELETE_TESTDESIGNMODULE_REQUEST,
    onDeleteTestDesignModuleWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.SHOW_TESTDESIGNMODULE_REQUEST,
    onViewTestDesignModuleWorkerSaga
  );

  // TESTDESIGN DETAIL
  yield takeLatest(
    TestDesignActionTypes.GET_TESTDESIGNDETAIL_INDEX_REQUEST,
    onGetTestDesignDetailIndexWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.POST_TESTDESIGNDETAIL_REQUEST,
    onPostTestDesignDetailWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.UPDATE_TESTDESIGNDETAIL_REQUEST,
    onUpdateTestDesignDetailWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.DELETE_TESTDESIGNDETAIL_REQUEST,
    onDeleteTestDesignDetailWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.SHOW_TESTDESIGNDETAIL_REQUEST,
    onViewTestDesignDetailWorkerSaga
  );

  // TESTDESIGN DETAIL ATTACHMENTS
  yield takeLatest(
    TestDesignActionTypes.GET_TESTDESIGNATTACH_INDEX_REQUEST,
    onGetTestDesignDetailAttachmentIndexWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.POST_TESTDESIGNATTACHMENT_REQUEST,
    onPostTestDesignDetailAttachmentWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.UPDATE_TESTDESIGNATTACHMENT_REQUEST,
    onUpdateTestDesignDetailAttachmentWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.DELETE_TESTDESIGNATTACHMENT_REQUEST,
    onDeleteTestDesignDetailAttachmentWorkerSaga
  );
  yield takeLatest(
    TestDesignActionTypes.SHOW_TESTDESIGNATTACHMENT_REQUEST,
    onViewTestDesignDetailAttachmentWorkerSaga
  );
}
