import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  postProjectRequest,
  postProjectResponseClear,
  updateProjectRequest,
  showProjectRequest,
  showProjectResponseClear,
  getProjectIndexRequest,
  showProjectMembersRequest,
  showProjectMembersResponseClear,
} from "../../Redux/ProjectSaga/ProjectAction";
import toast from "react-hot-toast";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import { Autocomplete, TextField } from "@mui/material";
import { useParams } from "react-router-dom";

const DefectsFilter = ({ handleClose, defectsList, payload, formik }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { projectMembers } = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(
      showProjectMembersRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, [id]);

  const status = [
    {
      name: "New",
      value: "NEW",
    },
    {
      name: "Fixed",
      value: "FIXED",
    },
    {
      name: "Reopened",
      value: "REOPENED",
    },
    {
      name: "On hold",
      value: "ON_HOLD",
    },
    {
      name: "Closed",
      value: "CLOSED",
    },
    {
      name: "Not a bug",
      value: "NOT_A_BUG",
    },
    {
      name: "Suggestion",
      value: "SUGGESTION",
    },
  ];

  return (
    <div className="project_add_container">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 err_margin details_list">
            <h3>Status</h3>

            <CustomSelect
              size="small"
              // placeholder="Status"
              options={status.map((values, index) => {
                return {
                  value: values?.value,
                  name: values.name,
                };
              })}
              name="status.value"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status.value}
              formik={formik}
            />
          </div>
          <div className="col-md-6 err_margin details_list">
            <h3>Assigned To</h3>
            <CustomSelect
              size="small"
              // placeholder="Assigned To"
              options={projectMembers?.data?.map((values, index) => {
                return {
                  value: values.user_fk,
                  name: `${values.first_name} ${values.last_name}`,
                };
              })}
              name="created_by_fk_id.value"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.created_by_fk_id.value}
              formik={formik}
            />
          </div>

          <div className="col-12">
            <div className="popformbutton">
              <CustomButton
                size="small"
                label="Cancel"
                btnType="secondary"
                onClick={handleClose}
              />
              <CustomButton size="small" label={"Filter"} type="submit" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DefectsFilter;
