import ReleaseActionTypes from "./ReleaseActionTypes";

// GET ALL RELEASES
export const getReleaseIndexRequest = (payload) => ({
  type: ReleaseActionTypes.RELEASE_INDEX_REQUEST,
  payload,
});

export const getReleaseIndexResponse = (payload) => ({
  type: ReleaseActionTypes.RELEASE_INDEX_RESPONSE,
  payload,
});

// POST RELEASE
export const postReleaseRequest = (payload) => ({
  type: ReleaseActionTypes.POST_RELEASE_REQUEST,
  payload,
});

export const postReleaseResponse = (payload) => ({
  type: ReleaseActionTypes.POST_RELEASE_RESPONSE,
  payload,
});

// UPDATE RELEASE
export const updateReleaseRequest = (payload) => ({
  type: ReleaseActionTypes.UPDATE_RELEASE_REQUEST,
  payload,
});

export const updateReleaseResponse = (payload) => ({
  type: ReleaseActionTypes.UPDATE_RELEASE_RESPONSE,
  payload,
});

// POST AND PUT RESPONSE CLEAR
export const postReleaseResponseClear = () => ({
  type: ReleaseActionTypes.POST_RELEASE_RES_CLEAR,
});

// DELETE RELEASE
export const deleteReleaseRequest = (payload) => ({
  type: ReleaseActionTypes.DELETE_RELEASE_REQUEST,
  payload,
});

export const deleteReleaseResponse = (payload) => ({
  type: ReleaseActionTypes.DELETE_RELEASE_RESPONSE,
  payload,
});

export const deleteReleaseResponseClear = () => ({
  type: ReleaseActionTypes.DELETE_RELEASE_RES_CLEAR,
});

// GET PARTICULAR RELEASE
export const showReleaseRequest = (payload) => ({
  type: ReleaseActionTypes.SHOW_RELEASE_REQUEST,
  payload,
});

export const showReleaseResponse = (payload) => ({
  type: ReleaseActionTypes.SHOW_RELEASE_RESPONSE,
  payload,
});

export const showReleaseResponseClear = () => ({
  type: ReleaseActionTypes.SHOW_RELEASE_RES_CLEAR,
});
