import React from "react";
import "./MultiLineBarChart.css";
import {
  Chart as ChartJs,
  Tooltip,
  ArcElement,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJs.register(
  ArcElement,
  Tooltip,
  //   Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement
);

const MultiLineBarchart = ({ values }) => {
  const options = {
    cutout: "10%",
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          stepSize: 20,
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
      bar: {
        borderRadius: 35,
        barThickness: 1,
      },
    },
  };

  // const values = [
  //   {
  //     id: 8,
  //     data: {
  //       UNEXECUTED: {
  //         percent: 75.0,
  //         count: 3,
  //       },
  //       PASSED: {
  //         percent: 25.0,
  //         count: 1,
  //       },
  //       FAILED: {
  //         percent: 0.0,
  //         count: 0,
  //       },
  //       IN_PROGRESS: {
  //         percent: 0.0,
  //         count: 0,
  //       },
  //     },
  //     full_name: "f_name",
  //   },
  //   {
  //     id: 8,
  //     data: {
  //       UNEXECUTED: {
  //         percent: 75.0,
  //         count: 3,
  //       },
  //       PASSED: {
  //         percent: 25.0,
  //         count: 1,
  //       },
  //       FAILED: {
  //         percent: 0.0,
  //         count: 0,
  //       },
  //       IN_PROGRESS: {
  //         percent: 0.0,
  //         count: 0,
  //       },
  //     },
  //     full_name: "f_name",
  //   },
  //   {
  //     id: 8,
  //     data: {
  //       UNEXECUTED: {
  //         percent: 55.0,
  //         count: 3,
  //       },
  //       PASSED: {
  //         percent: 15.0,
  //         count: 1,
  //       },
  //       FAILED: {
  //         percent: 0.0,
  //         count: 0,
  //       },
  //       IN_PROGRESS: {
  //         percent: 0.0,
  //         count: 0,
  //       },
  //     },
  //     full_name: "f_name",
  //   },
  // ];
  const labels = values?.map((item) => item.full_name);
  const datas = values?.map((item) => item.data);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Passed",

        data: datas?.map((item) => item.PASSED.percent),
        backgroundColor: "rgb(0 238 140)",
        categoryPercentage: 0.3,
        barPercentage: 0.3,
      },
      {
        label: "In Progress",
        data: datas?.map((item) => item?.IN_PROGRESS?.percent),

        backgroundColor: "rgb(254 157 0)",
        categoryPercentage: 0.3,
        barPercentage: 0.3,
      },
      {
        label: "Unexecuted",
        data: datas?.map((item) => item.UNEXECUTED?.percent),

        backgroundColor: "rgb(0 255 255)",
        categoryPercentage: 0.3,
        barPercentage: 0.3,
      },
      {
        label: "Failed",
        data: datas?.map((item) => item.FAILED.percent),

        backgroundColor: "rgb(247 52 52)",
        categoryPercentage: 0.3,
        barPercentage: 0.3,
      },
    ],
  };

  return (
    <div
      className="multiline_barchart"
      //   style={{
      //     width: "100%",
      //     height: "500px",
      //   }}
    >
      <Bar options={options} data={data} />
    </div>
  );
};

export default MultiLineBarchart;
