// ICONS IMPORT
import bugcolor from "../Assets/Icons/bugcolor.png";
import buggrey from "../Assets/Icons/buggray.png";
import folderopen from "../Assets/Icons/folderopen.png";
import deletewhite from "../Assets/Icons/Delete.png";
import deleteRed from "../Assets/Icons/Delete2.png";
import notification from "../Assets/Icons/notification.svg";
import settings from "../Assets/Icons/settings.svg";
import cloud from "../Assets/Icons/cloud.png";
import colorvector from "../Assets/Icons/colorvector.png";
import upvector from "../Assets/Icons/upvector.png";
import upvectorcolor from "../Assets/Icons/upvectorcolor.png";

// IMAGES IMPORT
import gway from "../Assets/Images/logo.svg";
import login from "../Assets/Images/login.png";
import gwaylogo from "../Assets/Images/gway.png";
import folder from "../Assets/Images/folder.png";
import notfound from "../Assets/Images/notfoundanimation.json";
import testunexecuted from "../Assets/Images/testunexecuted.png";
import testpassed from "../Assets/Images/testpassed.png";
import testhold from "../Assets/Images/testhold.png";
import testfailed from "../Assets/Images/testfailed.png";
import testForge from "../Assets/Images/testforge.png";
import piechart from "../Assets/Images/piechartdata2.png";
import barchart from "../Assets/Images/barChartData.png";
import noChartData from "../Assets/Images/nochart.jpeg";

// ICONS CONSTANT
export const BUGCOLOR_ICON = bugcolor;
export const BUGGREY_ICON = buggrey;
export const FOLDER_OPEN_ICON = folderopen;
export const DELETE_WHITE_ICON = deletewhite;
export const DELETE_RED_ICON = deleteRed;
export const NOTIFICATION_ICON = notification;
export const SETTINGS_ICON = settings;
export const CLOUD_ICON = cloud;
export const COLOR_VECTOR_ICON = colorvector;
export const UPVECTOR_ICON = upvector;
export const UPVECTOR_COLOR_ICON = upvectorcolor;

// IMAGES CONSTANT
export const GWAY_IMG = gway;
export const LOGIN_IMG = login;
export const FOLDER_IMG = folder;
export const GWAY_LOGO_IMG = gwaylogo;
export const PAGE_NOT_FOUND_IMG = notfound;
export const TEST_UNEXECUTED_IMG = testunexecuted;
export const TEST_FAILED_IMG = testfailed;
export const TEST_PASSED_IMG = testpassed;
export const TEST_HOLD_IMG = testhold;
export const TEST_FORGE_IMG = testForge;
export const PIE_CHART_IMG = piechart;
export const BAR_CHART_IMG = barchart;
export const NO_CHART_IMG = noChartData;
