import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import Login from "../Views/Login";
import Register from "../Views/Register";
import Projects from "../Views/ProjectsPage/Projects";
import Layout from "../Components/Organisms/Layout/Layout";
import ForgotPassword from "../Views/Login/ForgotPassword/ForgotPassword";
import ResetPassword from "../Views/Login/ResetPassword/ResetPassword";
import ChangeOldPassword from "../Views/Login/ChangeOldPassword/ChangeOldPassword";
import IntegrationIndex from "../Views/Settings/Integrate/IntegrationIndex";
import ViewProject from "../Views/Dashboard/Tabs/ViewProject";
import VerifyEmail from "../Views/Register/VerifyEmail";
import UserProfile from "../Views/UserProfile/UserProfile";
import PageNotFound from "../Views/PageNotFound/PageNotFound";
import TestExecutionRun from "../Views/TestExecution/TestExecutionTab";
import ViewExecutions from "../Views/TestExecetionTab/ViewExecutions";
import ParticularExecutionViewPage from "../Views/TestExecetionTab/ParticularExecutionViewPage";
import ViewDefect from "../Views/Defects/ViewDefect";
import TestExecutionView from "../Views/TestExecution/TestExecutionView";
import TaskView from "../Views/TestExecution/ViewTask";
import AdminUserRegistration from "../Views/Register/AdminUserRegistration";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/admin" element={<AdminUserRegistration />} />
        <Route path="/verify_otp" element={<VerifyEmail />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route element={<ProtectedRoutes />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Projects />} />
            <Route path="/userProfile" element={<UserProfile />} />
            <Route path="/project/:id/:tab" element={<ViewProject />} />
            <Route path="/projects/:id/:tab" element={<TestExecutionRun />} />
            <Route
              path="/projects/:id/viewexecution"
              element={<ViewExecutions />}
            />
            <Route
              path="/projects/:id/viewexecutions/:executionid"
              element={<ParticularExecutionViewPage />}
            />{" "}
            <Route
              path="/projects/:id/viewtestexecutions/:executionid"
              element={<TestExecutionView />}
            />
            <Route
              path="/project/:id/viewDefect/:defectid"
              element={<ViewDefect />}
            />
            <Route path="/integrate" element={<IntegrationIndex />} />
            <Route path="/changeoldpassword" element={<ChangeOldPassword />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
