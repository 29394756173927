import RequirementActionTypes from "./RequirementActionTypes";

const initialState = {
  // REQUIREMENT MODULE
  requirementModuleList: [],
  requirementModuleListLoading: false,
  requirementModuleListResponse: null,

  postRequirementModuleLoading: false,
  postRequirementModuleResponse: null,

  deleteRequirementModuleLoading: false,
  deleteRequirementModuleResponse: null,

  requirementModule: null,
  viewRequirementModuleLoading: false,
  viewRequirementModuleResponse: null,

  // REQUIREMENT DETAIL
  requirementDetailList: [],
  requirementDetailListLoading: false,
  requirementDetailListResponse: null,

  postRequirementDetailLoading: false,
  postRequirementDetailResponse: null,

  deleteRequirementDetailLoading: false,
  deleteRequirementDetailResponse: null,

  requirementDetail: null,
  viewRequirementDetailLoading: false,
  viewRequirementDetailResponse: null,

  // REQUIREMENT DETAIL ATTACHMENTS
  requirementDetailAttachmentList: [],
  requirementDetailAttachmentListLoading: false,
  requirementDetailAttachmentListResponse: null,

  postRequirementDetailAttachmentLoading: false,
  postRequirementDetailAttachmentResponse: null,

  deleteRequirementDetailAttachmentLoading: false,
  deleteRequirementDetailAttachmentResponse: null,

  requirementDetailAttachment: null,
  viewRequirementDetailAttachmentLoading: false,
  viewRequirementDetailAttachmentResponse: null,
};

export default function RequirementReducer(state = initialState, action) {
  switch (action.type) {
    // REQUIREMENT MODULE
    // GET ALL REQUIREMENT MODULES
    case RequirementActionTypes.GET_REQUIREMENTMODULES_INDEX_REQUEST:
      return {
        ...state,
        requirementModuleListLoading: true,
      };
    case RequirementActionTypes.GET_REQUIREMENTMODULE_INDEX_RESPONSE:
      return {
        ...state,
        requirementModuleListLoading: false,
        requirementModuleList: action.payload?.data,
        requirementModuleListResponse: action.payload,
      };
    // POST REQUIREMENT MODULES
    case RequirementActionTypes.POST_REQUIREMENTMODULES_REQUEST:
      return {
        ...state,
        postRequirementModuleLoading: true,
      };
    case RequirementActionTypes.POST_REQUIREMENTMODULES_RESPONSE:
      return {
        ...state,
        postRequirementModuleLoading: false,
        postRequirementModuleResponse: action.payload,
      };
    //  UPDATE REQUIREMENT MODULE
    case RequirementActionTypes.UPDATE_REQUIREMENTMODULES_REQUEST:
      return {
        ...state,
        postRequirementModuleLoading: true,
      };

    case RequirementActionTypes.UPDATE_REQUIREMENTMODULES_RESPONSE:
      return {
        ...state,
        postRequirementModuleLoading: false,
        postRequirementModuleResponse: action.payload,
      };

    // POST AND UPDATE REQUIREMENT MODULE RESPONSE CLEAR
    case RequirementActionTypes.POST_REQUIREMENTMODULES_RES_CLEAR:
      return {
        ...state,
        postRequirementModuleLoading: false,
        postRequirementModuleResponse: null,
      };

    //  DELETE REQUIREMENT MODULE
    case RequirementActionTypes.DELETE_REQUIREMENTMODULES_REQUEST:
      return {
        ...state,
        deleteRequirementModuleLoading: true,
      };

    case RequirementActionTypes.DELETE_REQUIREMENTMODULES_RESPONSE:
      return {
        ...state,
        deleteRequirementModuleLoading: false,
        deleteRequirementModuleResponse: action.payload,
      };
    case RequirementActionTypes.DELETE_REQUIREMENTMODULES_RES_CLEAR:
      return {
        ...state,
        deleteRequirementModuleLoading: false,
        deleteRequirementModuleResponse: null,
      };

    //  GET PARTICULAR REQUIREMENT MODULE
    case RequirementActionTypes.SHOW_REQUIREMENTMODULE_REQUEST:
      return {
        ...state,
        viewRequirementModuleLoading: true,
      };

    case RequirementActionTypes.SHOW_REQUIREMENTMODULE_RESPONSE:
      return {
        ...state,
        viewRequirementModuleLoading: false,
        requirementModule: action.payload?.data,
        viewRequirementModuleResponse: action.payload,
      };
    case RequirementActionTypes.SHOW_REQUIREMENTMODULE_RES_CLEAR:
      return {
        ...state,
        viewRequirementModuleLoading: false,
        requirementModule: null,
        viewRequirementModuleResponse: null,
      };

    // REQUIREMENT DETAILS
    // GET ALL REQUIREMENT DETAILS
    case RequirementActionTypes.GET_REQUIREMENT_INDEX_REQUEST:
      return {
        ...state,
        requirementDetailListLoading: true,
      };
    case RequirementActionTypes.GET_REQUIREMENT_INDEX_RESPONSE:
      return {
        ...state,
        requirementDetailListLoading: false,
        requirementDetailList: action.payload?.data,
        requirementDetailListResponse: action.payload,
      };
    // POST REQUIREMENT DETAIL
    case RequirementActionTypes.POST_REQUIREMENT_REQUEST:
      return {
        ...state,
        postRequirementDetailLoading: true,
      };
    case RequirementActionTypes.POST_REQUIREMENT_RESPONSE:
      return {
        ...state,
        postRequirementDetailLoading: false,
        postRequirementDetailResponse: action.payload,
      };
    //  UPDATE REQUIREMENT DETAIL
    case RequirementActionTypes.UPDATE_REQUIREMENT_REQUEST:
      return {
        ...state,
        postRequirementDetailLoading: true,
      };

    case RequirementActionTypes.UPDATE_REQUIREMENT_RESPONSE:
      return {
        ...state,
        postRequirementDetailLoading: false,
        postRequirementDetailResponse: action.payload,
      };

    // POST AND UPDATE REQUIREMENT DETAIL RESPONSE CLEAR
    case RequirementActionTypes.POST_REQUIREMENT_RES_CLEAR:
      return {
        ...state,
        postRequirementDetailLoading: false,
        postRequirementDetailResponse: null,
      };

    //  DELETE REQUIREMENT DETAIL
    case RequirementActionTypes.DELETE_REQUIREMENT_REQUEST:
      return {
        ...state,
        deleteRequirementDetailLoading: true,
      };

    case RequirementActionTypes.DELETE_REQUIREMENT_RESPONSE:
      return {
        ...state,
        deleteRequirementDetailLoading: false,
        deleteRequirementDetailResponse: action.payload,
      };
    case RequirementActionTypes.DELETE_REQUIREMENT_RES_CLEAR:
      return {
        ...state,
        deleteRequirementDetailLoading: false,
        deleteRequirementDetailResponse: null,
      };

    //  GET PARTICULAR REQUIREMENT DETAIL
    case RequirementActionTypes.SHOW_REQUIREMENT_REQUEST:
      return {
        ...state,
        viewRequirementDetailLoading: true,
      };

    case RequirementActionTypes.SHOW_REQUIREMENT_RESPONSE:
      return {
        ...state,
        viewRequirementDetailLoading: false,
        requirementDetail: action.payload?.data,
        viewRequirementDetailResponse: action.payload,
      };
    case RequirementActionTypes.SHOW_REQUIREMENT_RES_CLEAR:
      return {
        ...state,
        viewRequirementDetailLoading: false,
        requirementDetail: null,
        viewRequirementDetailResponse: null,
      };

    // REQUIREMENT DETAIL ATTACHMENTS
    // GET ALL REQUIREMENT DETAIL ATTACHMENTS
    case RequirementActionTypes.GET_REQUIREATTACHMENT_INDEX_REQUEST:
      return {
        ...state,
        requirementDetailAttachmentListLoading: true,
      };
    case RequirementActionTypes.GET_REQUIREATTACHMENT_INDEX_RESPONSE:
      return {
        ...state,
        requirementDetailAttachmentListLoading: false,
        requirementDetailAttachmentList: action.payload?.data,
        requirementDetailAttachmentListResponse: action.payload,
      };
    case RequirementActionTypes.GET_REQUIREATTACH_INDEX_RES_CLEAR:
      return {
        ...state,
        requirementDetailAttachmentListLoading: false,
        requirementDetailAttachmentList: null,
        requirementDetailAttachmentListResponse: null,
      };
    // POST REQUIREMENT DETAIL ATTACHMENTS
    case RequirementActionTypes.POST_REQUIREATTACHMENT_REQUEST:
      return {
        ...state,
        postRequirementDetailAttachmentLoading: true,
      };
    case RequirementActionTypes.POST_REQUIREATTACHMENT_RESPONSE:
      return {
        ...state,
        postRequirementDetailAttachmentLoading: false,
        postRequirementDetailAttachmentResponse: action.payload,
      };
    //  UPDATE REQUIREMENT DETAIL ATTACHMENTS
    case RequirementActionTypes.UPDATE_REQUIREATTACHMENT_REQUEST:
      return {
        ...state,
        postRequirementDetailAttachmentLoading: true,
      };

    case RequirementActionTypes.UPDATE_REQUIREATTACHMENT_RESPONSE:
      return {
        ...state,
        postRequirementDetailAttachmentLoading: false,
        postRequirementDetailAttachmentResponse: action.payload,
      };

    // POST AND UPDATE REQUIREMENT DETAIL RESPONSE CLEAR
    case RequirementActionTypes.POST_REQUIREATTACHMENT_RES_CLEAR:
      return {
        ...state,
        postRequirementDetailAttachmentLoading: false,
        postRequirementDetailAttachmentResponse: null,
      };

    //  DELETE REQUIREMENT DETAIL ATTACHMENTS
    case RequirementActionTypes.DELETE_REQUIREATTACHMENT_REQUEST:
      return {
        ...state,
        deleteRequirementDetailAttachmentLoading: true,
      };

    case RequirementActionTypes.DELETE_REQUIREATTACHMENT_RESPONSE:
      return {
        ...state,
        deleteRequirementDetailAttachmentLoading: false,
        deleteRequirementDetailAttachmentResponse: action.payload,
      };
    case RequirementActionTypes.DELETE_REQUIREATTACHMENT_RES_CLEAR:
      return {
        ...state,
        deleteRequirementDetailAttachmentLoading: false,
        deleteRequirementDetailAttachmentResponse: null,
      };

    //  GET PARTICULAR REQUIREMENT DETAIL ATTACHMENTS
    case RequirementActionTypes.SHOW_REQUIREATTACHMENT_REQUEST:
      return {
        ...state,
        viewRequirementDetailAttachmentLoading: true,
      };

    case RequirementActionTypes.SHOW_REQUIREATTACHMENT_RESPONSE:
      return {
        ...state,
        viewRequirementDetailAttachmentLoading: false,
        requirementDetailAttachment: action.payload?.data,
        viewRequirementDetailAttachmentResponse: action.payload,
      };
    case RequirementActionTypes.SHOW_REQUIREATTACHMENT_RES_CLEAR:
      return {
        ...state,
        viewRequirementDetailAttachmentLoading: false,
        requirementDetailAttachment: null,
        viewRequirementDetailAttachmentResponse: null,
      };

    default:
      return state;
  }
}
