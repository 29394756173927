import React from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ResponsiveDrawer from "../SideNav/SideNav";
import Navbar from "../Navbar/Navbar";
import { GWAY_IMG } from "../../../Utils/imageConstants";
import ViewProject from "../../../Views/Dashboard/Tabs/ViewProject";
import TestExecutionRun from "../../../Views/TestExecution/TestExecutionTab";
import ViewExecutions from "../../../Views/TestExecetionTab/ViewExecutions";
import ParticularExecutionViewPage from "../../../Views/TestExecetionTab/ParticularExecutionViewPage";
import ViewDefect from "../../../Views/Defects/ViewDefect";
import "./Layout.css";
import TestExecutionView from "../../../Views/TestExecution/TestExecutionView";

const Layout = () => {
  const { id } = useParams();
  const { executionid, defectid } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const isDashboardRoute = location.pathname === `/project/${id}/dashboard`;
  const isDashboardPlam = location.pathname == `/project/${id}/testplan`;
  const isDashboardReq = location.pathname == `/project/${id}/requirements`;
  const isDashboardtest = location.pathname == `/project/${id}/testdesign`;
  const isDashboardExe = location.pathname == `/project/${id}/testexecution`;
  const isDashboardteststeps = location.pathname == `/projects/${id}/teststeps`;
  const isDashboardDefect = location.pathname == `/project/${id}/defects`;
  const isDashboardReport = location.pathname == `/project/${id}/report`;
  const viewExecution = location.pathname == `/projects/${id}/viewexecution`;
  const viewTestExecution =
    location.pathname == `/projects/${id}/viewtestexecutions/${executionid}`;

  const particularExecution =
    location.pathname == `/projects/${id}/viewexecutions/${executionid}`;
  const viewDefect =
    location.pathname == `/project/${id}/viewDefect/${defectid}`;

  return (
    <div className="main_layout">
      {!isDashboardRoute &&
        !isDashboardPlam &&
        !isDashboardReq &&
        !isDashboardtest &&
        !isDashboardExe &&
        !isDashboardteststeps &&
        !isDashboardReport &&
        !viewExecution &&
        !particularExecution &&
        !isDashboardDefect &&
        !viewDefect &&
        !viewTestExecution && (
          <div className="sidenav">
            <ResponsiveDrawer />
          </div>
        )}

      {isDashboardRoute ? (
        <div className="w-100 dashboardWrpr outlet mt-0">
          <div className="headerWrpr fixedNavbar">
            <div className="gway_logo clickable" onClick={() => navigate("/")}>
              <img src={GWAY_IMG} alt="gwayLogo" className="img" />
            </div>

            <Navbar />
          </div>
          <Outlet />
        </div>
      ) : isDashboardPlam ||
        isDashboardReq ||
        isDashboardtest ||
        isDashboardExe ||
        isDashboardReport ||
        isDashboardDefect ? (
        <>
          <div className="w-100 dashboardWrpr outlet mt-0">
            <div className="headerWrpr fixedNavbar">
              <div
                className="gway_logo clickable"
                onClick={() => navigate("/")}
              >
                <img src={GWAY_IMG} alt="gwayLogo" className="img" />
              </div>
              <Navbar />
            </div>
            <div className="">
              <ViewProject />
            </div>
          </div>
        </>
      ) : isDashboardteststeps ? (
        <div className="w-100 dashboardWrpr outlet mt-0">
          <div className="headerWrpr fixedNavbar">
            <div className="gway_logo clickable" onClick={() => navigate("/")}>
              <img src={GWAY_IMG} alt="gwayLogo" width="20px" className="img" />
            </div>
            <Navbar />
          </div>
          <div className="">
            <ViewProject />

            <TestExecutionRun />
          </div>
        </div>
      ) : viewExecution ? (
        <div className="w-100 dashboardWrpr outlet mt-0">
          <div className="headerWrpr fixedNavbar">
            <div className="gway_logo clickable" onClick={() => navigate("/")}>
              <img src={GWAY_IMG} alt="gwayLogo" width="20px" className="img" />
            </div>
            <Navbar />
          </div>
          <div className="">
            <ViewProject />

            <ViewExecutions />
          </div>
        </div>
      ) : particularExecution ? (
        <div className="w-100 dashboardWrpr outlet mt-0">
          <div className="headerWrpr fixedNavbar">
            <div className="gway_logo clickable" onClick={() => navigate("/")}>
              <img src={GWAY_IMG} alt="gwayLogo" width="20px" className="img" />
            </div>
            <Navbar />
          </div>
          <div className="">
            <ViewProject />
            <ParticularExecutionViewPage />
          </div>
        </div>
      ) : viewDefect ? (
        <div className="w-100 dashboardWrpr outlet mt-0">
          <div className="headerWrpr fixedNavbar">
            <div className="gway_logo clickable" onClick={() => navigate("/")}>
              <img src={GWAY_IMG} alt="gwayLogo" width="20px" className="img" />
            </div>
            <Navbar />
          </div>
          <div className="">
            <ViewProject />
            <ViewDefect />
          </div>
        </div>
      ) : viewTestExecution ? (
        <div className="w-100 dashboardWrpr outlet mt-0">
          <div className="headerWrpr fixedNavbar">
            <div className="gway_logo clickable" onClick={() => navigate("/")}>
              <img src={GWAY_IMG} alt="gwayLogo" width="20px" className="img" />
            </div>
            <Navbar />
          </div>
          <div className="">
            <ViewProject />
            <TestExecutionView />
          </div>
        </div>
      ) : (
        <div className={`outlet`}>
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default Layout;
