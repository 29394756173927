export default {
  // GET ALL APPLICATIONS
  GET_APPLICATION_INDEX_REQUEST: "GET_APPLICATION_INDEX_REQUEST",
  GET_APPLICATION_INDEX_RESPONSE: "GET_APPLICATION_INDEX_RESPONSE",

  // INTEGRATION
  POST_INTEGRATION_REQUEST: "POST_INTEGRATION_REQUEST",
  POST_INTEGRATION_RESPONSE: "POST_INTEGRATION_RESPONSE",

  UPDATE_INTEGRATION_REQUEST: "UPDATE_INTEGRATION_REQUEST",
  UPDATE_INTEGRATION_RESPONSE: "UPDATE_INTEGRATION_RESPONSE",

  POST_INTEGRATION_RES_CLEAR: "POST_INTEGRATION_RES_CLEAR",

  SHOW_INTEGRATION_REQUEST: "SHOW_INTEGRATION_REQUEST",
  SHOW_INTEGRATION_RESPONSE: "SHOW_INTEGRATION_RESPONSE",
  SHOW_INTEGRATION_RES_CLEAR: "SHOW_INTEGRATION_RES_CLEAR",
};
