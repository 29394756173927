import TestDesignCommentActionTypes from "./TestDesignCommentActionTypes";

// TESTDESIGN HISTORY
// GET ALL TESTDESIGN HISTORY
export const getTestDesignHistoryIndexRequest = (payload) => ({
  type: TestDesignCommentActionTypes.GET_TESTDESIGNHISTORY_INDEX_REQUEST,
  payload,
});

export const getTestDesignHistoryIndexResponse = (payload) => ({
  type: TestDesignCommentActionTypes.GET_TESTDESIGNHSTORY_INDEX_RESPONSE,
  payload,
});

// TESTDESIGN COMMENT
// GET ALL TESTDESIGN COMMENT
export const getTestDesignCommentIndexRequest = (payload) => ({
  type: TestDesignCommentActionTypes.GET_TESTDESIGNCOMMENT_INDEX_REQUEST,
  payload,
});

export const getTestDesignCommentIndexResponse = (payload) => ({
  type: TestDesignCommentActionTypes.GET_TESTDESIGNCOMENT_INDEX_RESPONSE,
  payload,
});

// POST TESTDESIGN COMMENT
export const postTestDesignCommentRequest = (payload) => ({
  type: TestDesignCommentActionTypes.POST_TESTDESIGNCOMMENT_REQUEST,
  payload,
});

export const postTestDesignCommentResponse = (payload) => ({
  type: TestDesignCommentActionTypes.POST_TESTDESIGNCOMMENT_RESPONSE,
  payload,
});

// UPDATE TESTDESIGN COMMENT
export const updateTestDesignCommentRequest = (payload) => ({
  type: TestDesignCommentActionTypes.UPDATE_TESTDESIGNCOMMENT_REQUEST,
  payload,
});

export const updateTestDesignCommentResponse = (payload) => ({
  type: TestDesignCommentActionTypes.UPDATE_TESTDESIGNCOMMENT_RESPONSE,
  payload,
});

// POST AND UPDATE TESTDESIGN RESPONSE CLEAR
export const postTestDesignCommentResponseClear = () => ({
  type: TestDesignCommentActionTypes.POST_TESTDESIGNCOMMENT_RES_CLEAR,
});

// DELETE TESTDESIGN COMMENT
export const deleteTestDesignCommentRequest = (payload) => ({
  type: TestDesignCommentActionTypes.DELETE_TESTDESIGNCOMMENT_REQUEST,
  payload,
});

export const deleteTestDesignCommentResponse = (payload) => ({
  type: TestDesignCommentActionTypes.DELETE_TESTDESIGNCOMMENT_RESPONSE,
  payload,
});

export const deleteTestDesignCommentResponseClear = () => ({
  type: TestDesignCommentActionTypes.DELETE_TESTDESIGNCOMMENT_RES_CLEAR,
});

// GET PARTICULAR TESTDESIGN COMMENT
export const showTestDesignCommentRequest = (payload) => ({
  type: TestDesignCommentActionTypes.SHOW_TESTDESIGNCOMMENT_REQUEST,
  payload,
});

export const showTestDesignCommentResponse = (payload) => ({
  type: TestDesignCommentActionTypes.SHOW_TESTDESIGNCOMMENT_RESPONSE,
  payload,
});

export const showTestDesignCommentResponseClear = () => ({
  type: TestDesignCommentActionTypes.SHOW_TESTDESIGNCOMMENT_RES_CLEAR,
});
