import { takeLatest, call, put } from "redux-saga/effects";

import types from "./ExportcolumnActionTypes";

import { axiosPost } from "../../Utils/axios";

import { unauthorizedMessage } from "../Login/LoginActions";

import { getExportResponse } from "./ExportcolumnAction";

// WORKER SAGA

export function* onExportReqAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("get_columns", payload).then((response) => response?.data)
    );
    yield put(getExportResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* ExportWatcherSaga() {
  // dashboard
  yield takeLatest(types.EXPORT_COLUMN_REQUEST, onExportReqAsync);
}
