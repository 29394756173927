import { CLEAR_ERROR_RES } from "../Login/LoginActionTypes";
import ReleaseActionTypes from "./ReleaseActionTypes";

const initialState = {
  releaseList: [],
  releaseListLoading: false,
  releaseListResponse: null,

  postReleaseLoading: false,
  postReleaseResponse: null,

  deleteReleaseLoading: false,
  deleteReleaseResponse: null,

  release: null,
  viewReleaseLoading: false,
  viewReleaseResponse: null,
};

const ReleaseReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET ALL RELEASES
    case ReleaseActionTypes.RELEASE_INDEX_REQUEST:
      return {
        ...state,
        releaseListLoading: true,
      };
    case ReleaseActionTypes.RELEASE_INDEX_RESPONSE:
      return {
        ...state,
        releaseListLoading: false,
        releaseList: action.payload?.data,
        releaseListResponse: action.payload,
      };

    // POST RELEASE
    case ReleaseActionTypes.POST_RELEASE_REQUEST:
      return {
        ...state,
        postReleaseLoading: true,
      };

    case ReleaseActionTypes.POST_RELEASE_RESPONSE:
      return {
        ...state,
        postReleaseLoading: false,
        postReleaseResponse: action.payload,
      };

    // UPDATE RELEASE
    case ReleaseActionTypes.UPDATE_RELEASE_REQUEST:
      return {
        ...state,
        postReleaseLoading: true,
      };

    case ReleaseActionTypes.UPDATE_RELEASE_RESPONSE:
      return {
        ...state,
        postReleaseLoading: false,
        postReleaseResponse: action.payload,
      };

    case ReleaseActionTypes.POST_RELEASE_RES_CLEAR:
      return {
        ...state,
        postReleaseResponse: null,
        postReleaseLoading: false,
      };

    // DELETE RELEASE
    case ReleaseActionTypes.DELETE_RELEASE_REQUEST:
      return {
        ...state,
        deleteReleaseLoading: true,
      };
    case ReleaseActionTypes.DELETE_RELEASE_RESPONSE:
      return {
        ...state,
        deleteReleaseLoading: false,
        deleteReleaseResponse: action.payload,
      };

    case ReleaseActionTypes.DELETE_RELEASE_RES_CLEAR:
      return {
        ...state,
        deleteReleaseResponse: null,
        deleteReleaseLoading: false,
      };

    // GET PARTICULAR RELEASE
    case ReleaseActionTypes.SHOW_RELEASE_REQUEST:
      return {
        ...state,
        viewReleaseLoading: true,
      };

    case ReleaseActionTypes.SHOW_RELEASE_RESPONSE:
      return {
        ...state,
        viewReleaseLoading: false,
        viewReleaseResponse: action.payload,
        release: action.payload.data,
      };

    case ReleaseActionTypes.SHOW_RELEASE_RES_CLEAR:
      return {
        ...state,
        release: null,
        viewReleaseLoading: false,
        viewReleaseResponse: null,
      };
    case CLEAR_ERROR_RES:
      return {
        ...state,
        releaseListLoading: false,
        releaseListResponse: null,

        postReleaseLoading: false,
        postReleaseResponse: null,

        deleteReleaseLoading: false,
        deleteReleaseResponse: null,

        viewReleaseLoading: false,
        viewReleaseResponse: null,
      };

    default:
      return state;
  }
};

export default ReleaseReducer;
