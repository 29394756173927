import DashboardActiontypes from "./DashboardActiontypes";

const initialState = {
  DashboardList: [],
  DashboardLoading: false,
  DashboardResponse: null,
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET test case passed
    case DashboardActiontypes.DASHBOARD_REQUEST:
      return {
        ...state,
        DashboardLoading: true,
        DashboardList: null,
      };
    case DashboardActiontypes.DASHBOARD_RESPONSE:
      return {
        ...state,
        DashboardLoading: false,
        DashboardList: action.payload?.data,
        DashboardResponse: action.payload,
      };
    case DashboardActiontypes.DASHBOARD_RESCLEAR:
      return {
        ...state,
        DashboardLoading: false,
        DashboardList: null,
        DashboardResponse: null,
      };

    default:
      return state;
  }
};

export default DashboardReducer;
