import ProjectActionTypes from "./ProjectActionTypes";

const initialState = {
  projectsList: [],
  projectsListLoading: false,
  projectsListResponse: null,

  postProjectLoading: false,
  postProjectResponse: null,

  deleteProjectLoading: false,
  deleteProjectResponse: null,

  project: null,
  viewProjectLoading: false,
  viewProjectResponse: null,

  projectMembers: null,
  projectMembersLoading: false,
  projectMembersResponse: null,

  deleteProjectMemberLoading: false,
  deleteProjectMemberResponse: null,

  // APPLICATIONS LIST
  applicationList: [],
  applicationListLoading: false,
  applicationListResponse: null,

  // APPLICATION PROJECTS LIST
  appProjectList: [],
  appProjectListLoading: false,
  appProjectListResponse: null,

  // ISSUE TRACKER
  issueTrackerList: [],
  issueTrackerListLoading: false,
  issueTrackerListResponse: null,

  postIssueTrackerLoading: false,
  postIssueTrackerResponse: null,

  deleteIssueTrackerLoading: false,
  deleteIssueTrackerResponse: null,

  savedFilters: null,

  searchProjectText: {
    filters: {
      name: {
        type: "like",
        value: "",
      },
    },
    pagination_meta: {
      page: 1,
      limit: 10,
      order_by: "id_desc",
    },
  },
};

const ProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET ALL PROJECTS
    case ProjectActionTypes.PROJECT_INDEX_REQUEST:
      return {
        ...state,
        projectsListLoading: true,
      };
    case ProjectActionTypes.PROJECT_INDEX_RESPONSE:
      return {
        ...state,
        projectsListLoading: false,
        projectsList: action.payload?.data,
        projectsListResponse: action.payload,
      };

    // POST PROJECT
    case ProjectActionTypes.POST_PROJECT_REQUEST:
      return {
        ...state,
        postProjectLoading: true,
      };

    case ProjectActionTypes.POST_PROJECT_RESPONSE:
      return {
        ...state,
        postProjectLoading: false,
        postProjectResponse: action.payload,
      };

    // UPDATE PROJECT
    case ProjectActionTypes.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        postProjectLoading: true,
      };

    case ProjectActionTypes.UPDATE_PROJECT_RESPONSE:
      return {
        ...state,
        postProjectLoading: false,
        postProjectResponse: action.payload,
      };

    case ProjectActionTypes.POST_PROJECT_RES_CLEAR:
      return {
        ...state,
        postProjectResponse: null,
        postProjectLoading: false,
      };

    // DELETE PROJECT
    case ProjectActionTypes.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        deleteProjectLoading: true,
      };
    case ProjectActionTypes.DELETE_PROJECT_RESPONSE:
      return {
        ...state,
        deleteProjectLoading: false,
        deleteProjectResponse: action.payload,
      };

    case ProjectActionTypes.DELETE_PROJECT_RES_CLEAR:
      return {
        ...state,
        deleteProjectResponse: null,
        deleteProjectLoading: false,
      };

    // GET PARTICULAR PROJECT
    case ProjectActionTypes.SHOW_PROJECT_REQUEST:
      return {
        ...state,
        viewProjectLoading: true,
      };

    case ProjectActionTypes.SHOW_PROJECT_RESPONSE:
      return {
        ...state,
        viewProjectLoading: false,
        viewProjectResponse: action.payload,
        project: action.payload.data,
      };

    case ProjectActionTypes.SHOW_PROJECT_RES_CLEAR:
      return {
        ...state,
        project: null,
        viewProjectLoading: false,
        viewProjectResponse: null,
      };

    // GET PROJECT MEMBERS
    case ProjectActionTypes.GET_PROJECTMEMBERS_REQUEST:
      return {
        ...state,
        projectMembersLoading: true,
      };

    case ProjectActionTypes.GET_PROJECTMEMBERS_RESPONSE:
      return {
        ...state,
        projectMembersLoading: false,
        projectMembersResponse: action.payload,
        projectMembers: action.payload.data,
      };

    case ProjectActionTypes.GET_PROJECTMEMBERS_RES_CLEAR:
      return {
        ...state,
        projectMembers: null,
        projectMembersLoading: false,
        projectMembersResponse: null,
      };

    // DELETE PROJECT MEMBER
    case ProjectActionTypes.DELETE_PROJECTMEMBERS_REQUEST:
      return {
        ...state,
        deleteProjectMemberLoading: true,
      };

    case ProjectActionTypes.DELETE_PROJECTMEMBERS_RESPONSE:
      return {
        ...state,
        deleteProjectMemberLoading: false,
        deleteProjectMemberResponse: action.payload,
      };

    case ProjectActionTypes.DELETE_PROJECTMEMBERS_RES_CLEAR:
      return {
        ...state,
        deleteProjectMemberLoading: false,
        deleteProjectMemberResponse: null,
      };

    // GET ALL APPLICATIONS
    case ProjectActionTypes.GET_APPLICATIONS_INDEX_REQUEST:
      return {
        ...state,
        applicationListLoading: true,
      };
    case ProjectActionTypes.GET_APPLICATIONS_INDEX_RESPONSE:
      return {
        ...state,
        applicationListLoading: false,
        applicationList: action.payload?.data,
        applicationListResponse: action.payload,
      };
    // GET APPLICATION PROJECTS
    case ProjectActionTypes.GET_APP_PROJECTS_INDEX_REQUEST:
      return {
        ...state,
        appProjectListLoading: true,
      };
    case ProjectActionTypes.GET_APP_PROJECTS_INDEX_RESPONSE:
      return {
        ...state,
        appProjectListLoading: false,
        appProjectList: action.payload?.data,
        appProjectListResponse: action.payload,
      };

    // GET ALL ISSUE TRAKCER
    case ProjectActionTypes.GET_ISSUETRACKER_INDEX_REQUEST:
      return {
        ...state,
        issueTrackerListLoading: true,
      };
    case ProjectActionTypes.GET_ISSUETRACKER_INDEX_RESPONSE:
      return {
        ...state,
        issueTrackerListLoading: false,
        issueTrackerList: action.payload?.data,
        issueTrackerListResponse: action.payload,
      };

    // POST ISSUE TRACKER
    case ProjectActionTypes.POST_ISSUETRACKER_REQUEST:
      return {
        ...state,
        postIssueTrackerLoading: true,
      };

    case ProjectActionTypes.POST_ISSUETRACKER_RESPONSE:
      return {
        ...state,
        postIssueTrackerLoading: false,
        postIssueTrackerResponse: action.payload,
      };
    case ProjectActionTypes.POST_ISSUETRACKER_RES_CLEAR:
      return {
        ...state,
        postIssueTrackerResponse: null,
        postIssueTrackerLoading: false,
      };
    // DELETE ISSUE TRACKER
    case ProjectActionTypes.DELETE_ISSUETRACKER_REQUEST:
      return {
        ...state,
        deleteIssueTrackerLoading: true,
      };
    case ProjectActionTypes.DELETE_ISSUETRACKER_RESPONSE:
      return {
        ...state,
        deleteIssueTrackerLoading: false,
        deleteIssueTrackerResponse: action.payload,
      };
    case ProjectActionTypes.DELETE_ISSUETRACKER_RES_CLEAR:
      return {
        ...state,
        deleteIssueTrackerResponse: null,
        deleteIssueTrackerLoading: false,
      };

    case ProjectActionTypes.SEARCH_PROJECT_TEXT:
      return {
        ...state,
        searchProjectText: action.payload,
      };
    case ProjectActionTypes.CLEAR_PROJECT_TEXT:
      return {
        ...state,
        searchProjectText: {
          filters: {
            name: {
              type: "like",
              value: "",
            },
          },
          pagination_meta: {
            page: 1,
            limit: 10,
            order_by: "id_desc",
          },
        },
      };
    // case CLEAR_ERROR_RES:
    //   return {
    //     ...state,
    //     projectsListLoading: false,
    //     projectsListResponse: null,

    //     postProjectLoading: false,
    //     postProjectResponse: null,

    //     deleteProjectLoading: false,
    //     deleteProjectResponse: null,

    //     viewProjectLoading: false,
    //     viewProjectResponse: null,
    //   };

    case ProjectActionTypes.SAVED_PROJECT_FILTER:
      return {
        ...state,
        savedFilters: action.payload,
      };

    default:
      return state;
  }
};

export default ProjectReducer;
