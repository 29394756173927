import IntegrationActionTypes from "./IntegrationActionTypes";
import { CLEAR_ERROR_RES } from "../Login/LoginActionTypes";

const initialState = {
  //  APPLICATION
  appList: [],
  appListLoading: false,
  appListResponse: null,

  // INTEGRATION
  postIntegrationLoading: false,
  postIntegrationResponse: null,

  viewIntegration: null,
  viewIntegrationLoading: false,
  viewIntegrationResponse: null,
};

const integrationReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET ALL APPLICATIONS
    case IntegrationActionTypes.GET_APPLICATION_INDEX_REQUEST:
      return {
        ...state,
        appListLoading: true,
      };
    case IntegrationActionTypes.GET_APPLICATION_INDEX_RESPONSE:
      return {
        ...state,
        appListLoading: false,
        appList: action.payload?.data,
        appListResponse: action.payload,
      };
    // INTEGRATION
    // POST AND UPDATE INTEGRATION
    case IntegrationActionTypes.POST_INTEGRATION_REQUEST:
    case IntegrationActionTypes.UPDATE_INTEGRATION_REQUEST:
      return {
        ...state,
        postIntegrationLoading: true,
      };
    case IntegrationActionTypes.POST_INTEGRATION_RESPONSE:
    case IntegrationActionTypes.UPDATE_INTEGRATION_RESPONSE:
      return {
        ...state,
        postIntegrationLoading: false,
        postIntegrationResponse: action.payload,
      };
    // POST AND UPDATE RESPONSE CLEAR
    case IntegrationActionTypes.POST_INTEGRATION_RES_CLEAR:
      return {
        ...state,
        postIntegrationLoading: false,
        postIntegrationResponse: null,
      };
    // GET PARTICULAR INTEGRATION
    case IntegrationActionTypes.SHOW_INTEGRATION_REQUEST:
      return {
        ...state,
        viewIntegrationLoading: true,
      };
    case IntegrationActionTypes.SHOW_INTEGRATION_RESPONSE:
      return {
        ...state,
        viewIntegrationLoading: false,
        viewIntegration: action.payload?.data,
        viewIntegrationResponse: action.payload,
      };
    case IntegrationActionTypes.SHOW_INTEGRATION_RES_CLEAR:
      return {
        ...state,
        viewIntegrationLoading: false,
        viewIntegration: null,
        viewIntegrationResponse: null,
      };
    case CLEAR_ERROR_RES:
      return {
        ...state,
        appListLoading: false,
        appListResponse: null,

        postIntegrationLoading: false,
        postIntegrationResponse: null,

        viewIntegrationLoading: false,
        viewIntegrationResponse: null,
      };
    default:
      return state;
  }
};

export default integrationReducer;
