import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { GWAY_IMG, LOGIN_IMG } from "../../Utils/imageConstants";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import { NavLink, useNavigate } from "react-router-dom";
import {
  RegisterRequest,
  RegisterResClear,
  cityListRequest,
  countryListRequest,
  departmentListRequest,
  stateListRequest,
} from "../../Redux/Register/RegisterActions";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import MetaData from "../../Components/Organisms/MetaData/MetaData";
import LoadingButton from "../../Components/Atoms/LoadingButton/LoadingButton";
import {
  handlePaste,
  handlePassword,
  handleToastResponse,
} from "../../Utils/handles";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const {
    registerResponse,
    registerLoading,
    countryList,
    stateList,
    cityList,
    departmentList,
  } = useSelector((state) => state.register);

  const validation = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[^\s]*$/, "First name should not contain spaces")
      .matches(/^[a-zA-Z\s]*$/, "First name should not contain numbers")
      .min(3, "Minimum of 3 characters")
      .max(20, "Maximum of 20 characters")
      .required("First name is required!"),
    last_name: Yup.string()
      .matches(/^[^\s]*$/, "Last name should not contain spaces")
      .matches(/^[a-zA-Z\s]*$/, "Last name should not contain numbers")
      .min(1, "Minimum of 1 characters")
      .max(20, "Maximum of 20 characters"),
    organisation_name: Yup.string()
      .trim()
      .nullable()
      .matches(
        /^\s*\S[\s\S]*$/g,
        "Organization name cannot contain only blankspaces"
      )
      .min(2, "Minimum of 2 characters")
      .required("Organization name is required!"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        "Invalid email"
      )
      .lowercase("Email cannot contain uppercase!")
      .strict(true)
      .email("Invalid Email")
      .required("Email is required!"),
    department: Yup.number().required("Department is required!"),
    country: Yup.string().required("Country is required!"),
    department_name: Yup.string().when("department", (department) => {
      if (department == 0) {
        return Yup.string()
          .matches(/^\s*\S[\s\S]*$/g, "Cannot contain only blankspaces")
          .matches(
            /^[a-zA-Z\s]*$/,
            "Special character & number are not allowed"
          )
          .min(3, "Minimum of 3 characters")
          .max(20, "Maximum of 20 characters")
          .required("Department name is required");
      }
    }),
    password: Yup.string()
      .matches(/^\s*\S[\s\S]*$/g, "Password cannot contain only blankspaces")
      .matches(/[a-z]/, "Must have atleast 1 small letter.")
      .matches(/[A-Z]/, "Must have atleast 1 capital letter.")
      .matches(
        /^(?=.*\d)(?=.*[_\-+!@#$%^&*()])/,
        "Must have atleast 1 number & 1 special character."
      )
      .matches(/^.{8,}$/, "Minimum of 8 characters")
      .required("Password is required!"),
    retype_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Retype password is required!"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      organisation_name: "",
      department_name: "",
      country: "",
      state: "",
      city: "",
      department: "",
      password: "",
      retype_password: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      dispatch(RegisterRequest(values));
    },
  });

  useEffect(() => {
    handleToastResponse(registerResponse);
    if (registerResponse?.success) {
      navigate("/login");
    }
    return () => dispatch(RegisterResClear());
  }, [registerResponse]);

  // Fetch country list and department list
  useEffect(() => {
    dispatch(countryListRequest());
    dispatch(departmentListRequest());
  }, []);

  // Fetch state list if country is selected
  useEffect(() => {
    if (formik.values.country) {
      formik.setFieldValue("state", "");
      formik.setFieldValue("city", "");
      dispatch(stateListRequest(formik.values.country));
    }
  }, [formik.values.country]);

  // Fetch city list if country and state are selected
  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      formik.setFieldValue("city", "");
      dispatch(
        cityListRequest({
          country: formik.values.country,
          state: formik.values.state,
        })
      );
    }
  }, [formik.values.state, formik.values.country]);

  return (
    <>
      <MetaData title="Register" />
      <div className="access_container register_container">
        <div
          className="access_image_container"
          style={{ backgroundImage: `url(${LOGIN_IMG})` }}
        ></div>
        <div className="access_form_container">
          <form
            className="forgot_password_form  register_form"
            onSubmit={formik.handleSubmit}
          >
            <div className="empty_content"></div>
            <div className="form_img">
              <img src={GWAY_IMG} alt="gway" />
            </div>
            <div className="form_head">
              <h2> Register</h2>
              <p>It's completely FREE</p>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <CustomInput
                  placeholder="Email"
                  type="text"
                  name="email"
                  size="large"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  formik={formik}
                />
              </div>
              <div className="col-sm-6">
                <CustomInput
                  placeholder="First Name"
                  type="text"
                  name="first_name"
                  size="large"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                  formik={formik}
                />
              </div>
              <div className="col-sm-6">
                <CustomInput
                  placeholder="Last Name"
                  type="text"
                  name="last_name"
                  size="large"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                  formik={formik}
                />
              </div>
              <div className="col-sm-6">
                <CustomInput
                  placeholder="Organization Name"
                  type="text"
                  name="organisation_name"
                  size="large"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.organisation_name}
                  formik={formik}
                />
              </div>
              <div className="col-sm-6">
                <CustomSelect
                  size="small"
                  placeholder="Select Department"
                  options={departmentList?.map((values) => {
                    return {
                      value: values?.id,
                      name: values?.department_name,
                    };
                  })}
                  name="department"
                  onChange={(event) => {
                    formik.handleChange(event);
                    if (formik.values.department !== 0) {
                      formik.setFieldValue("department_name", "");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.department}
                  formik={formik}
                />
              </div>
              {formik.values.department === 0 && (
                <div className="col-sm-6">
                  <CustomInput
                    placeholder="Department Name"
                    type="text"
                    name="department_name"
                    size="large"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.department_name}
                    formik={formik}
                  />
                </div>
              )}
              <div className="col-sm-6">
                <CustomSelect
                  size="small"
                  placeholder="Select Country"
                  options={countryList?.map((values) => {
                    return {
                      value: values.iso2,
                      name: values.name,
                    };
                  })}
                  name="country"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                  formik={formik}
                />
              </div>
              <div className="col-sm-6">
                <CustomSelect
                  size="small"
                  placeholder="Select State"
                  options={stateList?.map((values) => {
                    return {
                      value: values.iso2,
                      name: values.name,
                    };
                  })}
                  name="state"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                  formik={formik}
                />
              </div>
              <div className="col-sm-6">
                <CustomSelect
                  size="small"
                  placeholder="Select City"
                  options={cityList?.map((values) => {
                    return {
                      value: values.name,
                      name: values.name,
                    };
                  })}
                  name="city"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  formik={formik}
                />
              </div>
              <div className="col-sm-6">
                <CustomInput
                  placeholder="Password"
                  type={password.showPassword ? "text" : "password"}
                  name="password"
                  size="large"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onPaste={handlePaste}
                  value={formik.values.password}
                  formik={formik}
                  endAdornment={
                    <IconButton
                      onClick={() =>
                        handlePassword(setPassword, "showPassword")
                      }
                    >
                      {password.showPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  }
                />
              </div>
              <div className="col-sm-6">
                <CustomInput
                  placeholder="Re-type Password"
                  type={password.showConfirmPassword ? "text" : "password"}
                  name="retype_password"
                  size="large"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onPaste={handlePaste}
                  value={formik.values.retype_password}
                  formik={formik}
                  endAdornment={
                    <IconButton
                      onClick={() =>
                        handlePassword(setPassword, "showConfirmPassword")
                      }
                    >
                      {password.showConfirmPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  }
                />
              </div>
            </div>
            <div className="register_button">
              <CustomButton
                type="submit"
                label={registerLoading ? <LoadingButton /> : "Create Account"}
              />
            </div>
            <div className="d-flex justify-content-center gap-1 mt-4">
              <p>Already have an account?</p>
              <p>
                <NavLink to={"/login"}>Sign in</NavLink>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
