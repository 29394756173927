import ProjectActionTypes from "./ProjectActionTypes";

// GET ALL PROJECTS
export const getProjectIndexRequest = (payload) => ({
  type: ProjectActionTypes.PROJECT_INDEX_REQUEST,
  payload,
});

export const getProjectIndexResponse = (payload) => ({
  type: ProjectActionTypes.PROJECT_INDEX_RESPONSE,
  payload,
});

// POST PROJECTS
export const postProjectRequest = (payload) => ({
  type: ProjectActionTypes.POST_PROJECT_REQUEST,
  payload,
});

export const postProjectResponse = (payload) => ({
  type: ProjectActionTypes.POST_PROJECT_RESPONSE,
  payload,
});

// UPDATE PROJECTS
export const updateProjectRequest = (payload) => ({
  type: ProjectActionTypes.UPDATE_PROJECT_REQUEST,
  payload,
});

export const updateProjectResponse = (payload) => ({
  type: ProjectActionTypes.UPDATE_PROJECT_RESPONSE,
  payload,
});

// POST AND PUT RESPONSE CLEAR
export const postProjectResponseClear = () => ({
  type: ProjectActionTypes.POST_PROJECT_RES_CLEAR,
});

// DELETE PROJECT
export const deleteProjectRequest = (payload) => ({
  type: ProjectActionTypes.DELETE_PROJECT_REQUEST,
  payload,
});

export const deleteProjectResponse = (payload) => ({
  type: ProjectActionTypes.DELETE_PROJECT_RESPONSE,
  payload,
});

export const deleteProjectResponseClear = () => ({
  type: ProjectActionTypes.DELETE_PROJECT_RES_CLEAR,
});

// GET PARTICULAR PROJECT
export const showProjectRequest = (payload) => ({
  type: ProjectActionTypes.SHOW_PROJECT_REQUEST,
  payload,
});

export const showProjectResponse = (payload) => ({
  type: ProjectActionTypes.SHOW_PROJECT_RESPONSE,
  payload,
});

export const showProjectResponseClear = () => ({
  type: ProjectActionTypes.SHOW_PROJECT_RES_CLEAR,
});

// GET PROJECT MEMBERS
export const showProjectMembersRequest = (payload) => ({
  type: ProjectActionTypes.GET_PROJECTMEMBERS_REQUEST,
  payload,
});

export const showProjectMembersResponse = (payload) => ({
  type: ProjectActionTypes.GET_PROJECTMEMBERS_RESPONSE,
  payload,
});

export const showProjectMembersResponseClear = () => ({
  type: ProjectActionTypes.GET_PROJECTMEMBERS_RES_CLEAR,
});

// DELETE PROJECT MEMBER
export const deleteProjectMemberRequest = (payload) => ({
  type: ProjectActionTypes.DELETE_PROJECTMEMBERS_REQUEST,
  payload,
});

export const deleteProjectMemberResponse = (payload) => ({
  type: ProjectActionTypes.DELETE_PROJECTMEMBERS_RESPONSE,
  payload,
});

export const deleteProjectMemberResClear = () => ({
  type: ProjectActionTypes.DELETE_PROJECTMEMBERS_RES_CLEAR,
});

export const searchProjectTextAction = (payload) => ({
  type: ProjectActionTypes.SEARCH_PROJECT_TEXT,
  payload,
});
export const clearProjectTextAction = () => ({
  type: ProjectActionTypes.CLEAR_PROJECT_TEXT,
});

export const saveProjectFilter = (payload) => ({
  type: ProjectActionTypes.SAVED_PROJECT_FILTER,
  payload: payload,
});

// GET APPLICATIONS
export const getApplicationsIndexRequest = (payload) => ({
  type: ProjectActionTypes.GET_APPLICATIONS_INDEX_REQUEST,
  payload,
});

export const getApplicationsIndexResponse = (payload) => ({
  type: ProjectActionTypes.GET_APPLICATIONS_INDEX_RESPONSE,
  payload,
});

// GET APPLICATION PROJECTS
export const getAppProjectsIndexRequest = (payload) => ({
  type: ProjectActionTypes.GET_APP_PROJECTS_INDEX_REQUEST,
  payload,
});

export const getAppProjectsIndexResponse = (payload) => ({
  type: ProjectActionTypes.GET_APP_PROJECTS_INDEX_RESPONSE,
  payload,
});

// GET ALL ISSUE TRACKER
export const getIssueTrackerIndexRequest = (payload) => ({
  type: ProjectActionTypes.GET_ISSUETRACKER_INDEX_REQUEST,
  payload,
});

export const getIssueTrackerIndexResponse = (payload) => ({
  type: ProjectActionTypes.GET_ISSUETRACKER_INDEX_RESPONSE,
  payload,
});

// POST ISSUE TRACKER
export const postIssueTrackerRequest = (payload) => ({
  type: ProjectActionTypes.POST_ISSUETRACKER_REQUEST,
  payload,
});

export const postIssueTrackerResponse = (payload) => ({
  type: ProjectActionTypes.POST_ISSUETRACKER_RESPONSE,
  payload,
});

export const postIssueTrackerResClear = () => ({
  type: ProjectActionTypes.POST_ISSUETRACKER_RES_CLEAR,
});

// DELETE ISSUE TRACKER
export const deleteIssueTrackerRequest = (payload) => ({
  type: ProjectActionTypes.DELETE_ISSUETRACKER_REQUEST,
  payload,
});

export const deleteIssueTrackerResponse = (payload) => ({
  type: ProjectActionTypes.DELETE_ISSUETRACKER_RESPONSE,
  payload,
});

export const deleteIssueTrackerResClear = () => ({
  type: ProjectActionTypes.DELETE_ISSUETRACKER_RES_CLEAR,
});
