import TestDesignCommentActionTypes from "./TestDesignCommentActionTypes";
import { call, takeLatest, put } from "redux-saga/effects";
import {
  axiosGet,
  axiosDelete,
  axiosPost,
  axiosPut,
} from "../../../Utils/axios";
import { unauthorizedMessage } from "../../Login/LoginActions";
import {
  deleteTestDesignCommentResponse,
  getTestDesignCommentIndexResponse,
  getTestDesignHistoryIndexResponse,
  postTestDesignCommentResponse,
  showTestDesignCommentResponse,
  updateTestDesignCommentResponse,
} from "./TestDesignCommentAction";

// WORKER SAGA
// TESTDESIGN HISTORY
// GET ALL TESTDESIGN HISTORY
export function* onGetTestDesignHistoryIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`test_case/${payload}/get_history`).then(
        (response) => response?.data
      )
    );
    yield put(getTestDesignHistoryIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// TESTDESIGN COMMENT
// GET ALL TESTDESIGN COMMENT
export function* onGetTestDesignCommentIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("test_case/view_comments", payload).then(
        (response) => response?.data
      )
    );
    yield put(getTestDesignCommentIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//  POST TESTDESIGN COMMENT
export function* onPostTestDesignCommentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("test_case/comment/new", payload).then(
        (response) => response?.data
      )
    );
    yield put(postTestDesignCommentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//  UPDATE TESTDESIGN COMMENT
export function* onUpdateTestDesignCommentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`test_case/comment/${payload?.id}`, {
        comment: payload?.value,
      }).then((response) => response?.data)
    );
    yield put(updateTestDesignCommentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE TESTDESIGN COMMENT
export function* onDeleteTestDesignCommentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`test_case/comment/${payload}`).then(
        (response) => response?.data
      )
    );
    yield put(deleteTestDesignCommentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR TESTDESIGN COMMENT
export function* onViewTestDesignCommentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`test_case/comment/${payload}`).then(
        (response) => response?.data
      )
    );
    yield put(showTestDesignCommentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* TestDesignCommentWatcherSaga() {
  // TESTDESIGN HISTORY
  yield takeLatest(
    TestDesignCommentActionTypes.GET_TESTDESIGNHISTORY_INDEX_REQUEST,
    onGetTestDesignHistoryIndexWorkerSaga
  );
  // TESTDESIGN COMMENT
  yield takeLatest(
    TestDesignCommentActionTypes.GET_TESTDESIGNCOMMENT_INDEX_REQUEST,
    onGetTestDesignCommentIndexWorkerSaga
  );
  yield takeLatest(
    TestDesignCommentActionTypes.POST_TESTDESIGNCOMMENT_REQUEST,
    onPostTestDesignCommentWorkerSaga
  );
  yield takeLatest(
    TestDesignCommentActionTypes.UPDATE_TESTDESIGNCOMMENT_REQUEST,
    onUpdateTestDesignCommentWorkerSaga
  );
  yield takeLatest(
    TestDesignCommentActionTypes.DELETE_TESTDESIGNCOMMENT_REQUEST,
    onDeleteTestDesignCommentWorkerSaga
  );
  yield takeLatest(
    TestDesignCommentActionTypes.SHOW_TESTDESIGNCOMMENT_REQUEST,
    onViewTestDesignCommentWorkerSaga
  );
}
