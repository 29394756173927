import React, { useState } from "react";
import { FileUpload } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { appUrl } from "../../Utils/axios";

const TestRunAttachmentForm = ({ formik, index }) => {
  const [steps, setSteps] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const { testCaseRunList } = useSelector((state) => state.testExecution);

  const handleFileUpload = (event, index) => {
    const { files, name } = event.target;

    formik.setFieldValue(`steps[${index}].log`, files[0]);
    setSteps((prev) => {
      prev[index].log = files[0];
      return prev;
    });
    setSelectedFileName(files[0].name);
    const imageURL = URL.createObjectURL(files[0]);
    setSelectedImage(imageURL);
  };

  return (
    <div className="testattach_container">
      {selectedFileName && selectedFileName}

      <div className="testrunattach_input_container">
        <div className="testattach_input">
          <h3 className="d-flex flex-column align-items-center">
            Upload File
            <span>
              <FileUpload sx={{ width: "30px", height: "30px" }} />
              <input
                type="file"
                accept="image/*"
                className="filee"
                name={`steps[${index}].log`}
                onChange={(e) => {
                  setSteps((prev) => {
                    if (!prev[index]) {
                      prev[index] = {};
                    }
                    prev[index].log = null;
                    return prev;
                  });
                  handleFileUpload(e, index);
                }}
              />
            </span>
          </h3>
        </div>
        <div className="testattach_input">
          <h3 className="text-center">
            Take ScreenShot
            <br />
            Paste <br />
            Ctrl + V
          </h3>
        </div>
        <div className="testattach_input drag_drop_border">
          <h3 className="text-center">
            Drag and drop <br />
            file here <br />
            (Maximum Size: <br />
            50MB)
          </h3>
        </div>
      </div>
      <div className="image_view_container">
        {selectedImage ? (
          <img className="signImg" src={selectedImage} />
        ) : testCaseRunList.data[index].log ? (
          <>
            <img
              className="signImg"
              src={`${appUrl}${testCaseRunList.data[index].log}`}
            />
          </>
        ) : (
          testCaseRunList.data[index].log === null && "No images"
        )}
      </div>
    </div>
  );
};

export default TestRunAttachmentForm;
