import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  EditOutlined,
  VisibilityOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { convertedString } from "../../Utils/handles";
import ProjectAddForm from "./ProjectAddForm";
import DeleteIndex from "../DeletePopup/DeleteIndex";
import { deleteProjectRequest } from "../../Redux/ProjectSaga/ProjectAction";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import ProjectIntegrateForm from "./ProjectIntegrateForm";

const ProjectBody = ({ data, index, listPerPage, page }) => {
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState({
    open: false,
    type: "",
    id: "",
  });

  const { deleteProjectLoading, deleteProjectResponse } = useSelector(
    (state) => state.project
  );
  const { user } = useSelector((state) => state.loginState);

  const handleClickOpen = (value, id) => {
    setOpenDialog({ open: true, type: value, id: id });
  };

  const handleCloseDialog = () => {
    setOpenDialog({ open: false, type: "", id: "" });
  };

  return (
    <>
      <td>{listPerPage * (page - 1) + index + 1}</td>
      <td> {data?.name}</td>
      <td>{data?.start_date}</td>
      <td>{data?.end_date}</td>
      <td>{data?.member_count}</td>
      <td>{convertedString(data?.status)}</td>
      <td>
        <div className="view">
          <button onClick={() => navigate(`/project/${data?.id}/dashboard`)}>
            <VisibilityOutlined />
          </button>
          {user?.id === data?.created_by && (
            <button value="edit" onClick={() => handleClickOpen("edit", "")}>
              <EditOutlined />
            </button>
          )}
          {user?.id === data?.created_by && (
            <DeleteIndex
              payload={data?.id}
              action={deleteProjectRequest}
              loading={deleteProjectLoading}
              res={deleteProjectResponse}
              name="Project"
            />
          )}
          {user?.id === data?.created_by && (
            <button onClick={() => handleClickOpen("integrate", data?.id)}>
              <SettingsOutlined />
            </button>
          )}
        </div>
      </td>
      <CustomDialog
        open={openDialog.open && openDialog.type === "edit"}
        handleClose={handleCloseDialog}
        title="Edit project"
      >
        <ProjectAddForm
          handleClose={handleCloseDialog}
          type="edit"
          data={data}
        />
      </CustomDialog>
      <CustomDialog
        open={openDialog.open && openDialog.type === "integrate"}
        handleClose={handleCloseDialog}
        title="Issue Tracker"
      >
        <ProjectIntegrateForm
          handleClose={handleCloseDialog}
          openDialog={openDialog}
        />
      </CustomDialog>
    </>
  );
};

ProjectBody.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    member_count: PropTypes.number.isRequired,
    integrated_by: PropTypes.string.isRequired,
  }),
  index: PropTypes.number.isRequired,
  listPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default ProjectBody;
