import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

export default function MetaData({ title }) {
  return (
    <Helmet>
      <title>{`${title[0].toUpperCase() + title.slice(1)} - TestForge`}</title>
    </Helmet>
  );
}
MetaData.propTypes = {
  title: PropTypes.string.isRequired,
};
