// STATUS DROPDOWN
export const status = [
  {
    value: "IN_PROGRESS",
    name: "In Progress",
  },
  {
    value: "ON_HOLD",
    name: "On Hold",
  },
  {
    value: "COMPLETED",
    name: "Completed",
  },
];

// PRIORITY DROPDOWN
export const priority = [
  {
    value: "HIGH",
    name: "High",
  },
  {
    value: "LOW",
    name: "Low",
  },
  {
    value: "MEDIUM",
    name: "Medium",
  },
];

// SECOND PRIORITY DROPDOWN
export const defectPriority = [
  {
    value: "P1",
    name: "P1",
  },
  {
    value: "P2",
    name: "P2",
  },
  {
    value: "P3",
    name: "P3",
  },
];

// TYPE DROPDOWN
export const type = [
  {
    value: "FUNCTIONAL",
    name: "Functional",
  },
  {
    value: "NON_FUNCTIONAL",
    name: "Non Functional",
  },
];

// PROJECT STATUS DROPDOWN
export const projectStatus = [
  {
    value: "OPEN",
    name: "Open",
  },
  {
    value: "ON_HOLD",
    name: "On Hold",
  },
  {
    value: "COMPLETED",
    name: "Completed",
  },
];

// SEVERITY DROPDOWN
export const severity = [
  {
    value: "Blocker",
    name: "Blocker",
  },
  {
    value: "Critical",
    name: "Critical",
  },
  {
    value: "Major",
    name: "Major",
  },
  {
    value: "Minor",
    name: "Minor",
  },
];

// DEFECT STATUS
export const defectStatus = [
  {
    value: "NEW",
    name: "New",
  },
  {
    value: "FIXED",
    name: "Fixed",
  },
  {
    value: "REOPENED",
    name: "Reopened",
  },
  {
    value: "ON_HOLD",
    name: "On Hold",
  },
  {
    value: "CLOSED",
    name: "Closed",
  },
  {
    value: "NOT_A_BUG",
    name: "Not a bug",
  },
  {
    value: "SUGGESTION",
    name: "Suggestion",
  },
];

// TESTCASE PRIORITY
export const testCasePriority = [
  {
    value: "HIGH",
    name: "High",
  },
  {
    value: "LOW",
    name: "Low",
  },
  {
    value: "MEDIUM",
    name: "Medium",
  },
  {
    value: "CRITICAL",
    name: "Critical",
  },
];

// PIECHART DATASET
export const pieChartDataSet = [
  {
    status: "In Progress",
    color: "rgb(254 157 0)",
  },
  {
    status: "Passed",
    color: "rgb(0 238 140)",
  },
  {
    status: "Unexecuted",
    color: "rgb(3 255 254)",
  },
  {
    status: "Failed",
    color: "rgb(247 52 52)",
  },
];

// MULTILINE DATASET
export const multiLineChartDataSet = [
  {
    status: "Passed",
    color: "rgb(0 238 140)",
  },
  {
    status: "In Progress",
    color: "rgb(254 157 0)",
  },
  {
    status: "Unexecuted",
    color: "rgb(0 255 255)",
  },
  {
    status: "Failed",
    color: "rgb(247 52 52)",
  },
];

export const reportPieChartStatus = [
  {
    status: "Unexecuted",
    color: "rgb(253,147,46)",
  },
  {
    status: "Inprogress",
    color: "rgb(125,176,232)",
  },
  {
    status: "Failed",
    color: "rgb(219,48,71) ",
  },
  {
    status: "Passed",
    color: "rgb(167,200,43)",
  },
];

export const reportBarChartStatus = [
  {
    status: "New",
    color: "rgb(26,183,191)",
  },
  {
    status: "Fixed",
    color: "rgb(28,102,168)",
  },
  {
    status: "Reopened",
    color: "rgb(219,48,71)",
  },
  {
    status: "On Hold",
    color: "rgb(214,119,142)",
  },
  {
    status: "Closed",
    color: "rgb(255,148,0)",
  },
  {
    status: "Not a Bug",
    color: "rgb(138,166,59)",
  },
  {
    status: "Suggestion",
    color: "rgb(139,186,241)",
  },
];

// CRM PRIORITY
export const crmPriority = [
  {
    value: "p1",
    name: "P1",
  },
  {
    value: "p2",
    name: "P2",
  },
  {
    value: "p3",
    name: "P3",
  },
];
