import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  postProjectRequest,
  postProjectResponseClear,
  updateProjectRequest,
  showProjectRequest,
  showProjectResponseClear,
  getProjectIndexRequest,
  showProjectMembersRequest,
  showProjectMembersResponseClear,
} from "../../Redux/ProjectSaga/ProjectAction";
import toast from "react-hot-toast";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import { Autocomplete, TextField } from "@mui/material";
import { useParams } from "react-router-dom";

const TestExecutionFilter = ({
  handleClose,
  testCaseRunningList,
  payload,
  formik,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { projectMembers } = useSelector((state) => state.project);
  const status = [
    {
      name: "Running",
      value: "RUNNING",
    },
    {
      name: "Stopped",
      value: "STOPPED",
    },
    {
      name: "Submitted",
      value: "SUBMITTED",
    },
  ];
  useEffect(() => {
    dispatch(
      showProjectMembersRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, [id]);

  return (
    <div className="project_add_container">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 err_margin details_list">
            <h3>Test CaseName</h3>
            <CustomSelect
              size="small"
              // placeholder="Test Case Name"
              options={testCaseRunningList?.data?.map((values, index) => {
                return {
                  value: values?.test_case_fk.id,
                  name: values.test_case_fk.name,
                };
              })}
              name="test_case_fk.value"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.test_case_fk.value}
              formik={formik}
            />
          </div>
          <div className="col-md-6 err_margin details_list">
            <h3>Executor</h3>
            <CustomSelect
              size="small"
              // placeholder="Executor"
              options={projectMembers?.data?.map((values, index) => {
                return {
                  value: values.user_fk,
                  name: `${values.first_name} ${values.last_name}`,
                };
              })}
              name="executor.value"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.executor.value}
              formik={formik}
            />
          </div>

          <div className="col-12">
            <div className="popformbutton">
              <CustomButton
                size="small"
                label="Cancel"
                btnType="secondary"
                onClick={handleClose}
              />
              <CustomButton size="small" label={"Filter"} type="submit" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TestExecutionFilter;
