import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { CLOUD_ICON } from "../../../Utils/imageConstants";
import { useParams } from "react-router-dom";
import { ExpandMore, AttachFileOutlined, Close } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CustomButton from "../../../Components/Atoms/CustomButton/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput/CustomInput";
import CustomSelect from "../../../Components/Atoms/CustomSelect/CustomSelect";
import RequirementHistory from "./RequirementHistory";
import RequirementComment from "./RequirementComment";
import { useDispatch, useSelector } from "react-redux";
import { priority, status, type } from "../../../Utils/constants";
import { showProjectMembersRequest } from "../../../Redux/ProjectSaga/ProjectAction";
import {
  deleteRequireDetailAttachmentRequest,
  deleteRequireDetailAttachmentResponseClear,
  getRequireDetailAttachmentIndexRequest,
  getRequireDetailAttachmentIndexResponseClear,
  getRequirementModuleIndexRequest,
  postRequireDetailAttachmentRequest,
  postRequireDetailAttachmentResponseClear,
  postRequirementDetailRequest,
  postRequirementDetailResponseClear,
  updateRequirementDetailRequest,
} from "../../../Redux/RequirementSaga/RequirementActions";
import MultiDocUpload from "../../../Components/Organisms/MultiDocUpload/MultiDocUpload";
import CustomDialog from "../../../Components/Atoms/CustomDialog/CustomDialog";
import { handleDownloadFile } from "../../../Utils/handles";
import { appUrl } from "../../../Utils/axios";
import DeleteIndex from "../../DeletePopup/DeleteIndex";
import CustomAttachmentIcon from "../../../Components/Atoms/CustomAttachmentIcon/CustomAttachmentIcon";

const RequirementDetails = ({
  types,
  reset,
  requirementId,
  activeTab,
  setActiveTab,
  requirementDetail,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);

  const maxSizeInBytes = 5 * 1024 * 1024;

  const {
    postRequirementDetailResponse,
    requirementModuleList,
    // requirementDetail,
    requirementDetailAttachmentList,
    deleteRequirementDetailAttachmentLoading,
    postRequirementDetailAttachmentLoading,
    deleteRequirementDetailAttachmentResponse,
    postRequirementDetailAttachmentResponse,
  } = useSelector((state) => state.requirement);

  const { projectMembers } = useSelector((state) => state.project);

  const validation = Yup.object().shape({
    name: Yup.string()
      .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces alone")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-_=+{}\[\]\n?">]+)$/,
        "Module name should contain characters too"
      )
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .trim()
      .required("Module Name is required!"),
    description: Yup.string().matches(
      /^\s*\S[\s\S]*$/g,
      "Should not contain spaces alone"
    ),
    status: Yup.string().required("Status is required!"),
    type: Yup.string().required("Type is required!"),
    priority: Yup.string().required("Priority is required!"),
    module_fk: Yup.string().required("Module is Required"),
    attachments: Yup.array().of(
      Yup.object().shape({
        attachment: Yup.mixed()
          .test("fileSize", "File size must be below 10MB", (value) => {
            if (value instanceof File) {
              return value.size <= maxSizeInBytes;
            }
            return true; // Allow the value to be a string (image URL from backend)
          })
          .nullable(),

        name: Yup.string()
          .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces")
          .matches(
            /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-_=+{}\[\]\n?">]+)$/,
            "Name should contain characters too"
          )
          .min(3, "Minimum of 3 characters")
          .max(15, "Maximum of 15 characters")
          .when("attachment", {
            is: (val) => val instanceof File,
            then: (schema) => schema.required("Attachment Name is required!"),
          }),
      })
    ),
  });

  // ATTACHMENT DOC FORMIK VALIDATION
  const Validation = Yup.object().shape({
    attachments: Yup.array().of(
      Yup.object().shape({
        attachment: Yup.mixed()
          .test("fileSize", "File size must be below 10MB", (value) => {
            if (value instanceof File) {
              return value.size <= maxSizeInBytes;
            }
            return true; // Allow the value to be a string (image URL from backend)
          })
          .required("Attachment is required!"),
        name: Yup.string()
          .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces")
          .matches(/^[a-zA-Z0-9\s]+$/, "Invalid Format")
          .matches(
            /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-=+\n]+)$/,
            "Numbers only not allowed"
          )
          .min(3, "Minimum of 3 characters")
          .max(15, "Maximum of 15 characters")
          .when("attachment", {
            is: (val) => val instanceof File,
            then: (schema) => schema.required("Name is required!"),
          }),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "Requirement",
      status: "",
      type: "",
      priority: "",
      assigned_to_fk: "",
      description: "",
      module_fk: "",
      attachments: [
        {
          attachment: null,
          name: "",
        },
      ],
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const formData = new FormData();
      for (let requirement_key in values) {
        if (
          requirement_key === "attachments" &&
          values.attachments.length > 0
        ) {
          if (types !== "edit") {
            values.attachments.forEach((item, index) => {
              if (item.attachment) {
                formData.append(
                  `attachments[${index}].attachment`,
                  item.attachment
                );
                formData.append(`attachments[${index}].name`, item.name);
              }
            });
          }
        } else {
          formData.append(`${requirement_key}`, values[requirement_key]);
        }
      }
      if (types === "edit") {
        // if (
        //   requirementDetail?.name != values.name ||
        //   requirementDetail?.status != values.status ||
        //   requirementDetail?.type != values.type ||
        //   requirementDetail?.priority != values.priority ||
        //   requirementDetail?.description != values.description ||
        //   requirementDetail?.assigned_to_fk != values.assigned_to_fk
        // ) {
        dispatch(
          updateRequirementDetailRequest({ formData: values, id: values?.id })
        );
        // }
      } else {
        dispatch(postRequirementDetailRequest(formData));
      }
    },
  });

  const docFormik = useFormik({
    initialValues: {
      attachments: [
        {
          attachment: null,
          name: "",
        },
      ],
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      if (types !== "edit") {
        handleClose();
      } else {
        const formData = new FormData();
        values.attachments.length > 0 &&
          values.attachments.forEach((item, index) => {
            if (item.attachment) {
              formData.append(
                `attachments[${index}].attachment`,
                item.attachment
              );
              formData.append(`attachments[${index}].name`, item.name);
            }
          });
        formData.append("requirement_fk", requirementId);
        dispatch(postRequireDetailAttachmentRequest(formData));
      }
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    if (types !== "edit") {
      setOpen(false);
    } else {
      docFormik.resetForm();
      setOpen(false);
    }
  };

  // HANDLE TAB SWITCH
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // TO SET FORMIK VALUES WHILE EDITING
  useEffect(() => {
    if (requirementDetail && types === "edit") {
      for (const key in formik.values) {
        if (key === "attachments") {
          formik.setFieldValue("attachments", [{ attachment: "", name: "" }]);
        } else {
          formik.setFieldValue(key, requirementDetail[key]);
        }
      }
      formik.setFieldValue("id", requirementDetail?.id);
    }
    // return () => {
    //   dispatch(showRequirementDetailResponseClear());
    // };
  }, [requirementDetail]);

  // PROJECT MEMBERS & MODULES LIST ON INITIAL RENDER FOR DROPDOWN
  useEffect(() => {
    dispatch(
      showProjectMembersRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
    dispatch(
      getRequirementModuleIndexRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, []);

  // TOAST MESSAGE FOR CREATE AND EDIT REQUIREMENT
  useEffect(() => {
    if (!postRequirementDetailResponse) return;
    if (postRequirementDetailResponse?.success) {
      toast.success(postRequirementDetailResponse?.message);
      if (types !== "edit") {
        formik.resetForm();
      }
    } else {
      toast.error(postRequirementDetailResponse?.message);
    }
    return () => {
      dispatch(postRequirementDetailResponseClear());
    };
  }, [postRequirementDetailResponse]);

  useEffect(() => {
    if (!reset) return;
    formik.resetForm();
    dispatch(getRequireDetailAttachmentIndexResponseClear());
  }, [reset]);

  useEffect(() => {
    if (
      deleteRequirementDetailAttachmentLoading ||
      postRequirementDetailAttachmentLoading
    )
      return;
    dispatch(
      getRequireDetailAttachmentIndexRequest({
        filters: {
          requirement_fk: {
            type: "exact",
            value: requirementId,
          },
        },
      })
    );
  }, [
    deleteRequirementDetailAttachmentLoading,
    postRequirementDetailAttachmentLoading,
  ]);

  // TOAST MESSAGE FOR DELETE ATTACHMENT
  useEffect(() => {
    if (!deleteRequirementDetailAttachmentResponse) return;
    if (
      deleteRequirementDetailAttachmentResponse?.message &&
      deleteRequirementDetailAttachmentResponse?.success
    ) {
      toast.success(deleteRequirementDetailAttachmentResponse?.message);
    } else {
      toast.error(deleteRequirementDetailAttachmentResponse?.message);
    }
    return () => {
      dispatch(deleteRequireDetailAttachmentResponseClear());
    };
  }, [deleteRequirementDetailAttachmentResponse]);

  useEffect(() => {
    if (!postRequirementDetailAttachmentResponse) return;
    if (postRequirementDetailAttachmentResponse) {
      if (
        postRequirementDetailAttachmentResponse?.message &&
        postRequirementDetailAttachmentResponse?.success
      ) {
        toast.success(postRequirementDetailAttachmentResponse?.message);
        handleCloseDialog();
      } else {
        toast.error(postRequirementDetailAttachmentResponse?.message);
      }
    }
    return () => {
      postRequireDetailAttachmentResponseClear();
    };
  }, [postRequirementDetailAttachmentResponse]);

  return (
    <>
      <div className="dashboard_container releaseLayout">
        <div className="position-relative">
          <div
            className={
              isEditing ? "test_header2" : "test_header test_header_hover"
            }
            onClick={() => {
              if (activeTab === "details") {
                setIsEditing(true);
              } else {
                setIsEditing(false);
              }
            }}
          >
            {isEditing ? (
              <CustomInput
                type="text"
                name="name"
                size="large"
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={(e) => {
                  if (formik.values.name) {
                    formik.handleBlur(e);
                    setIsEditing(false);
                  }
                }}
                value={formik.values.name}
                formik={formik}
              />
            ) : (
              <>
                <h3>{formik.values.name}</h3>
              </>
            )}
          </div>
          {formik.errors.name && !isEditing ? (
            <p className="CustErr">{formik.errors.name}</p>
          ) : (
            ""
          )}
        </div>
        <div className="reqTabHead">
          <div className="">
            <div className="pageTopLeft">
              <div className="project-boardhead-down-container d-flex gap-4 align-items-center">
                <div className="empViewBtn">
                  <p
                    className={activeTab === "details" ? "actives" : "details"}
                    onClick={() => handleTabClick("details")}
                    onKeyDown={() => {}}
                  >
                    Details
                  </p>
                  {!reset && (
                    <>
                      <p
                        className={activeTab === "history" ? "actives" : ""}
                        onClick={() => handleTabClick("history")}
                        onKeyDown={() => {}}
                      >
                        History
                      </p>
                      <p
                        className={activeTab === "comments" ? "actives" : ""}
                        onClick={() => handleTabClick("comments")}
                        onKeyDown={() => {}}
                      >
                        Comments
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {activeTab == "details" && (
          <form onSubmit={formik.handleSubmit}>
            <div className="testForm mt-2">
              <div className="row">
                <div className="col-md-6">
                  <CustomSelect
                    size="small"
                    placeholder="Status"
                    options={status.map((values, index) => {
                      return {
                        value: values.value,
                        name: values.name,
                      };
                    })}
                    name="status"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.status}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6 err_margin">
                  <CustomSelect
                    size="small"
                    placeholder="Type"
                    options={type.map((values, index) => {
                      return {
                        value: values.value,
                        name: values.name,
                      };
                    })}
                    name="type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.type}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6 err_margin">
                  <CustomSelect
                    size="small"
                    placeholder="Priority"
                    options={priority.map((values, index) => {
                      return {
                        value: values.value,
                        name: values.name,
                      };
                    })}
                    name="priority"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.priority}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6 err_margin">
                  <CustomSelect
                    size="small"
                    placeholder="Assigned To"
                    options={projectMembers?.data?.map((val, index) => {
                      return {
                        value: val?.user_fk,
                        name: val?.email,
                      };
                    })}
                    name="assigned_to_fk"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.assigned_to_fk}
                    formik={formik}
                  />
                </div>
                {types !== "edit" && (
                  <div className="col-md-6 err_margin">
                    <CustomSelect
                      size="small"
                      placeholder="Select Module"
                      options={requirementModuleList?.data?.map((val) => {
                        return {
                          value: val?.id,
                          name: val?.name,
                        };
                      })}
                      name="module_fk"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.module_fk}
                      formik={formik}
                    />
                  </div>
                )}
                <div className="col-md-12">
                  <CustomInput
                    multiline
                    rows={4}
                    placeholder="Description"
                    type="text"
                    name="description"
                    size="large"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    formik={formik}
                  />
                </div>
              </div>
              <div className="accordian_container">
                <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3>Resources</h3>
                  </AccordionSummary>
                  <AccordionDetails className="p-0">
                    <div className="contWrpr">
                      <div className="d-flex inner_cont justify-content-between">
                        <div className="flex_cont">
                          <p className="hrdr text-center">Attachments</p>
                          <div className="vendor_file_uploader p-3">
                            <div onClick={() => setOpen(true)}>
                              <label className="vendor_file_ip_wrp flex_column">
                                <img
                                  src={CLOUD_ICON}
                                  width="30px"
                                  className="image"
                                />
                                <CustomButton
                                  label={
                                    <>
                                      <AttachFileOutlined
                                        sx={{ marginBottom: "4px" }}
                                      />
                                      <span>Attach</span>
                                    </>
                                  }
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                  {formik?.errors?.attachments?.map((el, index) => (
                    <p className="CustErr mt-1">
                      {formik?.errors?.attachments[index]?.name}
                    </p>
                  ))}
                </Accordion>
              </div>
              <div>
                <div className="d-flex flex-wrap gap-2 my-4">
                  {requirementDetailAttachmentList?.data?.map((el, index) => (
                    <div
                      className="attachments_container"
                      key={el?.id}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDownloadFile(`${appUrl}${el?.attachment}`);
                      }}
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <DeleteIndex
                          className="delete_attachment"
                          payload={el?.id}
                          action={deleteRequireDetailAttachmentRequest}
                          loading={deleteRequirementDetailAttachmentLoading}
                          res={deleteRequirementDetailAttachmentResponse}
                          label={<Close />}
                        />
                      </div>
                      <CustomAttachmentIcon attachment={el?.attachment} />
                      <p className="file_uploaded_name">{el?.name}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="popformbutton">
                <CustomButton
                  size="small"
                  label="Cancel"
                  btnType="secondary"
                  onClick={() => navigate(`project/${id}/dashboard`)}
                />
                <CustomButton
                  size="small"
                  type="submit"
                  label={types === "edit" ? "Update" : "Save"}
                  onClick={formik.handleSubmit}
                />
              </div>
            </div>
          </form>
        )}

        {activeTab == "history" && (
          <RequirementHistory requirementId={requirementId} />
        )}
        {activeTab == "comments" && (
          <RequirementComment requirementId={requirementId} />
        )}
      </div>
      <CustomDialog
        open={open}
        title="Add Attachments"
        handleClose={handleCloseDialog}
        maxWidth="md"
      >
        <form
          onSubmit={
            types === "edit" ? docFormik.handleSubmit : formik.handleSubmit
          }
        >
          <div className="mb-3">
            <MultiDocUpload formik={types === "edit" ? docFormik : formik} />
          </div>
          <div className="popformbutton">
            {types === "edit" ? (
              <>
                <CustomButton
                  size="small"
                  btnType="secondary"
                  label="Cancel"
                  onClick={() => handleCloseDialog()}
                />
                <CustomButton
                  size="small"
                  label="Upload Attachment"
                  type={"submit"}
                />
              </>
            ) : (
              <CustomButton
                size="small"
                label="Next"
                onClick={() => handleCloseDialog()}
              />
            )}
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default RequirementDetails;
