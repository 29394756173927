import React from "react";
import "./LoadingAnimation.css";

const LoadingAnimation = () => {
  return (
    <div className="loading_container flex_row_center">
      <div class="loader"></div>
    </div>
  );
};

export default LoadingAnimation;
