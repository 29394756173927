import React from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import "./CustomSelect.css";
import PropTypes from "prop-types";

const CustomSelect = (props) => {
  const errorContent = () => {
    if (props.error) {
      return props.error;
    }

    const touched = props.formik?.touched[props.name];
    const formikError = props.formik?.errors[props.name];

    if (touched && formikError) {
      return formikError;
    }

    return "";
  };
  return (
    <div className={`customInputWrpr ${props.containerClasses}`}>
      {props.label ? (
        <FormControl fullWidth size={props.size}>
          <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
          <Select
            labelId={`${props.name}-label`}
            id={`${props.name}-label`}
            name={props.name}
            value={props.value}
            onBlur={props.onBlur}
            label={props.label !== "" ? props.label : null}
            onChange={props.onChange}
            disabled={props.disabled}
          >
            <MenuItem disabled value="">
              <p className="select_placeholder"> {props.placeholder}</p>
            </MenuItem>
            {props?.options?.length > 0 &&
              props?.options?.map((data, index) => (
                <MenuItem
                  key={data.value}
                  value={data.value}
                  onClick={data.onClick}
                >
                  {data.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl fullWidth size={props.size}>
          <Select
            name={props.name}
            id={`${props.name}-label`}
            labelId={`${props.name}-label`}
            value={props.value}
            label={props.label !== "" ? props.label : null}
            onChange={props.onChange}
            onBlur={props.onBlur}
            disabled={props.disabled}
            displayEmpty
            defaultValue={props.placeholder}
            className="custom_select"
          >
            <MenuItem disabled value="">
              <p className="select_placeholder"> {props.placeholder}</p>
            </MenuItem>
            {props?.options?.length > 0 &&
              props?.options?.map((data, index) => (
                <MenuItem
                  key={data.value}
                  value={data.value}
                  onClick={data.onClick}
                >
                  {data.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      <p className="errMsg">{errorContent()}</p>
    </div>
  );
};
CustomSelect.propTypes = {
  containerClasses: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
  error: PropTypes.string,
  formik: PropTypes.object,
};

export default CustomSelect;
