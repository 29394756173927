export default {
  // TESTDESIGN HISTORY
  GET_TESTDESIGNHISTORY_INDEX_REQUEST: "GET_TESTDESIGNHISTORY_INDEX_REQUEST",
  GET_TESTDESIGNHSTORY_INDEX_RESPONSE: "GET_TESTDESIGNHSTORY_INDEX_RESPONSE",

  // TESTDESIGN COMMENTS
  GET_TESTDESIGNCOMMENT_INDEX_REQUEST: "GET_TESTDESIGNCOMMENT_INDEX_REQUEST",
  GET_TESTDESIGNCOMENT_INDEX_RESPONSE: "GET_TESTDESIGNCOMENT_INDEX_RESPONSE",

  POST_TESTDESIGNCOMMENT_REQUEST: "POST_TESTDESIGNCOMMENT_REQUEST",
  POST_TESTDESIGNCOMMENT_RESPONSE: "POST_TESTDESIGNCOMMENT_RESPONSE",

  UPDATE_TESTDESIGNCOMMENT_REQUEST: "UPDATE_TESTDESIGNCOMMENT_REQUEST",
  UPDATE_TESTDESIGNCOMMENT_RESPONSE: "UPDATE_TESTDESIGNCOMMENT_RESPONSE",

  POST_TESTDESIGNCOMMENT_RES_CLEAR: "POST_TESTDESIGNCOMMENT_RES_CLEAR",

  DELETE_TESTDESIGNCOMMENT_REQUEST: "DELETE_TESTDESIGNCOMMENT_REQUEST",
  DELETE_TESTDESIGNCOMMENT_RESPONSE: "DELETE_TESTDESIGNCOMMENT_RESPONSE",
  DELETE_TESTDESIGNCOMMENT_RES_CLEAR: "DELETE_TESTDESIGNCOMMENT_RES_CLEAR",

  SHOW_TESTDESIGNCOMMENT_REQUEST: "SHOW_TESTDESIGNCOMMENT_REQUEST",
  SHOW_TESTDESIGNCOMMENT_RESPONSE: "SHOW_TESTDESIGNCOMMENT_RESPONSE",
  SHOW_TESTDESIGNCOMMENT_RES_CLEAR: "SHOW_TESTDESIGNCOMMENT_RES_CLEAR",
};
