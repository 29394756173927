import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

const ReporFilterCheckBox = ({ label, handleChange, value }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox className={"table_checkbox table_checkbox_2"} name={label} />
      }
      label={label}
      checked={value}
      onChange={handleChange}
    />
  );
};

export default ReporFilterCheckBox;
