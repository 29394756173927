import React from "react";

const DashboardCard = ({ img, alt, count, label }) => {
  return (
    <div className="dshStCard">
      <div className="imgCntnr">
        <img src={img} alt={alt} />
      </div>
      <div className="rtTxtIfo">
        <h2>{count}</h2>
        <label>{label}</label>
      </div>
    </div>
  );
};

export default DashboardCard;
