import { takeLatest, call, put } from "redux-saga/effects";
import DefectsActionTypes from "./DefectsActionTypes";
import { axiosGet, axiosPost, axiosPut } from "../../Utils/axios";
import { unauthorizedMessage } from "../Login/LoginActions";
import {
  getCommentHistoryResponse,
  getDefectsIndexResponse,
  getIndexDefectIndexResponse,
  getIndexDefectResponse,
  newDefectResponse,
  showDefectResponse,
  updateDefectResponse,
} from "./DefectsAction";
import axios from "axios";

// WORKER SAGA
// DEFECTS
// GET ALL DEFECTS
export function* onGetDefectsIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_defects", payload).then((response) => response?.data)
    );
    yield put(getDefectsIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR DEFECT
export function* onViewDefectWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`defect/${payload}`).then((response) => response?.data)
    );
    yield put(showDefectResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE DEFECTS

export function* onUpdateDefectWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`defect/${payload.id}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(updateDefectResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//commenthistory
export function* onCommentHistoryWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`defect/${payload}/get_history`, payload).then(
        (response) => response?.data
      )
    );
    yield put(getCommentHistoryResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// new DEFECTS
export function* onNewDefectWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost(`defect/new`, payload).then((response) => response?.data)
    );
    yield put(newDefectResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//indexSaga
export function* onIndexDefectWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("get_defects", payload).then((response) => response?.data)
    );
    yield put(getIndexDefectResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* DefectsWatcherSaga() {
  yield takeLatest(
    DefectsActionTypes.INDEX_DEFECT_REQUEST,
    onIndexDefectWorkerSaga
  );
  yield takeLatest(
    DefectsActionTypes.GET_DEFECTS_INDEX_REQUEST,
    onGetDefectsIndexWorkerSaga
  );
  yield takeLatest(
    DefectsActionTypes.NEW_DEFECT_REQUEST,
    onNewDefectWorkerSaga
  );
  yield takeLatest(
    DefectsActionTypes.COMMENT_HISTORY_REQUEST,
    onCommentHistoryWorkerSaga
  );
  yield takeLatest(
    DefectsActionTypes.SHOW_DEFECT_REQUEST,
    onViewDefectWorkerSaga
  );
  yield takeLatest(
    DefectsActionTypes.UPDATE_DEFECT_REQUEST,
    onUpdateDefectWorkerSaga
  );
}
