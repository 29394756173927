import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCommentHistoryRequest,
  postDefectResponseClear,
  showDefectRequest,
  updateDefectRequest,
} from "../../Redux/DefectsSaga/DefectsAction";
import "./Defects.css";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { severity, defectPriority, defectStatus } from "../../Utils/constants";
import { convertedString, handleToastResponse } from "../../Utils/handles";
import CustomCancelSubmit from "../../Components/Molecules/CancelSubmit/CancelSubmit";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";

const ViewDefect = () => {
  const navigate = useNavigate();
  const { defectid } = useParams();

  const dispatch = useDispatch();

  const { defect, updateDefectResponse, commentHistory, updateDefectLoading } =
    useSelector((state) => state.defect);

  const formik = useFormik({
    initialValues: {
      status: "",
      priority: "",
      severity: "",
      comment: "",
    },
    validationSchema: Yup.object().shape({
      comment: Yup.string()
        .required("Comment is required!")
        .min(3, "Minimum of 3 characters")
        .max(250, "Maximum of 250 characters"),
    }),
    onSubmit: (values) => {
      dispatch(updateDefectRequest({ ...values, id: defectid }));
    },
  });

  useEffect(() => {
    if (defect) {
      for (let key in formik.values) {
        if (key !== "comment") {
          formik.setFieldValue(key, defect?.[key]);
        }
      }
    }
  }, [defect]);

  // GET DEFECT ON INITIAL RENDER
  useEffect(() => {
    if (updateDefectLoading) return;
    dispatch(showDefectRequest(defectid));
    dispatch(getCommentHistoryRequest(defectid));
  }, [updateDefectLoading]);

  // TOAST MESSAGE FOR UPDATE DEFECT
  useEffect(() => {
    handleToastResponse(updateDefectResponse);
    if (updateDefectResponse?.success) {
      formik.resetForm();
    }
    return () => {
      dispatch(postDefectResponseClear());
    };
  }, [updateDefectResponse]);

  // ARRAY TO MAP THE DEFECT DATA
  const defectDetails = [
    { label: "Defect ID", value: `DF-${defect?.id}` },
    {
      label: "Fixed by",
      value: defect?.fixed_by_fk?.full_name || "_",
    },
    { label: "Test Step", value: `TS-${defect?.test_step_fk}` },
    {
      label: "Created by",
      value: defect?.created_by_fk?.full_name,
    },
    { label: "Summary", value: defect?.summary || "_" },
    {
      label: "Created at",
      value: moment(defect?.updated_at).format("DD.MM.YYYY hh:mm:ss A"),
    },
  ];

  return (
    <div className="view_execution_container">
      <div className="dashboard_container releaseLayout w-100 overflow-auto">
        <div className="position-relative">
          <div className="test_header ">
            <h3>Defect</h3>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            {defectDetails?.map((data, index) => (
              <div className="col-md-6 details_list" key={index}>
                <h3 className="defect_head">{data?.label}</h3>
                <p>:</p>
                <h3 className="defect_values">{data?.value}</h3>
              </div>
            ))}
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Status</h3>
              <p>:</p>
              <CustomSelect
                size="small"
                placeholder="Select Status"
                options={defectStatus.map((values, index) => {
                  return {
                    value: values.value,
                    name: values.name,
                  };
                })}
                name="status"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.status}
                formik={formik}
              />
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Priority</h3>
              <p>:</p>
              <CustomSelect
                size="small"
                placeholder="Select Priority"
                options={defectPriority.map((values, index) => {
                  return {
                    value: values.value,
                    name: values.name,
                  };
                })}
                name="priority"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.priority}
                formik={formik}
              />
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Severity</h3>
              <p>:</p>
              <CustomSelect
                size="small"
                placeholder="Select Severity"
                options={severity.map((values, index) => {
                  return {
                    value: values.value,
                    name: values.name,
                  };
                })}
                name="severity"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.severity}
                formik={formik}
              />
            </div>
          </div>
          <div>
            <CustomInput
              multiline
              rows={4}
              placeholder="Comment"
              type="text"
              name="comment"
              size="large"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.comment}
              formik={formik}
            />
          </div>
          <CustomCancelSubmit
            cancelLabel="Cancel"
            submitLabel="Save"
            handleClose={() => navigate(-1)}
          />
        </form>
        {commentHistory?.length > 0
          ? commentHistory?.map((comment) => (
              <div className="comment_box_container" key={comment?.comment_id}>
                <div className="comment_box">
                  <div className="comment_date_time ">
                    <p className="time_date">
                      {moment(comment?.updated_at).format(
                        "DD.MM.YYYY hh:mm:ss A"
                      )}
                    </p>
                  </div>
                  <div className="v-stepper">
                    <div className="dot"></div>
                    <div className="line"></div>
                  </div>
                  <div className={"comment_message_box redLine"}>
                    <div className="message-content">
                      <div className="message_hover">
                        <div className="comment_list mt-1">
                          <h3 className="comment_label">Status</h3>
                          <p>:</p>
                          <h3>{convertedString(comment?.status || "")}</h3>
                        </div>
                        <div className="comment_list mt-1">
                          <h3 className="comment_label">Comments</h3>
                          <p>:</p>
                          <h3> {comment?.comment || "_"}</h3>
                        </div>
                        <div className="comment_list mt-1">
                          <p className="comment_label">Updated By</p>
                          <p>:</p>
                          <p className="text_left">
                            {comment?.updated_by.full_name || "_"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : ""}
      </div>
    </div>
  );
};

export default ViewDefect;
