import React from "react";

const HistoryTable = ({ title, data }) => (
  <div className="flex-grow-1">
    <div className="tblWrpr">
      <div className="table-responsive">
        <table className="table" style={{ border: "1px solid #e5e5e5" }}>
          <thead>
            <tr>
              <th colSpan={3}>{title}</th>
            </tr>
            <tr>
              <th className="table_history_head">Step</th>
              <th className="table_history_head">Step Description</th>
              <th className="table_history_head">Expected Result</th>
            </tr>
          </thead>
          <tbody>
            {data?.test_steps?.map((row, index) => (
              <tr key={index}>
                <td>{row.step}</td>
                <td>{row.description}</td>
                <td>{row.expected_result}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default HistoryTable;
