import LoginActionTypes, {
  UNAUTHORIZED_ERROR,
  CLEAR_ERROR_RES,
} from "./LoginActionTypes";

const initialState = {
  loginLoading: false,
  loginResponse: null,

  logoutLoading: false,
  logoutResponse: null,

  forgotPasswordLoading: false,
  forgotPasswordResponse: false,

  changePasswordLoading: false,
  changePasswordResponse: false,

  changeOldPasswordLoading: false,
  changeOldPasswordResponse: false,

  user: null,
  userLoading: false,
  userResponse: null,
  userError: null,

  allUsers: null,
  allUsersLoading: false,
  allUsersResponse: null,

  allGroups: null,
  allGroupsLoading: false,
  allGroupsResponse: null,

  postPhotoLoading: false,
  postPhotoResponse: null,

  unauthorized: null,
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LoginActionTypes.LOGIN_REQUESTED:
      return {
        ...state,
        loginLoading: true,
      };
    case LoginActionTypes.LOGIN_RESPONSE:
      return {
        ...state,
        loginLoading: false,
        loginResponse: action.payload,
      };

    case LoginActionTypes.LOGIN_RES_CLEAR:
      return {
        ...state,
        loginLoading: false,
        loginResponse: null,
      };

    //LOGOUT
    case LoginActionTypes.LOGOUT_USER:
      return {
        ...state,
        user: null,
        userLoading: false,
        userResponse: null,
        userError: null,
      };

    case LoginActionTypes.LOGOUT_REQ:
      return {
        ...state,
        logoutLoading: true,
      };

    case LoginActionTypes.LOGOUT_RESPONSE:
      return {
        ...state,
        logoutLoading: false,
        logoutResponse: action.payload,
      };

    case LoginActionTypes.LOGOUT_RESPONSE_CLEAR:
      return {
        ...state,
        logoutLoading: false,
        logoutResponse: null,
      };

    //FORGOT PASSWORD

    case LoginActionTypes.FORGOT_PASSWORD_REQ:
      return {
        ...state,
        forgotPasswordLoading: true,
      };

    case LoginActionTypes.FORGOT_PASSWORD_RESPONSE:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordResponse: action.payload,
      };

    case LoginActionTypes.FORGOT_PASSWORD_RES_CLEAR:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordResponse: null,
      };

    //CAHANGE PASSWORD

    case LoginActionTypes.CHANGE_PASSWORD_REQ:
      return {
        ...state,
        changePasswordLoading: true,
      };

    case LoginActionTypes.CHANGE_PASSWORD_RESPONSE:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordResponse: action.payload,
      };

    case LoginActionTypes.CHANGE_PASSWORD_RES_CLEAR:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordResponse: null,
      };

    //CAHANGE OLD PASSWORD

    case LoginActionTypes.CHANGE_OLD_PASSWORD_REQ:
      return {
        ...state,
        changeOldPasswordLoading: true,
      };

    case LoginActionTypes.CHANGE_OLD_PASSWORD_RESPONSE:
      return {
        ...state,
        changeOldPasswordLoading: false,
        changeOldPasswordResponse: action.payload,
      };

    case LoginActionTypes.CHANGE_OLD_PASSWORD_RES_CLEAR:
      return {
        ...state,
        changeOldPasswordLoading: false,
        changeOldPasswordResponse: null,
      };

    //currentuser
    case LoginActionTypes.CURRENT_USER_REQUESTED:
      return {
        ...state,
        userLoading: true,
      };
    case LoginActionTypes.CURRENT_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userResponse: action.payload,
        user: action.payload?.data,
      };

    case LoginActionTypes.CURRENT_USER_RES_CLEAR:
      return {
        ...state,
        userResponse: null,
      };

    // ALL USERS
    case LoginActionTypes.ALL_USER_REQUESTED:
      return {
        ...state,
        allUsersLoading: true,
      };
    case LoginActionTypes.ALL_USER_SUCCESS:
      return {
        ...state,
        allUsersLoading: false,
        allUsersResponse: action.payload,
        allUsers: action.payload?.data,
      };

    case LoginActionTypes.ALL_USER_RES_CLEAR:
      return {
        ...state,
        allUsersResponse: null,
      };

    // ALL GROUPS
    case LoginActionTypes.ALL_GROUPS_REQUESTED:
      return {
        ...state,
        allGroupsLoading: true,
      };
    case LoginActionTypes.ALL_GROUPS_SUCCESS:
      return {
        ...state,
        allGroupsLoading: false,
        allGroupsResponse: action.payload,
        allGroups: action.payload?.data,
      };

    case LoginActionTypes.ALL_GROUPS_RES_CLEAR:
      return {
        ...state,
        allGroupsResponse: null,
      };

    // UPDATE PHOTO
    case LoginActionTypes.UPDATE_PROFILEPHOTO_REQUEST:
      return {
        ...state,
        postPhotoLoading: true,
      };
    case LoginActionTypes.UPDATE_PROFILEPHOTO_RESPONSE:
      return {
        ...state,
        postPhotoLoading: false,
        postPhotoResponse: action.payload,
      };
    case LoginActionTypes.UPDATE_PROFILEPHOTO_RES_CLEAR:
      return {
        ...state,
        postPhotoLoading: false,
        postPhotoResponse: action.payload,
      };

    case UNAUTHORIZED_ERROR:
      return {
        ...state,
        unauthorized: action.payload,
      };
    case CLEAR_ERROR_RES:
      return {
        ...state,
        loginLoading: false,
        loginResponse: null,

        logoutLoading: false,
        logoutResponse: null,

        forgotPasswordLoading: false,
        forgotPasswordResponse: false,

        changePasswordLoading: false,
        changePasswordResponse: false,

        changeOldPasswordLoading: false,
        changeOldPasswordResponse: false,

        user: null,
        userLoading: false,
        userResponse: null,
        userError: null,

        unauthorized: null,
      };
    default:
      return state;
  }
};

export default LoginReducer;
