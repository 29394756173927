import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getReportIndexRequest } from "../../Redux/ReportSaga/ReportAction";
import CustomCancelSubmit from "../../Components/Molecules/CancelSubmit/CancelSubmit";
import ReporFilterCheckBox from "./ReporFilterCheckBox";
import { convertedString } from "../../Utils/handles";

const ReportFilter = ({
  handleClose,
  type,
  checkboxValues,
  barCheckBoxValues,
  setBarCheckBoxValues,
  setCheckboxValues,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const pieCheckbox = Object.keys(checkboxValues || {}).filter(
    (key) => key !== "selectAll"
  );

  const barCheckbox = Object.keys(barCheckBoxValues || {}).filter(
    (key) => key !== "selectAll"
  );

  const handleSelectAll = (event) => {
    const { checked } = event.target;
    const checkboxes = type === "pie" ? pieCheckbox : barCheckbox;
    const setCheckboxFunction =
      type === "pie" ? setCheckboxValues : setBarCheckBoxValues;
    setCheckboxFunction({
      selectAll: checked,
      ...Object.fromEntries(checkboxes.map((checkbox) => [checkbox, checked])),
    });
  };

  const handleCheckboxChange = (label) => (event) => {
    const { checked } = event.target;
    if (type === "pie") {
      setCheckboxValues({
        ...checkboxValues,
        [label]: checked,
      });
      console.log("checkbox", checkboxValues);
    } else {
      setBarCheckBoxValues((prevValues) => ({
        ...prevValues,
        [label]: checked,
      }));
    }
  };

  useEffect(() => {
    const isAllChecked =
      checkboxValues.passed &&
      checkboxValues.in_progress &&
      checkboxValues.failed &&
      checkboxValues.unexecuted;
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      selectAll: isAllChecked,
    }));
  }, [
    checkboxValues.passed,
    checkboxValues.in_progress,
    checkboxValues.failed,
    checkboxValues.unexecuted,
  ]);

  useEffect(() => {
    const isAllChecked =
      barCheckBoxValues.new &&
      barCheckBoxValues.reopened &&
      barCheckBoxValues.suggestion &&
      barCheckBoxValues.not_a_bug &&
      barCheckBoxValues.on_hold &&
      barCheckBoxValues.closed &&
      barCheckBoxValues.fixed;
    setBarCheckBoxValues((prevValues) => ({
      ...prevValues,
      selectAll: isAllChecked,
    }));
  }, [
    barCheckBoxValues.new,
    barCheckBoxValues.reopened,
    barCheckBoxValues.suggestion,
    barCheckBoxValues.not_a_bug,
    barCheckBoxValues.on_hold,
    barCheckBoxValues.closed,
    barCheckBoxValues.fixed,
  ]);

  const getSelectedValues = () => {
    if (type === "pie") {
      const selectedValues = [];
      Object.entries(checkboxValues).forEach(([key, value]) => {
        if (value && key !== "selectAll") {
          selectedValues.push(key.toUpperCase());
        }
      });
      return selectedValues;
    } else {
      const barSelectedValues = [];
      Object.entries(barCheckBoxValues).forEach(([key, value]) => {
        if (value && key !== "selectAll") {
          barSelectedValues.push(key.toUpperCase());
        }
      });
      return barSelectedValues;
    }
  };

  const handlePreviousPieValue = () => {
    const selectedValues = [];
    Object.entries(checkboxValues).forEach(([key, value]) => {
      if (value && key !== "selectAll") {
        selectedValues.push(key.toUpperCase());
      }
    });
    return selectedValues;
  };

  const handlePreviousBarValue = () => {
    const barSelectedValues = [];
    Object.entries(barCheckBoxValues).forEach(([key, value]) => {
      if (value && key !== "selectAll") {
        barSelectedValues.push(key.toUpperCase());
      }
    });
    return barSelectedValues;
  };

  const handleFilterSubmit = () => {
    dispatch(
      getReportIndexRequest({
        project_id: id,
        test_run_report: {
          required_statuses:
            type === "pie" ? getSelectedValues() : handlePreviousPieValue(),
        },
        defect_report: {
          required_statuses:
            type === "pie" ? handlePreviousBarValue() : getSelectedValues(),
        },
      })
    );
    handleClose();
  };

  return (
    <>
      <div className="report_checkbox_container">
        <ReporFilterCheckBox
          label="Select All"
          value={
            type === "pie"
              ? checkboxValues.selectAll
              : barCheckBoxValues.selectAll
          }
          handleChange={handleSelectAll}
        />
        <div className="flex_column gap-3 child_checkbox">
          {type === "pie"
            ? pieCheckbox.map((data) => (
                <ReporFilterCheckBox
                  value={checkboxValues[data]}
                  label={convertedString(data)}
                  handleChange={handleCheckboxChange(data)}
                />
              ))
            : barCheckbox.map((data) => (
                <ReporFilterCheckBox
                  value={barCheckBoxValues[data]}
                  label={convertedString(data)}
                  handleChange={handleCheckboxChange(data)}
                />
              ))}
        </div>
      </div>
      <CustomCancelSubmit
        cancelLabel="Cancel"
        submitLabel="Filter"
        handleClose={handleClose}
        handleSubmit={handleFilterSubmit}
        type={false}
      />
    </>
  );
};

export default ReportFilter;
