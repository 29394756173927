import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import { Toaster } from "react-hot-toast";
import Router from "./Router";
import { setAuthToken } from "./Utils/axios";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    setAuthToken(localStorage.token);
  }, [localStorage]);
  return (
    <div className="App">
      <Toaster
        position="top center"
        toastOptions={{
          style: {
            wordBreak: "break-word",
          },
        }}
      />
      <HelmetProvider>
        <Router />
      </HelmetProvider>
    </div>
  );
}

export default App;
