export default {
  RELEASE_INDEX_REQUEST: "RELEASE_INDEX_REQUEST",
  RELEASE_INDEX_RESPONSE: "RELEASE_INDEX_RESPONSE",

  POST_RELEASE_REQUEST: "POST_RELEASE_REQUEST",
  POST_RELEASE_RESPONSE: "POST_RELEASE_RESPONSE",

  UPDATE_RELEASE_REQUEST: "UPDATE_RELEASE_REQUEST",
  UPDATE_RELEASE_RESPONSE: "UPDATE_RELEASE_RESPONSE",

  POST_RELEASE_RES_CLEAR: "POST_RELEASE_RES_CLEAR",

  DELETE_RELEASE_REQUEST: "DELETE_RELEASE_REQUEST",
  DELETE_RELEASE_RESPONSE: "DELETE_RELEASE_RESPONSE",
  DELETE_RELEASE_RES_CLEAR: "DELETE_RELEASE_RES_CLEAR",

  SHOW_RELEASE_REQUEST: "SHOW_RELEASE_REQUEST",
  SHOW_RELEASE_RESPONSE: "SHOW_RELEASE_RESPONSE",
  SHOW_RELEASE_RES_CLEAR: "SHOW_RELEASE_RES_CLEAR",
};
