import { takeLatest, call, put } from "redux-saga/effects";
import IntegrationActionTypes from "./IntegrationActionTypes";
import { axiosGet, axiosPost, axiosPut } from "../../Utils/axios";
import { unauthorizedMessage } from "../Login/LoginActions";
import {
  getApplicationsIndexResponse,
  postIntegrationResponse,
  showIntegrationResponse,
  updateIntegrationResponse,
} from "./IntegrationAction";

// WORKER SAGA
// APPLICATION
// GET ALL APPLICATIONS
export function* onGetApplicationsIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_applications", payload).then((response) => response?.data)
    );
    yield put(getApplicationsIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// INTEGRATION
// POST INTEGRATION
export function* onPostIntegrateWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("integration/new", payload).then((response) => response?.data)
    );
    yield put(postIntegrationResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE INTEGRATION
export function* onUpdateIntegrateWorkerSaga({ payload }) {
  const { id, ...newPayload } = payload;
  try {
    const response = yield call(() =>
      axiosPut(`integration/${id}`, newPayload).then(
        (response) => response?.data
      )
    );
    yield put(updateIntegrationResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR INTEGRATION
export function* onViewIntegrationWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`integration/${payload}`).then((response) => response?.data)
    );
    yield put(showIntegrationResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* IntegrationWatcherSaga() {
  // APPLICATION
  yield takeLatest(
    IntegrationActionTypes.GET_APPLICATION_INDEX_REQUEST,
    onGetApplicationsIndexWorkerSaga
  );

  // INTEGRATION
  yield takeLatest(
    IntegrationActionTypes.POST_INTEGRATION_REQUEST,
    onPostIntegrateWorkerSaga
  );
  yield takeLatest(
    IntegrationActionTypes.UPDATE_INTEGRATION_REQUEST,
    onUpdateIntegrateWorkerSaga
  );
  yield takeLatest(
    IntegrationActionTypes.SHOW_INTEGRATION_REQUEST,
    onViewIntegrationWorkerSaga
  );
}
