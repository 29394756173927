export default {
  GET_APPROVED_TESTCASES_INDEX_REQUEST: "GET_APPROVED_TESTCASES_INDEX_REQUEST",
  GET_APPROVED_TESTCASES_INDEX_RESPONSE:
    "GET_APPROVED_TESTCASES_INDEX_RESPONSE",

  POST_APPROVED_TESTCASES_REQUEST: "POST_APPROVED_TESTCASES_REQUEST",
  POST_APPROVED_TESTCASES_RESPONSE: "POST_APPROVED_TESTCASES_RESPONSE",
  POST_APPROVED_TESTCASES_RES_CLEAR: "POST_APPROVED_TESTCASES_RES_CLEAR",

  DELETE_APPROVED_TESTCASE_REQUEST: "DELETE_APPROVED_TESTCASE_REQUEST",
  DELETE_APPROVED_TESTCASE_RESPONSE: "DELETE_APPROVED_TESTCASE_RESPONSE",
  DELETE_APPROVED_TESTCASE_RES_CLEAR: "DELETE_APPROVED_TESTCASE_RES_CLEAR",

  GET_ADDED_TESTCASES_INDEX_REQUEST: "GET_ADDED_TESTCASES_INDEX_REQUEST",
  GET_ADDED_TESTCASES_INDEX_RESPONSE: "GET_ADDED_TESTCASES_INDEX_RESPONSE",

  TESTCASE_RUN_REQUEST: "TESTCASE_RUN_REQUEST",
  TESTSCASE_RUN_RESPONSE: "TESTSCASE_RUN_RESPONSE",
  TESTCASE_RUN_RESCLEAR: "TESTCASE_RUN_RESCLEAR",

  GETRUN_RESULT_REQUEST: "GETRUN_RESULT_REQUEST",
  GETRUN_RESULT_SUCCESS: "GETRUN_RESULT_SUCCESS",

  SAVE_DEFECTS_START: "SAVE_DEFECTS_START",
  SAVE_DEFECTS_SUCCESS: "SAVE_DEFECTS_SUCCESS",
  SAVE_DEFECTS_RESCLEAR: "SAVE_DEFECTS_RESCLEAR",

  SUBMITE_DEFECT_START: "SUBMITE_DEFECT_START",
  SUMBIT_DEFECT_SUCCESS: "SUMBIT_DEFECT_SUCCESS",
  SUBMIT_DEFECT_RESCLEAR: "SUBMIT_DEFECT_RESCLEAR",

  GET_DEFECTLIST_REQUEST: "GET_DEFECTLIST_REQUEST",
  GET_DEFECTLIST_RESPONSE: "GET_DEFECTLIST_RESPONSE",
  GET_DEFECTLIST_RESCLEAR: "GET_DEFECTLIST_RESCLEAR",

  GET_BUG_REQUEST: "GET_BUG_REQUEST",
  GET_BUG_SUCCESS: "GET_BUG_SUCCESS",
  GET_BUG_RESCLEAR: "GET_BUG_RESCLEAR",

  EDIT_BUG_REQUEST: "EDIT_BUG_REQUEST",
  EDIT_BUG_SUCCESS: "EDIT_BUG_SUCCESS",
  EDIT_BUG_RESCLEAR: "EDIT_BUG_RESCLEAR",

  GET_RUNNING_TESTCASES_REQUEST: "GET_RUNNING_TESTCASES_REQUEST",
  GET_RUNNING_TESTCASES_RESPONSE: "GET_RUNNING_TESTCASES_RESPONSE",

  SHOW_RUNNING_TESTCASES_REQUEST: "SHOW_RUNNING_TESTCASES_REQUEST",
  SHOW_RUNNING_TESTCASES_RESPONSE: "SHOW_RUNNING_TESTCASES_RESPONSE",
  SHOW_RUNNING_TESTCASE_RES_CLEAR: "SHOW_RUNNING_TESTCASE_RES_CLEAR",

  GET_ALL_ATTACHMENTS_INDEX_REQUEST: "GET_ALL_ATTACHMENTS_INDEX_REQUEST",
  GET_ALL_ATTACHMENTS_INDEX_RESPONSE: "GET_ALL_ATTACHMENTS_INDEX_RESPONSE",

  CANCEL_EXECUTION_REQUEST: "CANCEL_EXECUTION_REQUEST",
  CANCEL_EXECUTION_RESPONSE: "CANCEL_EXECUTION_RESPONSE",
  CANCEL_EXECUTION_RES_CLEAR: "CANCEL_EXECUTION_RES_CLEAR",

  GET_EXECUTION_BYID_REQUEST: "GET_EXECUTION_BYID_REQUEST",
  GET_EXECUTION_BYID_RESPONSE: "GET_EXECUTION_BYID_RESPONSE",
  GET_EXECUTION_BYID_RESCLEAR: "GET_EXECUTION_BYID_RESCLEAR",

  VIEW_TESTEXECUTION_BYID_REQUST: "VIEW_TESTEXECUTION_BYID_REQUST",
  VIEW_TESTEXECUTION_BYID_RESPONSE: "VIEW_TESTEXECUTION_BYID_RESPONSE",
};
