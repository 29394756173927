import TestExecutionActionTypes from "./TestExecutionActionTypes";

// GET APPROVED TESTCASES
export const getApprovedTestCaseIndexRequest = (payload) => ({
  type: TestExecutionActionTypes.GET_APPROVED_TESTCASES_INDEX_REQUEST,
  payload,
});

export const getApprovedTestCaseIndexResponse = (payload) => ({
  type: TestExecutionActionTypes.GET_APPROVED_TESTCASES_INDEX_RESPONSE,
  payload,
});

// GET ADDDED TESTCASES IN EXECUTION
export const getAddedTestCaseIndexRequest = (payload) => ({
  type: TestExecutionActionTypes.GET_ADDED_TESTCASES_INDEX_REQUEST,
  payload,
});

export const getAddedTestCaseIndexResponse = (payload) => ({
  type: TestExecutionActionTypes.GET_ADDED_TESTCASES_INDEX_RESPONSE,
  payload,
});

// POST APPROVED TESTCASE IN EXECUTION
export const postTestCaseAddRequest = (payload) => ({
  type: TestExecutionActionTypes.POST_APPROVED_TESTCASES_REQUEST,
  payload,
});

export const postTestCaseAddResponse = (payload) => ({
  type: TestExecutionActionTypes.POST_APPROVED_TESTCASES_RESPONSE,
  payload,
});

// POST APPROVED TESTCASE ADDED RESPONSE CLEAR
export const postTestCaseAddResponseClear = () => ({
  type: TestExecutionActionTypes.POST_APPROVED_TESTCASES_RES_CLEAR,
});

// DELETE ADDED TESTCASES IN EXECUTION
export const deleteAddedTestCaseRequest = (payload) => ({
  type: TestExecutionActionTypes.DELETE_APPROVED_TESTCASE_REQUEST,
  payload,
});

export const deleteAddedTestCaseResponse = (payload) => ({
  type: TestExecutionActionTypes.DELETE_APPROVED_TESTCASE_RESPONSE,
  payload,
});

export const deleteAddedTestCaseResponseClear = () => ({
  type: TestExecutionActionTypes.DELETE_APPROVED_TESTCASE_RES_CLEAR,
});

//RUNTESTCASE

export const runTestCaseRequest = (payload) => ({
  type: TestExecutionActionTypes.TESTCASE_RUN_REQUEST,
  payload,
});

export const runTestCaseResponse = (payload) => ({
  type: TestExecutionActionTypes.TESTSCASE_RUN_RESPONSE,
  payload,
});

export const runTestCaseResponseClear = () => ({
  type: TestExecutionActionTypes.TESTCASE_RUN_RESCLEAR,
});

//runresult
export const runTestCaseResultRequest = (payload) => ({
  type: TestExecutionActionTypes.GETRUN_RESULT_REQUEST,
  payload,
});

export const runTestCaseResultResponse = (payload) => ({
  type: TestExecutionActionTypes.GETRUN_RESULT_SUCCESS,
  payload,
});

//SAVETESTCASE

export const saveDefectsRequest = (payload) => ({
  type: TestExecutionActionTypes.SAVE_DEFECTS_START,
  payload,
});

export const saveDefectsResponse = (payload) => ({
  type: TestExecutionActionTypes.SAVE_DEFECTS_SUCCESS,
  payload,
});
export const saveDefectsResponseClear = () => ({
  type: TestExecutionActionTypes.SAVE_DEFECTS_RESCLEAR,
});

//SUBMIT

export const submitDefectsRequest = (payload) => ({
  type: TestExecutionActionTypes.SUBMITE_DEFECT_START,
  payload,
});

export const submitDefectsResponse = (payload) => ({
  type: TestExecutionActionTypes.SUMBIT_DEFECT_SUCCESS,
  payload,
});
export const submitDefectsResponseClear = () => ({
  type: TestExecutionActionTypes.SUBMIT_DEFECT_RESCLEAR,
});

//get bug
export const getBugRequest = (payload) => ({
  type: TestExecutionActionTypes.GET_BUG_REQUEST,
  payload,
});

export const getBugResponse = (payload) => ({
  type: TestExecutionActionTypes.GET_BUG_SUCCESS,
  payload,
});
export const getBugResponseClear = (payload) => ({
  type: TestExecutionActionTypes.GET_BUG_RESCLEAR,
  payload,
});

//edit bug
export const editBugRequest = (payload) => ({
  type: TestExecutionActionTypes.EDIT_BUG_REQUEST,
  payload,
});

export const editBugResponse = (payload) => ({
  type: TestExecutionActionTypes.EDIT_BUG_SUCCESS,
  payload,
});
export const editBugResponseClear = () => ({
  type: TestExecutionActionTypes.EDIT_BUG_RESCLEAR,
});

// VIEW ALL RUNNING TESTCASES
export const getRunningTestCaseIndexRequest = (payload) => ({
  type: TestExecutionActionTypes.GET_RUNNING_TESTCASES_REQUEST,
  payload,
});

export const getRunningTestCaseIndexResponse = (payload) => ({
  type: TestExecutionActionTypes.GET_RUNNING_TESTCASES_RESPONSE,
  payload,
});

// VIEW PARTICULAR RUNNING TESTCASES

export const showRunningTestCaseRequest = (payload) => ({
  type: TestExecutionActionTypes.SHOW_RUNNING_TESTCASES_REQUEST,
  payload,
});

export const showRunningTestCaseResponse = (payload) => ({
  type: TestExecutionActionTypes.SHOW_RUNNING_TESTCASES_RESPONSE,
  payload,
});

export const showRunningTestCaseResClear = () => ({
  type: TestExecutionActionTypes.SHOW_RUNNING_TESTCASE_RES_CLEAR,
});

// VIEW ALL UPLOADED  ATTACHMENTS
export const getAllAttachmentsIndexRequest = (payload) => ({
  type: TestExecutionActionTypes.GET_ALL_ATTACHMENTS_INDEX_REQUEST,
  payload,
});

export const getAllAttachmentsIndexResponse = (payload) => ({
  type: TestExecutionActionTypes.GET_ALL_ATTACHMENTS_INDEX_RESPONSE,
  payload,
});
//DEFECTLIST

// VIEW ALL UPLOADED  ATTACHMENTS
export const getAllDefectListRequest = (payload) => ({
  type: TestExecutionActionTypes.GET_DEFECTLIST_REQUEST,
  payload,
});

export const getAllDefectListResponse = (payload) => ({
  type: TestExecutionActionTypes.GET_DEFECTLIST_RESPONSE,
  payload,
});

export const getDefectListResClear = () => ({
  type: TestExecutionActionTypes.GET_DEFECTLIST_RESCLEAR,
});

// CANCEL EXECUTION
export const cancelExecutionRequest = (payload) => ({
  type: TestExecutionActionTypes.CANCEL_EXECUTION_REQUEST,
  payload,
});

export const cancelExecutionResponse = (payload) => ({
  type: TestExecutionActionTypes.CANCEL_EXECUTION_RESPONSE,
  payload,
});

export const cancelExecutionResClear = () => ({
  type: TestExecutionActionTypes.CANCEL_EXECUTION_RES_CLEAR,
});

//EXECUTION BY ID
export const getExecutionByIdRequest = (payload) => ({
  type: TestExecutionActionTypes.GET_EXECUTION_BYID_REQUEST,
  payload,
});

export const getExecutionByIdResponse = (payload) => ({
  type: TestExecutionActionTypes.GET_EXECUTION_BYID_RESPONSE,
  payload,
});

export const getExecutionByIdResponseClear = () => ({
  type: TestExecutionActionTypes.GET_EXECUTION_BYID_RESCLEAR,
});

//VIEW EXECUTION BY ID
export const viewExecutionByIdRequest = (payload) => ({
  type: TestExecutionActionTypes.VIEW_TESTEXECUTION_BYID_REQUST,
  payload,
});

export const viewExecutionByIdResponse = (payload) => ({
  type: TestExecutionActionTypes.VIEW_TESTEXECUTION_BYID_RESPONSE,
  payload,
});
