export default {
  REGISTER_USER_REQUEST: "REGISTER_USER_REQUEST",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_RESCLEAR: "REGISTER_USER_RESCLEAR",

  OTP_REQUEST: "OTP_REQUEST",
  OTP_SUCCESS: "OTP_SUCCESS",
  OTP_RES_CLEAR: "OTP_RES_CLEAR",

  // DEPARTMENT LIST
  GET_DEPARTMENTLIST_REQUEST: "GET_DEPARTMENTLIST_REQUEST",
  GET_DEPARTMENTLIST_RESPONSE: "GET_DEPARTMENTLIST_RESPONSE",
  GET_DEPARTMENTLIST_RES_CLEAR: "GET_DEPARTMENTLIST_RES_CLEAR",

  // COUNTRY LIST
  GET_COUNTRYLIST_REQUEST: "GET_COUNTRYLIST_REQUEST",
  GET_COUNTRYLIST_RESPONSE: "GET_COUNTRYLIST_RESPONSE",
  GET_COUNTRYLIST_RES_CLEAR: "GET_COUNTRYLIST_RES_CLEAR",

  // STATE LIST BASED ON COUNTRY
  GET_STATELIST_REQUEST: "GET_STATELIST_REQUEST",
  GET_STATELIST_RESPONSE: "GET_STATELIST_RESPONSE",
  GET_STATELIST_RES_CLEAR: "GET_STATELIST_RES_CLEAR",

  // CITY BASED ON COUNTRY AND STATE
  GET_CITYLIST_REQUEST: "GET_CITYLIST_REQUEST",
  GET_CITYLIST_RESPONSE: "GET_CITYLIST_RESPONSE",
  GET_CITYLIST_RES_CLEAR: "GET_CITYLIST_RES_CLEAR",
};
