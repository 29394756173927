import TaskActionTypes from "./TaskActionTypes";

// GET ALL TASKS
export const getTasksIndexRequest = (payload) => ({
  type: TaskActionTypes.GET_TASK_INDEX_REQUEST,
  payload,
});

export const getTasksIndexResponse = (payload) => ({
  type: TaskActionTypes.GET_TASK_INDEX_RESPONSE,
  payload,
});

// POST TASK
export const postTaskRequest = (payload) => ({
  type: TaskActionTypes.POST_TASK_REQUEST,
  payload,
});

export const postTaskResponse = (payload) => ({
  type: TaskActionTypes.POST_TASK_RESPONSE,
  payload,
});

// EDIT TASK
export const updateTaskRequest = (payload) => ({
  type: TaskActionTypes.EDIT_TASK_REQUEST,
  payload,
});

export const updateTaskResponse = (payload) => ({
  type: TaskActionTypes.EDIT_TASK_RESPONSE,
  payload,
});

// POST & EDIT TASK RES CLEAR
export const postTaskResClear = () => ({
  type: TaskActionTypes.POST_TASK_RES_CLEAR,
});

// GET TASK
export const showTaskRequest = (payload) => ({
  type: TaskActionTypes.SHOW_TASK_REQUEST,
  payload,
});

export const showTaskResponse = (payload) => ({
  type: TaskActionTypes.SHOW_TASK_RESPONSE,
  payload,
});

export const showTaskResClear = () => ({
  type: TaskActionTypes.SHOW_TASK_RES_CLEAR,
});

// GET TASK CHOICES
export const getTaskChoicesIndexRequest = (payload) => ({
  type: TaskActionTypes.GET_TASKCHOICES_REQUEST,
  payload,
});

export const getTaskChoicesIndexResponse = (payload) => ({
  type: TaskActionTypes.GET_TASKCHOICES_RESPONSE,
  payload,
});
