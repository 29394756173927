import NotificationTypes from "./NotificationActionTypes";

export const notificationsRequest = () => ({
  type: NotificationTypes.GET_NOTIFICATIONS_REQUEST,
});

export const notificationsResponse = (payload) => ({
  type: NotificationTypes.GET_NOTIFICATIONS_RESPONSE,
  payload,
});

export const notificationsResClear = () => ({
  type: NotificationTypes.GET_NOTIFICATIONS_RES_CLEAR,
});

//  CLEAR NOTIFICATION
export const clearnotificationsRequest = (payload) => ({
  type: NotificationTypes.CLEAR_NOTIFICATIONS_REQUEST,
  payload: payload,
});

export const clearnotificationsResponse = (payload) => ({
  type: NotificationTypes.CLEAR_NOTIFICATIONS_RESPONSE,
  payload: payload,
});

export const clearnotificationsResClear = () => ({
  type: NotificationTypes.CLEAR_NOTIFICATIONS_RES_CLEAR,
});
