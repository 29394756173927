import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Close, Height, SaveAlt } from "@mui/icons-material";
import { handleAttachmentName, handleDownloadFile } from "../../Utils/handles";
import { appUrl } from "../../Utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { getAllAttachmentsIndexRequest } from "../../Redux/TestExecution/TestExecutionAction";
import "./TestExecutionTab.css";

export default function ViewTestCaseAttachments() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [viewAttachment, setViewAttachment] = useState({ id: "" });
  const [resize, setResize] = useState(false);

  const { testCaseRunningAttachmentsList } = useSelector(
    (state) => state.testExecution
  );

  // GET ALL RUNNING TESTCASE ATTACHMENTS
  useEffect(() => {
    dispatch(
      getAllAttachmentsIndexRequest({
        project_id: id,
      })
    );
  }, []);

  const handleViewAttachment = (id) => {
    if (id === viewAttachment?.id) {
      setViewAttachment({ id: "" });
    } else {
      setViewAttachment({ ...viewAttachment, id: id });
      setResize(false);
    }
  };

  return (
    <div className="testcase_attachment_container">
      <div className="p-1 bg-white">
        <div className="tblWrpr">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>SI.No</th>

                  <th>Name</th>
                  <th className="attachment_uploaded_date">Date</th>
                </tr>
              </thead>
              <tbody>
                {testCaseRunningAttachmentsList?.data?.length > 0 ? (
                  testCaseRunningAttachmentsList?.data
                    ?.filter((data) => data?.attachment !== null)
                    .map((data, index) => (
                      <>
                        <tr key={data?.id}>
                          <td>{index + 1}</td>
                          <td
                            className={
                              viewAttachment?.id === data?.id
                                ? "color_attachment clickable"
                                : "clickable"
                            }
                            onClick={() =>
                              handleViewAttachment(data?.id, data?.attachment)
                            }
                          >
                            {handleAttachmentName(data?.attachment, "/")}
                          </td>
                          <td className="attachment_uploaded_date">
                            {moment(data?.log_uploaded_at).format(
                              "DD/MM/YYYY"
                            ) || ""}
                          </td>
                        </tr>
                        <td colSpan={3}>
                          {viewAttachment?.id === data?.id && (
                            <div className="attachment_view_container">
                              <div
                                className={
                                  resize
                                    ? "attachment_resize_view"
                                    : "attachment_view"
                                }
                              >
                                <div className="d-flex flex-wrap align-items-center justify-content-between mb-2">
                                  <h3>
                                    {handleAttachmentName(
                                      data?.attachment,
                                      "/"
                                    )}
                                  </h3>
                                  <div className="d-flex gap-2">
                                    <div
                                      className="attachments_icons"
                                      onClick={() => setResize(!resize)}
                                    >
                                      <Height className="resize_icon" />
                                    </div>
                                    <div
                                      className="attachments_icons"
                                      onClick={() => {
                                        handleDownloadFile(
                                          `${appUrl}${data?.attachment}`
                                        );
                                      }}
                                    >
                                      <SaveAlt />
                                    </div>
                                    <div
                                      className="attachments_icons"
                                      onClick={() =>
                                        setViewAttachment({ id: null })
                                      }
                                    >
                                      <Close />
                                    </div>
                                  </div>
                                </div>
                                <div className="attachment_photo">
                                  {data && data.attachment ? (
                                    <>
                                      {data.attachment
                                        .toLowerCase()
                                        .endsWith(".jpg") ||
                                      data.attachment
                                        .toLowerCase()
                                        .endsWith(".jpeg") ||
                                      data.attachment
                                        .toLowerCase()
                                        .endsWith(".png") ||
                                      data.attachment
                                        .toLowerCase()
                                        .endsWith(".gif") ? (
                                        <img
                                          src={`${appUrl}${data.attachment}`}
                                          alt="attachment_images"
                                        />
                                      ) : (
                                        <p>
                                          {data.attachment
                                            .toLowerCase()
                                            .endsWith(".pdf")
                                            ? "PDF"
                                            : "Excel"}
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <p>No attachment found</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                      </>
                    ))
                ) : (
                  <tr>
                    <td colSpan={3}>No Attachments Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
