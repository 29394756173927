export default {
  // TESTDESIGN MODULE
  GET_TESTDESIGNMODULE_INDEX_REQUEST: "GET_TESTDESIGNMODULE_INDEX_REQUEST",
  GET_TESTDESIGNMODULE_INDEX_RESPONSE: "GET_TESTDESIGNMODULE_INDEX_RESPONSE",

  POST_TESTDESIGNMODULE_REQUEST: "POST_TESTDESIGNMODULE_REQUEST",
  POST_TESTDESIGNMODULE_RESPONSE: "POST_TESTDESIGNMODULE_RESPONSE",

  UPDATE_TESTDESIGNMODULE_REQUEST: "UPDATE_TESTDESIGNMODULE_REQUEST",
  UPDATE_TESTDESIGNMODULE_RESPONSE: "UPDATE_TESTDESIGNMODULE_RESPONSE",

  POST_TESTDESIGNMODULE_RES_CLEAR: "POST_TESTDESIGNMODULE_RES_CLEAR",

  DELETE_TESTDESIGNMODULE_REQUEST: "DELETE_TESTDESIGNMODULE_REQUEST",
  DELETE_TESTDESIGNMODULE_RESPONSE: "DELETE_TESTDESIGNMODULE_RESPONSE",
  DELETE_TESTDESIGNMODULE_RES_CLEAR: "DELETE_TESTDESIGNMODULE_RES_CLEAR",

  SHOW_TESTDESIGNMODULE_REQUEST: "SHOW_TESTDESIGNMODULE_REQUEST",
  SHOW_TESTDESIGNMODULE_RESPONSE: "SHOW_TESTDESIGNMODULE_RESPONSE",
  SHOW_TESTDESIGNMODULE_RES_CLEAR: "SHOW_TESTDESIGNMODULE_RES_CLEAR",

  // TESTDESIGN DETAIL
  GET_TESTDESIGNDETAIL_INDEX_REQUEST: "GET_TESTDESIGNDETAIL_INDEX_REQUEST",
  GET_TESTDESIGNDETAIL_INDEX_RESPONSE: "GET_TESTDESIGNDETAIL_INDEX_RESPONSE",

  POST_TESTDESIGNDETAIL_REQUEST: "POST_TESTDESIGNDETAIL_REQUEST",
  POST_TESTDESIGNDETAIL_RESPONSE: "POST_TESTDESIGNDETAIL_RESPONSE",

  UPDATE_TESTDESIGNDETAIL_REQUEST: "UPDATE_TESTDESIGNDETAIL_REQUEST",
  UPDATE_TESTDESIGNDETAIL_RESPONSE: "UPDATE_TESTDESIGNDETAIL_RESPONSE",

  POST_TESTDESIGNDETAIL_RES_CLEAR: "POST_TESTDESIGNDETAIL_RES_CLEAR",

  DELETE_TESTDESIGNDETAIL_REQUEST: "DELETE_TESTDESIGNDETAIL_REQUEST",
  DELETE_TESTDESIGNDETAIL_RESPONSE: "DELETE_TESTDESIGNDETAIL_RESPONSE",
  DELETE_TESTDESIGNDETAIL_RES_CLEAR: "DELETE_TESTDESIGNDETAIL_RES_CLEAR",

  SHOW_TESTDESIGNDETAIL_REQUEST: "SHOW_TESTDESIGNDETAIL_REQUEST",
  SHOW_TESTDESIGNDETAIL_RESPONSE: "SHOW_TESTDESIGNDETAIL_RESPONSE",
  SHOW_TESTDESIGNDETAIL_RES_CLEAR: "SHOW_TESTDESIGNDETAIL_RES_CLEAR",

  // TESTDESIGN DETAIL ATTACHMENTS
  GET_TESTDESIGNATTACH_INDEX_REQUEST: "GET_TESTDESIGNATTACH_INDEX_REQUEST",
  GET_TESTDESIGNATTACH_INDEX_RESPONSE: "GET_TESTDESIGNATTACH_INDEX_RESPONSE",
  GET_TESTDESIGNATTACH_INDEX_RES_CLEAR: "GET_TESTDESIGNATTACH_INDEX_RES_CLEAR",

  POST_TESTDESIGNATTACHMENT_REQUEST: "POST_TESTDESIGNATTACHMENT_REQUEST",
  POST_TESTDESIGNATTACHMENT_RESPONSE: "POST_TESTDESIGNATTACHMENT_RESPONSE",

  UPDATE_TESTDESIGNATTACHMENT_REQUEST: "UPDATE_TESTDESIGNATTACHMENT_REQUEST",
  UPDATE_TESTDESIGNATTACHMENT_RESPONSE: "UPDATE_TESTDESIGNATTACHMENT_RESPONSE",

  POST_TESTDESIGNATTACHMENT_RES_CLEAR: "POST_TESTDESIGNATTACHMENT_RES_CLEAR",

  DELETE_TESTDESIGNATTACHMENT_REQUEST: "DELETE_TESTDESIGNATTACHMENT_REQUEST",
  DELETE_TESTDESIGNATTACHMENT_RESPONSE: "DELETE_TESTDESIGNATTACHMENT_RESPONSE",
  DELETE_TESTDESIGNATTACH_RES_CLEAR: "DELETE_TESTDESIGNATTACH_RES_CLEAR",

  SHOW_TESTDESIGNATTACHMENT_REQUEST: "SHOW_TESTDESIGNATTACHMENT_REQUEST",
  SHOW_TESTDESIGNATTACHMENT_RESPONSE: "SHOW_TESTDESIGNATTACHMENT_RESPONSE",
  SHOW_TESTDESIGNATTACHMENT_RES_CLEAR: "SHOW_TESTDESIGNATTACHMENT_RES_CLEAR",
};
