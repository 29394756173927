import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import "./PieChart.css";
import { Chart as ChartJs, Tooltip, ArcElement } from "chart.js";
import { Insights } from "@mui/icons-material";

ChartJs.register(ArcElement, Tooltip);
const PieChart = ({ results }) => {
  // To get structured pie chart data
  // const chartData = useMemo(() => {
  //   const data = [];
  //   // const backgroundColor = [];
  //   if (results && results?.length > 0) {
  //     results?.forEach((item) => {
  //       data.push(item?.value);
  //       // backgroundColor.push(getColorById(item?.id));
  //     });
  //   }
  //   return {
  //     data,
  //     // backgroundColor
  //   };
  // }, [results]);

  //   const data = {
  //     datasets: [
  //       {
  //         ...chartData,
  //         innerHeight: 5,
  //       },
  //     ],
  //   };
  const data = {
    datasets: [
      {
        // label: "# of Votes",
        data: [
          results?.PASSED?.percentage,
          results?.UNEXECUTED?.percentage,
          results?.FAILED?.percentage,
          results?.IN_PROGRESS?.percentage,
        ],
        backgroundColor: [
          "rgb(0 238 140)",
          "rgb(3 255 254)",
          "rgb(247 52 52)",
          "rgb(254 157 0)",
        ],
      },
    ],
  };
  // To modify pie chart
  const options = {
    cutout: "68%",
    plugins: {
      tooltip: {
        enabled: true,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  return (
    <>
      <div className="piechart">
        {results?.PASSED?.percentage === 0 &&
        results.FAILED.percentage === 0 &&
        results.UNEXECUTED.percentage === 0 &&
        results.IN_PROGRESS.percentage === 0 ? (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <h6 className="text-muted">
              <Insights className="me-2" fontSize="large" />
              No data available to render the chart.
            </h6>
          </div>
        ) : (
          <Pie data={data} options={options} />
        )}
      </div>
    </>
  );
};
export default PieChart;
