import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowDropDown,
  ArrowDropUp,
  ExpandMore,
  Add,
  AttachFileOutlined,
  DeleteOutlineOutlined,
  ArrowUpward,
  ArrowDownward,
  TaskOutlined,
  ImageOutlined,
  Close,
  Menu,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import TestDesignHistory from "./TestDesignHistory";
import TestDesignComment from "./TestDesignComments";
import CustomButton from "../../../Components/Atoms/CustomButton/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput/CustomInput";
import CustomSelect from "../../../Components/Atoms/CustomSelect/CustomSelect";
import { status, type } from "../../../Utils/constants";
import "./TestDesign.css";
import { showProjectMembersRequest } from "../../../Redux/ProjectSaga/ProjectAction";
import {
  getRequirementDetailIndexRequest,
  getRequirementModuleIndexRequest,
} from "../../../Redux/RequirementSaga/RequirementActions";
import {
  getTestDesignDetailIndexRequest,
  postTestDesignDetailRequest,
  postTestDesignDetailResponseClear,
  showTestDesignDetailRequest,
  updateTestDesignDetailRequest,
} from "../../../Redux/TestDesignSaga/TestDesignAction";
import toast from "react-hot-toast";
import { handleDownloadFile } from "../../../Utils/handles";
import { appUrl } from "../../../Utils/axios";
import { COLOR_VECTOR_ICON } from "../../../Utils/imageConstants";
import { testCasePriority } from "../../../Utils/constants";
import ExportData from "../../../Components/Molecules/ExportComponent/ExportToXL";

export default function TestDesignModule() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("details");
  const [isEditing, setIsEditing] = useState(false);
  const [visibleReleases, setVisibleReleases] = useState({});
  const [visibleRequirement, setVisibleRequirement] = useState({});
  const [types, setTypes] = useState("");
  const [deleteId, setDeleteId] = useState([]);
  const [steps, setSteps] = useState([]);
  const [testCaseId, setTestCaseId] = useState("");

  const { requirementModuleList, requirementDetailList } = useSelector(
    (state) => state.requirement
  );
  const { projectMembers } = useSelector((state) => state.project);
  const {
    testDesignDetailList,
    postTestDesignDetailResponse,
    testDesignDetail,
    postTestDesignDetailLoading,
  } = useSelector((state) => state.testDesign);

  const validation = Yup.object().shape({
    status: Yup.string().required("Status is required!"),
    type: Yup.string().required("Type is required!"),
    priority: Yup.string().required("Priority is required!"),
    assigned_to_fk: Yup.number().required("Assinged to is required!"),
    name: Yup.string()
      .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces alone")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-_=+{}\[\]\n?">]+)$/,
        "Test Case name should contain characters too"
      )
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .trim()
      .required("Test Case name is required!"),
    description: Yup.string().matches(
      /^\s*\S[\s\S]*$/g,
      "Should not contain spaces alone"
    ),
    pre_condition: Yup.string().matches(
      /^\s*\S[\s\S]*$/g,
      "Should not contain spaces alone"
    ),
    requirement_fk: Yup.number().required("Requirement is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "Test Cases",
      status: "",
      type: "",
      priority: "",
      assigned_to_fk: "",
      description: "",
      pre_condition: "",
      requirement_fk: "",
      module_fk: "",
      is_approved: false,
      steps: [
        { id: 1, description: "", expected_result: "", attachment: null },
      ],
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const formData = new FormData();
      for (let requirement_key in values) {
        if (requirement_key === "steps" && values.steps.length > 0) {
          values.steps.forEach((item, index) => {
            if (item) {
              formData.append(`steps[${index}].description`, item.description);
              formData.append(
                `steps[${index}].expected_result`,
                item.expected_result
              );
              if (typeof item.attachment === "string") {
                // Do nothing if attachment is a string
              } else if (
                item.attachment instanceof Blob ||
                item.attachment instanceof File
              ) {
                // Append attachment if it is binary (Blob or File)
                formData.append(`steps[${index}].attachment`, item.attachment);
              } else if (item.attachment === null) {
                // formData.append(`steps[${index}].attachment`, null);
              }
            }
            if (types === "edit") {
              formik.values.steps[index]?.id &&
                formData.append(
                  `steps[${index}].id`,
                  formik.values.steps[index].id
                );
            }
          });
          continue;
        }
        if (requirement_key === "is_approved") {
          values.is_approved === true &&
            formData.append("is_approved", values.is_approved);
          continue;
        }
        formData.append(`${requirement_key}`, values[requirement_key]);
      }
      if (types === "edit" && deleteId.length > 0) {
        let i = values.steps.length + 1;
        deleteId.forEach((item) => {
          formData.append(`steps[${i}].delete_step`, true);
          formData.append(`steps[${i}].id`, item?.id);
          i++;
        });
      }
      if (types === "edit") {
        dispatch(
          updateTestDesignDetailRequest({ formData: formData, id: values?.id })
        );
      } else {
        dispatch(postTestDesignDetailRequest(formData));
      }
    },
  });

  useEffect(() => {
    if (postTestDesignDetailLoading) return;
    if (testCaseId) {
      dispatch(showTestDesignDetailRequest(testCaseId));
    }
  }, [postTestDesignDetailLoading, testCaseId]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // ADDING TEST STEPS
  const addNewRow = () => {
    const newRow = {
      description: "",
      expected_result: "",
      attachment: null,
    };
    formik.setFieldValue(`steps[${formik.values.steps.length}]`, newRow);
    setSteps((prev) => [...prev, newRow]);
  };

  // DELETE TEST STEPS
  const deleteRow = (index, id) => {
    if (types === "edit" && id) {
      setDeleteId([...deleteId, { index: index, id: id }]);
    }
    const updatedRows = formik.values.steps.filter((row, i) => i !== index);
    formik.setFieldValue(`steps`, updatedRows);
    setSteps(updatedRows);
  };

  const handleFileUpload = (event, index) => {
    let { files, name } = event.target;
    formik.setFieldValue(name, files[0]);

    setSteps((prev) => {
      prev[index].attachment = files[0];
      return prev;
    });
  };

  const moveStep = (currentIndex, direction) => {
    const newIndex = direction === "up" ? currentIndex - 1 : currentIndex + 1;
    const newSteps = [...steps];
    if (newIndex >= 0 && newIndex < steps.length) {
      const [test] = newSteps.splice(currentIndex, 1);
      newSteps.splice(newIndex, 0, test);
      setSteps(newSteps);
      formik.setFieldValue("steps", newSteps);
    }
  };

  // FUNCTION TO TOGGLE VISIBILITY FOR SPECIFIC MODULE
  const toggleReleaseVisibility = (moduleId) => {
    setVisibleReleases((prev) => (prev === moduleId ? false : moduleId));

    // TO GET REQUIREMENT FOR PARTICULAR MODULE
    dispatch(
      getRequirementDetailIndexRequest({
        filters: {
          module_fk: {
            type: "exact",
            value: moduleId,
          },
        },
      })
    );
  };

  // FUNCTION TO TOGGLE VISIBILITY FOR SPECIFIC REQUIREMENT
  const toggleRequirementVisibility = (buildId) => {
    setVisibleRequirement((prev) => (prev === buildId ? false : buildId));
    // TO GET TESTCASES FOR PARTICULAR REQUIREMENT
    dispatch(
      getTestDesignDetailIndexRequest({
        filters: {
          requirement_fk: {
            type: "exact",
            value: buildId,
          },
        },
      })
    );
  };

  // PROJECT MEMBERS AND MODULES LIST ON INITIAL RENDER
  useEffect(() => {
    dispatch(
      showProjectMembersRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
    dispatch(
      getRequirementModuleIndexRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, []);

  // GET REQUIREMENTS LIST FOR PARTICULAR MODULE FOR DROPDOWN
  useEffect(() => {
    if (!formik.values.module_fk) return;
    if (formik.values.module_fk) {
      dispatch(
        getRequirementDetailIndexRequest({
          filters: {
            module_fk: {
              type: "exact",
              value: formik.values.module_fk,
            },
          },
        })
      );
    }
  }, [formik.values.module_fk]);

  // TO SET FORMIK VALUES WHILE EDITING
  useEffect(() => {
    if (testDesignDetail) {
      formik.resetForm();
    }
    if (testDesignDetail && types === "edit") {
      for (const key in formik.values) {
        if (key === "steps") {
          const sortedTestSteps = testDesignDetail?.test_steps.sort(
            (a, b) => a.step - b.step
          );
          for (let i = 0; i < sortedTestSteps.length; i++) {
            formik.setFieldValue(
              `steps[${i}].description`,
              testDesignDetail.test_steps[i]?.description
            );
            formik.setFieldValue(
              `steps[${i}].expected_result`,
              testDesignDetail.test_steps[i]?.expected_result
            );
            formik.setFieldValue(
              `steps[${i}].attachment`,
              testDesignDetail.test_steps[i]?.attachment
            );
            formik.setFieldValue(
              `steps[${i}].id`,
              testDesignDetail.test_steps[i]?.id
            );
          }
          // formik.setFieldValue();
        } else if (key === "assigned_to_fk") {
          formik.setFieldValue(
            "assigned_to_fk",
            testDesignDetail["assigned_to_fk"]?.id
          );
        } else {
          formik.setFieldValue(key, testDesignDetail[key]);
          setSteps(testDesignDetail["test_steps"]);
        }
      }
      if (testDesignDetail?.test_step?.length === 0) {
        formik.setFieldValue("steps", []);
      }
      formik.setFieldValue("id", testDesignDetail?.id);
    }
  }, [testDesignDetail]);

  // TOAST MESSAGE FOR CREATE TESTCASE
  useEffect(() => {
    if (!postTestDesignDetailResponse) return;
    if (postTestDesignDetailResponse) {
      if (
        postTestDesignDetailResponse?.message &&
        postTestDesignDetailResponse?.success
      ) {
        toast.success(postTestDesignDetailResponse?.message);
        if (types !== "edit") {
          formik.resetForm();
        }
        if (types === "edit") {
          setSteps(postTestDesignDetailResponse?.data?.test_steps);
          if (postTestDesignDetailResponse?.data?.is_approved) {
            formik.setFieldValue(
              postTestDesignDetailResponse?.data?.is_approved,
              true
            );
          }
        }
        setDeleteId([]);
      } else {
        toast.error(postTestDesignDetailResponse?.message);
      }
    }
    return () => {
      dispatch(postTestDesignDetailResponseClear());
    };
  }, [postTestDesignDetailResponse]);

  const handleResetRequirement = () => {
    formik.resetForm();
    setActiveTab("details");
    setTypes("");
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <div className="tests_layout">
        {/* SIDEBAR CONTENT */}
        <div className="new_sidebar new_sidebar_hide">
          <div className="side_layout">
            <div className="sidebar_plan" onClick={handleResetRequirement}>
              <img src={COLOR_VECTOR_ICON} alt="colorvector" />
              <p className={"colorRelease"}>Test Case</p>
            </div>
          </div>
          <>
            {requirementModuleList?.data?.map((module) => (
              <>
                <div key={module?.id}>
                  <div
                    className={`d-flex mt-3 sub_drop align-items-center ${
                      openDrawer && "draweropenMargin"
                    } `}
                    onClick={() => {
                      toggleReleaseVisibility(module?.id);
                    }}
                  >
                    {visibleReleases === module?.id ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                    <p>{module?.name}</p>
                  </div>
                  {visibleReleases === module?.id &&
                    requirementDetailList?.data?.map((build, index) => (
                      <div>
                        <div
                          className={`d-flex sub_drop align-items-center ${
                            openDrawer && "draweropenMargin"
                          } `}
                          style={{ marginLeft: "15px" }}
                          key={index}
                          onClick={() => {
                            toggleRequirementVisibility(build?.id);
                          }}
                        >
                          {visibleRequirement === build?.id ? (
                            <ArrowDropUp />
                          ) : (
                            <ArrowDropDown />
                          )}
                          <p className="list">{build?.name}</p>
                        </div>
                        {visibleRequirement === build?.id &&
                          testDesignDetailList?.data?.map((data) => (
                            <div className="testcase_modules">
                              <p
                                onClick={() => {
                                  dispatch(
                                    showTestDesignDetailRequest(data?.id)
                                  );
                                  setTypes("edit");
                                  setActiveTab("details");
                                  setTestCaseId(data?.id);
                                }}
                                className="list list2"
                              >
                                {data?.name}
                              </p>
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              </>
            ))}
          </>
        </div>
        <div className="side_modal bg-white">
          {!openDrawer && (
            <Menu
              className="clickable"
              onClick={() => setOpenDrawer(!openDrawer)}
            />
          )}
          <div className={openDrawer ? "sidenavopen" : "sideNav"}>
            <Close
              sx={{ display: openDrawer ? "unset" : "none" }}
              className="close_sidenav clickable"
              onClick={() => setOpenDrawer(false)}
            />
            <div
              className="new_sidebar new_sidebar_hide"
              style={{ display: openDrawer ? "unset" : "none" }}
            >
              <div
                className={`side_layout ${openDrawer && "draweropenMargin"}`}
              >
                <div className="sidebar_plan" onClick={handleResetRequirement}>
                  <img src={COLOR_VECTOR_ICON} alt="colorvector" />
                  <p className={"colorRelease"}>Test Case</p>
                </div>
              </div>
              <>
                {requirementModuleList?.data?.map((module) => (
                  <>
                    <div key={module?.id}>
                      <div
                        className={`d-flex mt-3 sub_drop align-items-center ${
                          openDrawer && "draweropenMargin"
                        } `}
                        onClick={() => {
                          toggleReleaseVisibility(module?.id);
                        }}
                      >
                        {visibleReleases === module?.id ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                        <p>{module?.name}</p>
                      </div>
                      {visibleReleases === module?.id &&
                        requirementDetailList?.data?.map((build, index) => (
                          <div>
                            <div
                              className={`d-flex  sub_drop align-items-center ${
                                openDrawer && "draweropenMargin"
                              } `}
                              style={{ marginLeft: "30px" }}
                              key={index}
                              onClick={() => {
                                toggleRequirementVisibility(build?.id);
                              }}
                            >
                              {visibleRequirement === build?.id ? (
                                <ArrowDropUp />
                              ) : (
                                <ArrowDropDown />
                              )}
                              <p className="list">{build?.name}</p>
                            </div>
                            {visibleRequirement === build?.id &&
                              testDesignDetailList?.data?.map((data) => (
                                <div className="testcase_modules">
                                  <p
                                    onClick={() => {
                                      dispatch(
                                        showTestDesignDetailRequest(data?.id)
                                      );
                                      setTypes("edit");
                                      setActiveTab("details");
                                      setTestCaseId(data?.id);
                                    }}
                                    className="list list2"
                                  >
                                    {data?.name}
                                  </p>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  </>
                ))}
              </>
            </div>
          </div>
          {/* <NewSideBar
          style={{ marginLeft: openDrawer ? "250px" : "-250px" }}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        > */}

          {/* </NewSideBar> */}
        </div>
        {/* RIGHT SIDE CONTENT*/}

        <div className="dashboard_container releaseLayout">
          <div className="position-relative">
            <div
              className={
                isEditing ? "test_header2" : "test_header test_header_hover"
              }
              onClick={() => {
                if (activeTab === "details") {
                  setIsEditing(true);
                } else {
                  setIsEditing(false);
                }
              }}
            >
              {isEditing ? (
                <CustomInput
                  type="text"
                  name="name"
                  size="large"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    if (formik.values.name) {
                      formik.handleBlur(e);
                      setIsEditing(false);
                    }
                  }}
                  value={formik.values.name}
                  formik={formik}
                  disabled={types === "edit" && testDesignDetail?.is_approved}
                />
              ) : (
                <>
                  <div className="d-flex">
                    <h3>{formik.values.name}</h3>
                  </div>
                </>
              )}
            </div>
            {formik.errors.name && !isEditing ? (
              <p className="CustErr">{formik.errors.name}</p>
            ) : (
              ""
            )}
          </div>
          <div className="reqTabHead">
            <div className="">
              <div className="pageTopLeft">
                <div className="project-boardhead-down-container d-flex gap-4 align-items-center">
                  <div className="empViewBtn">
                    <p
                      className={
                        activeTab === "details" ? "actives" : "details"
                      }
                      onClick={() => handleTabClick("details")}
                    >
                      Details
                    </p>
                    {types === "edit" && (
                      <>
                        <p
                          className={activeTab === "history" ? "actives" : ""}
                          onClick={() => handleTabClick("history")}
                        >
                          History
                        </p>
                        <p
                          className={activeTab === "comments" ? "actives" : ""}
                          onClick={() => handleTabClick("comments")}
                        >
                          Comments
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {activeTab == "details" && (
            <form onSubmit={formik.handleSubmit}>
              <div className="testForm mt-2">
                <div className="row">
                  <div className="col-md-4">
                    <CustomSelect
                      size="small"
                      placeholder="Status"
                      options={status.map((values, index) => {
                        return {
                          value: values.value,
                          name: values.name,
                        };
                      })}
                      name="status"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.status}
                      formik={formik}
                      disabled={
                        types === "edit" && testDesignDetail?.is_approved
                      }
                    />
                  </div>
                  <div className="col-md-4 err_margin">
                    <CustomSelect
                      size="small"
                      placeholder="Type"
                      options={type.map((values, index) => {
                        return {
                          value: values.value,
                          name: values.name,
                        };
                      })}
                      name="type"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.type}
                      formik={formik}
                      disabled={
                        types === "edit" && testDesignDetail?.is_approved
                      }
                    />
                  </div>
                  <div className="col-md-4 err_margin">
                    <CustomSelect
                      size="small"
                      placeholder="Assigned To"
                      options={projectMembers?.data?.map((val, index) => {
                        return {
                          value: val?.user_fk,
                          name: val?.email,
                        };
                      })}
                      name="assigned_to_fk"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.assigned_to_fk}
                      formik={formik}
                      disabled={
                        types === "edit" && testDesignDetail?.is_approved
                      }
                    />
                  </div>
                  <div className="col-md-4 err_margin">
                    <CustomSelect
                      size="small"
                      placeholder="Priority"
                      options={testCasePriority.map((values, index) => {
                        return {
                          value: values.value,
                          name: values.name,
                        };
                      })}
                      name="priority"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.priority}
                      formik={formik}
                      disabled={
                        types === "edit" && testDesignDetail?.is_approved
                      }
                    />
                  </div>
                  {types !== "edit" && (
                    <div className="col-md-4 err_margin">
                      <CustomSelect
                        size="small"
                        placeholder="Select Module"
                        options={requirementModuleList?.data?.map((val) => {
                          return {
                            value: val?.id,
                            name: val?.name,
                          };
                        })}
                        name="module_fk"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.module_fk}
                        formik={formik}
                      />
                    </div>
                  )}
                  {types !== "edit" && (
                    <div className="col-md-4 err_margin">
                      <CustomSelect
                        size="small"
                        placeholder="Select Requirement"
                        options={requirementDetailList?.data?.map((val) => {
                          return {
                            value: val?.id,
                            name: val?.name,
                          };
                        })}
                        name="requirement_fk"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.requirement_fk}
                        formik={formik}
                      />
                    </div>
                  )}
                  <div className="col-md-12">
                    <CustomInput
                      multiline
                      rows={4}
                      placeholder="Description"
                      type="text"
                      name="description"
                      size="large"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      formik={formik}
                      disabled={
                        types === "edit" && testDesignDetail?.is_approved
                      }
                    />
                  </div>
                  <div className="col-md-12">
                    <CustomInput
                      multiline
                      rows={4}
                      placeholder="Precondition"
                      type="text"
                      name="pre_condition"
                      size="large"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pre_condition}
                      formik={formik}
                      disabled={
                        types === "edit" && testDesignDetail?.is_approved
                      }
                    />
                  </div>
                </div>{" "}
                <div className="accordian_container mt-4 mb-4">
                  <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={
                        <ExpandMore className="accordion_drop_icon" />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h3>Test Steps</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <div className="mb-2 d-flex justify-content-end">
                          {testCaseId && (
                            <ExportData
                              exportLinks={[
                                {
                                  url: "api/test_step/export",
                                  label: "Export All",
                                  name: "Test Step",
                                  extension: "xls",
                                  type: "teststep",
                                  test_case_id: testCaseId,
                                },
                              ]}
                              cdConfig={{
                                test_case_id: testCaseId,

                                url: "api/test_step/export",
                                type: "teststep",
                                name: "teststep",
                                extension: "xls",
                              }}
                            />
                          )}
                        </div>
                        <div className="tblWrpr">
                          <div className="table-responsive ">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Step</th>
                                  <th>Step Description</th>
                                  <th>Expected Result</th>
                                  <th>Attached</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {formik?.values?.steps?.length > 0 ? (
                                  formik?.values?.steps?.map((row, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td className="test_step_row">
                                        <div className="w-100 col-md-6">
                                          <CustomInput
                                            type="text"
                                            multiline
                                            rows={2}
                                            autoComplete="off"
                                            placeholder="Enter Description"
                                            name={`steps[${index}].description`}
                                            size="large"
                                            disabled={
                                              types === "edit" &&
                                              testDesignDetail?.is_approved
                                            }
                                            // onChange={(e) => {
                                            //   formik.handleChange(e);
                                            //   setSteps((prev) => {
                                            //     if (!prev[index]) {
                                            //       prev[index] = {};
                                            //     }

                                            //     prev[index].description =
                                            //       e.target.value;
                                            //     return prev;
                                            //   });
                                            // }}
                                            onChange={(e) => {
                                              formik.handleChange(e);
                                              setSteps((prev) => {
                                                return prev.map((step, i) => {
                                                  if (i === index) {
                                                    return {
                                                      ...step,
                                                      description:
                                                        e.target.value,
                                                    };
                                                  }
                                                  return step;
                                                });
                                              });
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={
                                              formik?.values?.steps[index]
                                                ?.description
                                            }
                                            formik={formik}
                                          />
                                        </div>
                                      </td>
                                      <td className="test_step_row">
                                        <div className="w-100 col-md-6">
                                          <CustomInput
                                            type="text"
                                            multiline
                                            rows={2}
                                            placeholder="Enter result"
                                            name={`steps[${index}].expected_result`}
                                            size="large"
                                            onChange={(e) => {
                                              formik.handleChange(e);
                                              setSteps((prev) => {
                                                if (!prev[index]) {
                                                  prev[index] = {};
                                                }

                                                prev[index].expected_result =
                                                  e.target.value;
                                                return prev;
                                              });
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={
                                              formik?.values?.steps[index]
                                                ?.expected_result
                                            }
                                            formik={formik}
                                            disabled={
                                              types === "edit" &&
                                              testDesignDetail?.is_approved
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        {formik?.values?.steps[index]
                                          ?.attachment ? (
                                          <div className="testdesign_attachment">
                                            <button
                                              disabled={
                                                types === "edit" &&
                                                testDesignDetail?.is_approved
                                              }
                                            >
                                              <Close
                                                className="remove_attachment"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setSteps((prev) => {
                                                    if (!prev[index]) {
                                                      prev[index] = {};
                                                    }

                                                    prev[index].attachment =
                                                      null;
                                                    return prev;
                                                  });
                                                  formik.setFieldValue(
                                                    `steps[${index}].attachment`,
                                                    null
                                                  );
                                                }}
                                              />
                                            </button>

                                            <div
                                              onClick={(e) => {
                                                if (
                                                  typeof formik?.values?.steps[
                                                    index
                                                  ]?.attachment === "string"
                                                ) {
                                                  handleDownloadFile(
                                                    `${appUrl}${formik?.values?.steps[index]?.attachment}`
                                                  );
                                                } else {
                                                  const objectURL =
                                                    URL.createObjectURL(
                                                      formik?.values?.steps[
                                                        index
                                                      ]?.attachment
                                                    );
                                                  handleDownloadFile(objectURL);
                                                }
                                              }}
                                            >
                                              <ImageOutlined
                                                sx={{
                                                  width: "30px",
                                                  height: "30px",
                                                  color: "#adaaaa",
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="view">
                                            <label
                                              htmlFor={`steps[${index}].attachment`}
                                            >
                                              <AttachFileOutlined className="clickable" />
                                            </label>
                                            <input
                                              id={`steps[${index}].attachment`}
                                              type="file"
                                              accept="image/*"
                                              className="file_upload_input"
                                              name={`steps[${index}].attachment`}
                                              onChange={(e) => {
                                                setSteps((prev) => {
                                                  if (!prev[index]) {
                                                    prev[index] = {};
                                                  }

                                                  prev[index].attachment = null;
                                                  return prev;
                                                });
                                                handleFileUpload(e, index);
                                              }}
                                              onBlur={formik.handleBlur}
                                              formik={formik}
                                              disabled={
                                                types === "edit" &&
                                                testDesignDetail?.is_approved
                                              }
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        <div className="view">
                                          <button
                                            disabled={
                                              types === "edit" &&
                                              testDesignDetail?.is_approved
                                            }
                                            onClick={(e) => {
                                              e.preventDefault();
                                              deleteRow(
                                                index,
                                                formik.values.steps[index].id
                                              );
                                            }}
                                          >
                                            <DeleteOutlineOutlined />
                                          </button>
                                          <button
                                            disabled={
                                              types === "edit" &&
                                              testDesignDetail?.is_approved
                                            }
                                          >
                                            <ArrowUpward
                                              onClick={(e) => {
                                                if (
                                                  types === "edit" ||
                                                  types === ""
                                                ) {
                                                  e.preventDefault();
                                                  moveStep(index, "up");
                                                }
                                              }}
                                              disabled={index === 0}
                                            />
                                          </button>
                                          <button
                                            disabled={
                                              types === "edit" &&
                                              testDesignDetail?.is_approved
                                            }
                                          >
                                            <ArrowDownward
                                              onClick={(e) => {
                                                if (
                                                  types === "edit" ||
                                                  types === ""
                                                ) {
                                                  e.preventDefault();
                                                  moveStep(index, "down");
                                                }
                                              }}
                                              disabled={
                                                index === steps.length - 1
                                              }
                                            />
                                          </button>
                                          {/* <button>
                                            <TaskOutlined />
                                          </button> */}
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={5}>No Test Steps Yet</td>
                                  </tr>
                                )}
                                <tr>
                                  <td colSpan={10} className="addbutton_row">
                                    <div className="table_button px-1">
                                      <CustomButton
                                        onClick={addNewRow}
                                        disabled={
                                          types === "edit" &&
                                          testDesignDetail?.is_approved
                                        }
                                        label={
                                          <>
                                            <Add sx={{ marginBottom: "4px" }} />
                                            <span>Add</span>
                                          </>
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </form>
          )}
          {activeTab == "history" && (
            <TestDesignHistory testCaseId={testCaseId} />
          )}
          {activeTab == "comments" && (
            <TestDesignComment testCaseId={testCaseId} />
          )}
        </div>
      </div>
      {activeTab === "details" &&
        !(types === "edit" && testDesignDetail?.is_approved) && (
          <div className="popformbutton buttonBorder">
            <CustomButton
              size="small"
              label="Cancel"
              btnType="secondary"
              onClick={() => navigate(-1)}
            />
            {types === "edit" && (
              <CustomButton
                size="small"
                label={
                  types === "edit" && formik?.values?.is_approved
                    ? "Approved"
                    : "Approve"
                }
                btnType="tertiary"
                onClick={() => {
                  if (types === "edit") {
                    formik.setFieldValue("is_approved", true);
                    formik.handleSubmit();
                  }
                }}
                disabled={types === "edit" && formik?.values?.is_approved}
                // className={}
              />
            )}

            <CustomButton
              size="small"
              type="submit"
              onClick={formik.handleSubmit}
              label={types === "edit" ? "Update" : "Save"}
              disabled={types === "edit" && formik?.values?.is_approved}
            />
          </div>
        )}
    </>
  );
}
