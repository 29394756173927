import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from "./RootSaga";
import rootReducer from "./RootReducer";
import { CurrentUserRequest } from "./Login/LoginActions";
import { setAuthToken } from "../Utils/axios";

const sagaMiddleWare = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleWare));
const token = localStorage.getItem("token");

sagaMiddleWare.run(rootSaga);

if (token) {
  setAuthToken(token);
  store.dispatch(CurrentUserRequest());
}

export default store;
