import DefectsActionTypes from "./DefectsActionTypes";

const initialState = {
  defectsList: [],
  defectsListLoading: false,
  defectsListResponse: null,

  updateDefectLoading: false,
  updateDefectResponse: null,

  defect: null,
  viewDefectLoading: false,
  viewDefectResponse: null,

  commentHistory: null,
  commentHistoryLoading: false,

  newDefectLoading: false,
  newDefectResponse: null,

  indexDefectsList: [],
  indexDefectsListLoading: false,
};

const DefectReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET ALL DEFECTS
    case DefectsActionTypes.GET_DEFECTS_INDEX_REQUEST:
      return {
        ...state,
        defectsListLoading: true,
      };
    case DefectsActionTypes.GET_DEFECTS_INDEX_RESPONSE:
      return {
        ...state,
        defectsListLoading: false,
        defectsListResponse: action.payload,
        defectsList: action.payload?.data,
      };
    // GET PARTICULAR DEFECT
    case DefectsActionTypes.SHOW_DEFECT_REQUEST:
      return {
        ...state,
        viewDefectLoading: true,
      };
    case DefectsActionTypes.SHOW_DEFECT_RESPONSE:
      return {
        ...state,
        viewDefectLoading: false,
        viewDefectResponse: action.payload,
        defect: action.payload?.data,
      };
    case DefectsActionTypes.SHOW_DEFECT_RES_CLEAR:
      return {
        ...state,
        viewDefectLoading: false,
        viewDefectResponse: null,
        defect: null,
      };
    // UPDATE DEFECT
    case DefectsActionTypes.UPDATE_DEFECT_REQUEST:
      return {
        ...state,
        updateDefectLoading: true,
      };

    case DefectsActionTypes.UPDATE_DEFECT_RESPONSE:
      return {
        ...state,
        updateDefectLoading: false,
        updateDefectResponse: action.payload,
      };
    case DefectsActionTypes.UPDATE_DEFECT_RES_CLEAR:
      return {
        ...state,
        updateDefectLoading: false,
        updateDefectResponse: null,
      };
    //comment history
    case DefectsActionTypes.COMMENT_HISTORY_REQUEST:
      return {
        ...state,
        commentHistoryLoading: true,
      };
    case DefectsActionTypes.COMMENT_HISTORY_RESPONSE:
      return {
        ...state,
        commentHistoryLoading: false,
        commentHistory: action.payload.data,
      };

    // new DEFECT
    case DefectsActionTypes.NEW_DEFECT_REQUEST:
      return {
        ...state,
        newDefectLoading: true,
      };

    case DefectsActionTypes.NEW_DEFECT_RESPONSE:
      return {
        ...state,
        newDefectLoading: false,
        newDefectResponse: action.payload,
      };
    case DefectsActionTypes.NEW_DEFECT_RESCLEAR:
      return {
        ...state,
        newDefectLoading: false,
        newDefectResponse: null,
      };

    //index
    case DefectsActionTypes.INDEX_DEFECT_REQUEST:
      return {
        ...state,
        indexDefectsListLoading: false,
        // indexDefectsList: action.payload,
      };
    case DefectsActionTypes.INDEX_DEFECT_RESPOSE:
      return {
        ...state,
        indexDefectsListLoading: false,
        indexDefectsList: action.payload.data,
      };
    default:
      return state;
  }
};

export default DefectReducer;
