import RegisterActionTypes from "./RegisterActionTypes";

export const RegisterRequest = (payload) => ({
  type: RegisterActionTypes.REGISTER_USER_REQUEST,
  payload: payload,
});

export const RegisterSuccess = (payload) => ({
  type: RegisterActionTypes.REGISTER_USER_SUCCESS,
  payload: payload,
});

export const RegisterResClear = (payload) => ({
  type: RegisterActionTypes.REGISTER_USER_RESCLEAR,
});

export const otpRequest = (payload) => ({
  type: RegisterActionTypes.OTP_REQUEST,
  payload: payload,
});

export const otpSuccess = (payload) => ({
  type: RegisterActionTypes.OTP_SUCCESS,
  payload: payload,
});

export const otpResClear = () => ({
  type: RegisterActionTypes.OTP_RES_CLEAR,
});

// COUNTRY
export const countryListRequest = (payload) => ({
  type: RegisterActionTypes.GET_COUNTRYLIST_REQUEST,
  payload: payload,
});

export const countryListResponse = (payload) => ({
  type: RegisterActionTypes.GET_COUNTRYLIST_RESPONSE,
  payload: payload,
});

export const countryListResClear = () => ({
  type: RegisterActionTypes.GET_COUNTRYLIST_RES_CLEAR,
});

// STATE
export const stateListRequest = (payload) => ({
  type: RegisterActionTypes.GET_STATELIST_REQUEST,
  payload: payload,
});

export const stateListResponse = (payload) => ({
  type: RegisterActionTypes.GET_STATELIST_RESPONSE,
  payload: payload,
});

export const stateListResClear = () => ({
  type: RegisterActionTypes.GET_STATELIST_RES_CLEAR,
});

// CITY
export const cityListRequest = (payload) => ({
  type: RegisterActionTypes.GET_CITYLIST_REQUEST,
  payload: payload,
});

export const cityListResponse = (payload) => ({
  type: RegisterActionTypes.GET_CITYLIST_RESPONSE,
  payload: payload,
});

export const cityListResClear = () => ({
  type: RegisterActionTypes.GET_CITYLIST_RES_CLEAR,
});

// DEPARTMENT
export const departmentListRequest = (payload) => ({
  type: RegisterActionTypes.GET_DEPARTMENTLIST_REQUEST,
  payload: payload,
});

export const departmentListResponse = (payload) => ({
  type: RegisterActionTypes.GET_DEPARTMENTLIST_RESPONSE,
  payload: payload,
});

export const departmentListResClear = () => ({
  type: RegisterActionTypes.GET_DEPARTMENTLIST_RES_CLEAR,
});
