import { Task } from "@mui/icons-material";
import TaskActionTypes from "./TaskActionTypes";

const initialState = {
  taskList: [],
  taskListLoading: false,
  taskListResponse: null,

  postTaskLoading: false,
  postTaskResponse: null,

  task: null,
  taskLoading: false,
  taskResponse: null,

  taskChoices: null,
  taskChoicesLoading: false,
  taskChoicesResponse: null,
};

const TaskReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET ALL TASKS
    case TaskActionTypes.GET_TASK_INDEX_REQUEST:
      return {
        ...state,
        taskListLoading: true,
      };
    case TaskActionTypes.GET_TASK_INDEX_RESPONSE:
      return {
        ...state,
        taskList: action.payload?.data,
        taskListLoading: false,
        taskListResponse: action.payload,
      };
    // POST && EDIT TASK
    case TaskActionTypes.POST_TASK_REQUEST:
    case TaskActionTypes.EDIT_TASK_REQUEST:
      return {
        ...state,
        postTaskLoading: true,
      };
    case TaskActionTypes.POST_TASK_RESPONSE:
    case TaskActionTypes.EDIT_TASK_RESPONSE:
      return {
        ...state,
        postTaskLoading: false,
        postTaskResponse: action.payload,
      };
    // POST && EDIT TASK RES CLEAR
    case TaskActionTypes.POST_TASK_RES_CLEAR:
      return {
        ...state,
        postTaskLoading: false,
        postTaskResponse: null,
      };
    // GET PARTICULAR TASK
    case TaskActionTypes.SHOW_TASK_REQUEST:
      return {
        ...state,
        taskLoading: true,
      };
    case TaskActionTypes.SHOW_TASK_RESPONSE:
      return {
        ...state,
        taskLoading: false,
        taskResponse: action.payload,
        task: action.payload?.data,
      };
    case TaskActionTypes.SHOW_TASK_RES_CLEAR:
      return {
        ...state,
        taskLoading: false,
        taskResponse: null,
        task: null,
      };
    // GET TASK CHOICES
    case TaskActionTypes.GET_TASKCHOICES_REQUEST:
      return {
        ...state,
        taskChoicesLoading: true,
      };
    case TaskActionTypes.GET_TASKCHOICES_RESPONSE:
      return {
        ...state,
        taskChoicesLoading: false,
        taskChoices: action.payload?.data,
        taskChoicesResponse: action.payload,
      };

    default:
      return state;
  }
};

export default TaskReducer;
