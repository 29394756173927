import React from "react";
import "./BarChart.css";
import PropTypes from "prop-types";

const BarChart = ({ priority }) => {
  const CrgradientStyle = {
    background: `linear-gradient(90deg, rgb(255 31 0) 0%, rgb(255 31 0) ${priority?.CRITICAL}%, rgb(251 130 113) ${priority?.CRITICAL}%, rgb(251 130 113) 100%)`,
  };
  const HigradientStyle = {
    background: `linear-gradient(90deg, rgb(255 77 0) 0%, rgb(255 77 0)${priority?.HIGH}%, rgb(253 179 146)${priority?.HIGH}%, rgb(253 179 146) 100%)`,
  };
  const MegradientStyle = {
    background: `linear-gradient(90deg, rgb(255 214 0) 0%, rgb(255 214 0) ${priority?.MEDIUM}%, rgb(249 245 156) ${priority?.MEDIUM}%, rgb(249 245 156) 100%)`,
  };
  const LogradientStyle = {
    background: `linear-gradient(90deg, rgb(105 104 102) 0%, rgb(105 104 102) ${priority?.LOW}%, rgb(239 239 239) ${priority?.LOW}%, rgb(239 239 239) 100%)`,
  };

  const chartData = [
    {
      name: "Critical",
      style: CrgradientStyle,
      value: `${priority?.CRITICAL}%`,
    },
    {
      name: "High",
      style: HigradientStyle,
      value: `${priority?.HIGH}%`,
    },
    {
      name: "Medium",
      style: MegradientStyle,
      value: `${priority?.MEDIUM}%`,
    },
    {
      name: "Low",
      style: LogradientStyle,
      value: `${priority?.LOW}%`,
    },
  ];

  return (
    <div className="bar_chart">
      {chartData?.map((data, index) => (
        <div className="bar_name" key={index}>
          <div className="col-3">{data?.name}</div>
          <div className="bar_cont">
            <div className="bar" style={data?.style}></div>
          </div>
          <div>
            <p>{data?.value}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

BarChart.propTypes = {
  backendResponse: PropTypes.shape({
    colorPercentage: PropTypes.number,
  }),
};

export default BarChart;
