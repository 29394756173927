import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Tab.css";
import MetaData from "../../../Components/Organisms/MetaData/MetaData";
import ProjectDashboard from "../index";
import CustomButton from "../../../Components/Atoms/CustomButton/CustomButton";
import CustomDialog from "../../../Components/Atoms/CustomDialog/CustomDialog";
import InviteForm from "../../InviteUser/InviteForm";
import TestPlan from "../TestPlan/TestPlan/TestPlan";
import { ArrowBack } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectIndexRequest,
  showProjectRequest,
} from "../../../Redux/ProjectSaga/ProjectAction";
import RequirementModules from "../Requirement/RequirementModules";
import TestDesignModule from "../TestDesign/TestDesign";
import TestExecution from "../../TestExecution/TestExecution";
import { Button, Menu, MenuItem } from "@mui/material";
import Report from "../../ReportsView/Report";
import DefectsIndex from "../../Defects/DefectsIndex";
import CustomSelect from "../../../Components/Atoms/CustomSelect/CustomSelect";

export default function ViewProject() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, tab } = useParams();

  const { project, viewProjectLoading } = useSelector((state) => state.project);
  const [open, setOpen] = useState(false);

  const { projectsList } = useSelector((state) => state.project);

  const handleClose = () => {
    setOpen(false);
  };

  // GET PARTICULAR PROJECT
  useEffect(() => {
    dispatch(showProjectRequest(id));
    dispatch(
      getProjectIndexRequest({
        filters: {
          name: {
            type: "like",
            value: "",
          },
        },
        pagination_meta: {
          page: 1,
          // limit: 50,
          order_by: "id_desc",
        },
      })
    );
  }, [id]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseTabs = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MetaData
        title={!viewProjectLoading ? `${project?.name} Project` : "Loading"}
      />
      <div className="project_overview_container">
        <div className="pageTop justify-content-start flex-wrap">
          <div className="pageTopLeft pageTopLeftHide d-flex">
            <div className="d-flex gap-1 projDrop align-items-center projDrop2">
              <CustomSelect
                className="remBorder"
                size="small"
                placeholder="Select Project"
                options={projectsList?.data?.map((values, index) => {
                  return {
                    value: values.id,
                    name: `${values.name}`,
                    onClick: () => {
                      navigate(`/project/${values.id}/dashboard`); // Navigate to the desired route
                    },
                  };
                })}
                name="status"
                value={id}
              />
            </div>
          </div>
          <div className="pageTopRight">
            <div className="project-boardhead-down-container  mb-1 d-flex gap-4 align-items-center">
              <div className="d-flex flex-wrap gap-2 project_tab_hide empViewBtm">
                <p
                  onClick={() => navigate(`/project/${id}/dashboard`)}
                  onKeyDown={() => {}}
                  className={tab === "dashboard" ? "activeTab prTab" : "prTabs"}
                >
                  Dashboard
                </p>
                <p
                  onClick={() => navigate(`/project/${id}/testplan`)}
                  onKeyDown={() => {}}
                  className={tab === "testplan" ? "activeTab prTab" : "prTabs"}
                >
                  Test Plan
                </p>
                <p
                  onClick={() => navigate(`/project/${id}/requirements`)}
                  onKeyDown={() => {}}
                  className={
                    tab === "requirements" ? "activeTab prTab" : "prTabs"
                  }
                >
                  Requirements
                </p>
                <p
                  onKeyDown={() => {}}
                  onClick={() => navigate(`/project/${id}/testdesign`)}
                  className={
                    tab === "testdesign" ? "activeTab prTab" : "prTabs"
                  }
                >
                  Test Design
                </p>
                <p
                  onKeyDown={() => {}}
                  onClick={() => navigate(`/project/${id}/testexecution`)}
                  className={
                    tab === "testexecution" ? "activeTab prTab" : "prTabs"
                  }
                >
                  Test Execution
                </p>
                <p
                  onKeyDown={() => {}}
                  onClick={() => navigate(`/project/${id}/defects`)}
                  className={tab === "defects" ? "activeTab prTab" : "prTabs"}
                >
                  Defects
                </p>
                <p
                  onKeyDown={() => {}}
                  onClick={() => navigate(`/project/${id}/report`)}
                  className={tab === "report" ? "activeTab prTab" : "prTabs"}
                >
                  Report
                </p>
              </div>
              <div className="tabs_dropdown">
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Tabs
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseTabs}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <div className="d-flex flex-wrap gap-2 empViewBtm">
                      <MenuItem
                        onClick={() => {
                          navigate(`/project/${id}/dashboard`);
                          handleCloseTabs();
                        }}
                      >
                        <p
                          className={
                            tab === "dashboard" ? "activeTab prTab" : "prTabs"
                          }
                        >
                          Dashboard
                        </p>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/project/${id}/testplan`);
                          handleCloseTabs();
                        }}
                      >
                        <p
                          className={
                            tab === "testplan" ? "activeTab prTab" : "prTabs"
                          }
                        >
                          Test Plan
                        </p>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/project/${id}/requirements`);
                          handleCloseTabs();
                        }}
                      >
                        <p
                          className={
                            tab === "requirements"
                              ? "activeTab prTab"
                              : "prTabs"
                          }
                        >
                          Requirements
                        </p>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/project/${id}/testdesign`);
                          handleCloseTabs();
                        }}
                      >
                        <p
                          className={
                            tab === "testdesign" ? "activeTab prTab" : "prTabs"
                          }
                        >
                          Test Design
                        </p>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/project/${id}/testexecution`);
                          handleCloseTabs();
                        }}
                      >
                        <p
                          className={
                            tab === "testexecution"
                              ? "activeTab prTab"
                              : "prTabs"
                          }
                        >
                          Test Execution
                        </p>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/project/${id}/defects`);
                          handleCloseTabs();
                        }}
                      >
                        <p
                          className={
                            tab === "defects" ? "activeTab prTab" : "prTabs"
                          }
                        >
                          Defects
                        </p>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/project/${id}/report`);
                          handleCloseTabs();
                        }}
                      >
                        <p
                          className={
                            tab === "report" ? "activeTab prTab" : "prTabs"
                          }
                        >
                          Report
                        </p>
                      </MenuItem>
                    </div>
                  </Menu>
                </div>
              </div>
            </div>

            <div className="d-flex gap-2 inivite_button">
              <CustomButton
                btnType="secondary"
                size="small"
                label={
                  <div>
                    <ArrowBack className="me-1" />
                    <span>Back</span>
                  </div>
                }
                onClick={() => navigate(-1)}
              />
              <CustomButton
                size="small"
                label="Invite users"
                onClick={() => setOpen(true)}
                onKeyDown={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={tab === "dashboard" ? "tabs_container2" : "tabs_container"}
      >
        {tab === "dashboard" && <ProjectDashboard />}
        {tab === "testplan" && <TestPlan />}
        {tab === "requirements" && <RequirementModules />}
        {tab === "testdesign" && <TestDesignModule />}
        {tab === "testexecution" && <TestExecution />}
        {tab === "defects" && <DefectsIndex />}
        {tab === "report" && <Report />}
      </div>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        title="Invite User with email"
        maxWidth="xs"
      >
        <InviteForm handleClose={handleClose} />
      </CustomDialog>
    </>
  );
}
