import React from "react";
import TextField from "@mui/material/TextField";
import "./CustomInput.css";
import { InputLabel } from "@mui/material";
import PropTypes from "prop-types";

const CustomInput = (props) => {
  const { formik, name } = props;
  const isError = formik?.touched[name] && formik?.errors[name];

  return (
    <div className="customInputWrpr">
      {props.upperLabel ? (
        <>
          <InputLabel>{props.label}</InputLabel>
          {props.multiline ? (
            <TextField
              variant="outlined"
              name={props.name}
              type={props.type ? props.type : "text"}
              value={props.value}
              onBlur={props.onBlur}
              placeholder={props.placeholder}
              onChange={props.onChange}
              multiline
              rows={props.rows}
              inputProps={{ readOnly: props.readOnly, autoComplete: "off" }}
              InputLabelProps={{ shrink: props.shrink }}
            />
          ) : (
            <TextField
              variant="outlined"
              accept={props.accept}
              name={props.name}
              type={props.type ? props.type : "text"}
              value={props.value}
              onBlur={props.onBlur}
              placeholder={props.placeholder}
              onChange={props.onChange}
              min={props.min}
              size="small"
              inputProps={{ readOnly: props.readOnly }}
              InputLabelProps={{ shrink: props.shrink }}
              disabled={props.disabled}
              onPaste={props.onPaste}
              onKeyDown={props.onkeyDown}
              autoComplete={props.autoComplete}
              helperText={props.helperText}
            />
          )}
        </>
      ) : (
        <>
          {props.multiline ? (
            <TextField
              label={props.label}
              variant="outlined"
              name={props.name}
              type={props.type ? props.type : "text"}
              value={props.value}
              onBlur={props.onBlur}
              placeholder={props.placeholder}
              onChange={props.onChange}
              multiline
              rows={props.rows}
              disabled={props.disabled}
              InputLabelProps={{ shrink: props.shrink }}
              InputProps={{
                startAdornment: props.startAdornment,
                endAdornment: props.endAdornment,
                readOnly: props.readOnly,
              }}
            />
          ) : (
            <TextField
              label={props.label}
              variant="outlined"
              accept={props.accept}
              name={props.name}
              type={props.type ? props.type : "text"}
              value={props.value}
              onBlur={props.onBlur}
              helperText={props.helperText}
              placeholder={props.placeholder}
              size={props.size ? props.size : "small"}
              min={props.min}
              onChange={props.onChange}
              InputLabelProps={{ shrink: props.shrink }}
              InputProps={{
                startAdornment: props.startAdornment,
                endAdornment: props.endAdornment,
                readOnly: props.readOnly,
              }}
              autoComplete={props.autoComplete}
              disabled={props.disabled}
              onKeyDown={props.onKeyDown}
              onPaste={props.onPaste}
              onWheel={
                props.type === "number" ? (e) => e.target.blur() : props.onWheel
              }
              autoFocus={props.autoFocus}
            />
          )}
        </>
      )}
      {isError ? <p className="errMsg">{formik.errors[name]}</p> : ""}
    </div>
  );
};

CustomInput.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  upperLabel: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  shrink: PropTypes.bool,
  accept: PropTypes.string,
  min: PropTypes.number,
  size: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onPaste: PropTypes.func,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.string,
  helperText: PropTypes.string,
  onWheel: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export default CustomInput;
