import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import { PAGE_NOT_FOUND_IMG } from "../../Utils/imageConstants";

const AnimatedVideo = () => {
  const animationRef = useRef(null);
  useEffect(() => {
    const container = animationRef.current;
    const anim = lottie.loadAnimation({
      container,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: PAGE_NOT_FOUND_IMG,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return <div id="lottie_container" ref={animationRef}></div>;
};

export default AnimatedVideo;
