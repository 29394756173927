import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomInput from "../../../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../../../Components/Atoms/CustomButton/CustomButton";
import CustomSelect from "../../../../Components/Atoms/CustomSelect/CustomSelect";
import {
  Add,
  ExpandMore,
  AttachFileOutlined,
  Close,
} from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { status } from "../../../../Utils/constants";
import "./BuildPlan.css";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  deleteBuildAttachmentRequest,
  deleteBuildAttachmentResponseClear,
  getBuildAttachmentIndexRequest,
  getBuildAttachmentIndexResClear,
  postBuildAttachmentRequest,
  postBuildAttachmentResponseClear,
  postBuildRequest,
  postBuildResponseClear,
  showBuildResponseClear,
  updateBuildRequest,
} from "../../../../Redux/BuildSaga/BuildAction";
import { useParams } from "react-router-dom";
import { getReleaseIndexRequest } from "../../../../Redux/ReleaseSaga/ReleaseAction";
import CustomDialog from "../../../../Components/Atoms/CustomDialog/CustomDialog";
import MultiDocUpload from "../../../../Components/Organisms/MultiDocUpload/MultiDocUpload";
import { appUrl } from "../../../../Utils/axios";
import DeleteIndex from "../../../DeletePopup/DeleteIndex";
import { CLOUD_ICON } from "../../../../Utils/imageConstants";
import CustomAttachmentIcon from "../../../../Components/Atoms/CustomAttachmentIcon/CustomAttachmentIcon";

const BuildPlan = ({ type, reset, setType, buildId, setBuildId }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [addBuildScope, setAddBuildScope] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [scopeValue, setScopeValue] = useState("");
  const [open, setOpen] = useState(false);

  const {
    postBuildResponse,
    build,
    buildAttachmentsList,
    deleteBuildAttachmentsResponse,
    deleteBuildAttachmentsLoading,
    postBuildAttachmentsResponse,
    postBuildAttachmentsLoading,
  } = useSelector((state) => state.build);

  const { releaseList } = useSelector((state) => state.release);

  const handleClose = () => {
    if (type !== "edit") {
      setOpen(false);
    } else {
      docFormik.resetForm();
      setOpen(false);
    }
  };

  const maxSizeInBytes = 5 * 1024 * 1024;

  const Validation = Yup.object().shape({
    attachments: Yup.array().of(
      Yup.object().shape({
        attachment: Yup.mixed()
          .test("fileSize", "File size must be below 10MB", (value) => {
            if (value instanceof File) {
              return value.size <= maxSizeInBytes;
            }
            return true; // Allow the value to be a string (image URL from backend)
          })
          .required("Attachment is required!"),
        name: Yup.string()
          .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces")
          .matches(/^[a-zA-Z0-9\s]+$/, "Invalid Format")
          .matches(
            /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-=+\n]+)$/,
            "Numbers only not allowed"
          )
          .min(3, "Minimum of 3 characters")
          .max(15, "Maximum of 15 characters")
          .when("attachment", {
            is: (val) => val instanceof File,
            then: (schema) => schema.required("Name is required!"),
          }),
      })
    ),
  });

  const validation = Yup.object().shape({
    name: Yup.string()
      .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces alone")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-=+\n]+)$/,
        "Build name should contain characters too"
      )
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .trim()
      .required("Build Name is required!"),
    status: Yup.string().required("Status is required!"),
    build_date: Yup.date().required("Build Date is required"),
    release_fk: Yup.number().required("Release is required!"),
    build_note: Yup.string().matches(
      /^\s*\S[\s\S]*$/g,
      "Should not contain spaces alone"
    ),
    attachments: Yup.array().of(
      Yup.object().shape({
        attachment: Yup.mixed()
          .test("fileSize", "File size must be below 10MB", (value) => {
            if (value instanceof File) {
              return value.size <= maxSizeInBytes;
            }
            return true; // Allow the value to be a string (image URL from backend)
          })
          .nullable(),

        name: Yup.string()
          .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces")
          .matches(/^[a-zA-Z0-9\s]+$/, "Invalid Format")
          .matches(
            /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-=+\n]+)$/,
            "Numbers only not allowed"
          )
          .min(3, "Minimum of 3 characters")
          .max(15, "Maximum of 15 characters")
          .when("attachment", {
            is: (val) => val instanceof File,
            then: (schema) => schema.required("Attachment Name is required!"),
          }),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "Build",
      status: "",
      build_date: "",
      build_note: "",
      build_scope: [],
      attachments: [
        {
          attachment: null,
          name: "",
        },
      ],
      release_fk: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const formData = new FormData();
      for (let build_key in values) {
        if (build_key === "attachments" && values.attachments.length > 0) {
          if (type !== "edit") {
            values.attachments.forEach((item, index) => {
              if (item.attachment) {
                formData.append(
                  `attachments[${index}].attachment`,
                  item.attachment
                );
                formData.append(`attachments[${index}].name`, item.name);
              }
            });
          }
        } else if (build_key === "build_scope") {
          formData.append(
            "build_scope",
            JSON.stringify([...values[build_key]])
          );
        } else {
          formData.append(`${build_key}`, values[build_key]);
        }
      }
      if (type == "edit") {
        // const { attachments, ...value } = values;
        dispatch(updateBuildRequest({ formData: values, id: values?.id }));
      } else {
        dispatch(postBuildRequest(formData));
      }
    },
  });
  const docFormik = useFormik({
    initialValues: {
      attachments: [
        {
          attachment: null,
          name: "",
        },
      ],
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      if (type !== "edit") {
        handleClose();
      } else {
        const formData = new FormData();
        values.attachments.length > 0 &&
          values.attachments.forEach((item, index) => {
            if (item.attachment) {
              formData.append(
                `attachments[${index}].attachment`,
                item.attachment
              );
              formData.append(`attachments[${index}].name`, item.name);
            }
          });
        formData.append("build_fk", buildId);
        dispatch(postBuildAttachmentRequest(formData));
      }
    },
  });

  // const handleUploadAttachment = () => {
  //   const formData = new FormData();
  //   docFormik.values.attachments.length > 0 &&
  //     docFormik.values.attachments.forEach((item, index) => {
  //       if (item.attachment) {
  //         formData.append(`attachments[${index}].attachment`, item.attachment);
  //         formData.append(`attachments[${index}].name`, item.name);
  //       }
  //     });
  //   formData.append("build_fk", buildId);
  //   dispatch(postBuildAttachmentRequest(formData));
  // };

  useEffect(() => {
    if (!postBuildAttachmentsResponse) return;
    if (postBuildAttachmentsResponse) {
      if (
        postBuildAttachmentsResponse?.message &&
        postBuildAttachmentsResponse?.success
      ) {
        toast.success(postBuildAttachmentsResponse?.message);
        handleClose();
      } else {
        toast.error(postBuildAttachmentsResponse?.message);
      }
    }
    return () => {
      postBuildAttachmentResponseClear();
    };
  }, [postBuildAttachmentsResponse]);

  // POST AND EDIT BUILD TOAST MESSAGE
  useEffect(() => {
    if (!postBuildResponse) return;
    if (postBuildResponse) {
      if (postBuildResponse?.message && postBuildResponse?.success) {
        toast.success(postBuildResponse?.message);
        formik.resetForm();
      } else {
        toast.error(postBuildResponse?.message);
      }
    }
    return () => {
      dispatch(postBuildResponseClear());
    };
  }, [postBuildResponse]);

  // const handleFileUpload = (e) => {
  //   let { files, name } = e.target;
  //   formik.setFieldValue(name, files[0]);
  // };

  // GET ALL RELEASES ON INITIAL RENDERING FOR DROPDOWN
  useEffect(() => {
    dispatch(
      getReleaseIndexRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, []);

  useEffect(() => {
    if (deleteBuildAttachmentsLoading || postBuildAttachmentsLoading) return;
    dispatch(
      getBuildAttachmentIndexRequest({
        filters: {
          build_fk: {
            type: "exact",
            value: buildId,
          },
        },
      })
    );
  }, [deleteBuildAttachmentsLoading, postBuildAttachmentsLoading]);

  // TO SET FORMIK VALUES WHILE EDITING
  useEffect(() => {
    if (build && type === "edit") {
      for (const key in formik.values) {
        if (key === "attachments") {
          formik.setFieldValue("attachments", [{ attachment: "", name: "" }]);
        } else if (key === "build_scope") {
          const buildScope =
            build["build_scope"].length > 0 ? build?.[key] : [];
          formik.setFieldValue("build_scope", buildScope);
        } else {
          formik.setFieldValue(key, build[key]);
        }
      }
      formik.setFieldValue("id", build?.id);
    }
    return () => {
      dispatch(showBuildResponseClear());
    };
  }, [build]);

  useEffect(() => {
    setType("");
    if (!reset) return;
    formik.resetForm();
    dispatch(getBuildAttachmentIndexResClear());
  }, [reset]);

  useEffect(() => {
    if (!deleteBuildAttachmentsResponse) return;
    if (deleteBuildAttachmentsResponse) {
      if (
        deleteBuildAttachmentsResponse?.message &&
        deleteBuildAttachmentsResponse?.success
      ) {
        toast.success(deleteBuildAttachmentsResponse?.message);
      } else {
        toast.error(deleteBuildAttachmentsResponse?.message);
      }
    }
    return () => {
      dispatch(deleteBuildAttachmentResponseClear());
    };
  }, [deleteBuildAttachmentsResponse]);

  const handleDownloadFile = (url) => {
    window.open(url);
  };

  return (
    <>
      <div className="dashboard_container releaseLayout">
        <div
          onKeyDown={() => {}}
          className={
            isEditing ? "test_header2" : "test_header test_header_hover"
          }
          onClick={() => setIsEditing(true)}
        >
          {isEditing ? (
            <CustomInput
              type="text"
              name="name"
              size="large"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={(e) => {
                formik.handleBlur(e);
                setIsEditing(false);
              }}
              value={formik.values.name}
              formik={formik}
            />
          ) : (
            <h3>{formik.values.name}</h3>
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="testForm mt-2">
            <div className="row">
              <div className="col-md-6 err_margin">
                <CustomSelect
                  size="small"
                  placeholder="Status"
                  options={status.map((values, index) => {
                    return {
                      value: values.value,
                      name: values.name,
                    };
                  })}
                  name="status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.status}
                  formik={formik}
                />
              </div>
              {type !== "edit" && (
                <div className="col-md-6 err_margin">
                  <CustomSelect
                    size="small"
                    placeholder="Select Release"
                    options={releaseList?.data?.map((values, index) => {
                      return {
                        value: values?.id,
                        name: values?.name,
                      };
                    })}
                    name="release_fk"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.release_fk}
                    formik={formik}
                  />
                </div>
              )}
              <div className="col-md-6 err_margin">
                <CustomInput
                  type="date"
                  name="build_date"
                  size="large"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.build_date}
                  formik={formik}
                  shrink={true}
                  label="Build Date"
                />
              </div>
              <div className="col-md-12 ">
                <CustomInput
                  multiline
                  rows={4}
                  placeholder="Build Note"
                  type="text"
                  name="build_note"
                  size="large"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.build_note}
                  formik={formik}
                />
              </div>
            </div>
            <div className="accordian_container">
              <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Resources</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="contWrpr">
                    <div
                      className="d-flex 
                    inner_cont justify-content-between"
                    >
                      <div className="flex_cont">
                        <p className="hrdr text-center">Build Scope</p>
                        {formik?.values?.build_scope?.map((name) => (
                          <p className="hrdr p-3">{name}</p>
                        ))}
                        {addBuildScope ? (
                          <div className="m-2 d-flex gap-2">
                            <CustomInput
                              type="text"
                              name="build_scope"
                              size="large"
                              placeholder="Add Build Scope"
                              onChange={(e) => {
                                setScopeValue(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" && scopeValue) {
                                  e.stopPropagation();
                                  formik.setFieldValue("build_scope", [
                                    ...formik.values.build_scope,
                                    scopeValue,
                                  ]);
                                  setAddBuildScope(false);
                                  setScopeValue("");
                                }
                              }}
                              value={scopeValue}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="px-3">
                          <div className="popformbutton cont_button">
                            <CustomButton
                              label={
                                <>
                                  <Add sx={{ marginBottom: "4px" }} />
                                  <span>Add</span>
                                </>
                              }
                              onClick={() => setAddBuildScope(true)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex_cont">
                        <p className="hrdr text-center">Attachments</p>
                        <div className="vendor_file_uploader p-3">
                          <div onClick={() => setOpen(true)}>
                            <label className="vendor_file_ip_wrp flex_column">
                              <img
                                src={CLOUD_ICON}
                                width="30px"
                                className="image"
                              />
                              <CustomButton
                                label={
                                  <>
                                    <AttachFileOutlined
                                      sx={{ marginBottom: "4px" }}
                                    />
                                    <span>Attach</span>
                                  </>
                                }
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
                {formik?.errors?.attachments?.map((el, index) => (
                  <p className="CustErr">
                    {formik?.errors?.attachments[index]?.name}
                  </p>
                ))}
              </Accordion>
            </div>

            <div>
              <div className="d-flex flex-wrap gap-2 my-4">
                {buildAttachmentsList?.data?.map((el, index) => (
                  <div
                    className="attachments_container"
                    key={el?.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDownloadFile(`${appUrl}${el?.attachment}`);
                    }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <DeleteIndex
                        className="delete_attachment"
                        payload={el?.id}
                        action={deleteBuildAttachmentRequest}
                        loading={deleteBuildAttachmentsLoading}
                        res={deleteBuildAttachmentsResponse}
                        label={<Close />}
                      />
                    </div>
                    <CustomAttachmentIcon attachment={el?.attachment} />
                    <p className="file_uploaded_name">{el?.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="popformbutton">
              <CustomButton size="small" label="Cancel" btnType="secondary" />
              <CustomButton
                size="small"
                // type="submit"
                label={type === "edit" ? "Update" : "Save"}
                onClick={formik.handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
      <CustomDialog
        open={open}
        title="Add Attachments"
        handleClose={handleClose}
        maxWidth="md"
      >
        <form
          onSubmit={
            type === "edit" ? docFormik.handleSubmit : formik.handleSubmit
          }
        >
          <div className="mb-3">
            <MultiDocUpload
              formik={type === "edit" ? docFormik : formik}
              // type={type}
            />
          </div>
          <div className="popformbutton">
            {type === "edit" ? (
              <>
                <CustomButton
                  size="small"
                  btnType="secondary"
                  label="Cancel"
                  onClick={() => handleClose()}
                />
                <CustomButton
                  size="small"
                  label="Upload Attachment"
                  type={"submit"}
                />
              </>
            ) : (
              <CustomButton
                size="small"
                label="Next"
                onClick={() => handleClose()}
              />
            )}
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default BuildPlan;
