import { takeLatest, call, put } from "redux-saga/effects";
import BuildActionTypes from "./BuildActionTypes";
import { axiosGet, axiosPost, axiosPut, axiosDelete } from "../../Utils/axios";
import {
  deleteBuildAttachmentResponse,
  deleteBuildResponse,
  getBuildAttachmentIndexResponse,
  getBuildIndexResponse,
  postBuildAttachmentResponse,
  postBuildResponse,
  showBuildAttachmentResponse,
  showBuildResponse,
  updateBuildAttachmentResponse,
  updateBuildResponse,
} from "./BuildAction";
import { unauthorizedMessage } from "../Login/LoginActions";

// WORKER SAGA
// GET ALL BUILDS
export function* onGetBuildReqAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_builds", payload).then((response) => response?.data)
    );
    yield put(getBuildIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST BUILD
export function* newBuildReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("build/new", payload).then((response) => response?.data)
    );
    yield put(postBuildResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE BUILD
export function* updateBuildReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`build/${payload?.id}`, payload?.formData).then(
        (response) => response?.data
      )
    );
    yield put(updateBuildResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE BUILD
export function* deleteBuildReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`build/${payload}`).then((response) => response?.data)
    );
    yield put(deleteBuildResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR BUILD
export function* viewBuildReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`build/${payload}`).then((response) => response?.data)
    );
    yield put(showBuildResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// BUILD ATTACHMENTS
// GET ALL BUILD ATTACHMENTS
export function* onGetBuildAttachmentsReqAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("build/view_attachments", payload).then(
        (response) => response?.data
      )
    );
    yield put(getBuildAttachmentIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST BUILD ATTACHMENTS
export function* newBuildAttachmentsReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("build/attachment/new", payload).then(
        (response) => response?.data
      )
    );
    yield put(postBuildAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE BUILD ATTACHMENTS
export function* updateBuildAttachmentsReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(
        `build/attachment/${payload?.build_fk}`,
        payload?.formData,
        payload?.build_fk
      ).then((response) => response?.data)
    );
    yield put(updateBuildAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE BUILD ATTACHMENTS
export function* deleteBuildAttachmentsReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`build/attachment/${payload}`).then(
        (response) => response?.data
      )
    );
    yield put(deleteBuildAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR BUILD ATTACHMENT
export function* viewBuildAttachmentsReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`build/attachment/${payload}`).then((response) => response?.data)
    );
    yield put(showBuildAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* BuildWatcherSaga() {
  // BUILD
  yield takeLatest(BuildActionTypes.BUILD_INDEX_REQUEST, onGetBuildReqAsync);
  yield takeLatest(BuildActionTypes.POST_BUILD_REQUEST, newBuildReqWorkerSaga);
  yield takeLatest(
    BuildActionTypes.UPDATE_BUILD_REQUEST,
    updateBuildReqWorkerSaga
  );
  yield takeLatest(
    BuildActionTypes.DELETE_BUILD_REQUEST,
    deleteBuildReqWorkerSaga
  );
  yield takeLatest(BuildActionTypes.SHOW_BUILD_REQUEST, viewBuildReqWorkerSaga);

  // BUILD ATTACHMENTS
  yield takeLatest(
    BuildActionTypes.BUILDATTACHMENT_INDEX_REQUEST,
    onGetBuildAttachmentsReqAsync
  );
  yield takeLatest(
    BuildActionTypes.POST_BUILDATTACHMENT_REQUEST,
    newBuildAttachmentsReqWorkerSaga
  );
  yield takeLatest(
    BuildActionTypes.UPDATE_BUILDATTACHMENT_REQUEST,
    updateBuildAttachmentsReqWorkerSaga
  );
  yield takeLatest(
    BuildActionTypes.DELETE_BUILDATTACHMENT_REQUEST,
    deleteBuildAttachmentsReqWorkerSaga
  );
  yield takeLatest(
    BuildActionTypes.SHOW_BUILDATTACHMENT_REQUEST,
    viewBuildAttachmentsReqWorkerSaga
  );
}
