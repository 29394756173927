import types from "./ExportcolumnActionTypes";

// dashboard
export const getExportRequest = (payload) => ({
  type: types.EXPORT_COLUMN_REQUEST,
  payload,
});

export const getExportResponse = (payload) => ({
  type: types.EXPORT_COLUMN_RESPONSE,
  payload,
});

export const getExportResponseClear = () => ({
  type: types.EXPORT_COLUMN_RES_CLEAR,
});
