import types from "./ExportcolumnActionTypes";

const initialState = {
  ColumnList: [],
  ColumnLoading: false,
  ColumnResponse: null,
};

const ColumnReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EXPORT_COLUMN_REQUEST:
      return {
        ...state,
        ColumnLoading: true,
        ColumnList: null,
      };
    case types.EXPORT_COLUMN_RESPONSE:
      return {
        ...state,
        ColumnLoading: false,
        ColumnList: action.payload?.data,
        ColumnResponse: action.payload,
      };
    case types.EXPORT_COLUMN_RES_CLEAR:
      return {
        ...state,
        ColumnLoading: false,
        ColumnList: null,
        ColumnResponse: null,
      };

    default:
      return state;
  }
};

export default ColumnReducer;
