import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import MetaData from "../../../Components/Organisms/MetaData/MetaData";
import CustomInput from "../../../Components/Atoms/CustomInput/CustomInput";
import { IconButton } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  changeOldPasswordClearResponse,
  changeOldPasswordReq,
} from "../../../Redux/Login/LoginActions";
import {
  handlePaste,
  handlePassword,
  handleToastResponse,
} from "../../../Utils/handles";
import CustomCancelSubmit from "../../../Components/Molecules/CancelSubmit/CancelSubmit";

export default function ChangeOldPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState({
    showOldPassword: false,
    showNewPassword: false,
    showNewConfirmPassword: false,
  });

  const { changeOldPasswordResponse } = useSelector(
    (state) => state.loginState
  );

  const validationArray = Yup.object().shape({
    old_password: Yup.string().required("Old password is required!"),
    password: Yup.string()
      .matches(/^\s*\S[\s\S]*$/g, "Password cannot contain only blankspaces")
      .matches(/[a-z]/, "Must have atleast 1 small letter.")
      .matches(/[A-Z]/, "Must have atleast 1 capital letter.")
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])/,
        "Must have atleast 1 number & 1 special letter."
      )
      .matches(/^.{8,}$/, "Minimum of 8 characters")
      .required("Password is required!"),
    confirm_Password: Yup.string()
      .matches(
        /^\s*\S[\s\S]*$/g,
        "Confirm password cannot contain only blankspaces"
      )
      .oneOf([Yup.ref("password"), ""], "Password must match")
      .required("Confirm password is required!"),
  });

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      confirm_Password: "",
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      dispatch(changeOldPasswordReq(values));
    },
  });

  useEffect(() => {
    handleToastResponse(changeOldPasswordResponse);
    if (changeOldPasswordResponse?.success) {
      formik.resetForm();
    }
    return () => dispatch(changeOldPasswordClearResponse());
  }, [changeOldPasswordResponse]);

  return (
    <>
      <MetaData title={`Change Password`} />
      <div className="pageTop">
        <div className="pageTopLeft">
          <h3>Change Password</h3>
        </div>
      </div>
      <div className="change_password_container">
        <form onSubmit={formik.handleSubmit} className="p-1 bg-white">
          <div className="form">
            <div className="formCont changeOldPass">
              <div className="row">
                <div className="col-md-4">
                  <div className="formLabel">
                    <p className="label">Old Password</p>
                    <CustomInput
                      type={password.showOldPassword ? "text" : "password"}
                      name="old_password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onPaste={handlePaste}
                      value={formik.values.old_password}
                      formik={formik}
                      endAdornment={
                        <IconButton
                          onClick={() =>
                            handlePassword(setPassword, "showOldPassword")
                          }
                        >
                          {password.showOldPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="formLabel">
                    <p className="label">New Password</p>
                    <CustomInput
                      type={password.showNewPassword ? "text" : "password"}
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onPaste={handlePaste}
                      value={formik.values.password}
                      formik={formik}
                      endAdornment={
                        <IconButton
                          onClick={() =>
                            handlePassword(setPassword, "showNewPassword")
                          }
                        >
                          {password.showNewPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="formLabel">
                    <p className="label">Confirm New Password</p>
                    <CustomInput
                      type={
                        password.showNewConfirmPassword ? "text" : "password"
                      }
                      name="confirm_Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onPaste={handlePaste}
                      value={formik.values.confirm_Password}
                      formik={formik}
                      endAdornment={
                        <IconButton
                          onClick={() =>
                            handlePassword(
                              setPassword,
                              "showNewConfirmPassword"
                            )
                          }
                        >
                          {password.showNewConfirmPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <CustomCancelSubmit
              cancelLabel="Cancel"
              submitLabel="Change Password"
              handleClose={() => navigate(-1)}
            />
          </div>
        </form>
      </div>
    </>
  );
}
