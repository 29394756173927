import { takeLatest, call, put } from "redux-saga/effects";
import ReleaseActionTypes from "./ReleaseActionTypes";
import { axiosGet, axiosPost, axiosPut, axiosDelete } from "../../Utils/axios";
import {
  deleteReleaseResponse,
  getReleaseIndexResponse,
  postReleaseResponse,
  showReleaseResponse,
  updateReleaseResponse,
} from "./ReleaseAction";
import { unauthorizedMessage } from "../Login/LoginActions";

// WORKER SAGA
// GET ALL RELEASES
export function* onGetReleaseReqAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_releases", payload).then((response) => response?.data)
    );
    yield put(getReleaseIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST RELEASE
export function* newReleaseReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("release/new", payload).then((response) => response?.data)
    );
    yield put(postReleaseResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE RELEASE
export function* updateReleaseReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`release/${payload?.id}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(updateReleaseResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE RELEASE
export function* deleteReleaseReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`release/${payload}`).then((response) => response?.data)
    );
    yield put(deleteReleaseResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR RELEASE
export function* viewReleaseReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`release/${payload}`).then((response) => response?.data)
    );
    yield put(showReleaseResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* ReleaseWatcherSaga() {
  yield takeLatest(
    ReleaseActionTypes.RELEASE_INDEX_REQUEST,
    onGetReleaseReqAsync
  );
  yield takeLatest(
    ReleaseActionTypes.POST_RELEASE_REQUEST,
    newReleaseReqWorkerSaga
  );
  yield takeLatest(
    ReleaseActionTypes.UPDATE_RELEASE_REQUEST,
    updateReleaseReqWorkerSaga
  );
  yield takeLatest(
    ReleaseActionTypes.DELETE_RELEASE_REQUEST,
    deleteReleaseReqWorkerSaga
  );
  yield takeLatest(
    ReleaseActionTypes.SHOW_RELEASE_REQUEST,
    viewReleaseReqWorkerSaga
  );
}
