import { takeLatest, call, put } from "redux-saga/effects";
import ProjectActionTypes from "./ProjectActionTypes";
import {
  getProjectIndexResponse,
  postProjectResponse,
  updateProjectResponse,
  deleteProjectResponse,
  showProjectResponse,
  showProjectMembersResponse,
  deleteProjectMemberResponse,
  getAppProjectsIndexResponse,
  getApplicationsIndexResponse,
  postIssueTrackerResponse,
  deleteIssueTrackerResponse,
  getIssueTrackerIndexResponse,
} from "./ProjectAction";
import { axiosGet, axiosPost, axiosPut, axiosDelete } from "../../Utils/axios";
import { unauthorizedMessage } from "../Login/LoginActions";

// WORKER SAGA
// GET ALL PROJECTS
export function* onGetProjectReqAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_projects", payload).then((response) => response?.data)
    );
    yield put(getProjectIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST PROJECT
export function* newProjectReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("project/new", payload).then((response) => response?.data)
    );
    yield put(postProjectResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE PROJECT
export function* updateProjectReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`project/${payload?.id}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(updateProjectResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE PROJECT
export function* deleteProjectReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`project/${payload}`).then((response) => response?.data)
    );
    yield put(deleteProjectResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR PROJECT
export function* viewProjectReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`project/${payload}`).then((response) => response?.data)
    );
    yield put(showProjectResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PROJECT MEMBERS
export function* getProjectMembersReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_project_members", payload).then(
        (response) => response?.data
      )
    );
    yield put(showProjectMembersResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE PROJECT MEMBER
export function* deleteProjectMemberReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("remove_project_member", payload).then(
        (response) => response?.data
      )
    );
    yield put(deleteProjectMemberResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET ALL APPLICATIONS
export function* getApplicationsReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_applications", payload).then((response) => response?.data)
    );
    yield put(getApplicationsIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET APPLICATION PROJECTS
export function* getAppProjectsReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("integration/projects", payload).then(
        (response) => response?.data
      )
    );
    yield put(getAppProjectsIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET ALL ISSUE TRACKER
export function* getIssueTrackerReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_issue_trackers", payload).then(
        (response) => response?.data
      )
    );
    yield put(getIssueTrackerIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST ISSUE TRACKER
export function* newIssueTrackerReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("issue_tracker/new", payload).then((response) => response?.data)
    );
    yield put(postIssueTrackerResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE ISSUE TRACKER
export function* deleteIssueTrackerReqWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost(`issue_tracker${payload?.id}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(deleteIssueTrackerResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* ProjectWatcherSaga() {
  yield takeLatest(
    ProjectActionTypes.PROJECT_INDEX_REQUEST,
    onGetProjectReqAsync
  );
  yield takeLatest(
    ProjectActionTypes.POST_PROJECT_REQUEST,
    newProjectReqWorkerSaga
  );
  yield takeLatest(
    ProjectActionTypes.UPDATE_PROJECT_REQUEST,
    updateProjectReqWorkerSaga
  );
  yield takeLatest(
    ProjectActionTypes.DELETE_PROJECT_REQUEST,
    deleteProjectReqWorkerSaga
  );
  yield takeLatest(
    ProjectActionTypes.SHOW_PROJECT_REQUEST,
    viewProjectReqWorkerSaga
  );
  yield takeLatest(
    ProjectActionTypes.GET_PROJECTMEMBERS_REQUEST,
    getProjectMembersReqWorkerSaga
  );
  yield takeLatest(
    ProjectActionTypes.DELETE_PROJECTMEMBERS_REQUEST,
    deleteProjectMemberReqWorkerSaga
  );
  yield takeLatest(
    ProjectActionTypes.GET_APPLICATIONS_INDEX_REQUEST,
    getApplicationsReqWorkerSaga
  );
  yield takeLatest(
    ProjectActionTypes.GET_APP_PROJECTS_INDEX_REQUEST,
    getAppProjectsReqWorkerSaga
  );
  yield takeLatest(
    ProjectActionTypes.GET_ISSUETRACKER_INDEX_REQUEST,
    getIssueTrackerReqWorkerSaga
  );
  yield takeLatest(
    ProjectActionTypes.POST_ISSUETRACKER_REQUEST,
    newIssueTrackerReqWorkerSaga
  );
  yield takeLatest(
    ProjectActionTypes.DELETE_ISSUETRACKER_REQUEST,
    deleteIssueTrackerReqWorkerSaga
  );
}
