import { all } from "redux-saga/effects";
import { loginWatcherSaga } from "./Login/LoginSaga";
import { ProjectWatcherSaga } from "./ProjectSaga/ProjectSaga";
import { registerWatcherSaga } from "./Register/RegisterSaga";
import { inviteWatcherSaga } from "./Invite/InviteSaga";
import { ReleaseWatcherSaga } from "./ReleaseSaga/ReleaseSaga";
import { BuildWatcherSaga } from "./BuildSaga/BuildSaga";
import { notificationWatcherSaga } from "./NotificationSaga/NotificationSaga";
import { RequirementWatcherSaga } from "./RequirementSaga/RequirementSaga";
import { RequirementCommentWatcherSaga } from "./RequirementSaga/RequirementCommentSaga/RequirementCommentSaga";
import { TestDesignWatcherSaga } from "./TestDesignSaga/TestDesignSaga";
import { TestDesignCommentWatcherSaga } from "./TestDesignSaga/TestDesignCommentSaga/TestDesignCommentSaga";
import { TestExecutionWatcherSaga } from "./TestExecution/TestExecutionSaga";
import { DefectsWatcherSaga } from "./DefectsSaga/DefectsSaga";
import { dashboardWatcherSaga } from "./DashboardSaga/DashboardSaga";
import { ReportWatcherSaga } from "./ReportSaga/ReportSaga";
import { ExportWatcherSaga } from "./ExportcolumnSaga/ExportSaga";
import { IntegrationWatcherSaga } from "./IntegrationSaga/IntegrationSaga";
import { TaskWatcherSaga } from "./TaskSaga/TaskSaga";

export default function* rootSaga() {
  yield all([
    // LOGIN
    loginWatcherSaga(),

    // REGISTER
    registerWatcherSaga(),

    // INVITE USER
    inviteWatcherSaga(),

    // PROJECT
    ProjectWatcherSaga(),

    // BUILD
    BuildWatcherSaga(),

    // RELEASE
    ReleaseWatcherSaga(),

    // NOTIFICATION
    notificationWatcherSaga(),

    // REQUIREMENT DETAIL AND MODULE
    RequirementWatcherSaga(),

    // REQUIREMENT HISTORY AND COMMENT
    RequirementCommentWatcherSaga(),

    // TESTDESIGN
    TestDesignWatcherSaga(),

    // TESTDESIGN COMMENT
    TestDesignCommentWatcherSaga(),

    // TEST EXECUTION
    TestExecutionWatcherSaga(),

    // DEFECT
    DefectsWatcherSaga(),

    // DASHBOARD
    dashboardWatcherSaga(),

    // REPORT
    ReportWatcherSaga(),

    //columnsaga
    ExportWatcherSaga(),

    // INTEGRATION
    IntegrationWatcherSaga(),

    // TASK
    TaskWatcherSaga(),
  ]);
}
