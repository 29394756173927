import IntegrationActionTypes from "./IntegrationActionTypes";

// GET ALL APPLICATIONS
export const getApplicationsIndexRequest = (payload) => ({
  type: IntegrationActionTypes.GET_APPLICATION_INDEX_REQUEST,
  payload,
});

export const getApplicationsIndexResponse = (payload) => ({
  type: IntegrationActionTypes.GET_APPLICATION_INDEX_RESPONSE,
  payload,
});

// INTEGRATION
// POST INTEGRATION
export const postIntegrateRequest = (payload) => ({
  type: IntegrationActionTypes.POST_INTEGRATION_REQUEST,
  payload,
});

export const postIntegrationResponse = (payload) => ({
  type: IntegrationActionTypes.POST_INTEGRATION_RESPONSE,
  payload,
});

// UPDATE INTEGRATION
export const updateIntegrateRequest = (payload) => ({
  type: IntegrationActionTypes.UPDATE_INTEGRATION_REQUEST,
  payload,
});

export const updateIntegrationResponse = (payload) => ({
  type: IntegrationActionTypes.UPDATE_INTEGRATION_RESPONSE,
  payload,
});

// POST AND UPDATE RESPONSE CLEAR
export const postIntegrationResClear = () => ({
  type: IntegrationActionTypes.POST_INTEGRATION_RES_CLEAR,
});

// GET PARTICULAR INTEGRATION
export const showIntegrateRequest = (payload) => ({
  type: IntegrationActionTypes.SHOW_INTEGRATION_REQUEST,
  payload,
});

export const showIntegrationResponse = (payload) => ({
  type: IntegrationActionTypes.SHOW_INTEGRATION_RESPONSE,
  payload,
});

export const showIntegrationResClear = () => ({
  type: IntegrationActionTypes.SHOW_INTEGRATION_RES_CLEAR,
});
