import { ReportActionTypes } from "./ReportActionTypes";
import { takeLatest, call, put } from "@redux-saga/core/effects";
import { unauthorizedMessage } from "../Login/LoginActions";
import { getReportIndexResponse } from "./ReportAction";
import { axiosPost } from "../../Utils/axios";

// WORKER SAGA
// GET REPORT
export function* onGetReportIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("reports", payload).then((response) => response?.data)
    );
    yield put(getReportIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* ReportWatcherSaga() {
  yield takeLatest(
    ReportActionTypes.GET_REPORT_INDEX_REQUEST,
    onGetReportIndexWorkerSaga
  );
}
