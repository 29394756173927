import React from "react";
import { Button } from "@mui/material";
import "./CustomButton.css";
import PropTypes from "prop-types";

const CustomButton = ({
  btnClass,
  size,
  btnType,
  onClick,
  type,
  disabled,
  label,
}) => {
  return (
    <div
      className={`btnWrpr ${btnClass} ${
        btnType === "secondary"
          ? "secButton"
          : btnType === "tertiary"
          ? "tertiButton"
          : btnType === "quatnery"
          ? "quatButton"
          : "primButton"
      }`}
    >
      <Button
        className={`${size}`}
        variant={btnType === "secondary" ? "outlined" : "contained"}
        onClick={onClick}
        type={type}
        size={size}
        disabled={disabled}
        disableElevation
      >
        {label}
      </Button>
    </div>
  );
};

CustomButton.propTypes = {
  btnClass: PropTypes.string,
  size: PropTypes.string,
  btnType: PropTypes.oneOf(["primary", "secondary"]),
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};
export default CustomButton;
