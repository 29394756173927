import RequirementCommentActionTypes from "./RequirementCommentActionTypes";
import { call, takeLatest, put } from "redux-saga/effects";
import {
  axiosGet,
  axiosDelete,
  axiosPost,
  axiosPut,
} from "../../../Utils/axios";
import { unauthorizedMessage } from "../../Login/LoginActions";
import {
  deleteRequirementCommentResponse,
  getRequirementCommentIndexResponse,
  getRequirementHistoryIndexResponse,
  postRequirementCommentResponse,
  showRequirementCommentResponse,
  updateRequirementCommentResponse,
} from "./RequirementCommentAction";

// WORKER SAGA
// REQUIREMENT HISTORY
// GET ALL REQUIREMENT HISTORY
export function* onGetRequirementHistoryIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`requirement/${payload}/get_history`).then(
        (response) => response?.data
      )
    );
    yield put(getRequirementHistoryIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// REQUIREMENT COMMENT
// GET ALL REQUIREMENT COMMENT
export function* onGetRequirementCommentIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("requirement/view_comments", payload).then(
        (response) => response?.data
      )
    );
    yield put(getRequirementCommentIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//  POST REQUIREMENT COMMENT
export function* onPostRequirementCommentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("requirement/comment/new", payload).then(
        (response) => response?.data
      )
    );
    yield put(postRequirementCommentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//  UPDATE REQUIREMENT COMMENT
export function* onUpdateRequiremenCommentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`requirement/comment/${payload?.requirement_fk}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(updateRequirementCommentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE REQUIREMENT COMMENT
export function* onDeleteRequiremenCommentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`requirement/comment/${payload}`).then(
        (response) => response?.data
      )
    );
    yield put(deleteRequirementCommentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR REQUIREMENT COMMENT
export function* onViewRequiremenCommentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`requirement/comment/${payload}`).then(
        (response) => response?.data
      )
    );
    yield put(showRequirementCommentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* RequirementCommentWatcherSaga() {
  // REQUIREMENT HISTORY
  yield takeLatest(
    RequirementCommentActionTypes.GET_REQUIREMENTHISTORY_INDEX_REQUEST,
    onGetRequirementHistoryIndexWorkerSaga
  );
  // REQUIREMENT COMMENT
  yield takeLatest(
    RequirementCommentActionTypes.GET_REQUIREMENTCOMMENT_INDEX_REQUEST,
    onGetRequirementCommentIndexWorkerSaga
  );
  yield takeLatest(
    RequirementCommentActionTypes.POST_REQUIREMENTCOMMENT_REQUEST,
    onPostRequirementCommentWorkerSaga
  );
  yield takeLatest(
    RequirementCommentActionTypes.UPDATE_REQUIREMENTCOMMENT_REQUEST,
    onUpdateRequiremenCommentWorkerSaga
  );
  yield takeLatest(
    RequirementCommentActionTypes.DELETE_REQUIREMENTCOMMENT_REQUEST,
    onDeleteRequiremenCommentWorkerSaga
  );
  yield takeLatest(
    RequirementCommentActionTypes.DELETE_REQUIREMENTCOMMENT_RESPONSE,
    onGetRequirementCommentIndexWorkerSaga
  );
  yield takeLatest(
    RequirementCommentActionTypes.SHOW_REQUIREMENTCOMMENT_REQUEST,
    onViewRequiremenCommentWorkerSaga
  );
}
