import { takeLatest, call, put } from "redux-saga/effects";
import InviteActionTypes from "./InviteActiontypes";
import { axiosGet, axiosPost } from "../../Utils/axios";
import { inviteIndexUserSuccess, inviteSuccess } from "./InviteActions";
import { unauthorizedMessage } from "../Login/LoginActions";

export function* inviteWorkerSaga({ payload }) {
  try {
    let res = yield call(() =>
      axiosPost("invite_to_project", payload).then((response) => response?.data)
    );

    yield put(inviteSuccess(res));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//CURRENT USER
export function* inviteIndexWorkerSaga() {
  try {
    let res = yield call(() =>
      axiosGet("invite/index").then((res) => res?.data)
    );
    yield put(inviteIndexUserSuccess(res));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

export function* inviteWatcherSaga() {
  yield takeLatest(InviteActionTypes.INVITE_REQUESTED, inviteWorkerSaga);
  yield takeLatest(
    InviteActionTypes.INVITE_INDEX_REQUEST,
    inviteIndexWorkerSaga
  );
}
