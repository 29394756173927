import React, { useEffect, useState } from "react";
import { appUrl } from "../../Utils/axios";
import { handleDownloadFile } from "../../Utils/handles";

const ImageSlider = (props) => {
  const { attachments, data } = props;

  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (data?.length > 0) {
      const attachmentURLs = data?.map((attachment) => {
        return `${appUrl}${attachment.attachment}`;
      });
      setImages(attachmentURLs);
    }
  }, [data]);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  console.log(images[currentIndex]?.toLowerCase()?.endsWith(".jpg"), "imagess");

  return (
    <>
      <div className="d-flex align-items-center gap-3 sliderHeight">
        {images.length != 0 ? (
          <>
            <button className="prev-button" onClick={goToPrevSlide}>
              &#10094;
            </button>
            <div className="image-slider">
              {images[currentIndex].toLowerCase().endsWith(".jpg") ||
              images[currentIndex].toLowerCase().endsWith(".jpeg") ||
              images[currentIndex].toLowerCase().endsWith(".png") ||
              images[currentIndex].toLowerCase().endsWith(".gif") ? (
                <img
                  src={images[currentIndex]}
                  alt={`Image ${currentIndex + 1}`}
                />
              ) : (
                <p
                  onClick={() => {
                    handleDownloadFile(`${images[currentIndex]}`);
                  }}
                  className="downloadFile"
                >
                  {images[currentIndex]}
                </p>
              )}
            </div>
            <button className="next-button" onClick={goToNextSlide}>
              &#10095;
            </button>
          </>
        ) : (
          <p className="py-3"> No Attachments</p>
        )}
      </div>
      {images.length != 0 ? (
        <p className="text-center p-2 fs-6">{`${currentIndex + 1}/${
          images.length
        }`}</p>
      ) : (
        ""
        // <p className="text-center p-2 fs-6">{`${currentIndex}/${images.length}`}</p>
      )}
    </>
  );
};

export default ImageSlider;
