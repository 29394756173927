import BuildActionTypes from "./BuildActionTypes";
import { CLEAR_ERROR_RES } from "../Login/LoginActionTypes";

const initialState = {
  // BUILD
  buildList: [],
  buildListLoading: false,
  buildListResponse: null,

  postBuildLoading: false,
  postBuildResponse: null,

  deleteBuildLoading: false,
  deleteBuildResponse: null,

  build: null,
  viewBuildLoading: false,
  viewBuildResponse: null,

  // BUILD ATTACHMENTS
  buildAttachmentsList: [],
  buildAttachmentsListLoading: false,
  buildAttachmentsListResponse: null,

  postBuildAttachmentsLoading: false,
  postBuildAttachmentsResponse: null,

  deleteBuildAttachmentsLoading: false,
  deleteBuildAttachmentsResponse: null,

  buildAttachments: null,
  viewBuildAttachmentsLoading: false,
  viewBuildAttachmentsResponse: null,
};

const BuildReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET ALL BUILDS
    case BuildActionTypes.BUILD_INDEX_REQUEST:
      return {
        ...state,
        buildListLoading: true,
        buildList: null,
      };
    case BuildActionTypes.BUILD_INDEX_RESPONSE:
      return {
        ...state,
        buildListLoading: false,
        buildList: action.payload?.data,
        buildListResponse: action.payload,
      };

    // POST BUILD
    case BuildActionTypes.POST_BUILD_REQUEST:
      return {
        ...state,
        postBuildLoading: true,
      };

    case BuildActionTypes.POST_BUILD_RESPONSE:
      return {
        ...state,
        postBuildLoading: false,
        postBuildResponse: action.payload,
      };

    // UPDATE BUILD
    case BuildActionTypes.UPDATE_BUILD_REQUEST:
      return {
        ...state,
        postBuildLoading: true,
      };

    case BuildActionTypes.UPDATE_BUILD_RESPONSE:
      return {
        ...state,
        postBuildLoading: false,
        postBuildResponse: action.payload,
      };

    case BuildActionTypes.POST_BUILD_RES_CLEAR:
      return {
        ...state,
        postBuildResponse: null,
        postBuildLoading: false,
      };

    // DELETE BUILD
    case BuildActionTypes.DELETE_BUILD_REQUEST:
      return {
        ...state,
        deleteBuildLoading: true,
      };
    case BuildActionTypes.DELETE_BUILD_RESPONSE:
      return {
        ...state,
        deleteBuildLoading: false,
        deleteBuildResponse: action.payload,
      };

    case BuildActionTypes.DELETE_BUILD_RES_CLEAR:
      return {
        ...state,
        deleteBuildResponse: null,
        deleteBuildLoading: false,
      };

    // GET PARTICULAR BUILD
    case BuildActionTypes.SHOW_BUILD_REQUEST:
      return {
        ...state,
        viewBuildLoading: true,
      };

    case BuildActionTypes.SHOW_BUILD_RESPONSE:
      return {
        ...state,
        viewBuildLoading: false,
        viewBuildResponse: action.payload,
        build: action.payload.data,
      };

    case BuildActionTypes.SHOW_BUILD_RES_CLEAR:
      return {
        ...state,
        build: null,
        viewBuildLoading: false,
        viewBuildResponse: null,
      };

    // GET ALL BUILDS ATTACHMENTS
    case BuildActionTypes.BUILDATTACHMENT_INDEX_REQUEST:
      return {
        ...state,
        buildAttachmentsListLoading: true,
      };
    case BuildActionTypes.BUILDATTACHMENT_INDEX_RESPONSE:
      return {
        ...state,
        buildAttachmentsListLoading: false,
        buildAttachmentsList: action.payload?.data,
        buildAttachmentsListResponse: action.payload,
      };
    case BuildActionTypes.BUILDATTACHMENT_INDEX_RES_CLEAR:
      return {
        ...state,
        buildAttachmentsListLoading: false,
        buildAttachmentsList: null,
        buildAttachmentsListResponse: null,
      };

    // POST BUILD ATTACHMENT
    case BuildActionTypes.POST_BUILDATTACHMENT_REQUEST:
      return {
        ...state,
        postBuildAttachmentsLoading: true,
      };

    case BuildActionTypes.POST_BUILDATTACHMENT_RESPONSE:
      return {
        ...state,
        postBuildAttachmentsLoading: false,
        postBuildAttachmentsResponse: action.payload,
      };

    // UPDATE BUILD ATTACHMENTS
    case BuildActionTypes.UPDATE_BUILDATTACHMENT_REQUEST:
      return {
        ...state,
        postBuildAttachmentsLoading: true,
      };

    case BuildActionTypes.UPDATE_BUILDATTACHMENT_RESPONSE:
      return {
        ...state,
        postBuildAttachmentsLoading: false,
        postBuildAttachmentsResponse: action.payload,
      };

    case BuildActionTypes.POST_BUILDATTACHMENT_RES_CLEAR:
      return {
        ...state,
        postBuildAttachmentsResponse: null,
        postBuildAttachmentsLoading: false,
      };

    // DELETE BUILD ATTACHMENTS
    case BuildActionTypes.DELETE_BUILDATTACHMENT_REQUEST:
      return {
        ...state,
        deleteBuildAttachmentsLoading: true,
      };
    case BuildActionTypes.DELETE_BUILDATTACHMENT_RESPONSE:
      return {
        ...state,
        deleteBuildAttachmentsLoading: false,
        deleteBuildAttachmentsResponse: action.payload,
      };

    case BuildActionTypes.DELETE_BUILDATTACHMENT_RES_CLEAR:
      return {
        ...state,
        deleteBuildAttachmentsResponse: null,
        deleteBuildAttachmentsLoading: false,
      };

    // GET PARTICULAR BUILD ATTACHMENT
    case BuildActionTypes.SHOW_BUILDATTACHMENT_REQUEST:
      return {
        ...state,
        viewBuildAttachmentsLoading: true,
      };

    case BuildActionTypes.SHOW_BUILDATTACHMENT_RESPONSE:
      return {
        ...state,
        viewBuildAttachmentsLoading: false,
        viewBuildAttachmentsResponse: action.payload,
        buildAttachments: action.payload.data,
      };

    case BuildActionTypes.SHOW_BUILDATTACHMENT_RES_CLEAR:
      return {
        ...state,
        buildAttachments: null,
        viewBuildAttachmentsLoading: false,
        viewBuildAttachmentsResponse: null,
      };
    case CLEAR_ERROR_RES:
      return {
        ...state,
        // BUILD
        buildListLoading: false,
        buildListResponse: null,

        postBuildLoading: false,
        postBuildResponse: null,

        deleteBuildLoading: false,
        deleteBuildResponse: null,

        viewBuildLoading: false,
        viewBuildResponse: null,

        // BUILD ATTACHMENTS
        buildAttachmentsListLoading: false,
        buildAttachmentsListResponse: null,

        postBuildAttachmentsLoading: false,
        postBuildAttachmentsResponse: null,

        deleteBuildAttachmentsLoading: false,
        deleteBuildAttachmentsResponse: null,

        viewBuildAttachmentsLoading: false,
        viewBuildAttachmentsResponse: null,
      };
    default:
      return state;
  }
};

export default BuildReducer;
