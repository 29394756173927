import React from "react";
import {
  Description,
  FileCopy,
  InsertDriveFile,
  PictureAsPdfOutlined,
  TextSnippet,
} from "@mui/icons-material";
import "./CustomAttachmentIcon.css";

const CustomAttachmentIcon = ({ attachment }) => {
  return (
    <div className="attachments_box flex_column_center ">
      {attachment
        ?.split(".")
        ?.pop()
        ?.includes("doc" || "docx") ? (
        <TextSnippet className="attachments_icon" />
      ) : attachment?.split(".")?.pop()?.includes("pdf") ? (
        <PictureAsPdfOutlined className="attachments_icon" />
      ) : attachment?.split(".")?.pop()?.includes("xlsx") ||
        attachment?.split(".")?.pop()?.includes("xls") ? (
        <Description className="attachments_icon" />
      ) : attachment?.split(".")?.pop()?.includes("txt") ? (
        <TextSnippet className="attachments_icon" />
      ) : attachment
          ?.split(".")
          ?.pop()
          ?.includes("ppt" || "pptx") ? (
        <FileCopy className="attachments_icon" />
      ) : (
        <InsertDriveFile className="attachments_icon" />
      )}
    </div>
  );
};

export default CustomAttachmentIcon;
