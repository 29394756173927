import React from "react";
import { Pagination } from "@mui/material";
import "./Pagination.css";

export const CustomPagination = ({
  dataList,
  name,
  applied = true,
  listPerPage,
  page,
  setPage,
}) => {
  const itemLength = dataList?.data?.length || 0;
  const totalItems = dataList?.total_count || 0;
  const startItem = listPerPage * (page - 1) + 1;
  const endItem = startItem + itemLength - 1;

  return (
    <div className="pagination_container">
      <div className="TableLen">
        <p>
          Displaying
          {itemLength > 0 ? (
            <span>
              {startItem} - {endItem}
            </span>
          ) : (
            <span>0</span>
          )}
          of <span>{totalItems}</span> {`${applied ? "Applied" : ""} `}
          {(applied = true && `${name}${itemLength > 1 ? "s" : ""}`)}
        </p>
      </div>
      <div className="pagination flex_row_center">
        <Pagination
          count={Math.ceil(totalItems / listPerPage) || 1}
          onChange={(e, val) => {
            setPage(val);
          }}
          page={page}
          boundaryCount={1}
          siblingCount={1}
        />
      </div>
    </div>
  );
};
