import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import { ErrorMessage, useFormik } from "formik";
import moment from "moment";
import ViewTask from "./ViewTask";
import {
  convertedString,
  handleAttachmentName,
  handleDownloadFile,
} from "../../Utils/handles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  AttachFileOutlined,
  PlayArrow,
  VisibilityOutlined,
  InsertPhotoOutlined,
  FileUploadOutlined,
  Close,
  ExpandMore,
} from "@mui/icons-material";
import { viewExecutionByIdRequest } from "../../Redux/TestExecution/TestExecutionAction";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import ImageSlider from "./ImageSlider";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import CustomCancelSubmit from "../../Components/Molecules/CancelSubmit/CancelSubmit";
import {
  getTaskChoicesIndexRequest,
  getTasksIndexRequest,
  postTaskRequest,
  postTaskResClear,
} from "../../Redux/TaskSaga/TaskAction";
import { crmPriority } from "../../Utils/constants";
import { getApplicationsIndexRequest } from "../../Redux/ProjectSaga/ProjectAction";
import { appUrl } from "../../Utils/axios";
import toast from "react-hot-toast";

const TestExecutionView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, executionid } = useParams();

  const { viewExectionByid } = useSelector((state) => state.testExecution);
  const { taskList, taskChoices, postTaskResponse, postTaskLoading } =
    useSelector((state) => state.task);
  const { applicationList } = useSelector((state) => state.project);

  const [accordionVisible, setAccordionVisible] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(false);
  const [newData, setNewData] = useState();
  const [giIds, setGiIds] = useState([]);
  const [preview, setPreview] = useState(false);
  const [fileData, setFileData] = useState();
  const [applicationId, setApplicationId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [addAttachments, setAddAttachments] = useState([]);
  const [view, setView] = useState(false);
  const [viewId, setViewID] = useState(false);

  const validationArr = Yup.object().shape({
    title: Yup.string().required("Title is required!"),
    // due_date: Yup.date().required("Due date is required"),
    column: Yup.number().required("Column is required!"),
    priority: Yup.string().required("Priority is required!"),
    application_id: Yup.number().required("Application is required!"),
    description: Yup.string().required("Summary is required!"),
    due_date: Yup.string()
      .required("Required")
      .test("date", "Date must not be in the past", function (value) {
        const selectedDate = new Date(value);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return (
          selectedDate > currentDate ||
          selectedDate.toDateString() === currentDate.toDateString()
        );
      }),
  });

  const formik = useFormik({
    initialValues: {
      title: "Title",
      description: "",
      due_date: "",
      column: "",
      assign_fk: "",
      test_case_id: executionid,
      application_id: "",
      priority: "",
      attachment: [],
      attachment_ids: [],
    },
    validationSchema: validationArr,
    onSubmit: (values) => {
      const attachmentsIds = selectedIds.map((data) => data);
      const formData = new FormData();
      for (let key in values) {
        if (key === "attachment_ids") {
          if (attachmentsIds.length > 0) {
            attachmentsIds.map((data, index) =>
              formData.append(`attachment_ids${[index]}`, data)
            );
          }
        } else if (key === "attachment") {
          if (addAttachments.length > 0) {
            addAttachments.map((data) => formData.append("attachment", data));
          }
        } else {
          formData.append(`${key}`, values[key]);
        }
      }
      dispatch(postTaskRequest(formData));
    },
  });

  // FLATMAP FOR NESTED MAPPINGS
  const attachmentIds =
    viewExectionByid?.data?.test_steps_at_execution_data?.flatMap((step) =>
      step?.bug?.flatMap((bug) =>
        bug?.attachments
          ? bug?.attachments?.map((attachment) => ({
              id: attachment?.id,
              file: attachment?.attachment,
            }))
          : []
      )
    );

  const handlePlayArrowClick = (index) => {
    const updatedAccordionVisibleArray = [...accordionVisible];
    updatedAccordionVisibleArray[index] = !updatedAccordionVisibleArray[index];
    setAccordionVisible(updatedAccordionVisibleArray);
  };

  // const handleSelectPoIds = (item) => {
  //   setGiIds((state) =>
  //     state.includes(item)
  //       ? state.filter((selectedItem) => selectedItem.id !== item.id).sort()
  //       : [...state, item].sort()
  //   );
  //   console.log(giIds, "ids");
  //   if (giIds) {
  //     formik.setFieldValue(
  //       "description",
  //       giIds.map((data, index) => data?.summary).join(", ")
  //     );
  //   }
  // };
  const handleSelectPoIds = (item) => {
    setGiIds((state) =>
      state.includes(item)
        ? state.filter((selectedItem) => selectedItem.id !== item.id).sort()
        : [...state, item].sort()
    );
  };
  const handleCheckboxChange = (bugData) => {
    handleSelectPoIds(bugData);

    // Move this inside the callback of setGiIds to ensure it uses the updated state
    setGiIds((updatedGiIds) => {
      formik.setFieldValue(
        "description",
        updatedGiIds.map((data) => data?.summary).join(", ")
      );
      return updatedGiIds;
    });
  };
  const handlePreviewallImages = (files) => {
    setPreview(true);
    setFileData(files);
  };

  const handlePreviewallImagesClose = () => {
    setPreview(false);
    setFileData(null);
  };

  const handleViewOpen = (id) => {
    setView(true);
    setViewID(id);
  };

  const handleViewClose = () => {
    setView(false);
    setViewID(null);
  };

  useEffect(() => {
    dispatch(viewExecutionByIdRequest(executionid));
    dispatch(
      getApplicationsIndexRequest({
        filters: {},
        pagination_meta: {},
      })
    );
    const applicationtoken_id = localStorage.getItem("application_id");
    if (applicationtoken_id) {
      formik.setFieldValue("application_id", applicationtoken_id);
      setSelectedCheckboxes(true);
    }
  }, []);

  useEffect(() => {
    if (formik.values.application_id) {
      dispatch(
        getTaskChoicesIndexRequest({
          project_id: id,
          application_id: formik.values.application_id,
        })
      );
      dispatch(
        getTasksIndexRequest({
          test_case_id: executionid,
          application_id: formik.values.application_id,
        })
      );
    }
  }, [formik.values.application_id]);

  useEffect(() => {
    // if (summaryValues) {
    // formik.setFieldValue(
    //   "content",
    //   giIds.map((data) => data.summary)
    // );
    // }
  }, [newData]);

  useEffect(() => {
    if (!postTaskResponse) return;
    if (postTaskResponse) {
      if (postTaskResponse?.message && postTaskResponse?.success) {
        toast.success(postTaskResponse?.message);
        dispatch(
          getTasksIndexRequest({
            test_case_id: executionid,
            application_id: applicationId,
          })
        );
        formik.resetForm();
        setSelectedIds([]);
        setGiIds([]);
        setAddAttachments([]);
      } else {
        toast.error(postTaskResponse?.message);
      }
    }
    return () => {
      dispatch(postTaskResClear());
    };
  }, [postTaskResponse]);

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    let file = event.target.files[0];
    setAddAttachments([...addAttachments, file]);
  };

  const handleFileRemove = (name) => {
    const filteredAttachments = addAttachments.filter(
      (data) => data.name !== name
    );
    setAddAttachments(filteredAttachments);
  };

  useEffect(() => {
    if (applicationId) {
      dispatch(
        getTasksIndexRequest({
          test_case_id: executionid,
          application_id: applicationId,
        })
      );
    }
  }, [applicationId]);

  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckBoxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleDefaultCheckBox = () => {
    setSelectedCheckboxes(!selectedCheckboxes);
    if (selectedCheckboxes) {
      localStorage.setItem("application_id", "");
    } else {
      localStorage.setItem("application_id", formik.values.application_id);
    }
  };

  return (
    <div className="view_execution_container">
      <div className="dashboard_container releaseLayout w-100 overflow-auto">
        <div className="position-relative">
          <div className="test_header ">
            <h3>Run</h3>
          </div>
        </div>
        <form>
          <div className="row">
            <div className="col-md-6 details_list">
              <h3 className="defect_head">TestCase ID</h3>
              <p>:</p>
              <h3 className="defect_values">
                {viewExectionByid?.data?.test_case_data?.id}
              </h3>
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Created At</h3>
              <p>:</p>
              <h3 className="defect_values">
                {moment(
                  viewExectionByid?.data?.test_case_data?.created_at
                ).format("DD.MM.YYYY h:mm:ssA")}
              </h3>
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Status</h3>
              <p>:</p>
              <h3 className="defect_values">
                {convertedString(
                  viewExectionByid?.data?.test_case_data?.status
                )}
              </h3>
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Updated At</h3>
              <p>:</p>
              <h3 className="defect_values">
                {moment(
                  viewExectionByid?.data?.test_case_data?.updated_at
                ).format("DD.MM.YYYY h:mm:ssA")}
              </h3>
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Type</h3>
              <p>:</p>
              <h3 className="defect_values">
                {convertedString(viewExectionByid?.data?.test_case_data?.type)}
              </h3>
            </div>
            {/* <div className="col-md-6 details_list">
              <h3 className="defect_head">Is Approved</h3>
              <p>:</p>
              <h3 className="defect_values">
                {viewExectionByid?.data?.test_case_data?.is_approved
                  ? "Approved"
                  : "Not Approved"}
              </h3>
            </div> */}
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Version</h3>
              <p>:</p>
              <h3 className="defect_values">
                {viewExectionByid?.data?.test_case_data?.version}
              </h3>
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Requirement Id</h3>
              <p>:</p>
              <h3 className="defect_values">
                {viewExectionByid?.data?.test_case_data?.requirement_fk}
              </h3>
            </div>{" "}
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Priority</h3>
              <p>:</p>
              <h3 className="defect_values">
                {convertedString(
                  viewExectionByid?.data?.test_case_data?.priority
                )}
              </h3>
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Assinged To</h3>
              <p>:</p>
              <h3 className="defect_values">
                {convertedString(
                  viewExectionByid?.data?.test_case_data?.assigned_to_fk
                    ?.full_name
                )}
              </h3>
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Description</h3>
              <p>:</p>
              <h3 className="defect_values">
                {viewExectionByid?.data?.test_case_data?.description}
              </h3>
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Created By</h3>
              <p>:</p>
              <h3 className="defect_values">
                {
                  viewExectionByid?.data?.test_case_data?.created_by_fk
                    ?.full_name
                }
              </h3>
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Pre - condition</h3>
              <p>:</p>
              <h3 className="defect_values">
                {viewExectionByid?.data?.test_case_data?.pre_condition}
              </h3>
            </div>
            <div className="col-md-6 details_list">
              <h3 className="defect_head">Approved By</h3>
              <p>:</p>
              <h3 className="defect_values">
                {
                  viewExectionByid?.data?.test_case_data?.approved_by_fk
                    ?.full_name
                }
              </h3>
            </div>
          </div>
        </form>
        <div className="d-flex gap-3 mt-4">
          <div className="d-flex  gap-2">
            <div className="mt-2">
              <h3 style={{ fontSize: "13px" }}>Track Issue in</h3>
            </div>
            <div className="">
              <CustomSelect
                size="small"
                placeholder="Select Application"
                options={applicationList?.data?.map((values) => {
                  return {
                    value: values?.id,
                    name: convertedString(values?.name),
                  };
                })}
                name="application_id"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.application_id}
                formik={formik}
              />
            </div>
          </div>
          <div className="col-md-1 d-flex gap-2 mt-2">
            <Checkbox
              className={"table_checkbox"}
              checked={selectedCheckboxes}
              onChange={() => handleDefaultCheckBox()}
            />
            <h3 style={{ fontSize: "13px", marginTop: "5px" }}>Default</h3>
          </div>
        </div>
        <div className="tblWrpr">
          <div className="table-responsive">
            <div className="accordian_containers">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Step No</th>
                    <th>Description</th>
                    <th>Expected Result</th>
                    <th>Actual Result</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {viewExectionByid?.data?.test_steps_at_execution_data
                    ?.length > 0 ? (
                    viewExectionByid?.data?.test_steps_at_execution_data.map(
                      (data, index) => (
                        <React.Fragment key={index}>
                          <tr className="">
                            <td
                              className=""
                              onClick={() => handlePlayArrowClick(index)}
                              onKeyDown={() => {}}
                            >
                              <p
                                className={
                                  accordionVisible?.[index]
                                    ? "conthrd clickable"
                                    : "clickable"
                                }
                              >
                                <PlayArrow
                                  style={{
                                    transform: accordionVisible?.[index]
                                      ? "rotate(90deg)"
                                      : "rotate(0deg)",
                                  }}
                                />
                              </p>
                            </td>
                            <td>
                              <p>{data?.step}</p>
                            </td>

                            <td>
                              <p>{data?.description}</p>
                            </td>
                            <td>
                              <p>{data?.expected_result}</p>
                            </td>
                            <td>
                              <p>{data?.actual_result || "NA"}</p>
                            </td>
                            <td>
                              <p>{convertedString(data?.status) || "NA"}</p>
                            </td>
                            <td>
                              <p></p>
                            </td>
                          </tr>
                          {/* )} */}
                          {accordionVisible?.[index] && (
                            <>
                              <tr className="">
                                <td></td>
                                <td></td>
                                <td className="conthrdr">ID</td>
                                <td className="conthrdr">Summary</td>
                                <td className="conthrdr">Status</td>
                                <td className="conthrdr">Attachments</td>
                                <td></td>
                              </tr>
                              {data?.bug?.length > 0 ? (
                                data?.bug?.map((bugData, index) => (
                                  <tr className=" hisAlltRow">
                                    <td></td>
                                    <td
                                      className=""
                                      // onClick={() =>
                                      //   handlePlayArrowClick(index)
                                      // }
                                    >
                                      <Checkbox
                                        className={"table_checkbox"}
                                        checked={giIds.some(
                                          (selectedItem) =>
                                            selectedItem.id === bugData?.id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(bugData)
                                        }
                                      />
                                    </td>
                                    <td className="">{bugData?.id}</td>
                                    <td className="">{bugData?.summary}</td>
                                    <td className="">
                                      <span
                                        className={
                                          bugData?.status === "FIXED"
                                            ? "fixedStatus"
                                            : bugData?.status === "OPEN"
                                            ? "openStatus"
                                            : bugData?.status === "NEW"
                                            ? "newStatus"
                                            : "Suggestion"
                                        }
                                      >
                                        {convertedString(bugData?.status)}
                                      </span>
                                    </td>
                                    <td className="px-5 ">
                                      <VisibilityOutlined
                                        className="preview"
                                        onClick={() =>
                                          handlePreviewallImages(
                                            bugData?.attachments
                                          )
                                        }
                                      />
                                    </td>
                                    <td className=""></td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="10"
                                    className="text-start py-4 fs-6"
                                  >
                                    <div className="text-start">
                                      <p>No Bugs Found</p>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </React.Fragment>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No History Yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div className="position-relative">
            <div
              className={
                isEditing
                  ? "test_header2 mb-3"
                  : "test_header test_header_hover mb-3"
              }
              onClick={() => {
                setIsEditing(true);
              }}
            >
              {isEditing ? (
                <CustomInput
                  type="text"
                  name="title"
                  size="large"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    if (formik.values.title) {
                      formik.handleBlur(e);
                      setIsEditing(false);
                    }
                  }}
                  value={formik.values.title}
                  formik={formik}
                />
              ) : (
                <>
                  <h3>{formik.values.title}</h3>
                </>
              )}
            </div>
            {formik.errors.title ? (
              <p className="CustErr">{formik.errors.title}</p>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-md-1">
              <h3>Summary</h3>
            </div>
            <div className="col-md-11">
              <CustomInput
                multiline
                rows={4}
                formik={formik}
                value={formik.values.description}
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={giIds.map((data, index) => data?.summary).join(", ")}
              />
            </div>
            <div className="col-md-1">
              <h3 className="mt-2">Assignee</h3>
            </div>
            <div className="col-md-5">
              <CustomSelect
                size="small"
                placeholder="Assign To"
                options={taskChoices?.assignees?.map((values) => {
                  return {
                    value: values.view_id,
                    name: values.name,
                  };
                })}
                name="assign_fk"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.assign_fk}
                formik={formik}
              />
            </div>
            <div className="col-md-1">
              <h3 className="mt-2">Priority</h3>
            </div>
            <div className="col-md-5">
              <CustomSelect
                size="small"
                placeholder="Select Priority"
                options={crmPriority.map((values) => {
                  return {
                    value: values.value,
                    name: values.name,
                  };
                })}
                name="priority"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.priority}
                formik={formik}
              />
            </div>
            <div className="col-md-1">
              <h3 className="mt-2">Column</h3>
            </div>
            <div className="col-md-5">
              <CustomSelect
                size="small"
                placeholder="Select Column"
                options={taskChoices?.columns?.map((values) => {
                  return {
                    value: values.id,
                    name: values.title,
                  };
                })}
                name="column"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.column}
                formik={formik}
              />
            </div>
            <div className="col-md-1">
              <h3 className="mt-2">Due date</h3>
            </div>
            <div className="col-md-5">
              <CustomInput
                type="date"
                name="due_date"
                size="large"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.due_date}
                formik={formik}
              />
            </div>
          </div>

          {/* <div className="ckeditor">
            <CustomEditor
              name="content"
              data={giIds.map((data, index) => data.summary).join(", ")}
              // data={formik?.values?.content
              //   ?.map((item) => `<p>${item}</p>`)
              //   .join(" ")}
              formik={formik}
            />
          </div> */}
        </div>
        <h3>Attachments</h3>
        <div className="attachment_container">
          <div className="attachments_list">
            {attachmentIds?.map((data, index) => (
              <div className="attachment_boxes clickable" key={index}>
                <div
                  className="attachment_detail"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDownloadFile(`${appUrl}${data?.file}`);
                  }}
                >
                  <div className="image_upload_icon">
                    <InsertPhotoOutlined sx={{ color: "#979797" }} />
                  </div>
                  <p>
                    {handleAttachmentName(data?.file, "/")?.length > 30
                      ? handleAttachmentName(data?.file, "/").slice(0, 27) +
                        "..."
                      : handleAttachmentName(data?.file, "/")}
                  </p>
                </div>
                <div className="attachment_checkbox">
                  <Checkbox
                    className={"table_checkbox"}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleCheckBoxChange(data?.id);
                    }}
                    checked={selectedIds.includes(data?.id)}
                  />
                </div>
              </div>
            ))}
            {addAttachments.length > 0 &&
              addAttachments.map((data) => (
                <div className="attachment_boxes clickable">
                  <div
                    className="attachment_detail"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const objectURL = URL.createObjectURL(data);
                      handleDownloadFile(objectURL);
                    }}
                  >
                    <div className="image_upload_icon">
                      <InsertPhotoOutlined sx={{ color: "#979797" }} />
                    </div>
                    <p>
                      {handleAttachmentName(data?.name, "/")?.length > 30
                        ? handleAttachmentName(data?.name, "/").slice(0, 27) +
                          "..."
                        : handleAttachmentName(data?.name, "/")}
                    </p>
                  </div>
                  <div
                    className="remove_task_attachment"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleFileRemove(data?.name);
                    }}
                  >
                    <Close sx={{ color: "#fc8686" }} />
                  </div>
                </div>
              ))}
          </div>
          <div className="attachment_upload" onClick={handleUploadClick}>
            <FileUploadOutlined className="upload_file_attachments" />
            <h3>Click here to upload</h3>
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <CustomButton
              size={"small"}
              label={
                <>
                  <AttachFileOutlined sx={{ marginBottom: "4px" }} />
                  <span>Attach</span>
                </>
              }
            />
          </div>
        </div>
        <CustomCancelSubmit
          cancelLabel="Cancel"
          submitLabel="Create Task"
          handleClose={() => navigate(-1)}
          handleSubmit={formik.handleSubmit}
        />
        {applicationList?.data
          ?.filter((data) => !data.is_integrated)
          .slice(0, 1)
          .map((data) =>
            data.is_integrated ? (
              ""
            ) : (
              <Alert
                severity="error"
                className="custom_alert"
                action={
                  <CustomButton
                    onClick={() => navigate("/integrate")}
                    label="Integrate"
                  />
                }
              >
                Please integrate this project
              </Alert>
            )
          )}

        {applicationList?.data
          ?.filter((data) => !data.is_expired)
          .slice(0, 1)
          .map((data) =>
            data.is_expired ? (
              <Alert
                severity="error"
                className="custom_alert"
                action={
                  <CustomButton
                    onClick={() =>
                      window.open(`https://crmdev.gwayerp.in/UserProfile`)
                    }
                    label="Regenerate Token"
                  />
                }
              >
                Token Expired
              </Alert>
            ) : (
              ""
            )
          )}

        <div className="accordian_container">
          <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h3>Task</h3>
            </AccordionSummary>
            <AccordionDetails className="">
              <div className="tblWrpr">
                <div className="table-responsive">
                  <div className="accordian_containers">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <th>Title</th>
                          <th>Summary</th>
                          <th>Assigned to</th>
                          <th>Priority</th>
                          <th>Due date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskList?.length > 0 ? (
                          taskList?.map((data, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{data?.title}</td>
                              <td>{data?.description}</td>
                              <td>
                                {convertedString(data?.assign_fk?.user_name)}
                              </td>
                              <td>{convertedString(data?.priority)}</td>
                              <td>{data?.due_date}</td>
                              <td>
                                <div className="view">
                                  <button
                                    onClick={() => handleViewOpen(data?.id)}
                                  >
                                    <VisibilityOutlined />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <CustomDialog
        open={preview}
        maxWidth="md"
        handleClose={handlePreviewallImagesClose}
        title="Attachments"
      >
        <ImageSlider
          data={fileData}
          handleClose={handlePreviewallImagesClose}
        />
      </CustomDialog>
      <CustomDialog
        open={view}
        title="View Task"
        maxWidth="md"
        handleClose={handleViewClose}
      >
        <ViewTask
          data={viewId}
          applicationId={formik.values.application_id}
          handleClose={handleViewClose}
        />
      </CustomDialog>
    </div>
  );
};

export default TestExecutionView;
