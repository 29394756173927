import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  TEST_UNEXECUTED_IMG,
  TEST_FAILED_IMG,
  TEST_PASSED_IMG,
  TEST_HOLD_IMG,
} from "../../Utils/imageConstants";
import BarChart from "./BarChart/BarChart";
import LineChart from "./LineChart/LineChart";
import PieChart from "./PieChart/PieChart";
import MultiLineBarchart from "./MultiLineBarChart/MultiLineBarChart";
import { getDashboardRequest } from "../../Redux/DashboardSaga/DashboardActions";
import { pieChartDataSet, multiLineChartDataSet } from "../../Utils/constants";
import "./Dashboard.css";
import DashboardChartLabel from "./DashboardChartLabel";
import DashboardCard from "./DashboardCard";

const ProjectDashboard = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { DashboardList } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getDashboardRequest({ project_id: id }));
  }, [id]);

  const cardsData = [
    {
      alt: "passed",
      count: DashboardList?.card_data?.PASSED,
      img: TEST_PASSED_IMG,
      label: "Test cases passed",
    },
    {
      alt: "in progress",
      count: DashboardList?.card_data?.IN_PROGRESS,
      img: TEST_HOLD_IMG,
      label: "Test cases In Progress",
    },
    {
      alt: "failed",
      count: DashboardList?.card_data?.FAILED,
      img: TEST_FAILED_IMG,
      label: "Test cases failed",
    },
    {
      alt: "unexecuted",
      count: DashboardList?.card_data?.UNEXECUTED,
      img: TEST_UNEXECUTED_IMG,
      label: "Test cases unexecuted",
    },
  ];

  return (
    <div className="dashboard_container">
      <div className="dashboard_grid_view">
        {cardsData.map((card, index) => (
          <DashboardCard key={index} {...card} />
        ))}
      </div>
      <div className="chart_container">
        <div className="charts">
          <div className="pie_chart_header">
            <h3>Test run results</h3>
          </div>
          <PieChart results={DashboardList?.test_run_results} />
          <div className="d-flex justify-content-around">
            <DashboardChartLabel data={pieChartDataSet} />
          </div>
        </div>
        <div className="charts">
          <div className="pie_chart_header">
            <h3>Priority</h3>
          </div>
          <BarChart priority={DashboardList?.priority} />
        </div>
        <div className="charts">
          <div className="pie_chart_header">
            <h3>Users working by %</h3>
          </div>
          {DashboardList?.users_working_by?.map((val, index) => {
            return (
              <div className="pie_chart_bottom d-flex" key={val.id}>
                <LineChart value={val} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="chart_containers">
        <div className="charts">
          <div className="pie_chart_header">
            <h3>Test Status by tester</h3>
            <div className="d-flex flex-wrap align-items-center gap-2">
              <DashboardChartLabel data={multiLineChartDataSet} />
            </div>
          </div>
          <MultiLineBarchart values={DashboardList?.test_status_by_tester} />
        </div>
      </div>
    </div>
  );
};

export default ProjectDashboard;
