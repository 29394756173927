import React, { useState } from "react";
import PropTypes from "prop-types";
import "./LineChart.css";

const LineChart = ({ backendResponse, value }) => {
  const colorPercentage = value?.percentage;

  const MegradientStyle = {
    background: `linear-gradient(90deg, #8591EF 0%, #8591EF ${colorPercentage}%, #DBDEFE ${colorPercentage}%, #DBDEFE 100%)`,
  };

  return (
    <div className="slider-container">
      <div>
        <div className="slider_info">
          <h6>{value?.full_name}</h6>
          <h6>{`${value.percentage.toString().slice(0, 4)}%`}</h6>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={value?.percentage}
          id="slider"
          style={MegradientStyle}
        />
      </div>
      {/* <div>
        <div className="slider_info">
          <h6>Pavi</h6>
          <h6>100%</h6>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value="50"
          id="slider"
          style={MegradientStyle}
        />
      </div>
      <div>
        <div className="slider_info">
          <h6>Pavi</h6>
          <h6>100%</h6>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value="50"
          id="slider"
          style={MegradientStyle}
        />
      </div>
      <div>
        <div className="slider_info">
          <h6>Pavi</h6>
          <h6>100%</h6>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value="50"
          id="slider"
          style={MegradientStyle}
        />
      </div>
      <div>
        <div className="slider_info">
          <h6>Pavi</h6>
          <h6>100%</h6>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value="50"
          id="slider"
          style={MegradientStyle}
        />
      </div> */}
    </div>
  );
};

LineChart.propTypes = {
  backendResponse: PropTypes.shape({
    colorPercentage: PropTypes.number,
  }),
};

export default LineChart;
