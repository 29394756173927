import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  postProjectRequest,
  postProjectResponseClear,
  updateProjectRequest,
  showProjectRequest,
  showProjectResponseClear,
  getProjectIndexRequest,
  showProjectMembersRequest,
  showProjectMembersResponseClear,
} from "../../Redux/ProjectSaga/ProjectAction";
import toast from "react-hot-toast";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import { Autocomplete, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import CustomCancelSubmit from "../../Components/Molecules/CancelSubmit/CancelSubmit";

const TestExecutionFilter = ({
  handleClose,
  testCaseExecutionAddedList,
  payload,
  formik,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { projectMembers } = useSelector((state) => state.project);
  const status = [
    {
      name: "Running",
      value: "RUNNING",
    },
    {
      name: "Stopped",
      value: "STOPPED",
    },
    {
      name: "Submitted",
      value: "SUBMITTED",
    },
  ];
  useEffect(() => {
    dispatch(
      showProjectMembersRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, [id]);

  return (
    <div className="project_add_container">
      <form>
        <div className="row">
          <div className="col-md-6 err_margin details_list">
            <h3>Approved by</h3>

            <CustomSelect
              size="small"
              // placeholder="Approved by"
              options={projectMembers?.data?.map((values, index) => {
                return {
                  value: values.user_fk,
                  name: `${values.first_name} ${values.last_name}`,
                };
              })}
              name="approved_by_fk.value"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.approved_by_fk.value}
              formik={formik}
            />
          </div>
          <div className="col-md-6 err_margin details_list">
            <h3>Assigned to</h3>
            <CustomSelect
              size="small"
              // placeholder="Assigned to"
              options={projectMembers?.data?.map((values, index) => {
                return {
                  value: values.user_fk,
                  name: `${values.first_name} ${values.last_name}`,
                };
              })}
              name="assigned_to_fk_id.value"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.assigned_to_fk_id.value}
              formik={formik}
            />
          </div>

          <div className="col-12">
            <CustomCancelSubmit
              cancelLabel="Cancel"
              submitLabel="Filter"
              handleClose={handleClose}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TestExecutionFilter;
