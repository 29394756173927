import { takeLatest, call, put } from "redux-saga/effects";
import RequirementActionTypes from "./RequirementActionTypes";
import { axiosGet, axiosPost, axiosPut, axiosDelete } from "../../Utils/axios";
import {
  deleteRequirementModuleResponse,
  getRequirementModuleIndexResponse,
  postRequirementModuleResponse,
  showRequirementModuleResponse,
  updateRequirementModuleResponse,
  showRequireDetailAttachmentResponse,
  deleteRequireDetailAttachmentResponse,
  updateRequireDetailAttachmentResponse,
  postRequireDetailAttachmentResponse,
  getRequireDetailAttachmentIndexResponse,
  getRequirementDetailIndexResponse,
  postRequirementDetailResponse,
  updateRequirementDetailResponse,
  deleteRequirementDetailResponse,
  showRequirementDetailResponse,
} from "./RequirementActions";
import { unauthorizedMessage } from "../Login/LoginActions";

// WORKER SAGA
// REQUIREMENT MODULE
// GET ALL REQUIREMENT MODULE
export function* onGetRequirementModuleIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_modules", payload).then((response) => response?.data)
    );
    yield put(getRequirementModuleIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST REQUIREMENT MODULE
export function* onPostRequirementModuleWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("module/new", payload).then((response) => response?.data)
    );
    yield put(postRequirementModuleResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE REQUIREMENT MODULE
export function* onUpdateRequirementModuleWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`module/${payload?.id}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(updateRequirementModuleResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE REQUIREMENT MODULE
export function* onDeleteRequirementModuleWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`module/${payload}`).then((response) => response?.data)
    );
    yield put(deleteRequirementModuleResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR REQUIREMENT MODULE
export function* onViewRequirementModuleWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`module/${payload}`).then((response) => response?.data)
    );
    yield put(showRequirementModuleResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// REQUIREMENT DETAIL
// GET ALL REQUIREMENT DETAILS
export function* onGetRequirementDetailIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_requirements", payload).then((response) => response?.data)
    );
    yield put(getRequirementDetailIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST REQUIREMENT DETAILS
export function* onPostRequirementDetailWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("requirement/new", payload).then((response) => response?.data)
    );
    yield put(postRequirementDetailResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE REQUIREMENT DETAILS
export function* onUpdateRequirementDetailWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`requirement/${payload?.id}`, payload?.formData).then(
        (response) => response?.data
      )
    );
    yield put(updateRequirementDetailResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE REQUIREMENT DETAILS
export function* onDeleteRequirementDetailWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`requirement/${payload}`).then((response) => response?.data)
    );
    yield put(deleteRequirementDetailResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR REQUIREMENT DETAIL
export function* onViewRequirementDetailWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`requirement/${payload}`).then((response) => response?.data)
    );
    yield put(showRequirementDetailResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// REQUIREMENT DETAIL ATTACHMENTS
// GET ALL REQUIREMENT DETAIL ATTACHMENTS
export function* onGetRequirementDetailAttachmentIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("requirement/view_attachments", payload).then(
        (response) => response?.data
      )
    );
    yield put(getRequireDetailAttachmentIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST REQUIREMENT DETAIL ATTACHMENTS
export function* onPostRequirementDetailAttachmentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("requirement/attachment/new", payload).then(
        (response) => response?.data
      )
    );
    yield put(postRequireDetailAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// UPDATE REQUIREMENT DETAIL ATTACHMENTS
export function* onUpdateRequirementDetailAttachmentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`requirement/attachment/${payload?.id}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(updateRequireDetailAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE REQUIREMENT DETAIL ATTACHMENTS
export function* onDeleteRequirementDetailAttachmentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosDelete(`requirement/attachment/${payload}`).then(
        (response) => response?.data
      )
    );
    yield put(deleteRequireDetailAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR REQUIREMENT DETAIL ATTACHMENTS
export function* onViewRequirementDetailAttachmentWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`requirement/attachment/${payload}`).then(
        (response) => response?.data
      )
    );
    yield put(showRequireDetailAttachmentResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// WATCHER SAGA
export function* RequirementWatcherSaga() {
  // REQUIREMENT MODULE
  yield takeLatest(
    RequirementActionTypes.GET_REQUIREMENTMODULES_INDEX_REQUEST,
    onGetRequirementModuleIndexWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.POST_REQUIREMENTMODULES_REQUEST,
    onPostRequirementModuleWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.UPDATE_REQUIREMENTMODULES_REQUEST,
    onUpdateRequirementModuleWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.DELETE_REQUIREMENTMODULES_REQUEST,
    onDeleteRequirementModuleWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.SHOW_REQUIREMENTMODULE_REQUEST,
    onViewRequirementModuleWorkerSaga
  );

  // REQUIREMENT DETAIL
  yield takeLatest(
    RequirementActionTypes.GET_REQUIREMENT_INDEX_REQUEST,
    onGetRequirementDetailIndexWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.POST_REQUIREMENT_REQUEST,
    onPostRequirementDetailWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.UPDATE_REQUIREMENT_REQUEST,
    onUpdateRequirementDetailWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.DELETE_REQUIREMENT_REQUEST,
    onDeleteRequirementDetailWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.SHOW_REQUIREMENT_REQUEST,
    onViewRequirementDetailWorkerSaga
  );

  // REQUIREMENT DETAIL ATTACHMENTS
  yield takeLatest(
    RequirementActionTypes.GET_REQUIREATTACHMENT_INDEX_REQUEST,
    onGetRequirementDetailAttachmentIndexWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.POST_REQUIREATTACHMENT_REQUEST,
    onPostRequirementDetailAttachmentWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.UPDATE_REQUIREATTACHMENT_REQUEST,
    onUpdateRequirementDetailAttachmentWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.DELETE_REQUIREATTACHMENT_REQUEST,
    onDeleteRequirementDetailAttachmentWorkerSaga
  );
  yield takeLatest(
    RequirementActionTypes.SHOW_REQUIREATTACHMENT_REQUEST,
    onViewRequirementDetailAttachmentWorkerSaga
  );
}
