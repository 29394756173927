import NotificationTypes from "./NotificationActionTypes";

const initialState = {
  notificationList: [],
  notificationLoading: false,
  notificationResponse: null,

  clearNotificationLoading: false,
  clearNotificationResponse: null,
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NotificationTypes.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notificationLoading: true,
      };

    case NotificationTypes.GET_NOTIFICATIONS_RESPONSE:
      return {
        ...state,
        notificationLoading: false,
        notificationResponse: action?.payload,
        notificationList: action?.payload?.data,
      };

    case NotificationTypes.GET_NOTIFICATIONS_RES_CLEAR:
      return {
        ...state,
        notificationLoading: false,
        notificationResponse: null,
      };

    //  CLEAR NOTIFICATION

    case NotificationTypes.CLEAR_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        clearNotificationLoading: true,
      };

    case NotificationTypes.CLEAR_NOTIFICATIONS_RESPONSE:
      return {
        ...state,
        clearNotificationLoading: false,
        clearNotificationResponse: action?.payload,
      };

    case NotificationTypes.CLEAR_NOTIFICATIONS_RES_CLEAR:
      return {
        ...state,
        clearNotificationLoading: false,
        clearNotificationResponse: null,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
