import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import { LoginRequest, LoginResClear } from "../../Redux/Login/LoginActions";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import { GWAY_IMG, LOGIN_IMG } from "../../Utils/imageConstants";
import { IconButton } from "@mui/material";
import CryptoJS from "crypto-js";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import MetaData from "../../Components/Organisms/MetaData/MetaData";
import { useDispatch, useSelector } from "react-redux";
import CustomCheckBox from "../../Components/Atoms/CustomCheckBox/CustomCheckBox";
import "./Login.css";
import { handlePaste, handleToastResponse } from "../../Utils/handles";
import LoadingButton from "../../Components/Atoms/LoadingButton/LoadingButton";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const secret_key = process.env.REACT_APP_SECRET_KEY;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const { loginLoading, user, loginResponse } = useSelector(
    (state) => state.loginState
  );

  const validation = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        "Invalid email"
      )
      .lowercase("Email cannot contain uppercase!")
      .strict(true)
      .max(50, "Maximum 50 characters")
      .required("Email is required!"),
    password: Yup.string().required("Password is required!"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: validation,
    onSubmit: (values) => {
      if (values.rememberMe && values.email && values.password) {
        const encrypt = (value) =>
          CryptoJS.AES.encrypt(value, secret_key).toString();
        ["email", "password", "rememberMe"].forEach((field) =>
          Cookies.set(
            field,
            field === "rememberMe" ? values[field] : encrypt(values[field])
          )
        );
      } else {
        ["password", "email", "rememberMe"].forEach((field) =>
          Cookies.remove(field)
        );
      }
      dispatch(LoginRequest(values));
    },
  });

  // SUCCESSFULL LOGIN TOAST
  useEffect(() => {
    handleToastResponse(loginResponse);
    return () => dispatch(LoginResClear());
  }, [loginResponse]);

  useEffect(() => {
    const rememberMe = Cookies.get("rememberMe");
    if (rememberMe) {
      const decrypt = (value) =>
        CryptoJS.AES.decrypt(value, secret_key).toString(CryptoJS.enc.Utf8);

      formik.setValues({
        email: decrypt(Cookies.get("email")),
        password: decrypt(Cookies.get("password")),
        rememberMe: Boolean(rememberMe),
      });
    }
  }, [Cookies]);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <>
      <MetaData title="Login" />
      <div className="access_container">
        <div
          className="access_image_container"
          style={{ backgroundImage: `url(${LOGIN_IMG})` }}
        ></div>
        <div className="access_form_container">
          <form onSubmit={formik.handleSubmit} className="forgot_password_form">
            <div className="empty_content"></div>
            <div className="form_img">
              <img src={GWAY_IMG} alt="gway" />
            </div>
            <div className="form_head">
              <h2>LOGIN</h2>
              <p>Let's get started now!</p>
            </div>
            <div className="row">
              <div className="col-12">
                <CustomInput
                  placeholder="Email"
                  type="text"
                  name="email"
                  size="large"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  formik={formik}
                />
              </div>
              <div className="col-12">
                <CustomInput
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  size="large"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  formik={formik}
                  onPaste={handlePaste}
                  endAdornment={
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  }
                />
              </div>
            </div>
            <div>
              <div className="checkbox_container mt-3">
                <CustomCheckBox
                  name="rememberMe"
                  value={formik.values.rememberMe}
                  onChange={formik.handleChange}
                  checked={formik.values.rememberMe}
                  label="Remember me"
                />
                <div>
                  <p>
                    <NavLink to="/forgotpassword">Forgot Password?</NavLink>
                  </p>
                </div>
              </div>
              <CustomButton
                label={loginLoading ? <LoadingButton /> : "Log In"}
                type="submit"
                disabled={loginLoading}
              />
              <div className="d-flex justify-content-center gap-1 mt-4">
                <p>Don't have an account?</p>
                <p>
                  <NavLink to={"/register"}>Sign up</NavLink>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
