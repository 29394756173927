import LoginActionTypes, {
  UNAUTHORIZED_ERROR,
  CLEAR_ERROR_RES,
} from "./LoginActionTypes";

export const LoginRequest = (payload) => ({
  type: LoginActionTypes.LOGIN_REQUESTED,
  payload: payload,
});

export const LoginSuccess = (payload) => ({
  type: LoginActionTypes.LOGIN_RESPONSE,
  payload: payload,
});

export const CurrentUserRequest = () => ({
  type: LoginActionTypes.CURRENT_USER_REQUESTED,
});

export const CurrentUserSuccess = (payload) => ({
  type: LoginActionTypes.CURRENT_USER_SUCCESS,
  payload: payload,
});

export const CurrentUserResClear = (payload) => ({
  type: LoginActionTypes.CURRENT_USER_RES_CLEAR,
});

export const getAllUsers = (payload) => ({
  type: LoginActionTypes.ALL_USER_REQUESTED,
  payload,
});

export const getAllUsersResponse = (payload) => ({
  type: LoginActionTypes.ALL_USER_SUCCESS,
  payload,
});

export const getAllUsersResClear = () => ({
  type: LoginActionTypes.ALL_USER_RES_CLEAR,
});

export const getAllGroups = (payload) => ({
  type: LoginActionTypes.ALL_GROUPS_REQUESTED,
  payload,
});

export const getAllGroupsRes = (payload) => ({
  type: LoginActionTypes.ALL_GROUPS_SUCCESS,
  payload,
});

export const getAllGroupsResClear = () => ({
  type: LoginActionTypes.ALL_GROUPS_RES_CLEAR,
});

export const LoginResClear = () => ({
  type: LoginActionTypes.LOGIN_RES_CLEAR,
});

export const logout_user = () => ({
  type: LoginActionTypes.LOGOUT_USER,
});

export const LogoutReq = (payload) => ({
  type: LoginActionTypes.LOGOUT_REQ,
  payload: payload,
});

export const LogoutResponse = (logoutRes) => ({
  type: LoginActionTypes.LOGOUT_RESPONSE,
  payload: logoutRes,
  user: null,
});

export const LogoutResClear = () => ({
  type: LoginActionTypes.LOGOUT_RESPONSE_CLEAR,
});

//FORGOT PASSWORD

export const forgotPasswordReq = (payload) => ({
  type: LoginActionTypes.FORGOT_PASSWORD_REQ,
  payload: payload,
});

export const forgotPasswordResponse = (loginRes) => ({
  type: LoginActionTypes.FORGOT_PASSWORD_RESPONSE,
  payload: loginRes,
});

export const forgotPasswordClearResponse = () => ({
  type: LoginActionTypes.FORGOT_PASSWORD_RES_CLEAR,
});

//CHANGE PASSWORD

export const changePasswordReq = (payload) => ({
  type: LoginActionTypes.CHANGE_PASSWORD_REQ,
  payload: payload,
});

export const changePasswordResponse = (loginRes) => ({
  type: LoginActionTypes.CHANGE_PASSWORD_RESPONSE,
  payload: loginRes,
});

export const changePasswordClearResponse = () => ({
  type: LoginActionTypes.CHANGE_PASSWORD_RES_CLEAR,
});

//CHANGE OLD PASSWORD
export const changeOldPasswordReq = (payload) => ({
  type: LoginActionTypes.CHANGE_OLD_PASSWORD_REQ,
  payload: payload,
});

export const changeOldPasswordResponse = (loginRes) => ({
  type: LoginActionTypes.CHANGE_OLD_PASSWORD_RESPONSE,
  payload: loginRes,
});

export const changeOldPasswordClearResponse = () => ({
  type: LoginActionTypes.CHANGE_OLD_PASSWORD_RES_CLEAR,
});

//UNAUTHORIZED
export const unauthorizedMessage = (payload) => ({
  type: UNAUTHORIZED_ERROR,
  payload,
});

export const clearErrorRes = () => ({
  type: CLEAR_ERROR_RES,
});

// ADD PROFILE PHOTO
export const postProfilePhotoRequest = (payload) => ({
  type: LoginActionTypes.UPDATE_PROFILEPHOTO_REQUEST,
  payload,
});
export const postProfilePhotoResponse = (payload) => ({
  type: LoginActionTypes.UPDATE_PROFILEPHOTO_RESPONSE,
  payload,
});
export const postProfilePhotoResponseClear = () => ({
  type: LoginActionTypes.UPDATE_PROFILEPHOTO_RES_CLEAR,
});
