import React from "react";
import "./CustomCheckBox.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";

const CustomCheckBox = (props) => {
  return (
    <div className="cstmChkBox">
      <FormControlLabel
        control={
          <Checkbox
            name={props.name}
            checked={props.checked}
            disabled={props.disabled}
            value={props.value}
            onChange={props.onChange}
            onClick={props.onClick}
            disableRipple
          />
        }
        label={props.label}
      />
    </div>
  );
};

CustomCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
};

export default CustomCheckBox;
