import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Checkbox, Popover } from "@mui/material";
import {
  Add,
  ArrowDropDown,
  ArrowRight,
  Close,
  HomeOutlined,
  SearchOutlined,
  FolderOpen,
  Filter,
  Search,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  deleteAddedTestCaseRequest,
  deleteAddedTestCaseResponseClear,
  getAddedTestCaseIndexRequest,
  getApprovedTestCaseIndexRequest,
  postTestCaseAddRequest,
  postTestCaseAddResponseClear,
  runTestCaseRequest,
  runTestCaseResponseClear,
} from "../../Redux/TestExecution/TestExecutionAction";
import {
  getRequirementDetailIndexRequest,
  getRequirementModuleIndexRequest,
} from "../../Redux/RequirementSaga/RequirementActions";
import {
  clearProjectTextAction,
  getProjectIndexRequest,
  showProjectRequest,
} from "../../Redux/ProjectSaga/ProjectAction";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import DeleteIndex from "../DeletePopup/DeleteIndex";
import {
  BUGCOLOR_ICON,
  BUGGREY_ICON,
  FOLDER_OPEN_ICON,
} from "../../Utils/imageConstants";
import CloseIcon from "@mui/icons-material/Close";
import "./TestExecution.css";
import { useFormik } from "formik";
import { CustomPagination } from "../../Components/Molecules/PaginationComponent/Pagination";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import TestExecutionFilter from "./TestExecutionFilter";
import ExportData from "../../Components/Molecules/ExportComponent/ExportToXL";
import { showProjectMembersRequest } from "../../Redux/ProjectSaga/ProjectAction";

export default function TestExecution() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reset, setReset] = useState(false);
  const { projectMembers, projectMembersLoading } = useSelector(
    (state) => state.project
  );

  const [visibleReleases, setVisibleReleases] = useState({});
  const [visibleRequirement, setVisibleRequirement] = useState({});
  const [visibleTestCase, setVisibleTestCase] = useState({});
  const [selectedTestCase, setSelectedTestCase] = useState("");
  const [page, setPage] = useState(1);
  const [listPerPage, setListPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAllIds, setSelectAllIds] = useState(false);
  const open = Boolean(anchorEl);

  const {
    testCaseExecutionAddedList,
    postTestCaseAddResponse,
    testCaseApprovedList,
    deleteTestCaseAddedResponse,
    deleteTestCaseAddedLoading,
    postTestCaseAddLoading,
    runTestCaseAddResponse,
  } = useSelector((state) => state.testExecution);

  const { requirementModuleList, requirementDetailList } = useSelector(
    (state) => state.requirement
  );

  const { projectsList, project } = useSelector((state) => state.project);

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: () => {},
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setVisibleReleases({});
    setVisibleRequirement({});
    setVisibleTestCase({});
    setSelectedTestCase({});
    // formik.resetForm();
  };

  useEffect(() => {
    dispatch(
      showProjectMembersRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, [id]);

  //TO GET ADDED TESTCASES IN EXECUTION
  useEffect(() => {
    if (postTestCaseAddLoading || deleteTestCaseAddedLoading) return;
    dispatch(
      getAddedTestCaseIndexRequest({
        filters: {
          project_id: {
            type: "exact",
            value: id,
          },
        },
        pagination_meta: {
          page: page,
          limit: listPerPage,
          order_by: "id_desc",
        },
      })
    );
  }, [postTestCaseAddLoading, deleteTestCaseAddedLoading, page, listPerPage]);

  useEffect(() => {
    // GET ALL PROJECTS FOR DROPDOWN
    dispatch(
      getProjectIndexRequest({
        filters: {
          name: {
            type: "like",
            value: formik.values.search || "",
          },
        },
        pagination_meta: {
          page: 1,
          order_by: "id_desc",
        },
      })
    );
  }, [formik.values.search]);

  // GET PARTICULAR PROJECT
  useEffect(() => {
    showProjectRequest(id);
  }, []);

  //TOAST MESSAGE FOR ADDING APPROVED TESTCASES IN EXECUTION
  useEffect(() => {
    if (!postTestCaseAddResponse) return;
    if (postTestCaseAddResponse) {
      if (
        postTestCaseAddResponse?.message &&
        postTestCaseAddResponse?.success
      ) {
        toast.success(postTestCaseAddResponse?.message);
        handleClose();
      } else {
        toast.error(postTestCaseAddResponse?.message);
      }
    }
    return () => {
      dispatch(postTestCaseAddResponseClear());
    };
  }, [postTestCaseAddResponse]);

  // FUNCTION TO TOGGLE VISIBILITY FOR SPECIFIC MODULE
  const toggleReleaseVisibility = (moduleId) => {
    setVisibleReleases((prev) => (prev === moduleId ? false : moduleId));
  };

  // FUNCTION TO TOGGLE VISIBILITY FOR SPECIFIC REQUIREMENT
  const toggleRequirementVisibility = (buildId) => {
    setVisibleRequirement((prev) => (prev === buildId ? false : buildId));
  };

  // FUNCTION TO TOGGLE VISIBILITY FOR SPECIFIC REQUIREMENT
  const toggleTestCasesVisibility = (buildId) => {
    setVisibleTestCase((prev) => (prev === buildId ? false : buildId));
  };

  const handleGetDropDowns = (action, id, name) => {
    if (action === getApprovedTestCaseIndexRequest) {
      dispatch(
        action({
          filters: {
            requirement_fk: {
              type: "exact",
              value: id,
            },
            is_approved: {
              type: "exact",
              value: true,
            },
          },
        })
      );
    } else {
      dispatch(
        action({
          filters: {
            [name]: {
              type: "exact",
              value: id,
            },
          },
        })
      );
    }
  };

  const handleHeaderCheckboxChange = (res, event) => {
    if (event?.target?.checked) {
      setSelectAllIds(event?.target?.checked);
      setSelectedIds(res?.data?.map((data) => data?.id));
    } else {
      setSelectedIds([]);
      setSelectAllIds(event?.target?.checked);
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
      if (selectedIds.length === testCaseExecutionAddedList?.total_count) {
        setSelectAllIds(true);
      }
    }

    if (selectAllIds && selectedIds.includes(id)) {
      setSelectAllIds(false);
    }
  };

  // TOAST MESSAGE FOR DELETE ADDED TESTCASES IN EXECUTION
  useEffect(() => {
    if (!deleteTestCaseAddedResponse) return;
    if (deleteTestCaseAddedResponse) {
      if (
        deleteTestCaseAddedResponse?.message &&
        deleteTestCaseAddedResponse?.success
      ) {
        toast.success(deleteTestCaseAddedResponse?.message);
        setSelectedIds([]);
        setSelectAllIds(false);
      } else {
        toast.error(deleteTestCaseAddedResponse?.message);
      }
    }
    return () => {
      dispatch(deleteAddedTestCaseResponseClear());
    };
  }, [deleteTestCaseAddedResponse]);

  //run response
  useEffect(() => {
    if (!runTestCaseAddResponse) return;
    if (runTestCaseAddResponse) {
      if (runTestCaseAddResponse?.message && runTestCaseAddResponse?.success) {
        // if (selectedIds.length > 0) {
        navigate(`projects/${id}/teststeps`);
        toast.success(runTestCaseAddResponse?.message);
        setSelectedIds([]);
        // }
      } else {
        toast.error(runTestCaseAddResponse?.message);
      }
    }
    return () => {
      dispatch(runTestCaseResponseClear());
    };
  }, [runTestCaseAddResponse]);

  const [filter, setFilter] = useState(false);
  const handleCloseFilter = () => {
    setFilter(false);
    // searchFormik.resetForm();
  };

  const searchSchema = {
    filters: {
      name: {
        type: "like",
        value: "",
      },
      project_id: {
        type: "exact",
        value: id,
      },
      assigned_to_fk_id: {
        type: "exact",
        value: "",
      },
      approved_by_fk: {
        type: "exact",
        value: "",
      },
    },
    pagination_meta: {
      page: "",
      limit: "",
      order_by: "id_desc",
    },
  };

  const searchFormik = useFormik({
    initialValues: searchSchema.filters,

    onSubmit: (values) => {
      // searchFormik.resetForm();
      handleCloseFilter();
      setReset(true);

      // dispatch(
      //   getRunningTestCaseIndexRequest({
      //     project_id: id,
      //     ...payload,
      //   })
      // );
      dispatch(
        getAddedTestCaseIndexRequest({
          ...payload,
        })
      );
    },
  });

  const payload = {
    filters: {
      name: {
        type: "like",
        value: searchFormik.values.name.value,
      },
      project_id: {
        type: "exact",
        value: id,
      },
      assigned_to_fk_id: {
        value: searchFormik.values.assigned_to_fk_id.value,
        type: "exact",
      },
      approved_by_fk: {
        type: "exact",
        value: searchFormik.values.approved_by_fk.value,
      },
    },

    pagination_meta: {
      page: page,
      limit: listPerPage,
      order_by: "id_desc",
    },
  };

  const handleFilter = () => {
    searchFormik.resetForm();
    setReset(false);
    dispatch(
      getAddedTestCaseIndexRequest({
        filters: {
          project_id: {
            type: "exact",
            value: id,
          },
        },
        pagination_meta: {
          page: page,
          limit: listPerPage,
          order_by: "id_desc",
        },
      })
    );
  };
  return (
    <div className="tests_layout">
      <div className="testexecution_container overflow-auto w-100">
        <div className="w-100 bg-white releaseLayout">
          <div className="test_header mb-1 d-flex justify-content-between align-items-center">
            <h3>Test Execution</h3>
            <div className="d-flex gap-2">
              <form
                className="left_side_nav"
                onSubmit={searchFormik.handleSubmit}
              >
                <input
                  type="text"
                  name="name.value"
                  id="projectSearchInput"
                  placeholder={"Search Testcase"}
                  className="search_input_project"
                  onChange={searchFormik.handleChange}
                  onBlur={searchFormik.handleBlur}
                  value={searchFormik.values.name.value}
                  formik={searchFormik}
                  autoComplete="off"
                />
                <Search className="search_input_icon" />
                {searchFormik.values.name.value && (
                  <CloseIcon
                    className="reset_filter_icon"
                    onClick={() => {
                      searchFormik.resetForm();
                      dispatch(clearProjectTextAction());

                      dispatch(
                        getAddedTestCaseIndexRequest({
                          filters: {
                            project_id: {
                              type: "exact",
                              value: id,
                            },
                          },
                          pagination_meta: {
                            page: page,
                            limit: listPerPage,
                            order_by: "id_desc",
                          },
                        })
                      );
                    }}
                  />
                )}
              </form>
              <CustomButton
                size="small"
                label="Filter"
                onClick={() => setFilter(true)}
              />
              {reset && payload.filters.name.value !== "" && (
                <CustomButton
                  label="Reset All"
                  onClick={handleFilter}
                  size="small"
                />
              )}
              <ExportData
                exportLinks={[
                  {
                    url: "api/test_case/export",
                    label: "Export All",
                    name: "Test Case",
                    extension: "xls",
                    type: "testcase",
                    project_id: id,
                  },
                ]}
                cdConfig={{
                  project_id: id,
                  url: "api/test_case/export",
                  type: "testcase",
                  name: "testcase",
                  extension: "xls",
                }}
                extraFilterFields={[
                  {
                    placeholder: "Assigned To",
                    name: "filters.assigned_to_fk_id.value",
                    inputType: "select",
                    options: projectMembers,
                  },
                  {
                    placeholder: "Approved By",
                    name: "filters.approved_by_fk.value",
                    inputType: "select",
                    options: projectMembers,
                  },
                ]}
              />
            </div>
          </div>

          <div className="popformbutton flex-wrap border-bottom border-1 border-solid border-color-ded9d9">
            {!selectedIds.length > 0 ? (
              <CustomButton
                size="small"
                label="View Executions"
                btnType="secondary"
                onClick={() => navigate(`projects/${id}/viewexecution`)}
              />
            ) : (
              ""
            )}

            <CustomButton
              size="small"
              // label={selectedIds.length > 0 ? "Run" : "View Running TestCases"}
              label={"View Selected TestCases"}
              btnType="secondary"
              onClick={() =>
                dispatch(
                  runTestCaseRequest({
                    test_case_ids: selectedIds,
                    project_id: id,
                  })
                )
              }
            />
            {/* <CustomButton
              size="small"
              type="submit"
              btnType="secondary"
              label="Add"
              onClick={handleClick}
            /> */}
            {/* <Popover
              id={open ? "simple-popover" : undefined}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{
                style: {
                  width: "300px",
                  height: "300px",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "10px",
                  marginTop: "10px",
                },
              }}
            >
              <div>
                <div className="pophrdr">
                  <p>Add TestCases</p>

                  <Close onClick={handleClose} />
                </div>
                <div className="testCasewrpr">
                  <div className="search-container w-100">
                    <form onSubmit={formik.handleSubmit}>
                      <SearchOutlined />
                      <input
                        placeholder="Search"
                        class="small-placeholder"
                        name="search"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        formik={formik}
                        autoComplete="off"
                      />
                    </form>
                  </div>
                  <div className="mt-4">
                    <div key={project?.id}>
                      <div
                        className="d-flex mt-2 sub_drop align-items-center"
                        onClick={() => {
                          toggleReleaseVisibility(project?.id);
                          handleGetDropDowns(
                            getRequirementModuleIndexRequest,
                            project?.id,
                            "project_fk"
                          );
                        }}
                      >
                        {visibleReleases === project?.id ? (
                          <ArrowDropDown />
                        ) : (
                          <ArrowRight />
                        )}
                        <HomeOutlined
                          className={
                            visibleReleases === project?.id
                              ? "icon_coloured"
                              : "px-1"
                          }
                        />
                        <p>{project?.name}</p>
                      </div>
                      {visibleReleases === project?.id &&
                        requirementModuleList?.data?.map((module, index) => (
                          <div>
                            <div
                              className="d-flex mt-2 sub_drop align-items-center"
                              key={index}
                              onClick={() => {
                                toggleRequirementVisibility(module?.id);
                                handleGetDropDowns(
                                  getRequirementDetailIndexRequest,
                                  module?.id,
                                  "module_fk"
                                );
                              }}
                            >
                              {visibleRequirement === module?.id ? (
                                <>
                                  <ArrowDropDown />
                                  <img
                                    src={FOLDER_OPEN_ICON}
                                    alt="folderopen"
                                    className="icon_coloured"
                                  />
                                </>
                              ) : (
                                <>
                                  <ArrowRight />
                                  <FolderOpen className="px-1" />
                                </>
                              )}

                              <p className="list">{module?.name}</p>
                            </div>
                            {visibleRequirement === module?.id &&
                              requirementDetailList?.data?.map(
                                (data, index) => (
                                  <div>
                                    <div
                                      className="d-flex mt-2 sub_drop align-items-center"
                                      key={index}
                                      onClick={() => {
                                        toggleTestCasesVisibility(data?.id);
                                        handleGetDropDowns(
                                          getApprovedTestCaseIndexRequest,
                                          data?.id,
                                          "requirement_fk"
                                        );
                                      }}
                                    >
                                      {visibleTestCase === data?.id ? (
                                        <>
                                          <ArrowDropDown />
                                          <img
                                            src={FOLDER_OPEN_ICON}
                                            alt="folderopen"
                                            className="icon_coloured"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <ArrowRight />
                                          <FolderOpen className="px-1" />
                                        </>
                                      )}
                                      <p className="list">{data?.name}</p>
                                    </div>
                                    {visibleTestCase === data?.id &&
                                      testCaseApprovedList?.data?.map(
                                        (data, index) => (
                                          <div>
                                            <div
                                              className="d-flex mt-2 px-4 sub_drop align-items-center"
                                              key={index}
                                              onClick={() => {
                                                setSelectedTestCase(data?.id);
                                              }}
                                            >
                                              <p
                                                className={
                                                  selectedTestCase === data?.id
                                                    ? "list selected_test"
                                                    : "list"
                                                }
                                              >
                                                {data?.name}
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                )
                              )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="popformbutton px-3">
                <CustomButton
                  onClick={() => {
                    dispatch(
                      postTestCaseAddRequest({
                        test_case_id: selectedTestCase,
                      })
                    );
                  }}
                  label={
                    <>
                      <Add className="me-1" />
                      <span>Add</span>
                    </>
                  }
                />
              </div>
            </Popover> */}
            {/* <DeleteIndex
              payload={{
                project_id: id,
                test_case_ids: selectedIds,
              }}
              disabled={selectedIds.length === 0}
              action={deleteAddedTestCaseRequest}
              loading={deleteTestCaseAddedLoading}
              res={deleteTestCaseAddedResponse}
              label={
                <CustomButton size="small" label="Delete" btnType="secondary" />
              }
              name="Test case"
            /> */}
            {/* <CustomButton
              size="small"
              type="submit"
              btnType="secondary"
              label="Export"
            /> */}
          </div>
          <div className="mt-4">
            <div className="tblWrpr">
              <div className="table-responsive ">
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        <Checkbox
                          className={
                            selectAllIds
                              ? "table_checkbox_checked"
                              : "table_checkbox"
                          }
                          onChange={(event) =>
                            handleHeaderCheckboxChange(
                              testCaseExecutionAddedList,
                              event
                            )
                          }
                          checked={
                            selectAllIds ||
                            (selectedIds.length ===
                              testCaseExecutionAddedList?.total_count &&
                              testCaseExecutionAddedList?.total_count !== 0)
                          }
                        />
                      </th>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Version</th>
                      <th>Assigned to</th>
                      <th>Approved by</th>
                      <th>Actions</th>

                      {/* <th>Log</th>
                        <th>
                          <div className="bug_image_container">
                            <img src={BUGGREY_ICON} alt="greycolorbug" />
                          </div>
                        </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {testCaseExecutionAddedList?.data?.length > 0 ? (
                      testCaseExecutionAddedList?.data?.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <Checkbox
                              className={
                                selectedIds.length > 0 &&
                                selectedIds[index] === data?.id
                                  ? "table_checkbox_checked"
                                  : "table_checkbox"
                              }
                              onChange={() => handleCheckboxChange(data?.id)}
                              checked={selectedIds.includes(data?.id)}
                            />
                          </td>
                          <td>{`Tr-${data?.id}`}</td>
                          <td>{data?.name}</td>
                          <td>{`0.${data?.version}`}</td>
                          <td>
                            {data?.assigned_to_fk?.full_name[0].toUpperCase() +
                              data?.assigned_to_fk?.full_name.slice(1)}
                          </td>
                          <td>
                            {data?.approved_by_fk?.full_name[0].toUpperCase() +
                              data?.approved_by_fk?.full_name.slice(1) || "_"}
                          </td>
                          <td>
                            <div className="view px-5">
                              <button
                                onClick={() =>
                                  navigate(
                                    `/projects/${id}/viewtestexecutions/${data.id}`
                                  )
                                }
                              >
                                <VisibilityOutlined />
                              </button>
                            </div>
                          </td>
                          {/* <td>{data?.log_count}</td>
                            <td>
                              <div className="bug_image_container">
                                <span style={{ marginTop: "2px" }}>
                                  {data?.bug_count}
                                </span>
                                <img src={BUGCOLOR_ICON} alt="greycolorbug" />
                              </div>
                            </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10}>
                          <p>No TestCases Added Yet</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {testCaseExecutionAddedList?.data?.length > 0 && (
            <CustomPagination
              dataList={testCaseExecutionAddedList}
              name="TestCase"
              page={page}
              setPage={setPage}
              listPerPage={listPerPage}
              applied={false}
            />
          )}
        </div>
        <CustomDialog
          open={filter}
          handleClose={handleCloseFilter}
          title="Filter"
          maxWidth="md"
        >
          <TestExecutionFilter
            handleClose={handleCloseFilter}
            payload={payload}
            formik={searchFormik}
            testCaseExecutionAddedList={testCaseExecutionAddedList}
          />
        </CustomDialog>
      </div>
    </div>
  );
}
