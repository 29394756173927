import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomInput from "../../../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../../../Components/Atoms/CustomButton/CustomButton";
import BuildPlan from "../BuildPlan/BuildPlan";
import CustomSelect from "../../../../Components/Atoms/CustomSelect/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowDropDown,
  ArrowDropUp,
  Close,
  Delete,
  Menu,
} from "@mui/icons-material";
import { status } from "../../../../Utils/constants";
import "./TestPlan.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReleaseRequest,
  deleteReleaseResponseClear,
  getReleaseIndexRequest,
  postReleaseRequest,
  postReleaseResponseClear,
  showReleaseRequest,
  showReleaseResponseClear,
  updateReleaseRequest,
} from "../../../../Redux/ReleaseSaga/ReleaseAction";
import toast from "react-hot-toast";
import {
  deleteBuildRequest,
  deleteBuildResponseClear,
  getBuildAttachmentIndexRequest,
  getBuildIndexRequest,
  showBuildRequest,
  showBuildResponseClear,
} from "../../../../Redux/BuildSaga/BuildAction";
import { DeleteContext } from "../../../../Utils/handles";
import NewSideBar from "../../../../Components/Organisms/NewSideBar/NewSideBar";
import {
  COLOR_VECTOR_ICON,
  UPVECTOR_ICON,
  UPVECTOR_COLOR_ICON,
} from "../../../../Utils/imageConstants";

const TestPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleReleases, setVisibleReleases] = useState({});
  const [currentImage, setCurrentImage] = useState("release");
  const [type, setType] = useState("");
  const [typeBuild, setTypeBuild] = useState("");
  const [reset, setReset] = useState(false);
  const [buildId, setBuildId] = useState("");

  const {
    postReleaseResponse,
    release,
    postReleaseLoading,
    deleteReleaseLoading,
    releaseList,
    deleteReleaseResponse,
  } = useSelector((state) => state.release);

  const {
    buildList,
    deleteBuildResponse,
    postBuildLoading,
    deleteBuildLoading,
  } = useSelector((state) => state.build);

  const handleContextMenu = (e, id, type, releaseId) => {
    e.preventDefault();
    setAnchorEl({
      anchor: e.currentTarget,
      id: id,
      type: type,
      release_id: releaseId,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBuild = (id) => {
    setBuildId(id);
    dispatch(showBuildRequest(id));
    dispatch(
      getBuildAttachmentIndexRequest({
        filters: {
          build_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
    handleClick("build");
    setTypeBuild("edit");
    setReset(false);
  };

  useEffect(() => {
    if (postBuildLoading) return;
    if (!visibleReleases) return;
    dispatch(
      getBuildIndexRequest({
        filters: {
          release_fk: {
            type: "exact",
            value: visibleReleases,
          },
        },
      })
    );
  }, [postBuildLoading]);

  useEffect(() => {
    if (!deleteBuildResponse) return;
    if (deleteBuildResponse?.message && deleteBuildResponse?.success) {
      dispatch(
        getBuildIndexRequest({
          filters: {
            release_fk: {
              type: "exact",
              value: anchorEl?.release_id,
            },
          },
        })
      );
    }
    handleClose();
  }, [deleteBuildResponse]);

  // Function to toggle visibility for a specific release
  const toggleReleaseVisibility = (releaseId) => {
    setVisibleReleases((prev) => (prev === releaseId ? false : releaseId));

    // TO GET BUILDLIST FOR PARTICULAR RELEASE
    dispatch(
      getBuildIndexRequest({
        filters: {
          release_fk: {
            type: "exact",
            value: releaseId,
          },
        },
      })
    );
  };

  const Validation = Yup.object().shape({
    name: Yup.string()
      .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces alone")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-_=+{}\[\]\n?">]+)$/,
        "Release name should contain characters too"
      )
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .trim()
      .required("Release Name is required!"),
    status: Yup.string().required("Status is required!"),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date()
      .min(
        Yup.ref("start_date"),
        "End Date must be greater than or equal to Start Date"
      )
      .required("End Date is required"),
    description: Yup.string().matches(
      /^\s*\S[\s\S]*$/g,
      "Should not contain spaces alone"
    ),
    release_note: Yup.string().matches(
      /^\s*\S[\s\S]*$/g,
      "Should not contain spaces alone"
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: `Release ${
        releaseList?.total_count !== undefined && releaseList?.total_count !== 0
          ? releaseList?.total_count + 1
          : 1
      }`,
      project_fk: id,
      status: "",
      start_date: "",
      end_date: "",
      description: "",
      release_note: "",
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      if (type === "edit") {
        dispatch(updateReleaseRequest(values));
      } else {
        dispatch(postReleaseRequest(values));
      }
    },
  });

  // TO UPDATE NAME WHEN RELEASELIST CHANGES
  useEffect(() => {
    if (releaseList && type !== "edit") {
      formik.setValues({
        ...formik.values,
        name: `Release ${
          releaseList?.total_count !== undefined &&
          releaseList?.total_count !== 0
            ? releaseList?.total_count + 1
            : 1
        }`,
      });
    }
  }, [releaseList, type]);

  const handleClick = (type) => {
    {
      (type === "release" && setCurrentImage("release")) ||
        (type === "build" && setCurrentImage("build"));
    }
  };

  // GET ALL RELEASES ON INITIAL RENDERING
  useEffect(() => {
    if (postReleaseLoading || deleteReleaseLoading) return;
    dispatch(
      getReleaseIndexRequest({
        filters: {
          project_fk: {
            type: "exact",
            value: id,
          },
        },
      })
    );
  }, [postReleaseLoading, deleteReleaseLoading]);

  // TO SET FORMIK VALUES WHILE EDITING
  useEffect(() => {
    if (type === "edit" && release) {
      for (const key in formik.values) {
        formik.setFieldValue(key, release[key]);
      }
      formik.setFieldValue("id", release?.id);
    }
    return () => {
      dispatch(showReleaseResponseClear());
    };
    // setType("");
  }, [release]);

  // POST AND EDIT RELEASE TOAST MESSAGE
  useEffect(() => {
    if (!postReleaseResponse) return;
    if (postReleaseResponse) {
      if (postReleaseResponse?.message && postReleaseResponse?.success) {
        toast.success(postReleaseResponse?.message);
        if (type !== "edit") {
          formik.resetForm();
        }
      } else {
        toast.error(postReleaseResponse?.message);
      }
    }
    return () => {
      dispatch(postReleaseResponseClear());
    };
  }, [postReleaseResponse]);

  // DELETE RELEASE TOAST MESSAGE
  useEffect(() => {
    if (!deleteReleaseResponse) return;
    if (deleteReleaseResponse) {
      if (deleteReleaseResponse?.message && deleteReleaseResponse?.success) {
        toast.success(deleteReleaseResponse?.message);
      } else {
        toast.error(deleteReleaseResponse?.message);
      }
      handleClose();
    }
    return () => {
      dispatch(deleteReleaseResponseClear());
    };
  }, [deleteReleaseResponse]);

  // DELETE BUILD TOAST MESSAGE
  useEffect(() => {
    if (!deleteBuildResponse) return;
    if (deleteBuildResponse) {
      if (deleteBuildResponse?.message && deleteBuildResponse?.success) {
        toast.success(deleteBuildResponse?.message);
      } else {
        toast.error(deleteBuildResponse?.message);
      }
    }
    return () => {
      dispatch(deleteBuildResponseClear());
    };
  }, [deleteBuildResponse]);

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="tests_layout">
      <div className="new_sidebar new_sidebar_hide">
        <div className="side_layout">
          {/* RELEASE */}
          <div
            className="sidebar_plan"
            onClick={() => {
              formik.resetForm({
                values: {
                  ...formik.initialValues,
                  name: `Release ${
                    releaseList?.total_count !== undefined &&
                    releaseList?.total_count !== 0
                      ? releaseList?.total_count + 1
                      : 1
                  }`, // Keep the current value of the 'name' field
                },
              });
              setType("");
              handleClick("release");
            }}
            onKeyDown={() => {}}
          >
            <img
              src={
                currentImage === "release" ? COLOR_VECTOR_ICON : UPVECTOR_ICON
              }
              className="image"
              alt="releaseicon"
            />
            <p className={currentImage === "release" ? "colorRelease" : ""}>
              Release
            </p>
          </div>
          {/* BUILD */}
          <div
            className="sidebar_plan"
            onKeyDown={() => {}}
            onClick={() => {
              handleClick("build");
              dispatch(showBuildResponseClear());
              setReset(true);
              setBuildId("");
              setTypeBuild("");
            }}
          >
            <img
              src={
                currentImage === "build" ? UPVECTOR_COLOR_ICON : UPVECTOR_ICON
              }
              className="image"
            />
            <p className={currentImage === "build" ? "colorRelease" : ""}>
              Build
            </p>
          </div>
        </div>
        {(currentImage === "release" || "build") && (
          <>
            {releaseList?.data?.map((release) => (
              <>
                <div key={release?.id}>
                  <div
                    className="d-flex mt-3 sub_drop align-items-center"
                    onKeyDown={() => {}}
                    onClick={() => {
                      toggleReleaseVisibility(release?.id);
                      dispatch(showReleaseRequest(release?.id));
                      handleClick("release");
                      setType("edit");
                      setReset(false);
                    }}
                    onContextMenu={(e) =>
                      handleContextMenu(e, release.id, "release")
                    }
                  >
                    {visibleReleases === release?.id ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                    <p>{release?.name}</p>
                  </div>
                  {visibleReleases === release.id &&
                    buildList?.data?.map((build, index) => (
                      <div className="px-4" key={index}>
                        <p
                          className="list"
                          onKeyDown={() => {}}
                          onClick={() => handleBuild(build?.id)}
                          onContextMenu={(e) =>
                            handleContextMenu(
                              e,
                              build?.id,
                              "build",
                              release?.id
                            )
                          }
                        >
                          {build?.name}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            ))}
            <DeleteContext
              anchorEl={anchorEl?.anchor}
              onClose={handleClose}
              name={anchorEl?.type === "release" ? "Release" : "Build"}
              action={
                anchorEl?.type === "release"
                  ? deleteReleaseRequest
                  : deleteBuildRequest
              }
              res={
                anchorEl?.type === "release"
                  ? deleteReleaseResponse
                  : deleteBuildResponse
              }
              payload={anchorEl?.id}
              loading={
                anchorEl?.type === "release"
                  ? deleteReleaseLoading
                  : deleteBuildLoading
              }
            />
          </>
        )}
      </div>
      <div className="side_modal bg-white">
        {!openDrawer && (
          <Menu
            className="clickable"
            onClick={() => setOpenDrawer(!openDrawer)}
          />
        )}
        <div className={openDrawer ? "sidenavopen" : "sideNav"}>
          <Close
            sx={{ display: openDrawer ? "unset" : "none" }}
            className="close_sidenav clickable"
            onClick={() => setOpenDrawer(false)}
          />
          <div
            className="new_sidebar new_sidebar_hide"
            style={{ display: openDrawer ? "unset" : "none" }}
          >
            <div className={`side_layout ${openDrawer && "draweropenMargin"}`}>
              {/* RELEASE */}
              <div
                className="sidebar_plan"
                onClick={() => {
                  formik.resetForm({
                    values: {
                      ...formik.initialValues,
                      name: `Release ${
                        releaseList?.total_count !== undefined &&
                        releaseList?.total_count !== 0
                          ? releaseList?.total_count + 1
                          : 1
                      }`, // Keep the current value of the 'name' field
                    },
                  });
                  setType("");
                  handleClick("release");
                }}
                onKeyDown={() => {}}
              >
                <img
                  src={
                    currentImage === "release"
                      ? COLOR_VECTOR_ICON
                      : UPVECTOR_ICON
                  }
                  className="image"
                  alt="releaseicon"
                />
                <p className={currentImage === "release" ? "colorRelease" : ""}>
                  Release
                </p>
              </div>
              {/* BUILD */}
              <div
                className="sidebar_plan"
                onKeyDown={() => {}}
                onClick={() => {
                  handleClick("build");
                  dispatch(showBuildResponseClear());
                  setReset(true);
                  setBuildId("");
                  setTypeBuild("");
                }}
              >
                <img
                  src={
                    currentImage === "build"
                      ? UPVECTOR_COLOR_ICON
                      : UPVECTOR_ICON
                  }
                  className="image"
                />
                <p className={currentImage === "build" ? "colorRelease" : ""}>
                  Build
                </p>
              </div>
            </div>
            {(currentImage === "release" || "build") && (
              <>
                {releaseList?.data?.map((release) => (
                  <>
                    <div key={release?.id}>
                      <div
                        className={`d-flex mt-3 sub_drop align-items-center ${
                          openDrawer && "draweropenMargin"
                        }`}
                        onKeyDown={() => {}}
                        onClick={() => {
                          toggleReleaseVisibility(release?.id);
                          dispatch(showReleaseRequest(release?.id));
                          handleClick("release");
                          setType("edit");
                          setReset(false);
                        }}
                        onContextMenu={(e) =>
                          handleContextMenu(e, release.id, "release")
                        }
                      >
                        {visibleReleases === release?.id ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                        <p>{release?.name}</p>
                      </div>
                      {visibleReleases === release.id &&
                        buildList?.data?.map((build, index) => (
                          <div
                            className={`px-4 ${
                              openDrawer && "draweropenMargin"
                            }`}
                            key={index}
                          >
                            <p
                              className="list"
                              onKeyDown={() => {}}
                              onClick={() => handleBuild(build?.id)}
                              onContextMenu={(e) =>
                                handleContextMenu(
                                  e,
                                  build?.id,
                                  "build",
                                  release?.id
                                )
                              }
                            >
                              {build?.name}
                            </p>
                          </div>
                        ))}
                    </div>
                  </>
                ))}
                <DeleteContext
                  anchorEl={anchorEl?.anchor}
                  onClose={handleClose}
                  name={anchorEl?.type === "release" ? "Release" : "Build"}
                  action={
                    anchorEl?.type === "release"
                      ? deleteReleaseRequest
                      : deleteBuildRequest
                  }
                  res={
                    anchorEl?.type === "release"
                      ? deleteReleaseResponse
                      : deleteBuildResponse
                  }
                  payload={anchorEl?.id}
                  loading={
                    anchorEl?.type === "release"
                      ? deleteReleaseLoading
                      : deleteBuildLoading
                  }
                />
              </>
            )}
          </div>
        </div>
        {/* <NewSideBar
          style={{ marginLeft: openDrawer ? "250px" : "-250px" }}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        > */}

        {/* </NewSideBar> */}
      </div>
      {currentImage === "release" ? (
        <div className="w-100 bg-white releaseLayout">
          <div className="position-relative">
            <div
              className={
                isEditing ? "test_header2" : "test_header test_header_hover"
              }
              onKeyDown={() => {}}
              onClick={() => setIsEditing(true)}
            >
              {isEditing ? (
                <CustomInput
                  type="text"
                  name="name"
                  size="large"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    if (formik.values.name) {
                      formik.handleBlur(e);
                      setIsEditing(false);
                    }
                  }}
                  value={formik.values.name}
                  formik={formik}
                />
              ) : (
                <>
                  <h3>{formik.values.name}</h3>
                </>
              )}
            </div>
            {formik.errors.name && !isEditing ? (
              <p className="CustErr">{formik.errors.name}</p>
            ) : (
              ""
            )}
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="testForm mt-4">
              <div className="row">
                <div className="col-md-4 err_margin">
                  <CustomSelect
                    size="small"
                    placeholder="Status"
                    options={status.map((values, index) => {
                      return {
                        value: values.value,
                        name: values.name,
                      };
                    })}
                    name="status"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.status}
                    formik={formik}
                  />
                </div>
                <div className="col-md-4 err_margin">
                  <CustomInput
                    type="date"
                    name="start_date"
                    size="large"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.start_date}
                    formik={formik}
                    shrink={true}
                    label="Start Date"
                  />
                </div>
                <div className="col-md-4 err_margin">
                  <CustomInput
                    type="date"
                    name="end_date"
                    size="large"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.end_date}
                    formik={formik}
                    shrink={true}
                    label="End Date"
                  />
                </div>
                <div className="col-md-12">
                  <CustomInput
                    multiline
                    rows={4}
                    placeholder="Description"
                    type="text"
                    name="description"
                    size="large"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    formik={formik}
                  />
                </div>
                <div className="col-md-12">
                  <CustomInput
                    multiline
                    rows={3}
                    placeholder="Release Note"
                    type="text"
                    name="release_note"
                    size="large"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.release_note}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
            <div className="popformbutton mt-3">
              <CustomButton
                size="small"
                label="Cancel"
                btnType="secondary"
                // onClick={() => {
                //   if (type != "edit") {
                //     formik.resetForm({
                //       values: {
                //         ...formik.initialValues,
                //         name: formik.values.name, // Keep the current value of the 'name' field
                //       },
                //     });
                //   }
                // }}
                onClick={() => navigate(`project/${id}/dashboard`)}
                onKeyDown={() => {}}
              />
              <CustomButton
                size="small"
                type="submit"
                label={type === "edit" ? "Update" : "Save"}
              />
            </div>
          </form>
        </div>
      ) : (
        <BuildPlan
          type={typeBuild}
          reset={reset}
          setType={setType}
          buildId={buildId}
          setBuildId={setBuildId}
        />
      )}
    </div>
  );
};

export default TestPlan;
