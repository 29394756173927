import RequirementCommentActionTypes from "./RequirementCommentActionTypes";

// REQUIREMENT HISTORY
// GET ALL REQUIREMENT HISTORY
export const getRequirementHistoryIndexRequest = (payload) => ({
  type: RequirementCommentActionTypes.GET_REQUIREMENTHISTORY_INDEX_REQUEST,
  payload,
});

export const getRequirementHistoryIndexResponse = (payload) => ({
  type: RequirementCommentActionTypes.GET_REQUIREMENTHSTORY_INDEX_RESPONSE,
  payload,
});

// REQUIREMENT COMMENT
// GET ALL REQUIREMENT COMMENT
export const getRequirementCommentIndexRequest = (payload) => ({
  type: RequirementCommentActionTypes.GET_REQUIREMENTCOMMENT_INDEX_REQUEST,
  payload,
});

export const getRequirementCommentIndexResponse = (payload) => ({
  type: RequirementCommentActionTypes.GET_REQUIREMENTCOMENT_INDEX_RESPONSE,
  payload,
});

// POST REQUIREMENT COMMENT
export const postRequirementCommentRequest = (payload) => ({
  type: RequirementCommentActionTypes.POST_REQUIREMENTCOMMENT_REQUEST,
  payload,
});

export const postRequirementCommentResponse = (payload) => ({
  type: RequirementCommentActionTypes.POST_REQUIREMENTCOMMENT_RESPONSE,
  payload,
});

// UPDATE REQUIREMENT COMMENT
export const updateRequirementCommentRequest = (payload) => ({
  type: RequirementCommentActionTypes.UPDATE_REQUIREMENTCOMMENT_REQUEST,
  payload,
});

export const updateRequirementCommentResponse = (payload) => ({
  type: RequirementCommentActionTypes.UPDATE_REQUIREMENTCOMMENT_RESPONSE,
  payload,
});

// POST AND UPDATE REQUIREMENT RESPONSE CLEAR
export const postRequirementCommentResponseClear = () => ({
  type: RequirementCommentActionTypes.POST_REQUIREMENTCOMMENT_RES_CLEAR,
});

// DELETE REQUIREMENT COMMENT
export const deleteRequirementCommentRequest = (payload) => ({
  type: RequirementCommentActionTypes.DELETE_REQUIREMENTCOMMENT_REQUEST,
  payload,
});

export const deleteRequirementCommentResponse = (payload) => ({
  type: RequirementCommentActionTypes.DELETE_REQUIREMENTCOMMENT_RESPONSE,
  payload,
});

export const deleteRequirementCommentResponseClear = () => ({
  type: RequirementCommentActionTypes.DELETE_REQUIREMENTCOMMENT_RES_CLEAR,
});

// GET PARTICULAR REQUIREMENT COMMENT
export const showRequirementCommentRequest = (payload) => ({
  type: RequirementCommentActionTypes.SHOW_REQUIREMENTCOMMENT_REQUEST,
  payload,
});

export const showRequirementCommentResponse = (payload) => ({
  type: RequirementCommentActionTypes.SHOW_REQUIREMENTCOMMENT_RESPONSE,
  payload,
});

export const showRequirementCommentResponseClear = () => ({
  type: RequirementCommentActionTypes.SHOW_REQUIREMENTCOMMENT_RES_CLEAR,
});
