export default {
  INVITE_REQUESTED: "INVITE_REQUESTED",
  INVITE_RESPONSE: "INVITE_RESPONSE",
  INVITE_RES_CLEAR: "INVITE_RES_CLEAR",

  //invite index
  INVITE_INDEX_REQUEST: "INVITE_INDEX_REQUEST",
  INVITE_INDEX_RESPONSE: "INVITE_INDEX_RESPONSE",
  INVITE_INDEX_RES_CLEAR: "INVITE_INDEX_RES_CLEAR",
};
