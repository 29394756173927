import React from "react";
import CustomButton from "../../Atoms/CustomButton/CustomButton";

const CustomCancelSubmit = ({
  cancelLabel,
  submitLabel,
  handleClose,
  handleSubmit,
  type = true,
  btnType = "secondary",
  loading,
}) => {
  return (
    <div className="popformbutton">
      <CustomButton
        size="small"
        label={cancelLabel}
        btnType={btnType}
        onClick={handleClose}
      />
      <CustomButton
        size="small"
        label={submitLabel}
        type={type ? "submit" : ""}
        onClick={handleSubmit}
        disabled={loading}
      />
    </div>
  );
};

export default CustomCancelSubmit;
