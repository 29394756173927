import TestDesignCommentActionTypes from "./TestDesignCommentActionTypes";

const initialState = {
  // TESTDESIGN HISTORY
  testDesignHistoryList: [],
  testDesignHistoryListLoading: false,
  testDesignHistoryListResponse: null,

  // TESTDESIGN COMMENT
  testDesignCommentList: [],
  testDesignCommentListLoading: false,
  testDesignCommentListResponse: null,

  postTestDesignCommentLoading: false,
  postTestDesignCommentResponse: null,

  deleteTestDesignCommentLoading: false,
  deleteTestDesignCommentResponse: null,

  testDesignComment: null,
  viewTestDesignCommentLoading: false,
  viewTestDesignCommentResponse: null,
};

export default function TestDesignCommentReducer(state = initialState, action) {
  switch (action.type) {
    // TESTDESIGN HISTORY
    // GET ALL TESTDESIGN HISTORY
    case TestDesignCommentActionTypes.GET_TESTDESIGNHISTORY_INDEX_REQUEST:
      return {
        ...state,
        testDesignHistoryListLoading: true,
      };
    case TestDesignCommentActionTypes.GET_TESTDESIGNHSTORY_INDEX_RESPONSE:
      return {
        ...state,
        testDesignHistoryListLoading: false,
        testDesignHistoryList: action.payload?.data,
        testDesignHistoryListResponse: action.payload,
      };

    // TESTDESIGN COMMENT
    // GET ALL TESTDESIGN COMMENT
    case TestDesignCommentActionTypes.GET_TESTDESIGNCOMMENT_INDEX_REQUEST:
      return {
        ...state,
        testDesignCommentListLoading: true,
      };
    case TestDesignCommentActionTypes.GET_TESTDESIGNCOMENT_INDEX_RESPONSE:
      return {
        ...state,
        testDesignCommentListLoading: false,
        testDesignCommentList: action.payload?.data,
        testDesignCommentListResponse: action.payload,
      };
    // POST TESTDESIGN COMMENT
    case TestDesignCommentActionTypes.POST_TESTDESIGNCOMMENT_REQUEST:
      return {
        ...state,
        postTestDesignCommentLoading: true,
      };
    case TestDesignCommentActionTypes.POST_TESTDESIGNCOMMENT_RESPONSE:
      return {
        ...state,
        postTestDesignCommentLoading: false,
        postTestDesignCommentResponse: action.payload,
      };
    // UPDATE TESTDESIGN COMMENT
    case TestDesignCommentActionTypes.UPDATE_TESTDESIGNCOMMENT_REQUEST:
      return {
        ...state,
        postTestDesignCommentLoading: true,
      };
    case TestDesignCommentActionTypes.UPDATE_TESTDESIGNCOMMENT_RESPONSE:
      return {
        ...state,
        postTestDesignCommentLoading: false,
        postTestDesignCommentResponse: action.payload,
      };
    // POST AND UPDATE TESTDESIGN COMMENT RESPONSE CLEAR
    case TestDesignCommentActionTypes.POST_TESTDESIGNCOMMENT_RES_CLEAR:
      return {
        ...state,
        postTestDesignCommentLoading: false,
        postTestDesignCommentResponse: null,
      };
    // DELETE TESTDESIGN COMMENT
    case TestDesignCommentActionTypes.DELETE_TESTDESIGNCOMMENT_REQUEST:
      return {
        ...state,
        deleteTestDesignCommentLoading: true,
      };
    case TestDesignCommentActionTypes.DELETE_TESTDESIGNCOMMENT_RESPONSE:
      return {
        ...state,
        deleteTestDesignCommentLoading: false,
        deleteTestDesignCommentResponse: action.payload,
      };
    case TestDesignCommentActionTypes.DELETE_TESTDESIGNCOMMENT_RES_CLEAR:
      return {
        ...state,
        deleteTestDesignCommentLoading: false,
        deleteTestDesignCommentResponse: null,
      };
    // GET PARTICULAR TESTDESIGN COMMENT
    case TestDesignCommentActionTypes.SHOW_TESTDESIGNCOMMENT_REQUEST:
      return {
        ...state,
        viewTestDesignCommentLoading: true,
      };
    case TestDesignCommentActionTypes.SHOW_TESTDESIGNCOMMENT_RESPONSE:
      return {
        ...state,
        viewTestDesignCommentLoading: false,
        testDesignComment: action.payload?.data,
        viewTestDesignCommentResponse: action.payload,
      };
    case TestDesignCommentActionTypes.SHOW_TESTDESIGNCOMMENT_RES_CLEAR:
      return {
        ...state,
        viewTestDesignCommentLoading: false,
        testDesignComment: null,
        viewTestDesignCommentResponse: null,
      };
    default:
      return state;
  }
}
