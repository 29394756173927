import React, { useEffect, useState } from "react";
import MetaData from "../../../Components/Organisms/MetaData/MetaData";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../Components/Atoms/CustomInput/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton/CustomButton";
import { GWAY_IMG, LOGIN_IMG } from "../../../Utils/imageConstants";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import {
  changePasswordClearResponse,
  changePasswordReq,
} from "../../../Redux/Login/LoginActions";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  handlePaste,
  handlePassword,
  handleToastResponse,
} from "../../../Utils/handles";
import LoadingButton from "../../../Components/Atoms/LoadingButton/LoadingButton";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const { changePasswordResponse, changePasswordLoading } = useSelector(
    (state) => state.loginState
  );
  const [password, setPassword] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const validation = Yup.object().shape({
    password: Yup.string()
      .matches(/^\s*\S[\s\S]*$/g, "Password cannot contain only blankspaces")
      .matches(/[a-z]/, "Must have atleast 1 small letter.")
      .matches(/[A-Z]/, "Must have atleast 1 capital letter.")
      .matches(
        /^(?=.*\d)(?=.*[_\-+!@#$%^&*()])/,
        "Must have atleast 1 number & 1 special character."
      )
      .matches(/^.{8,}$/, "Minimum of 8 characters")
      .required("Password is required!"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Retype password is required!"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      dispatch(
        changePasswordReq({
          getToken: searchParams.get("tk"),
          getid: searchParams.get("id"),
          values: values,
        })
      );
    },
  });

  useEffect(() => {
    handleToastResponse(changePasswordResponse);
    if (changePasswordResponse.success) {
      formik.handleReset();
      navigate("/login");
    }
    return () => dispatch(changePasswordClearResponse());
  }, [changePasswordResponse]);

  return (
    <>
      <MetaData title="Reset Password" />
      <div className="access_container">
        <div
          className="access_image_container"
          style={{ backgroundImage: `url(${LOGIN_IMG})` }}
        ></div>
        <div className="access_form_container">
          <form onSubmit={formik.handleSubmit} className="forgot_password_form">
            <div className="empty_content"></div>
            <div className="form_img">
              <img src={GWAY_IMG} alt="gway" />
            </div>
            <div className="form_head">
              <h2>RESET PASSWORD</h2>
              <p>
                Your new password should be different from passwords previously
                used
              </p>
            </div>
            <div className="row">
              <div className="col-12">
                <CustomInput
                  placeholder="New Password"
                  type={password.showPassword ? "text" : "password"}
                  name="password"
                  size="large"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  formik={formik}
                  onPaste={handlePaste}
                  endAdornment={
                    <IconButton
                      onClick={() =>
                        handlePassword(setPassword, "showPassword")
                      }
                    >
                      {password.showPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  }
                />
              </div>
              <div className="col-12">
                <CustomInput
                  placeholder="Re-type Password"
                  type={password.showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  size="large"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirm_password}
                  formik={formik}
                  onPaste={handlePaste}
                  endAdornment={
                    <IconButton
                      onClick={() =>
                        handlePassword(setPassword, "showConfirmPassword")
                      }
                    >
                      {password.showConfirmPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  }
                />
              </div>
            </div>

            <CustomButton
              type="submit"
              label={
                changePasswordLoading ? <LoadingButton /> : "Reset Password"
              }
              disabled={changePasswordLoading}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
