import TestExecutionActionTypes from "./TestExecutionActionTypes";
import { CLEAR_ERROR_RES } from "../Login/LoginActionTypes";

const initialState = {
  // TESTEXECUTION INDEX
  testCaseApprovedList: [],
  testCaseApprovedListLoading: false,
  testCaseApprovedListResponse: null,

  testCaseExecutionAddedList: [],
  testCaseExecutionAddedListLoading: false,
  testCaseExecutionAddedListResponse: null,

  postTestCaseAddLoading: false,
  postTestCaseAddResponse: null,

  deleteTestCaseAddedLoading: false,
  deleteTestCaseAddedResponse: null,

  runTestCaseAddLoading: false,
  runTestCaseAddResponse: null,

  testCaseRunList: [],
  testCaseRunListLoading: false,
  testCaseRunListResponse: null,

  saveDefectsLoading: false,
  saveDefectsResponse: null,

  submitDefectsLoading: false,
  submitDefectsResponse: null,

  bugListLoading: false,
  bugListResponse: {},

  editBugListLoading: false,
  editBugListResponse: null,

  // ALL RUNNING TESTCASES LIST
  testCaseRunningList: [],
  testCaseRunningListLoading: false,
  testCaseRunningListResponse: null,

  testCaseRun: null,
  testCaseRunLoading: false,
  testCaseRunResponse: null,

  testCaseRunningAttachmentsList: [],
  testCaseRunningAttachmentsListLoading: false,
  testCaseRunningAttachmentsListResponse: null,

  // CANCEL EXECUTION
  cancelExecutionLoading: false,
  cancelExecutionResponse: null,

  list: {},
  listLoading: false,

  exectionByid: {},
  executionByidLoading: false,

  viewExectionByid: null,
  viewExecutionByidLoading: false,
};

export default function TestExecutionReducer(state = initialState, action) {
  switch (action.type) {
    // TEST EXECUTION INDEX
    // GET ALL APPROVED TESTCASES
    case TestExecutionActionTypes.GET_APPROVED_TESTCASES_INDEX_REQUEST:
      return {
        ...state,
        testCaseApprovedListLoading: true,
      };
    case TestExecutionActionTypes.GET_APPROVED_TESTCASES_INDEX_RESPONSE:
      return {
        ...state,
        testCaseApprovedListLoading: false,
        testCaseApprovedList: action.payload?.data,
        testCaseApprovedListResponse: action.payload,
      };

    //  GET ALL ADDED TESTCASES IN EXECUTION
    case TestExecutionActionTypes.GET_ADDED_TESTCASES_INDEX_REQUEST:
      return {
        ...state,
        testCaseExecutionAddedListLoading: true,
      };

    case TestExecutionActionTypes.GET_ADDED_TESTCASES_INDEX_RESPONSE:
      return {
        ...state,
        testCaseExecutionAddedListLoading: false,
        testCaseExecutionAddedList: action.payload?.data,
        testCaseExecutionAddedListResponse: action.payload,
      };

    // POST TESTCASE IN EXECUTION
    case TestExecutionActionTypes.POST_APPROVED_TESTCASES_REQUEST:
      return {
        ...state,
        postTestCaseAddLoading: true,
      };
    case TestExecutionActionTypes.POST_APPROVED_TESTCASES_RESPONSE:
      return {
        ...state,
        postTestCaseAddLoading: false,
        postTestCaseAddResponse: action.payload,
      };

    // POST ADDED TESTCASE RESPONSE CLEAR
    case TestExecutionActionTypes.POST_APPROVED_TESTCASES_RES_CLEAR:
      return {
        ...state,
        postTestCaseAddLoading: false,
        postTestCaseAddResponse: null,
      };

    //  DELETE TESTCASES IN EXECUTION
    case TestExecutionActionTypes.DELETE_APPROVED_TESTCASE_REQUEST:
      return {
        ...state,
        deleteTestCaseAddedLoading: true,
      };

    case TestExecutionActionTypes.DELETE_APPROVED_TESTCASE_RESPONSE:
      return {
        ...state,
        deleteTestCaseAddedLoading: false,
        deleteTestCaseAddedResponse: action.payload,
      };
    case TestExecutionActionTypes.DELETE_APPROVED_TESTCASE_RES_CLEAR:
      return {
        ...state,
        deleteTestCaseAddedLoading: false,
        deleteTestCaseAddedResponse: null,
      };
    //runtestcase
    case TestExecutionActionTypes.TESTCASE_RUN_REQUEST:
      return {
        ...state,
        runTestCaseAddLoading: true,
      };

    case TestExecutionActionTypes.TESTSCASE_RUN_RESPONSE:
      return {
        ...state,
        runTestCaseAddLoading: false,
        runTestCaseAddResponse: action.payload,
      };
    case TestExecutionActionTypes.TESTCASE_RUN_RESCLEAR:
      return {
        ...state,
        runTestCaseAddLoading: false,
        runTestCaseAddResponse: null,
      };

    //runresult
    case TestExecutionActionTypes.GETRUN_RESULT_REQUEST:
      return {
        ...state,
        testCaseRunListLoading: true,
      };

    case TestExecutionActionTypes.GETRUN_RESULT_SUCCESS:
      return {
        ...state,
        testCaseRunListLoading: false,
        testCaseRunList: action.payload?.data,
        testCaseRunListResponse: action.payload,
      };

    //savedefects
    case TestExecutionActionTypes.SAVE_DEFECTS_START:
      return {
        ...state,
        saveDefectsLoading: true,
      };

    case TestExecutionActionTypes.SAVE_DEFECTS_SUCCESS:
      return {
        ...state,
        saveDefectsLoading: false,
        saveDefectsResponse: action.payload,
      };
    case TestExecutionActionTypes.SAVE_DEFECTS_RESCLEAR:
      return {
        ...state,
        saveDefectsLoading: false,
        saveDefectsResponse: null,
      };

    //submitdefect
    case TestExecutionActionTypes.SUBMITE_DEFECT_START:
      return {
        ...state,
        submitDefectsLoading: true,
      };

    case TestExecutionActionTypes.SUMBIT_DEFECT_SUCCESS:
      return {
        ...state,
        submitDefectsLoading: false,
        submitDefectsResponse: action.payload,
      };
    case TestExecutionActionTypes.SUBMIT_DEFECT_RESCLEAR:
      return {
        ...state,
        submitDefectsLoading: false,
        submitDefectsResponse: null,
      };

    //bug lost
    case TestExecutionActionTypes.GET_BUG_REQUEST:
      return {
        ...state,
        bugListLoading: true,
      };

    case TestExecutionActionTypes.GET_BUG_SUCCESS:
      return {
        ...state,
        bugListLoading: false,
        bugListResponse: action.payload,
      };
    case TestExecutionActionTypes.GET_BUG_RESCLEAR:
      return {
        ...state,
        bugListLoading: false,
        bugListResponse: null,
      };

    //edit bug lois
    case TestExecutionActionTypes.EDIT_BUG_REQUEST:
      return {
        ...state,
        editBugListLoading: true,
      };

    case TestExecutionActionTypes.EDIT_BUG_SUCCESS:
      return {
        ...state,
        editBugListLoading: false,
        editBugListResponse: action.payload,
      };
    case TestExecutionActionTypes.EDIT_BUG_RESCLEAR:
      return {
        ...state,
        editBugListLoading: false,
        editBugListResponse: null,
      };

    // RUNNING TESTCASES
    case TestExecutionActionTypes.GET_RUNNING_TESTCASES_REQUEST:
      return {
        ...state,
        testCaseRunningListLoading: true,
      };

    case TestExecutionActionTypes.GET_RUNNING_TESTCASES_RESPONSE:
      return {
        ...state,
        testCaseRunningListLoading: false,
        testCaseRunningList: action.payload?.data,
        testCaseRunningListResponse: action.payload,
      };

    // GET PARTICULAR RUNNING TESTCASES
    case TestExecutionActionTypes.SHOW_RUNNING_TESTCASES_REQUEST:
      return {
        ...state,
        testCaseRunLoading: true,
      };

    case TestExecutionActionTypes.SHOW_RUNNING_TESTCASES_RESPONSE:
      return {
        ...state,
        testCaseRunLoading: false,
        testCaseRun: action.payload?.data,
        testCaseRunResponse: action.payload,
      };
    case TestExecutionActionTypes.SHOW_RUNNING_TESTCASE_RES_CLEAR:
      return {
        ...state,
        testCaseRunLoading: false,
        testCaseRun: null,
        testCaseRunResponse: null,
      };

    // GET ALL RUNNING TESTCASE ATTACHMENTS
    case TestExecutionActionTypes.GET_ALL_ATTACHMENTS_INDEX_REQUEST:
      return {
        testCaseRunningAttachmentsListLoading: true,
      };
    case TestExecutionActionTypes.GET_ALL_ATTACHMENTS_INDEX_RESPONSE:
      return {
        ...state,
        testCaseRunningAttachmentsListLoading: false,
        testCaseRunningAttachmentsList: action.payload?.data,
        testCaseRunningAttachmentsListResponse: action.payload,
      };

    //getdefectList
    case TestExecutionActionTypes.GET_DEFECTLIST_REQUEST:
      return {
        ...state,
        listLoading: true,
      };

    case TestExecutionActionTypes.GET_DEFECTLIST_RESPONSE:
      return {
        ...state,
        listLoading: false,
        list: action.payload,
      };
    case TestExecutionActionTypes.GET_DEFECTLIST_RESCLEAR:
      return {
        ...state,
        listLoading: false,
        list: null,
      };

    // CANCEL EXECUTION
    case TestExecutionActionTypes.CANCEL_EXECUTION_REQUEST:
      return {
        ...state,
        cancelExecutionLoading: true,
      };

    case TestExecutionActionTypes.CANCEL_EXECUTION_RESPONSE:
      return {
        ...state,
        cancelExecutionLoading: false,
        cancelExecutionResponse: action.payload,
      };
    case TestExecutionActionTypes.CANCEL_EXECUTION_RES_CLEAR:
      return {
        ...state,
        cancelExecutionLoading: false,
        cancelExecutionResponse: null,
      };

    //executionbyid
    case TestExecutionActionTypes.GET_EXECUTION_BYID_REQUEST:
      return {
        ...state,
        executionByidLoading: true,
      };

    case TestExecutionActionTypes.GET_EXECUTION_BYID_RESPONSE:
      return {
        ...state,
        executionByidLoading: false,
        exectionByid: action.payload.data,
      };
    case TestExecutionActionTypes.GET_EXECUTION_BYID_RESCLEAR:
      return {
        ...state,
        executionByidLoading: false,
        exectionByid: null,
      };

    case TestExecutionActionTypes.VIEW_TESTEXECUTION_BYID_REQUST:
      return {
        ...state,
        viewExecutionByidLoading: true,
        viewExectionByid: null,
      };
    case TestExecutionActionTypes.VIEW_TESTEXECUTION_BYID_RESPONSE:
      return {
        ...state,
        viewExecutionByidLoading: false,
        viewExectionByid: action.payload,
      };
    default:
      return state;
  }
}
