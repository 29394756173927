export default {
  // REQUIREMENT MODULE
  GET_REQUIREMENTMODULES_INDEX_REQUEST: "GET_REQUIREMENTMODULES_INDEX_REQUEST",
  GET_REQUIREMENTMODULE_INDEX_RESPONSE: "GET_REQUIREMENTMODULE_INDEX_RESPONSE",

  POST_REQUIREMENTMODULES_REQUEST: "POST_REQUIREMENTMODULES_REQUEST",
  POST_REQUIREMENTMODULES_RESPONSE: "POST_REQUIREMENTMODULES_RESPONSE",

  UPDATE_REQUIREMENTMODULES_REQUEST: "UPDATE_REQUIREMENTMODULES_REQUEST",
  UPDATE_REQUIREMENTMODULES_RESPONSE: "UPDATE_REQUIREMENTMODULES_RESPONSE",

  POST_REQUIREMENTMODULES_RES_CLEAR: "POST_REQUIREMENTMODULES_RES_CLEAR",

  DELETE_REQUIREMENTMODULES_REQUEST: "DELETE_REQUIREMENTMODULES_REQUEST",
  DELETE_REQUIREMENTMODULES_RESPONSE: "DELETE_REQUIREMENTMODULES_RESPONSE",
  DELETE_REQUIREMENTMODULES_RES_CLEAR: "DELETE_REQUIREMENTMODULES_RES_CLEAR",

  SHOW_REQUIREMENTMODULE_REQUEST: "SHOW_REQUIREMENTMODULE_REQUEST",
  SHOW_REQUIREMENTMODULE_RESPONSE: "SHOW_REQUIREMENTMODULE_RESPONSE",
  SHOW_REQUIREMENTMODULE_RES_CLEAR: "SHOW_REQUIREMENTMODULE_RES_CLEAR",

  // REQUIREMENT DETAIL
  GET_REQUIREMENT_INDEX_REQUEST: "GET_REQUIREMENT_INDEX_REQUEST",
  GET_REQUIREMENT_INDEX_RESPONSE: "GET_REQUIREMENT_INDEX_RESPONSE",

  POST_REQUIREMENT_REQUEST: "POST_REQUIREMENT_REQUEST",
  POST_REQUIREMENT_RESPONSE: "POST_REQUIREMENT_RESPONSE",

  UPDATE_REQUIREMENT_REQUEST: "UPDATE_REQUIREMENT_REQUEST",
  UPDATE_REQUIREMENT_RESPONSE: "UPDATE_REQUIREMENT_RESPONSE",

  POST_REQUIREMENT_RES_CLEAR: "POST_REQUIREMENT_RES_CLEAR",

  DELETE_REQUIREMENT_REQUEST: "DELETE_REQUIREMENT_REQUEST",
  DELETE_REQUIREMENT_RESPONSE: "DELETE_REQUIREMENT_RESPONSE",
  DELETE_REQUIREMENT_RES_CLEAR: "DELETE_REQUIREMENT_RES_CLEAR",

  SHOW_REQUIREMENT_REQUEST: "SHOW_REQUIREMENT_REQUEST",
  SHOW_REQUIREMENT_RESPONSE: "SHOW_REQUIREMENT_RESPONSE",
  SHOW_REQUIREMENT_RES_CLEAR: "SHOW_REQUIREMENT_RES_CLEAR",

  // REQUIREMENT DETAIL ATTACHMENTS
  GET_REQUIREATTACHMENT_INDEX_REQUEST: "GET_REQUIREATTACHMENT_INDEX_REQUEST",
  GET_REQUIREATTACHMENT_INDEX_RESPONSE: "GET_REQUIREATTACHMENT_INDEX_RESPONSE",
  GET_REQUIREATTACH_INDEX_RES_CLEAR: "GET_REQUIREATTACH_INDEX_RES_CLEAR",

  POST_REQUIREATTACHMENT_REQUEST: "POST_REQUIREATTACHMENT_REQUEST",
  POST_REQUIREATTACHMENT_RESPONSE: "POST_REQUIREATTACHMENT_RESPONSE",

  UPDATE_REQUIREATTACHMENT_REQUEST: "UPDATE_REQUIREATTACHMENT_REQUEST",
  UPDATE_REQUIREATTACHMENT_RESPONSE: "UPDATE_REQUIREATTACHMENT_RESPONSE",

  POST_REQUIREATTACHMENT_RES_CLEAR: "POST_REQUIREATTACHMENT_RES_CLEAR",

  DELETE_REQUIREATTACHMENT_REQUEST: "DELETE_REQUIREATTACHMENT_REQUEST",
  DELETE_REQUIREATTACHMENT_RESPONSE: "DELETE_REQUIREATTACHMENT_RESPONSE",
  DELETE_REQUIREATTACHMENT_RES_CLEAR: "DELETE_REQUIREATTACHMENT_RES_CLEAR",

  SHOW_REQUIREATTACHMENT_REQUEST: "SHOW_REQUIREATTACHMENT_REQUEST",
  SHOW_REQUIREATTACHMENT_RESPONSE: "SHOW_REQUIREATTACHMENT_RESPONSE",
  SHOW_REQUIREATTACHMENT_RES_CLEAR: "SHOW_REQUIREMENTATTACHMENT_RES_CLEAR",
};
