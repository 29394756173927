import React from "react";

const ReportChartLabel = ({ data }) => {
  return (
    <div
      className="d-flex flex-wrap justify-content-around"
      style={{ margin: "10px 25px 30px" }}
    >
      {data?.map((val, index) => {
        return (
          <div className="report_pie_chart_bottom" key={index}>
            <div
              style={{
                border: `1px solid ${val?.color}`,
                borderRadius: "30px",
                padding: "1px",
              }}
            >
              <div
                className="dot_icon"
                style={{
                  color: `${val?.color}`,
                  backgroundColor: `${val?.color}`,
                }}
              ></div>
            </div>

            <p style={{ color: "black" }}>{val?.status}</p>
          </div>
        );
      })}
    </div>
  );
};

export default ReportChartLabel;
