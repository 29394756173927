import { Close, UploadFile } from "@mui/icons-material";
import { FieldArray, FormikProvider } from "formik";
import React from "react";
import "./MultiDocUpload.css";

function MultiDocUpload({ formik }) {
  const handleFileUpload = (event) => {
    let { files, name } = event.target;
    formik.setFieldValue(name, files[0]);
  };
  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="attachments"
        render={(arrayHelpers) => (
          <>
            <div className="popformLabel w-100">
              <label>Attachments</label>
              <div className=" field-form  d-flex flex-wrap align-items-center gap-4 w-100">
                {formik?.values?.attachments?.map((item, index) => {
                  return (
                    <div key={index} className="position-relative">
                      <div className="document_upload fileInputWrpr">
                        <input
                          type="file"
                          className="document-upload__input-2"
                          formik={formik}
                          name={`attachments.${index}.attachment`}
                          onChange={(e) => handleFileUpload(e)}
                          onBlur={formik.handleBlur}
                        />
                        <div className="image-upload__preview-container-2">
                          <div>
                            {formik?.values?.attachments?.length > 1 ? (
                              <Close
                                className="delete_document_upload_icon"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="image-upload__placeholder-2">
                            <div className="d-flex flex-wrap flex-column align-items-center justify-content-center">
                              <UploadFile
                                fontSize="small"
                                sx={{ width: "25px", height: "25px" }}
                              />
                              {formik?.values?.attachments[index]?.attachment
                                ?.name && (
                                <p className="upload_image_file-name">
                                  {formik?.values?.attachments[index]
                                    ?.attachment?.name || ""}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        {formik?.touched?.attachments?.[index]?.attachment &&
                        formik?.errors?.attachments?.[index]?.attachment ? (
                          <p className="errMsg">
                            {formik.errors?.attachments[index]?.attachment}
                          </p>
                        ) : (
                          ""
                        )}
                        <input
                          type="text"
                          placeholder="Enter document name"
                          className="document_name"
                          name={`attachments.${index}.name`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik?.values.attachments[index].name}
                        />
                        {formik?.touched?.attachments?.[index]?.name &&
                        formik?.errors?.attachments?.[index]?.name ? (
                          <p className="errMsg">
                            {formik.errors?.attachments[index]?.name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
                {formik?.values?.attachments?.[
                  formik?.values?.attachments?.length - 1
                ]?.attachment && (
                  <div
                    className="image-upload__preview-container-2 add-more-placeholder"
                    onClick={() => {
                      arrayHelpers.push({
                        attachment: null,
                        name: "",
                      });
                    }}
                  >
                    <div className="image-upload__placeholder-2">
                      <div className="flex_column_center">
                        <span className="plus-icon">+</span>
                        <p className="upload_image_file-name">Add more</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      />
    </FormikProvider>
  );
}

export default MultiDocUpload;
