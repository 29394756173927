import { FiberManualRecord } from "@mui/icons-material";
import React from "react";

const DashboardChartLabel = ({ data }) => {
  return (
    <>
      {data?.map((val, index) => {
        return (
          <div className="pie_chart_bottom" key={index}>
            <FiberManualRecord style={{ color: `${val?.color}` }} />
            <p>{val?.status}</p>
          </div>
        );
      })}
    </>
  );
};

export default DashboardChartLabel;
