import { takeLatest, call, put } from "redux-saga/effects";
import TestExecutionActionTypes from "./TestExecutionActionTypes";
import { axiosPost, axiosPut, axiosDelete, axiosGet } from "../../Utils/axios";
import { unauthorizedMessage } from "../Login/LoginActions";
import {
  cancelExecutionResponse,
  deleteAddedTestCaseResponse,
  editBugResponse,
  getAddedTestCaseIndexResponse,
  getAllAttachmentsIndexResponse,
  getAllDefectListResponse,
  getApprovedTestCaseIndexResponse,
  getBugResponse,
  getExecutionByIdResponse,
  getRunningTestCaseIndexResponse,
  postTestCaseAddResponse,
  runTestCaseResponse,
  runTestCaseResultResponse,
  saveDefectsResponse,
  showRunningTestCaseResponse,
  submitDefectsResponse,
  viewExecutionByIdResponse,
} from "./TestExecutionAction";
import { defer } from "react-router-dom";

// WORKER SAGA
// TEST EXECUTION INDEX
// GET ALL TESTCASES APPROVED
export function* onGetTestCaseApprovedIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_test_cases", payload).then((response) => response?.data)
    );
    yield put(getApprovedTestCaseIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET TESTCASES ADDED IN EXECUTION
export function* onGetTestCaseAddedIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("test_execution/view_test_cases", payload).then(
        (response) => response?.data
      )
    );
    yield put(getAddedTestCaseIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}
//viewtestexectuion
export function* oviewtTestExecutionWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`test_execution/test_case/${payload}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(viewExecutionByIdResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// POST TESTCASE IN EXECUTION
export function* onPostTestCaseAddSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut("test_execution/add", payload).then((response) => response?.data)
    );
    yield put(postTestCaseAddResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// DELETE TESTCASE IN EXECUTION
export function* onDeleteTestCaseAddedWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut("test_execution/delete", payload).then(
        (response) => response?.data
      )
    );
    yield put(deleteAddedTestCaseResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//runtestcase
export function* onRunTestCaseWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut("test_execution/run", payload).then((response) => response?.data)
    );
    yield put(runTestCaseResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//getruntestcaseresult
export function* onRunTestCaseListWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("test_execution/view_test_steps", payload).then(
        (response) => response?.data
      )
    );
    yield put(runTestCaseResultResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//savedefects
export function* onSaveDefectsWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("test_execution/save_execution_data", payload).then(
        (response) => response?.data
      )
    );
    yield put(saveDefectsResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}
//submit
export function* onSubmitDefectsWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut("test_execution/submit_defects", payload).then(
        (response) => response?.data
      )
    );
    yield put(submitDefectsResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//get bug
export function* onGetBugListWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut("get_defect", payload).then((response) => response?.data)
    );
    yield put(getBugResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//getdefectlist
export function* onGetDefectListWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut(`defect/${payload.id}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(getAllDefectListResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//edit bug
export function* onEditBugListWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut("edit_defect", payload).then((response) => response?.data)
    );
    yield put(editBugResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET ALL RUNNIG TESTCASES
export function* onGetRunningTestCasesIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("view_executions", payload).then((response) => response?.data)
    );
    yield put(getRunningTestCaseIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET PARTICULAR RUNNIG TESTCASES
export function* onshowRunningTestCasesWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosGet(`test_case/${payload}`).then((response) => response?.data)
    );
    yield put(showRunningTestCaseResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// GET ALL RUNNING TESTCASES ATTACHMENTS
export function* onGetRunningTestcaseAttachmentsIndexWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("test_execution/view_attachments", payload).then(
        (response) => response?.data
      )
    );
    yield put(getAllAttachmentsIndexResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

// CANCEL EXECUTION
export function* onCancelExecutionWorkerSaga({ payload }) {
  try {
    const response = yield call(() =>
      axiosPut("test_execution/cancel_execution", payload).then(
        (response) => response?.data
      )
    );
    yield put(cancelExecutionResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}

//executionbyid
export function* onExecutiByIdonWorkerSaga({ payload }) {
  // executionid;
  try {
    const response = yield call(() =>
      axiosGet(`test_execution/get_execution/${payload}`, payload).then(
        (response) => response?.data
      )
    );
    yield put(getExecutionByIdResponse(response));
  } catch (error) {
    yield put(unauthorizedMessage(error));
  }
}
// WATCHER SAGA
export function* TestExecutionWatcherSaga() {
  //execution
  yield takeLatest(
    TestExecutionActionTypes.VIEW_TESTEXECUTION_BYID_REQUST,
    oviewtTestExecutionWorkerSaga
  );

  yield takeLatest(
    TestExecutionActionTypes.GET_EXECUTION_BYID_REQUEST,
    onExecutiByIdonWorkerSaga
  );

  //testcase run
  yield takeLatest(
    TestExecutionActionTypes.TESTCASE_RUN_REQUEST,
    onRunTestCaseWorkerSaga
  );
  //edit bug run
  yield takeLatest(
    TestExecutionActionTypes.EDIT_BUG_REQUEST,
    onEditBugListWorkerSaga
  );
  //getdefectlist
  yield takeLatest(
    TestExecutionActionTypes.GET_DEFECTLIST_REQUEST,
    onGetDefectListWorkerSaga
  );
  //getbug
  yield takeLatest(
    TestExecutionActionTypes.GET_BUG_REQUEST,
    onGetBugListWorkerSaga
  );
  //savedefects
  yield takeLatest(
    TestExecutionActionTypes.SAVE_DEFECTS_START,
    onSaveDefectsWorkerSaga
  );

  //submitdefects
  yield takeLatest(
    TestExecutionActionTypes.SUBMITE_DEFECT_START,
    onSubmitDefectsWorkerSaga
  );

  yield takeLatest(
    TestExecutionActionTypes.GETRUN_RESULT_REQUEST,
    onRunTestCaseListWorkerSaga
  );

  yield takeLatest(
    TestExecutionActionTypes.GET_APPROVED_TESTCASES_INDEX_REQUEST,
    onGetTestCaseApprovedIndexWorkerSaga
  );
  yield takeLatest(
    TestExecutionActionTypes.GET_ADDED_TESTCASES_INDEX_REQUEST,
    onGetTestCaseAddedIndexWorkerSaga
  );
  yield takeLatest(
    TestExecutionActionTypes.POST_APPROVED_TESTCASES_REQUEST,
    onPostTestCaseAddSaga
  );
  yield takeLatest(
    TestExecutionActionTypes.DELETE_APPROVED_TESTCASE_REQUEST,
    onDeleteTestCaseAddedWorkerSaga
  );
  yield takeLatest(
    TestExecutionActionTypes.GET_RUNNING_TESTCASES_REQUEST,
    onGetRunningTestCasesIndexWorkerSaga
  );
  yield takeLatest(
    TestExecutionActionTypes.SHOW_RUNNING_TESTCASES_REQUEST,
    onshowRunningTestCasesWorkerSaga
  );
  yield takeLatest(
    TestExecutionActionTypes.GET_ALL_ATTACHMENTS_INDEX_REQUEST,
    onGetRunningTestcaseAttachmentsIndexWorkerSaga
  );
  yield takeLatest(
    TestExecutionActionTypes.CANCEL_EXECUTION_REQUEST,
    onCancelExecutionWorkerSaga
  );
}
