import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { showTaskRequest } from "../../Redux/TaskSaga/TaskAction";
import moment from "moment";
const TaskView = ({ data, testCase, applicationId }) => {
  const { id, executionid } = useParams();
  const dispatch = useDispatch();

  const { task } = useSelector((state) => state.task);

  useEffect(() => {
    dispatch(
      showTaskRequest({
        task_id: data,
        test_case_id: executionid,
        application_id: applicationId,
      })
    );
  }, [id]);
  // const data = [
  //   {
  //     id: 66,
  //     position: 10,
  //     title: "My Task From live Qtest 21",
  //     description: "Description",
  //     due_date: "2024-02-10",
  //     priority: "p1",
  //     is_completed: false,
  //     is_validated: false,
  //     created_at: "2024-02-07T16:29:06.913922+05:30",
  //     updated_at: "2024-02-07T16:29:06.913954+05:30",
  //     project_fk: 37,
  //     column: 53,
  //     assign_fk: {
  //       user_id: 67,
  //       user_name: "Durgairaj ",
  //     },
  //     createdby_fk: 67,
  //     days_left: "2 days left",
  //     task_id: "#PM010",
  //     task_timeline: [
  //       {
  //         data: "Durgairaj Assigned this task to Durgairaj",
  //         time: "07/02/2024 04:29 PM",
  //         created_by: {
  //           id: 67,
  //           name: "Durgairaj",
  //         },
  //       },
  //       {
  //         data: "Durgairaj Created this task under Completed",
  //         time: "07/02/2024 04:29 PM",
  //         created_by: {
  //           id: 67,
  //           name: "Durgairaj",
  //         },
  //       },
  //     ],
  //     attachments: [],
  //     in_testforge_created_by: {
  //       id: 90,
  //       full_name: "durgai",
  //     },
  //   },
  //   {
  //     id: 67,
  //     position: 11,
  //     title: "My Task From live Qtest 212",
  //     description: "Description",
  //     due_date: "2024-02-10",
  //     priority: "p1",
  //     is_completed: false,
  //     is_validated: false,
  //     created_at: "2024-02-07T16:29:31.825035+05:30",
  //     updated_at: "2024-02-07T16:29:31.825056+05:30",
  //     project_fk: 37,
  //     column: 53,
  //     assign_fk: {
  //       user_id: 67,
  //       user_name: "Durgairaj ",
  //     },
  //     createdby_fk: 67,
  //     days_left: "2 days left",
  //     task_id: "#PM011",
  //     task_timeline: [
  //       {
  //         data: "Durgairaj Assigned this task to Durgairaj",
  //         time: "07/02/2024 04:29 PM",
  //         created_by: {
  //           id: 67,
  //           name: "Durgairaj",
  //         },
  //       },
  //       {
  //         data: "Durgairaj Created this task under Completed",
  //         time: "07/02/2024 04:29 PM",
  //         created_by: {
  //           id: 67,
  //           name: "Durgairaj",
  //         },
  //       },
  //     ],
  //     attachments: [
  //       {
  //         id: 97,
  //         document:
  //           "https://api.greenwayitsolutions.com/media/project/task_documents/gway_crm_2.png",
  //       },
  //     ],
  //     in_testforge_created_by: {
  //       id: 90,
  //       full_name: "durgai",
  //     },
  //   },
  // ];

  return (
    <div>
      <div className=" bg-white  mb-5">
        {/* <div className="tblWrpr">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Due Date</th>
                  <th>Priority</th>
                  <th>Assigned User Name</th>
                  <th>Days Left</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{task?.id}</td>
                  <td>{task?.title}</td>
                  <td>{task?.description}</td>
                  <td>{task?.due_date}</td>
                  <td>{task?.priority}</td>
                  <td>{task?.assign_fk.user_name}</td>
                  <td>{task?.days_left}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
        <div className="view_execution_container">
          <div className="dashboard_container w-100 overflow-auto">
            <div className="row">
              <div className="col-md-6 details_list">
                <h3 className="defect_head">Title</h3>
                <p>:</p>
                <h3 className="defect_values">{task?.title}</h3>
              </div>
              <div className="col-md-6 details_list">
                <h3 className="defect_head">Description</h3>
                <p>:</p>
                <h3 className="defect_values">{task?.description || "_"}</h3>
              </div>
              <div className="col-md-6 details_list">
                <h3 className="defect_head">Due Date</h3>
                <p>:</p>
                <h3 className="defect_values">{task?.due_date}</h3>
              </div>
              <div className="col-md-6 details_list">
                <h3 className="defect_head">Priority</h3>
                <p>:</p>
                <h3 className="defect_values">{task?.priority}</h3>
              </div>
              <div className="col-md-6 details_list">
                <h3 className="defect_head">Days Left</h3>
                <p>:</p>
                <h3 className="defect_values">{task?.days_left || "_"}</h3>
              </div>

              <div className="col-md-6 details_list">
                <h3 className="defect_head">Created At</h3>
                <p>:</p>

                <h3 className="defect_values">
                  {moment(task?.created_at).format("DD.MM.YYYY hh:mm:ss A") ||
                    "_"}
                </h3>
              </div>
              <div className="col-md-6 details_list">
                <h3 className="defect_head">Updated At</h3>
                <p>:</p>
                <h3 className="defect_values">
                  {moment(task?.updated_at).format("DD.MM.YYYY hh:mm:ss A") ||
                    "_"}
                </h3>
              </div>
            </div>
          </div>

          {/* commentrs */}
        </div>
      </div>
    </div>
  );
};
export default TaskView;
