import { ReportActionTypes } from "./ReportActionTypes";
import { CLEAR_ERROR_RES } from "../Login/LoginActionTypes";

const initialState = {
  report: null,
  reportLoading: false,
  reportResponse: null,
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReportActionTypes.GET_REPORT_INDEX_REQUEST:
      return {
        ...state,
        reportLoading: true,
      };
    case ReportActionTypes.GET_REPORT_INDEX_RESPONSE:
      return {
        ...state,
        reportLoading: false,
        reportResponse: action.payload,
        report: action.payload?.data,
      };
    case CLEAR_ERROR_RES:
      return {
        ...state,
        reportLoading: false,
        reportResponse: null,
      };
    default:
      return state;
  }
};

export default ReportReducer;
