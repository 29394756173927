import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import "./CustomDialog.css";
import PropTypes from "prop-types";

export default function CustomDialog({
  open,
  maxWidth = "sm",
  fullWidth = true,
  handleClose,
  children,
  title,
  divider = true,
  ...rest
}) {
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      {...rest}
    >
      {/* <div className="dialog_container"> */}
      <DialogTitle className="dialog_title">
        <h3>{title}</h3>
        <IconButton onClick={handleClose}>
          <Close sx={{ color: "black", width: "20px", height: "20px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={divider} className="dialog_content">
        <div className="popFORM">{children}</div>
      </DialogContent>
      {/* </div> */}
    </Dialog>
  );
}
CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fullWidth: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  divider: PropTypes.bool,
};

// import React from "react";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import { DialogContent, IconButton } from "@mui/material";
// import { Close } from "@mui/icons-material";
// import "./CustomDialog.css";
// import PropTypes from "prop-types";

// export default function CustomDialog({
//   open,
//   maxWidth = "sm",
//   fullWidth = true,
//   handleClose,
//   children,
//   title,
//   divider = true,
//   ...rest
// }) {
//   return (
//     <Dialog
//       fullWidth={fullWidth}
//       maxWidth={maxWidth}
//       open={open}
//       onClose={handleClose}
//       {...rest}
//     >
//       <DialogTitle className="d-flex align-items-center justify-content-between px-3 py-2">
//         {title}
//         <IconButton onClick={handleClose}>
//           <Close />
//         </IconButton>
//       </DialogTitle>
//       <DialogContent dividers={divider}>
//         <div className="">{children}</div>
//       </DialogContent>
//     </Dialog>
//   );
// }
// CustomDialog.propTypes = {
//   open: PropTypes.bool.isRequired,
//   maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
//   fullWidth: PropTypes.bool,
//   handleClose: PropTypes.func.isRequired,
//   children: PropTypes.node.isRequired,
//   title: PropTypes.string.isRequired,
//   divider: PropTypes.bool,
// };
