import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AttachFile, VisibilityOutlined } from "@mui/icons-material";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import {
  runTestCaseResultRequest,
  saveDefectsRequest,
  saveDefectsResponseClear,
  submitDefectsRequest,
  submitDefectsResponseClear,
} from "../../Redux/TestExecution/TestExecutionAction";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import CustomDialog from "../../Components/Atoms/CustomDialog/CustomDialog";
import TestRunAttachmentForm from "../TestExecution/TestRunAttachmentForm";
import AddDefectForm from "../TestExecution/AddDefectForm";
import { CustomPagination } from "../../Components/Molecules/PaginationComponent/Pagination";
import { BUGCOLOR_ICON, BUGGREY_ICON } from "../../Utils/imageConstants";
import CustomInput from "../../Components/Atoms/CustomInput/CustomInput";
import CustomCancelSubmit from "../../Components/Molecules/CancelSubmit/CancelSubmit";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";

export default function TestExecutionTestSTeps() {
  const [listPerPage, setListPerPage] = useState(10);
  const [activeTab, setActiveTab] = useState("teststeps");

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { testCaseRunList, list, submitDefectsResponse, saveDefectsResponse } =
    useSelector((state) => state.testExecution);

  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [index, setIndex] = useState(null);

  const [isStatusEditing, setIsStatusEditing] = useState("");

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { bugListResponse } = useSelector((state) => state.testExecution);
  const [bugResponse, setBugResponse] = useState(bugListResponse);
  const [bugsResponse, setBugsResponse] = useState("");
  const [testCaseData, setTestCaseData] = useState(false);

  // useEffect(() => {
  //   setBugResponse(bugListResponse);
  // }, []);

  const handleClose = () => {
    setOpen(false);
  };
  // p
  const [openDialog, setOpenDialog] = useState({
    open: false,
    type: null,
    data: null,
  });

  // HANDLE DIALOG OPENING
  // p
  const handleOpenDialog = (type, data, index) => {
    const bug = data?.bug ? data?.bug : bugsResponse;
    setOpenDialog({
      open: true,
      type: type,
      data: { ...data, bug: bug },
    });
    // setIndex(index);
  };

  const handleCloseDialog = () => {
    setOpenDialog({ open: false, type: null });
  };

  const handleOpen = (index) => {
    setIndex(index);

    setOpen(true);
  };

  // HANDLE TAB SWITCH
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const payload = {
    filters: {
      project_id: {
        type: "exact",
        value: id,
      },
    },
    pagination_meta: {
      page: page,
      limit: listPerPage,
    },
  };
  useEffect(() => {
    dispatch(runTestCaseResultRequest(payload));
  }, [id, page, listPerPage]);

  const validationSchema =
    status &&
    Yup.object().shape({
      steps: Yup.array().of(
        Yup.object().shape({
          actual_result: Yup.string().required("Actual Result is required"),
          status: Yup.string().required("Status is required"),
        })
      ),
    });

  const formik = useFormik({
    initialValues: {
      project_id: id,
      steps: [
        {
          status: "",
          test_step_fk: "",
          actual_result: "",
          bug: "",
          log: "",
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // const id = anchorEl?.id;
      // const bug = list?.data?.id;

      const formData = new FormData();

      for (let requirement_key in values) {
        if (requirement_key === "steps" && values.steps.length > 0) {
          values.steps.forEach((item, index) => {
            if (item) {
              formData.append(
                `steps[${index}].actual_result`,
                item.actual_result
              );
              if (item.status) {
                formData.append(`steps[${index}].status`, item.status);
              }
              if (
                bugsResponse &&
                bugsResponse?.test_step_fk === isStatusEditing[index]
              ) {
                formData.append(`steps[${index}].bug`, bugsResponse?.id);
              }
              // formData.append(`steps[${index}].bug`, bug);

              // formData.append(`steps[${index}].log`, item.log);

              formData.append(
                `steps[${index}].test_step_fk`,
                isStatusEditing[index]
              );
              if (typeof item.log === "string" || item.log === null) {
                // Do nothing if attachment is a string
              } else if (item.log instanceof Blob || item.log instanceof File) {
                // Append attachment if it is binary (Blob or File)
                formData.append(`steps[${index}].log`, item.log);
              }
              // else if (item.log === null) {
              //   formData.append(`steps[${index}].log`, null);
              // }
            }
          });
        } else {
          formData.append(`${requirement_key}`, values[requirement_key]);
        }
      }

      dispatch(saveDefectsRequest(formData));
    },
  });

  useEffect(() => {
    if (!submitDefectsResponse) return;
    if (submitDefectsResponse) {
      if (submitDefectsResponse?.message && submitDefectsResponse?.success) {
        toast.success(submitDefectsResponse?.message);
        dispatch(runTestCaseResultRequest(payload));
        navigate(-1);
      } else {
        toast.error(submitDefectsResponse?.message);
      }
    }
    return () => {
      dispatch(submitDefectsResponseClear());
    };
  }, [submitDefectsResponse]);

  //save

  useEffect(() => {
    if (!saveDefectsResponse) return;
    if (saveDefectsResponse) {
      if (saveDefectsResponse?.message && saveDefectsResponse?.success) {
        toast.success(saveDefectsResponse?.message);
        dispatch(runTestCaseResultRequest(payload));
      } else {
        toast.error(saveDefectsResponse?.message);
      }
    }
    return () => {
      dispatch(saveDefectsResponseClear());
    };
  }, [saveDefectsResponse]);

  const type = [
    {
      value: "UNEXECUTED",
      name: "Unexecuted",
    },
    {
      value: "PASSED",
      name: "Passed",
    },
    {
      value: "FAILED",
      name: "Failed",
    },
  ];
  useEffect(() => {
    const ids = testCaseRunList?.data?.map((data) => data?.id);
    setIsStatusEditing(ids);

    const stepsArr = testCaseRunList?.data?.map((data, index) => {
      const { status, actual_result } = data;
      return { actual_result, status };
    });
    formik.setFieldValue("steps", stepsArr);
  }, [testCaseRunList]);

  const handleStatus = (e) => {
    if (e.target.value === "Unexecuted") {
      setStatus(true);
    }
  };

  // useEffect(() => {
  //   dispatch(getAllDefectListRequest({ id: openDialog.open?.data?.id }));
  // }, []);
  const Validation = Yup.object().shape({
    summary: Yup.string()
      .matches(/^\s*\S[\s\S]*$/g, "Should not contain spaces alone")

      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .trim()
      .required("Module Name is required!"),
  });

  const Formik = useFormik({
    initialValues: {
      test_step_id: open?.data?.id,
      status: "",
      summary: "",
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      // if (list?.data?.summary) {
      //   dispatch(
      //     editBugRequest({
      //       ...values,
      //       defect_id: list?.data?.id,
      //     })
      //   );
      // } else {
      //   dispatch(getBugRequest({ ...values, test_step_id: open?.data?.id }));
      // }
    },
  });

  useEffect(() => {
    // if (list?.data?.summary) {
    Formik?.setFieldValue("summary", "newwew");
    // }
  }, []);

  useEffect(() => {
    if (testCaseRunList) {
      if (testCaseRunList?.data) {
        setTestCaseData(testCaseRunList?.data);
      }
    }
  }, [testCaseRunList]);

  useEffect(() => {
    if (!bugsResponse) return;
    if (bugsResponse) {
      setTestCaseData(
        testCaseRunList?.data?.map((testCaseDetail) => {
          if (testCaseDetail?.test_step_fk === bugsResponse?.test_step_fk) {
            return { ...testCaseDetail, bug: bugsResponse };
          }
          // Otherwise, return the original testCaseDetail
          return testCaseDetail;
        })
      );
    }

    setBugResponse("");
  }, [bugsResponse]);

  const handleBugsResponse = (data) => {
    setBugsResponse(data);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="testexecution_container">
          <div className="mt-4">
            <div className="tblWrpr">
              <div className="table-responsive ">
                <table className="table">
                  <thead>
                    <tr>
                      <th>TestCase ID</th>
                      <th>Step No</th>
                      <th>Description</th>
                      <th>Expected Result</th>
                      <th>Actual Result</th>
                      {/* <th>Log</th> */}
                      <th>Status</th>
                      <th>
                        <div className="bug_image_container">
                          <img src={BUGGREY_ICON} alt="greycolorbug" />
                          {/* <span>{data?.no_of_bugs}</span> */}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {testCaseData?.length > 0 ? (
                      testCaseData?.map((data, index) => (
                        <tr key={index} onClick={() => setSelectedId(data?.id)}>
                          <td>{`TC - ${data?.test_case_fk}`}</td>
                          <td>{data?.step}</td>
                          <td>{data?.description}</td>
                          <td>{data?.expected_result}</td>
                          {/* <td>{data?.actual_result}</td> */}
                          <td>
                            {/* <div
                            className={
                              isEditing
                                ? "test_header2"
                                : "test_header test_header_hover"
                            }
                            onClick={() => {
                              if (activeTab === "teststeps") {
                                setIsEditing(true);
                              } else {
                                setIsEditing(false);
                              }
                            }}
                          > */}
                            {/* {isEditing ? ( */}
                            {/* <>
                              <div className="customInputWrpr">
                                <textarea
                                  type="text"
                                  name={`steps[${index}].actual_result`}
                                  size="large"
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={(e) => {
                                    setAnchorEl(data);
                                    if (
                                      formik.values.steps[index]?.actual_result
                                    ) {
                                      formik.handleBlur(e);
                                      setIsEditing(false);
                                    }
                                  }}
                                  value={
                                    formik.values.steps[index]?.actual_result
                                  }
                                  formik={formik}
                                />
                                {formik.errors.steps?.[index]?.actual_result ? (
                                  <p className="errMsg">
                                    {
                                      formik.errors.steps?.[index]
                                        ?.actual_result
                                    }
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </> */}
                            <CustomInput
                              type="text"
                              name={`steps[${index}].actual_result`}
                              size="large"
                              autoComplete="off"
                              onChange={formik.handleChange}
                              onBlur={(e) => {
                                setAnchorEl(data);
                                if (formik.values.steps[index]?.actual_result) {
                                  formik.handleBlur(e);
                                  setIsEditing(false);
                                }
                              }}
                              multiline
                              rows={2}
                              value={formik.values.steps[index]?.actual_result}
                              formik={formik}
                            />
                            {formik.errors.steps?.[index]?.actual_result ? (
                              <p className="errMsg">
                                {formik.errors.steps?.[index]?.actual_result}
                              </p>
                            ) : (
                              ""
                            )}
                            {/* ) : (
                            <>
                              <h3>
                                {formik.values.steps[index]?.actual_result}
                              </h3>
                            </>
                          )} */}
                            {/* </div> */}
                          </td>
                          {/* <td>
                            {data?.log == null ? (
                              <AttachFile
                                className="clickable"
                                onClick={() => handleOpen(index)}
                              />
                            ) : (
                              <VisibilityOutlined
                                className="clickable"
                                onClick={() => handleOpen(index)}
                              />
                            )}
                          </td> */}
                          {/* <td>{data?.status}</td> */}
                          <td>
                            {/* <div
                            className={
                              isStatusEditing
                                ? "test_header2"
                                : "test_header test_header_hover"
                            }
                            onClick={() => {
                              if (activeTab === "teststeps") {
                                setIsStatusEditing(true);
                              } else {
                                setIsStatusEditing(false);
                              }
                            }}
                          > */}
                            {/* {isStatusEditing ? ( */}
                            <div className="customInputWrpr staticWidth">
                              {/* <CustomSelect
                              type="text"
                              name={`steps[${index}].status`}
                              options={type.map((values, index) => {
                                return {
                                  value: values.value,
                                  name: values.name,
                                };
                              })}
                              size="large"
                              autoComplete="off"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              onBlur={(e) => {
                                if (formik.values.steps[index]?.status) {
                                  formik.handleBlur(e);
                                  setIsStatusEditing(false);
                                }
                              }}
                              value={formik.values.steps[index]?.status}
                              formik={formik}
                            /> */}
                              {/* <select
                                type="text"
                                name={`steps[${index}].status`}
                                size="large"
                                autoComplete="off"
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  handleStatus(e);
                                }}
                                onBlur={(e) => {
                                  setAnchorEl(data);
                                  if (formik.values.steps[index]?.status) {
                                    formik.handleBlur(e);
                                    setIsEditing(false);
                                  }
                                }}
                                value={formik.values.steps[index]?.status}
                              >
                                {type.map((values, index) => (
                                  <option key={index} value={values.value}>
                                    {values.name}
                                  </option>
                                ))}
                              </select> */}
                              <CustomSelect
                                size="small"
                                placeholder="Select Status"
                                options={type.map((values, index) => {
                                  return {
                                    value: values.value,
                                    name: values.name,
                                  };
                                })}
                                name={`steps[${index}].status`}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  handleStatus(e);
                                }}
                                onBlur={(e) => {
                                  setAnchorEl(data);
                                  if (formik.values.steps[index]?.status) {
                                    formik.handleBlur(e);
                                    setIsEditing(false);
                                  }
                                }}
                                value={formik.values.steps[index]?.status}
                                formik={formik}
                              />
                              {status &&
                              formik.errors.steps?.[index]?.status ? (
                                <p className="errMsg">
                                  {formik.errors.steps?.[index]?.status}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            {/* ) : (
                              <>
                                <h3>{formik.values.steps[index]?.status}</h3>
                              </>
                            )} */}
                            {/* </div> */}
                          </td>
                          <td>
                            <div
                              className="bug_image_container"
                              onClick={() => {
                                handleOpenDialog("defect", data);
                              }}
                            >
                              <span>{data?.no_of_bugs}</span>
                              <img src={BUGCOLOR_ICON} alt="greycolorbug" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8}>No TestSteps Added yet</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="d-flex align-items-center justify-content-between">
              <p>Showing 6 results</p>
              <div className="tables_pagination">
                <TablePagination
                  component="div"
                  count={100}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Results per page:"
                />
              </div>
            </div> */}
          </div>
        </div>
        {testCaseData?.length > 0 && (
          <CustomPagination
            dataList={testCaseRunList}
            name="Test Step"
            page={page}
            setPage={setPage}
            listPerPage={listPerPage}
            applied={false}
          />
        )}
        {testCaseData?.length > 0 && (
          <div className="popformbutton">
            <CustomButton size="small" type="submit" label="Save" />
          </div>
        )}
      </form>
      <CustomDialog
        open={open}
        title="Attachments"
        handleClose={handleClose}
        maxWidth="sm"
      >
        <TestRunAttachmentForm
          handleClose={handleClose}
          index={index}
          formik={formik}
        />
      </CustomDialog>

      <CustomDialog
        open={openDialog.open && openDialog.type === "defect"}
        title="Defects"
        handleClose={handleCloseDialog}
      >
        <AddDefectForm
          handleClose={handleCloseDialog}
          open={openDialog}
          newData={openDialog?.data}
          handleBugsResponse={handleBugsResponse}
        />
      </CustomDialog>
    </>
  );
}
