import TestDesignActionTypes from "./TestDesignActionTypes";

// TESTDESIGN MODULES
// GET ALL TESTDESIGN MODULES
export const getTestDesignModuleIndexRequest = (payload) => ({
  type: TestDesignActionTypes.GET_TESTDESIGNMODULE_INDEX_REQUEST,
  payload,
});

export const getTestDesignModuleIndexResponse = (payload) => ({
  type: TestDesignActionTypes.GET_TESTDESIGNMODULE_INDEX_RESPONSE,
  payload,
});

// POST TESTDESIGN MODULE
export const postTestDesignModuleRequest = (payload) => ({
  type: TestDesignActionTypes.POST_TESTDESIGNMODULE_REQUEST,
  payload,
});

export const postTestDesignModuleResponse = (payload) => ({
  type: TestDesignActionTypes.POST_TESTDESIGNMODULE_RESPONSE,
  payload,
});

// UPDATE TESTDESIGN MODULE
export const updateTestDesignModuleRequest = (payload) => ({
  type: TestDesignActionTypes.UPDATE_TESTDESIGNMODULE_REQUEST,
  payload,
});

export const updateTestDesignModuleResponse = (payload) => ({
  type: TestDesignActionTypes.UPDATE_TESTDESIGNMODULE_RESPONSE,
  payload,
});

// POST AND UPDATE TESTDESIGN MODULE RESPONSE CLEAR
export const postTestDesignModuleResponseClear = () => ({
  type: TestDesignActionTypes.POST_TESTDESIGNMODULE_RES_CLEAR,
});

// DELETE TESTDESIGN MODULE
export const deleteTestDesignModuleRequest = (payload) => ({
  type: TestDesignActionTypes.DELETE_TESTDESIGNMODULE_REQUEST,
  payload,
});

export const deleteTestDesignModuleResponse = (payload) => ({
  type: TestDesignActionTypes.DELETE_TESTDESIGNMODULE_RESPONSE,
  payload,
});

export const deleteTestDesignModuleResponseClear = () => ({
  type: TestDesignActionTypes.DELETE_TESTDESIGNMODULE_RES_CLEAR,
});

// GET PARTICULAR TESTDESIGN MODULE
export const showTestDesignModuleRequest = (payload) => ({
  type: TestDesignActionTypes.SHOW_TESTDESIGNMODULE_REQUEST,
  payload,
});

export const showTestDesignModuleResponse = (payload) => ({
  type: TestDesignActionTypes.SHOW_TESTDESIGNMODULE_RESPONSE,
  payload,
});

export const showTestDesignModuleResponseClear = () => ({
  type: TestDesignActionTypes.SHOW_TESTDESIGNMODULE_RES_CLEAR,
});

// TESTDESIGN DETAILS
// GET ALL TESTDESIGN DETAILS
export const getTestDesignDetailIndexRequest = (payload) => ({
  type: TestDesignActionTypes.GET_TESTDESIGNDETAIL_INDEX_REQUEST,
  payload,
});

export const getTestDesignDetailIndexResponse = (payload) => ({
  type: TestDesignActionTypes.GET_TESTDESIGNDETAIL_INDEX_RESPONSE,
  payload,
});

// POST TESTDESIGN DETAIL
export const postTestDesignDetailRequest = (payload) => {
  return { type: TestDesignActionTypes.POST_TESTDESIGNDETAIL_REQUEST, payload };
};

export const postTestDesignDetailResponse = (payload) => ({
  type: TestDesignActionTypes.POST_TESTDESIGNDETAIL_RESPONSE,
  payload,
});

// UPDATE TESTDESIGN DETAIL
export const updateTestDesignDetailRequest = (payload) => ({
  type: TestDesignActionTypes.UPDATE_TESTDESIGNDETAIL_REQUEST,
  payload,
});

export const updateTestDesignDetailResponse = (payload) => ({
  type: TestDesignActionTypes.UPDATE_TESTDESIGNDETAIL_RESPONSE,
  payload,
});

// POST AND UPDATE TESTDESIGN DETAIL RESPONSE CLEAR
export const postTestDesignDetailResponseClear = () => ({
  type: TestDesignActionTypes.POST_TESTDESIGNDETAIL_RES_CLEAR,
});

// DELETE TESTDESIGN DETAIL
export const deleteTestDesignDetailRequest = (payload) => ({
  type: TestDesignActionTypes.DELETE_TESTDESIGNDETAIL_REQUEST,
  payload,
});

export const deleteTestDesignDetailResponse = (payload) => ({
  type: TestDesignActionTypes.DELETE_TESTDESIGNDETAIL_RESPONSE,
  payload,
});

export const deleteTestDesignDetailResponseClear = () => ({
  type: TestDesignActionTypes.DELETE_TESTDESIGNDETAIL_RES_CLEAR,
});

// GET PARTICULAR TESTDESIGN DETAIL
export const showTestDesignDetailRequest = (payload) => ({
  type: TestDesignActionTypes.SHOW_TESTDESIGNDETAIL_REQUEST,
  payload,
});

export const showTestDesignDetailResponse = (payload) => ({
  type: TestDesignActionTypes.SHOW_TESTDESIGNDETAIL_RESPONSE,
  payload,
});

export const showTestDesignDetailResponseClear = () => ({
  type: TestDesignActionTypes.SHOW_TESTDESIGNDETAIL_RES_CLEAR,
});

// TESTDESIGN DETAIL ATTACHMENTS
// GET ALL TESTDESIGN DETAIL ATTACHMENTS
export const getTestDesignDetailAttachmentIndexRequest = (payload) => ({
  type: TestDesignActionTypes.GET_TESTDESIGNATTACH_INDEX_REQUEST,
  payload,
});

export const getTestDesignDetailAttachmentIndexResponse = (payload) => ({
  type: TestDesignActionTypes.GET_TESTDESIGNATTACH_INDEX_RESPONSE,
  payload,
});

export const getTestDesignDetailAttachmentIndexResponseClear = () => ({
  type: TestDesignActionTypes.GET_TESTDESIGNATTACH_INDEX_RES_CLEAR,
});

// POST TESTDESIGN DETAIL ATTACHMENT
export const postTestDesignDetailAttachmentRequest = (payload) => ({
  type: TestDesignActionTypes.POST_TESTDESIGNATTACHMENT_REQUEST,
  payload,
});

export const postTestDesignDetailAttachmentResponse = (payload) => ({
  type: TestDesignActionTypes.POST_TESTDESIGNATTACHMENT_RESPONSE,
  payload,
});

// UPDATE TESTDESIGN DETAIL ATTACHMENT
export const updateTestDesignDetailAttachmentRequest = (payload) => ({
  type: TestDesignActionTypes.UPDATE_TESTDESIGNATTACHMENT_REQUEST,
  payload,
});

export const updateTestDesignDetailAttachmentResponse = (payload) => ({
  type: TestDesignActionTypes.UPDATE_TESTDESIGNATTACHMENT_RESPONSE,
  payload,
});

// POST AND UPDATE TESTDESIGN DETAIL ATTACHMENT RESPONSE CLEAR
export const postTestDesignDetailAttachmentResponseClear = () => ({
  type: TestDesignActionTypes.POST_TESTDESIGNATTACHMENT_RES_CLEAR,
});

// DELETE TESTDESIGN DETAIL ATTACHMENT
export const deleteTestDesignDetailAttachmentRequest = (payload) => ({
  type: TestDesignActionTypes.DELETE_TESTDESIGNATTACHMENT_REQUEST,
  payload,
});

export const deleteTestDesignDetailAttachmentResponse = (payload) => ({
  type: TestDesignActionTypes.DELETE_TESTDESIGNATTACHMENT_RESPONSE,
  payload,
});

export const deleteTestDesignDetailAttachmentResponseClear = () => ({
  type: TestDesignActionTypes.DELETE_TESTDESIGNATTACH_RES_CLEAR,
});

// GET PARTICULAR TESTDESIGN DETAIL ATTACHMENT
export const showTestDesignDetailAttachmentRequest = (payload) => ({
  type: TestDesignActionTypes.SHOW_TESTDESIGNATTACHMENT_REQUEST,
  payload,
});

export const showTestDesignDetailAttachmentResponse = (payload) => ({
  type: TestDesignActionTypes.SHOW_TESTDESIGNATTACHMENT_RESPONSE,
  payload,
});

export const showTestDesignDetailAttachmentResponseClear = () => ({
  type: TestDesignActionTypes.SHOW_TESTDESIGNATTACHMENT_RES_CLEAR,
});
