import InviteActionTypes from "./InviteActiontypes";

const initialState = {
  inviteLoading: false,
  inviteResponse: null,

  list: null,
  listLoading: false,
  listResponse: null,
};

const inviteReducer = (state = initialState, action) => {
  switch (action.type) {
    //invite
    case InviteActionTypes.INVITE_REQUESTED:
      return {
        ...state,
        inviteLoading: true,
      };
    case InviteActionTypes.INVITE_RESPONSE:
      return {
        ...state,
        inviteLoading: false,
        inviteResponse: action.payload,
      };

    case InviteActionTypes.INVITE_RES_CLEAR:
      return {
        ...state,
        inviteResponse: null,
      };

    //inviteindex
    case InviteActionTypes.INVITE_INDEX_REQUEST:
      return {
        ...state,
        listLoading: true,
      };
    case InviteActionTypes.INVITE_INDEX_RESPONSE:
      return {
        ...state,
        listLoading: false,
        listResponse: action.payload,
      };

    case InviteActionTypes.INVITE_INDEX_RES_CLEAR:
      return {
        ...state,
        listResponse: null,
      };

    default:
      return state;
  }
};

export default inviteReducer;
