export default {
  // REQUIREMENT HISTORY
  GET_REQUIREMENTHISTORY_INDEX_REQUEST: "GET_REQUIREMENTHISTORY_INDEX_REQUEST",
  GET_REQUIREMENTHSTORY_INDEX_RESPONSE: "GET_REQUIREMENTHSTORY_INDEX_RESPONSE",

  // REQUIREMENT COMMENTS
  GET_REQUIREMENTCOMMENT_INDEX_REQUEST: "GET_REQUIREMENTCOMMENT_INDEX_REQUEST",
  GET_REQUIREMENTCOMENT_INDEX_RESPONSE: "GET_REQUIREMENTCOMENT_INDEX_RESPONSE",

  POST_REQUIREMENTCOMMENT_REQUEST: "POST_REQUIREMENTCOMMENT_REQUEST",
  POST_REQUIREMENTCOMMENT_RESPONSE: "POST_REQUIREMENTCOMMENT_RESPONSE",

  UPDATE_REQUIREMENTCOMMENT_REQUEST: "UPDATE_REQUIREMENTCOMMENT_REQUEST",
  UPDATE_REQUIREMENTCOMMENT_RESPONSE: "UPDATE_REQUIREMENTCOMMENT_RESPONSE",

  POST_REQUIREMENTCOMMENT_RES_CLEAR: "POST_REQUIREMENTCOMMENT_RES_CLEAR",

  DELETE_REQUIREMENTCOMMENT_REQUEST: "DELETE_REQUIREMENTCOMMENT_REQUEST",
  DELETE_REQUIREMENTCOMMENT_RESPONSE: "DELETE_REQUIREMENTCOMMENT_RESPONSE",
  DELETE_REQUIREMENTCOMMENT_RES_CLEAR: "DELETE_REQUIREMENTCOMMENT_RES_CLEAR",

  SHOW_REQUIREMENTCOMMENT_REQUEST: "SHOW_REQUIREMENTCOMMENT_REQUEST",
  SHOW_REQUIREMENTCOMMENT_RESPONSE: "SHOW_REQUIREMENTCOMMENT_RESPONSE",
  SHOW_REQUIREMENTCOMMENT_RES_CLEAR: "SHOW_REQUIREMENTCOMMENT_RES_CLEAR",
};
