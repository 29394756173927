import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  HelpOutline,
  ArrowDropDownOutlined,
  Search,
  LockReset,
  Logout,
  Close,
} from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  CurrentUserRequest,
  LogoutReq,
  LogoutResClear,
  logout_user,
} from "../../../Redux/Login/LoginActions";
import { appUrl, setAuthToken } from "../../../Utils/axios";
import { NO_PROFILE_IMG } from "../../../Utils/imageConstants";
import { notificationsRequest } from "../../../Redux/NotificationSaga/NotificationAction";
import Notification from "../Notification/Notification";
import "./Navbar.css";

import {
  clearProjectTextAction,
  getProjectIndexRequest,
  searchProjectTextAction,
} from "../../../Redux/ProjectSaga/ProjectAction";
import { GWAY_LOGO_IMG } from "../../../Utils/imageConstants";
import { getAddedTestCaseIndexRequest } from "../../../Redux/TestExecution/TestExecutionAction";
import { convertedString } from "../../../Utils/handles";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { id } = useParams();

  const location = useLocation();
  const { logoutResponse, user } = useSelector((state) => state.loginState);
  const viewExecution = location.pathname == `/project/${id}/testexecution`;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(notificationsRequest());
  }, []);

  // LOGOUT
  const handleLogOut = () => {
    dispatch(LogoutReq({ token: localStorage.getItem("token") }));
  };

  useEffect(() => {
    if (!logoutResponse) return;
    if (logoutResponse?.message && logoutResponse?.success) {
      setAuthToken(null);
      localStorage.removeItem("token");
      navigate("/login");
      toast.success(logoutResponse?.message);
      dispatch(logout_user()); // TO CLEAR USER DATA FROM REDUX STORE
    }
    return () => {
      dispatch(LogoutResClear());
    };
  }, [logoutResponse]);

  useEffect(() => {
    dispatch(CurrentUserRequest());
  }, []);

  const formik = useFormik({
    initialValues: generatePayload(),
    validationSchema: "", // Add your validation schema here if needed
    onSubmit: (values) => {
      const payload = generatePayload(values);
      const testPaload = testgeneratePayload(values);
      if (viewExecution) {
        dispatch(
          getAddedTestCaseIndexRequest({
            ...testPaload,
          })
        );
      } else {
        dispatch(searchProjectTextAction(payload));
      }

      dispatch(getProjectIndexRequest(payload));
    },
  });

  function generatePayload(values) {
    return {
      filters: {
        name: {
          type: "like",
          value: values?.filters?.name?.value ?? "",
        },
      },
      pagination_meta: {
        page: 1,
        limit: 10,
        order_by: "id_desc",
      },
    };
  }

  function testgeneratePayload(values) {
    return {
      filters: {
        project_id: {
          type: "like",
          value: id,
        },
        name: {
          type: "like",
          value: values?.filters?.name?.value ?? "",
        },
      },
      pagination_meta: {
        page: 1,
        limit: 10,
        order_by: "id_desc",
      },
    };
  }

  return (
    <div className="navbar_container">
      <div className="right_side_nav">
        <HelpOutline />
        <Notification />
        <Avatar
          className="gwayimg_container"
          src={`${appUrl}${user?.user_profile_photo}`}
        />
        <IconButton
          disableRipple
          onKeyDown={() => {}}
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, color: "black", padding: "0", marginLeft: "0" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <p className="user_name">{convertedString(user?.first_name)}</p>
          <ArrowDropDownOutlined sx={{ marginBottom: "4px" }} />
        </IconButton>
        <Menu
          className="prflDrop"
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          onKeyDown={() => {}}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onKeyDown={() => {}}
            onClick={() => navigate("/UserProfile")}
            sx={{
              fontSize: 13,
              padding: "5px 13px 5px 7px",
              minHeight: "0px",
            }}
          >
            <ListItemIcon>
              <Avatar
                src={`${appUrl}${user?.user_profile_photo}`}
                sx={{ width: 20, height: 20 }}
              />
            </ListItemIcon>
            My Profile
          </MenuItem>
          <MenuItem
            onKeyDown={() => {}}
            onClick={() => navigate("/changeoldpassword")}
            sx={{
              fontSize: 13,
              padding: "5px 13px 5px 8px",
              minHeight: "0px",
            }}
          >
            <ListItemIcon>
              <LockReset fontSize="small" sx={{ fontSize: 17, mr: 0.7 }} />
            </ListItemIcon>
            Change Password
          </MenuItem>
          <MenuItem
            onKeyDown={() => {}}
            onClick={() => handleLogOut()}
            sx={{
              fontSize: 13,
              padding: "5px 13px 5px 8px",
              minHeight: "0px",
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" sx={{ fontSize: 17, mr: 0.7 }} />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Navbar;
