import * as Yup from "yup";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import CustomCancelSubmit from "../../Components/Molecules/CancelSubmit/CancelSubmit";
import CustomSelect from "../../Components/Atoms/CustomSelect/CustomSelect";
import {
  getAppProjectsIndexRequest,
  getApplicationsIndexRequest,
  getIssueTrackerIndexRequest,
  postIssueTrackerRequest,
  postIssueTrackerResClear,
} from "../../Redux/ProjectSaga/ProjectAction";
import { useDispatch, useSelector } from "react-redux";
import { convertedString } from "../../Utils/handles";
import toast from "react-hot-toast";

const ProjectIntegrateForm = ({ handleClose, openDialog }) => {
  const dispatch = useDispatch();

  const {
    applicationList,
    appProjectList,
    issueTrackerList,
    postIssueTrackerResponse,
  } = useSelector((state) => state.project);

  const validationArr = Yup.object().shape({
    integration_fk: Yup.string(),
    integrated_project_id: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      project_fk: openDialog?.id,
      integration_fk: "",
      integrated_project_id: "",
    },
    validationSchema: validationArr,
    onSubmit: (values) => {
      dispatch(postIssueTrackerRequest(values));
    },
  });

  useEffect(() => {
    dispatch(
      getApplicationsIndexRequest({
        filters: {},
        pagination_meta: {},
      })
    );
    dispatch(
      getIssueTrackerIndexRequest({
        filters: {
          project_fk: {
            type: "equal",
            value: openDialog?.id,
          },
        },
      })
    );
  }, []);

  // TOAST MESSAGE FOR CREATE
  useEffect(() => {
    if (postIssueTrackerResponse) {
      if (
        postIssueTrackerResponse?.message &&
        postIssueTrackerResponse?.success
      ) {
        toast.success(postIssueTrackerResponse?.message);
        handleClose();
      } else {
        toast.error(postIssueTrackerResponse?.message);
      }
    }
    return () => {
      dispatch(postIssueTrackerResClear());
    };
  }, [postIssueTrackerResponse]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <CustomSelect
              size="small"
              placeholder="Select Application"
              options={applicationList?.data
                ?.filter((value) => value.is_integrated === true)
                .map((values) => {
                  return {
                    value: values?.integration_data?.id,
                    name: convertedString(values?.name),
                    onClick: () => {
                      dispatch(
                        getAppProjectsIndexRequest({
                          application_id: values?.id,
                        })
                      );
                    },
                  };
                })}
              name="integration_fk"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.integration_fk}
              formik={formik}
            />
          </div>
          <div className="col-md-6">
            <CustomSelect
              size="small"
              placeholder="Select Project"
              options={appProjectList?.map((values) => {
                return {
                  value: values?.id,
                  name: values?.name,
                };
              })}
              name="integrated_project_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.integrated_project_id}
              formik={formik}
            />
          </div>
        </div>
        <CustomCancelSubmit
          cancelLabel="Cancel"
          submitLabel="Save"
          handleClose={handleClose}
          handleSubmit={formik.handleSubmit}
        />
      </form>
      <div className="tblWrpr">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Application name</th>
                <th>Project name</th>
              </tr>
            </thead>
            <tbody>
              {issueTrackerList?.data?.length > 0 ? (
                issueTrackerList?.data?.map((data, index) => (
                  <tr>
                    <td>
                      {convertedString(
                        data?.integration_fk?.application_fk?.name
                      )}
                    </td>
                    <td>{convertedString(data?.integrated_project_name)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="text-start py-4 fs-6">
                    <div className="text-start">
                      <p>No Data Found</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProjectIntegrateForm;
