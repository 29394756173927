import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { otpRequest } from "../../Redux/Register/RegisterActions";
import toast from "react-hot-toast";
import { handleToastResponse } from "../../Utils/handles";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();

  const { otpResponse } = useSelector((state) => state.register);

  useEffect(() => {
    dispatch(
      otpRequest({
        otp: urlSearchParams.get("otp"),
        email: urlSearchParams.get("email"),
      })
    );
  }, []);

  useEffect(() => {
    handleToastResponse(otpResponse);
    otpResponse?.success ? navigate("/login") : navigate("/register");
  }, [otpResponse]);

  return (
    <div>
      <p>Please Wait While your Email is being verified...</p>
    </div>
  );
};

export default VerifyEmail;
