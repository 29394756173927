import React from "react";
import { useDispatch } from "react-redux";
import { DELETE_RED_ICON } from "../../Utils/imageConstants";
import CustomButton from "../../Components/Atoms/CustomButton/CustomButton";
import "./Delete.css";

export default function Delete({
  handleClose,
  loading,
  payload,
  action,
  name,
}) {
  const dispatch = useDispatch();
  return (
    <div className="delete_form_container">
      <div className="delete_form_icon">
        <img src={DELETE_RED_ICON} alt="delete_icon" />
      </div>
      <div>
        <h2>Are you sure?</h2>
      </div>
      <div className="text-center">
        <h3 className="mb-1">{`Do you really want to delete this ${
          name || "record"
        }?`}</h3>
        <h3>This process cannot be undone.</h3>
      </div>
      <div className="d-flex gap-2 mb-4">
        <CustomButton
          size="small"
          label="Cancel"
          btnType="secondary"
          onClick={handleClose}
        />
        <CustomButton
          size="small"
          label={loading ? "Loading" : "Delete"}
          onClick={() => dispatch(action(payload))}
        />
      </div>
    </div>
  );
}
